import { Entity, EntityType } from "../core/Entities/Entity";
import { Scene } from "../core/Scene";
import pixi = require("pixi.js");
import { TextureManager } from "../core/TextureManager";
import { ConstrainBehavior } from "../Gameplay/Behaviors/ConstrainBehavior";
import { Vec2 } from "@h4x/common";
import { Globals } from "../Gameplay/Constants";

/** An entity that does the "impact" effect when damage is done */
export class SectorJumpSelectEntity extends Entity {
	public type = EntityType.JumpSelect;

	private graphicsSprite: pixi.Sprite;
	private iterator: number = 0;
	private constrainBehavior: ConstrainBehavior;
	private jumpActive: boolean = false;

	constructor(scene: Scene, x: number, y: number) {
		super(scene, x, y);

		this.graphicsSprite = pixi.Sprite.from(TextureManager.WarpHole);
		this.graphicsSprite.anchor.set(0.5);
		this.graphicsContainer.addChild(this.graphicsSprite);

		this.constrainBehavior = new ConstrainBehavior(this);
		this.constrainBehavior.execute([
			new Vec2(0, 0),
			new Vec2(Globals.WORLD_WIDTH, Globals.WORLD_HEIGHT)
		]);
	}

	public update(dt: number) {
		this.constrainBehavior.update(dt);

		this.iterator += this.jumpActive ? (dt * 5.0) : (dt * 1.5);

		this.setRotation(this.iterator);

		if (this.jumpActive === false) {
			let s = (Math.sin(this.iterator) / 4) + 1.0;
			this.setScale(s / 2);
		}
	}

	/** Set the color of the jump select entity */
	public setColor(color: number) {
		this.graphicsSprite.tint = color;
	}

	public async jumpIn(timeMS: number) {
		this.jumpActive = true;
		await this.eventSystem.fnTick((perc) => {
			this.setScale(perc / 2);
		}, timeMS);
		this.jumpActive = false;
	}

	public async jumpOut(timeMS: number) {
		this.jumpActive = true;
		await this.eventSystem.fnTick((perc) => {
			let pVal = 1 - perc;
			this.setScale(pVal / 2);
		}, timeMS);
		this.jumpActive = false;
	}

	public setTint(color: number) {
		this.graphicsSprite.tint = color;
	}

	public getTint(): number {
		return this.graphicsSprite.tint;
	}
}
