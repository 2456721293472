import { ScenesManager } from "../core/SceneManager";
import { GameSceneSector } from "../scenes/GameSceneSector";
import { GameSceneWarp } from "../scenes/GameSceneWarp";
import { GameSceneTutorial } from "../scenes/GameSceneTutorial";
import { ScoreManager } from "./ScoreManager";
import { GameSceneLoading } from "../scenes/GameSceneLoading";
import { GlobalCluster } from "../cluster/GlobalCluster";
import { ClusterUtils } from "../cluster/ClusterUtils";
import { BrowserRequest } from "../utils/BrowserRequest";
import { SoundInstance, SoundManager } from "../core/SoundManager";
import { GameSceneEnd } from "../scenes/GameSceneEnd";
import { DifficultyManager } from "./DifficultyManager";
import { StatsManager } from "./StatsManager";

export enum GameplayLogicState {
	INACTIVE,
	LOADING,
	SECTOR,
	// WARP, // When not making MVP
	DEATH,
	WIN,
	LEADERBOARD,
	TUTORIAL,
}

// Primary Class controlling the active gameplay and the storage of the current game state
export class GameplayLogicController {
	// Holds the current gameplay state
	private static currentState: GameplayLogicState = GameplayLogicState.INACTIVE;
	private static currentRound: number = 0;
	private static hasWonGame: boolean = false;
	private static inTutorial: boolean = false;

	// Cluster Properties
	public static readonly baseGlobalScore: number;
	public static readonly lastRoundGlobalScore: number;
	public static readonly cluster: GlobalCluster;
	public static readonly problemUUID: string;
	public static readonly bestScore = 0;
	public static readonly bestCentroids?: { type: boolean, values: number[] }[];

	public static backgroundMusic: SoundInstance;

	// Create a new scene
	public static async setupData() {
		let data = await ClusterUtils.loadData();
		(GameplayLogicController.problemUUID as any) = data.problemUUID;
		(GameplayLogicController.cluster as any) = new GlobalCluster(data.data, data.balancedMode);

		ClusterUtils.updateClusterCentroids(GameplayLogicController.cluster, data.centroids);
		GameplayLogicController.cluster.calculate();

		(this.bestScore as any) = 0;
		(this.bestCentroids as any) = undefined;
		(this.baseGlobalScore as any) = this.cluster.globalScore;
	}

	public static updateCluster(clusterX: number, clusterY: number) {
		// Calculate Global Score
		GameplayLogicController.cluster.calculate(clusterX);
		GameplayLogicController.cluster.calculate(clusterY);

		let score = GameplayLogicController.cluster.globalScore;
		if (GameplayLogicController.bestScore < score) {
			// console.log(GameplayLogicController.bestScore, "->", score);
			(GameplayLogicController.bestCentroids as any) = GameplayLogicController.cluster.getCentroids().map(x => { return { type: x.type, values: x.values }; });
			(GameplayLogicController.bestScore as any) = score;
		}
	}

	// Submit a result up to Synapse
	public static async submitResult(clusterX: number, clusterY: number) {
		if (GameplayLogicController.bestCentroids === undefined) {
			console.error("Missing best centroids");
			return;
		}

		let { body } = await BrowserRequest.post<{
			result: {
				balancedMode: boolean;
				problemUUID: string;
				data: { type: boolean; values: number[]; }[];
				centroids: { type: boolean; values: number[]; }[];
			};
		}>(ClusterUtils.ResultAPI, {
			body: {
				result: {
					problemUUID: this.problemUUID,
					data: {
						balancedMode: this.cluster.balancedMode,
						current: {
							x: clusterX,
							y: clusterY
						},
						result: this.bestScore,
						centroids: this.bestCentroids
					}
				},
				params: {
					score: this.bestScore
				}
			}
		});
		let result = body.result;
		console.log("savedData", result);

		/*let ret = await BrowserRequest.post<any>("https://synapse.dev.hewmen.com/api/v1/result", {
			body: {
				problemUUID: this.problemUUID,
				data: {
					balancedMode: this.cluster.balancedMode,
					current: {
						x: clusterX,
						y: clusterY
					},
					result: this.bestScore,
					centroids: this.bestCentroids
				}
			}
		});
		console.log("result submitted", ret);*/
		// return ret;
	}

	/** Called when the game should be started! */
	public static play(round: number = 0) {
		if (this.currentState !== GameplayLogicState.INACTIVE) {
			throw Error("GameplayLogicController:play - Game has already been started! Cannot call 'play' again!");
		}

		this.currentRound = round;
		this.inTutorial = false;
		this.hasWonGame = false;

		// Setup Managers
		StatsManager.newGame();
		ScoreManager.newGame(round);
		DifficultyManager.setDifficultyRound(round);

		if (this.cluster === undefined || round === 0) {
			this.setState(GameplayLogicState.LOADING);
			if (this.backgroundMusic !== undefined) {
				this.backgroundMusic.stop();
			}
			this.backgroundMusic = SoundManager.GameLoopMusic.play();
			this.backgroundMusic.loop(true);
		} else {
			this.setState(GameplayLogicState.SECTOR);
		}
	}

	public static playTutorial() {
		if (this.currentState !== GameplayLogicState.INACTIVE) {
			throw Error("GameplayLogicController:play - Game has already been started! Cannot call 'playTutorial' again!");
		}

		const tutorialRound = 0;
		this.currentRound = tutorialRound;
		this.inTutorial = true;

		// Setup Managers
		ScoreManager.newGame(tutorialRound);
		DifficultyManager.setDifficultyRound(tutorialRound);
		if (this.backgroundMusic !== undefined) {
			this.backgroundMusic.stop();
		}
		this.backgroundMusic = SoundManager.GameLoopMusic.play();
		this.backgroundMusic.loop(true);
		this.setState(GameplayLogicState.TUTORIAL);
	}

	/** Update the Gameplay Logic */
	public static update(dt: number) { }

	/** End the Current Game and Reset Data */
	public static end() {
		this.currentState = GameplayLogicState.INACTIVE;
		if (this.backgroundMusic !== undefined) {
			this.backgroundMusic.stop();
		}
	}

	/** Push the game to a new round (Starts Sector Game Immediately) */
	public static nextRound() {

		if (this.currentRound + 1 > this.getMaxRounds()) {
			this.setState(GameplayLogicState.WIN);
		} else {
			this.currentRound++;
			// Save off last score
			(this.lastRoundGlobalScore as any) = this.cluster.globalScore;

			ScoreManager.pushNewRound(this.currentRound);
			DifficultyManager.setDifficultyRound(this.currentRound);
			this.setState(GameplayLogicState.SECTOR);
		}
	}

	/** Return wether or not the player has one the game */
	public static hasWon(): boolean {
		return this.hasWonGame;
	}

	/** Return the current round we're on */
	public static getCurrentRound(): number {
		return this.currentRound;
	}

	/** Return the current round we're on */
	public static getMaxRounds(): number {
		return DifficultyManager.getMaxDifficultyRounds();
	}

	/** Return the current state of the gameplay logic controller */
	public static getState(): GameplayLogicState {
		return this.currentState;
	}

	/** Set Gameplay State */
	public static setState(state: GameplayLogicState) {
		// Make sure we aren't setting a state we're already on
		// TODO: This needs to not be checked for MVP
		// if (this.currentState === state) {
		// 	throw Error("GameplayLogicController:setState - Attempting to change state to a state we're already on!");
		// }

		this.currentState = state;

		switch (state) {
			case GameplayLogicState.LOADING:
				ScenesManager.changeScene(new GameSceneLoading());
				break;
			case GameplayLogicState.SECTOR:
				ScenesManager.changeScene(new GameSceneSector());
				break;
			case GameplayLogicState.WIN:
				this.hasWonGame = true;
				ScenesManager.changeScene(new GameSceneEnd());
				break;
			case GameplayLogicState.DEATH:
				this.hasWonGame = false;
				ScenesManager.changeScene(new GameSceneEnd());
				break;
			case GameplayLogicState.TUTORIAL:
				ScenesManager.changeScene(new GameSceneTutorial());
				break;
		}

		return true;
	}

	/** Return true if in the tutorial */
	public static isInTutorial(): boolean {
		return this.inTutorial;
	}
}
