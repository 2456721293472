import { Vec3 } from "./Vec3";
import { Mat4 } from "./Mat4";
export class Quat {

	constructor()
	constructor(x: number, y: number, z: number, w: number)
	constructor(public x = 0, public y = 0, public z = 0, public w = 1) { }

	public toString() {
		return `Quat<${this.x},${this.y},${this.z},${this.w}>`;
	}

	public set(x: number, y: number, z: number, w: number) {
		this.x = x; this.y = y; this.z = z; this.w = w; return this;
	}

	public reset(): Quat {
		this.x = 0;
		this.y = 0;
		this.z = 0;
		this.w = 1;
		return this;
	}

	public copy(dest?: Quat): Quat {
		if (dest === undefined) {
			dest = new Quat(this.x, this.y, this.z, this.w);
		} else {
			dest.x = this.x;
			dest.y = this.y;
			dest.z = this.z;
			dest.w = this.w;
		}

		return dest;
	}

	public roll(): number {
		let x = this.x,
			y = this.y,
			z = this.z,
			w = this.w;

		return Math.atan2(2.0 * (x * y + w * z), w * w + x * x - y * y - z * z);
	}

	public pitch(): number {
		let x = this.x,
			y = this.y,
			z = this.z,
			w = this.w;

		return Math.atan2(2.0 * (y * z + w * x), w * w - x * x - y * y + z * z);
	}

	public yaw(): number {
		return Math.asin(2.0 * (this.x * this.z - this.w * this.y));
	}

	public equals(other: Quat, threshold: number = 0.000001): boolean {
		if (Math.abs(this.x - other.x) > threshold) { return false; }
		if (Math.abs(this.y - other.y) > threshold) { return false; }
		if (Math.abs(this.z - other.z) > threshold) { return false; }
		if (Math.abs(this.w - other.w) > threshold) { return false; }

		return true;
	}

	public calculateW(): Quat {
		let x = this.x,
			y = this.y,
			z = this.z;

		this.w = -(Math.sqrt(Math.abs(1.0 - x * x - y * y - z * z)));

		return this;
	}

	public static dot(q1: Quat, q2: Quat): number {
		return q1.x * q2.x + q1.y * q2.y + q1.z * q2.z + q1.w * q2.w;
	}

	public inverse(): Quat {
		let dot = Quat.dot(this, this);

		if (!dot) {
			this.reset();
			return this;
		}

		let invDot = dot ? 1.0 / dot : 0;

		this.x *= -invDot;
		this.y *= -invDot;
		this.z *= -invDot;
		this.w *= invDot;

		return this;
	}

	public conjugate(): Quat {
		this.x *= -1;
		this.y *= -1;
		this.z *= -1;

		return this;
	}

	public length(): number {
		let x = this.x,
			y = this.y,
			z = this.z,
			w = this.w;

		return Math.sqrt(x * x + y * y + z * z + w * w);
	}

	public normalize(dest?: Quat): Quat {
		if (!dest) { dest = this; }

		let x = this.x,
			y = this.y,
			z = this.z,
			w = this.w;

		let length = Math.sqrt(x * x + y * y + z * z + w * w);

		if (!length) {
			dest.x = 0;
			dest.y = 0;
			dest.z = 0;
			dest.w = 0;

			return dest;
		}

		length = 1 / length;

		dest.x = x * length;
		dest.y = y * length;
		dest.z = z * length;
		dest.w = w * length;

		return dest;
	}

	public add(other: Quat): Quat {
		this.x += other.x;
		this.y += other.y;
		this.z += other.z;
		this.w += other.w;

		return this;
	}

	public multiply(other: Quat): Quat {
		let q1x = this.x,
			q1y = this.y,
			q1z = this.z,
			q1w = this.w;

		let q2x = other.x,
			q2y = other.y,
			q2z = other.z,
			q2w = other.w;

		this.x = q1x * q2w + q1w * q2x + q1y * q2z - q1z * q2y;
		this.y = q1y * q2w + q1w * q2y + q1z * q2x - q1x * q2z;
		this.z = q1z * q2w + q1w * q2z + q1x * q2y - q1y * q2x;
		this.w = q1w * q2w - q1x * q2x - q1y * q2y - q1z * q2z;

		return this;
	}

	public multiplyVec3(vector: Vec3, dest?: Vec3): Vec3 {
		if (!dest) { dest = new Vec3(); }

		let x = vector.x,
			y = vector.y,
			z = vector.z;

		let qx = this.x,
			qy = this.y,
			qz = this.z,
			qw = this.w;

		let ix = qw * x + qy * z - qz * y,
			iy = qw * y + qz * x - qx * z,
			iz = qw * z + qx * y - qy * x,
			iw = -qx * x - qy * y - qz * z;

		dest.x = ix * qw + iw * -qx + iy * -qz - iz * -qy;
		dest.y = iy * qw + iw * -qy + iz * -qx - ix * -qz;
		dest.z = iz * qw + iw * -qz + ix * -qy - iy * -qx;

		return dest;
	}

	public toMat4(dest?: Mat4): Mat4 {
		if (!dest) { dest = new Mat4(); }

		let x = this.x,
			y = this.y,
			z = this.z,
			w = this.w,

			x2 = x + x,
			y2 = y + y,
			z2 = z + z,

			xx = x * x2,
			xy = x * y2,
			xz = x * z2,
			yy = y * y2,
			yz = y * z2,
			zz = z * z2,
			wx = w * x2,
			wy = w * y2,
			wz = w * z2;

		dest.set([
			1 - (yy + zz), xy + wz, xz - wy, 0,
			xy - wz, 1 - (xx + zz), yz + wx, 0,
			xz + wy, yz - wx, 1 - (xx + yy), 0,
			0, 0, 0, 1
		]);

		return dest;
	}

	public static sum(q1: Quat, q2: Quat, dest?: Quat): Quat {
		if (!dest) { dest = new Quat(); }

		dest.x = q1.x + q2.x;
		dest.y = q1.y + q2.y;
		dest.z = q1.z + q2.z;
		dest.w = q1.w + q2.w;

		return dest;
	}

	public static product(q1: Quat, q2: Quat, dest?: Quat): Quat {
		if (!dest) { dest = new Quat(); }

		let q1x = q1.x;
		let q1y = q1.y;
		let q1z = q1.z;
		let q1w = q1.w;

		let q2x = q2.x;
		let q2y = q2.y;
		let q2z = q2.z;
		let q2w = q2.w;

		dest.x = q1x * q2w + q1w * q2x + q1y * q2z - q1z * q2y;
		dest.y = q1y * q2w + q1w * q2y + q1z * q2x - q1x * q2z;
		dest.z = q1z * q2w + q1w * q2z + q1x * q2y - q1y * q2x;
		dest.w = q1w * q2w - q1x * q2x - q1y * q2y - q1z * q2z;

		return dest;
	}

	public static cross(q1: Quat, q2: Quat, dest?: Quat): Quat {
		if (!dest) { dest = new Quat(); }

		let q1x = q1.x;
		let q1y = q1.y;
		let q1z = q1.z;
		let q1w = q1.w;

		let q2x = q2.x;
		let q2y = q2.y;
		let q2z = q2.z;
		let q2w = q2.w;

		dest.x = q1w * q2z + q1z * q2w + q1x * q2y - q1y * q2x;
		dest.y = q1w * q2w - q1x * q2x - q1y * q2y - q1z * q2z;
		dest.z = q1w * q2x + q1x * q2w + q1y * q2z - q1z * q2y;
		dest.w = q1w * q2y + q1y * q2w + q1z * q2x - q1x * q2z;

		return dest;
	}

	public static shortMix(q1: Quat, q2: Quat, time: number, dest?: Quat): Quat {
		if (!dest) { dest = new Quat(); }

		if (time <= 0.0) {
			q1.copy(dest);

			return dest;
		} else if (time >= 1.0) {
			q2.copy(dest);

			return dest;
		}

		let cos = Quat.dot(q1, q2),
			q2a = q2.copy();

		if (cos < 0.0) {
			q2a.inverse();
			cos = -cos;
		}

		let k0: number,
			k1: number;

		if (cos > 0.9999) {
			k0 = 1 - time;
			k1 = 0 + time;
		} else {
			let sin: number = Math.sqrt(1 - cos * cos);
			let angle: number = Math.atan2(sin, cos);

			let oneOverSin: number = 1 / sin;

			k0 = Math.sin((1 - time) * angle) * oneOverSin;
			k1 = Math.sin((0 + time) * angle) * oneOverSin;
		}

		dest.x = k0 * q1.x + k1 * q2a.x;
		dest.y = k0 * q1.y + k1 * q2a.y;
		dest.z = k0 * q1.z + k1 * q2a.z;
		dest.w = k0 * q1.w + k1 * q2a.w;

		return dest;
	}

	public static mix(q1: Quat, q2: Quat, time: number, dest?: Quat): Quat {
		if (!dest) { dest = new Quat(); }

		let cosHalfTheta = q1.x * q2.x + q1.y * q2.y + q1.z * q2.z + q1.w * q2.w;

		if (Math.abs(cosHalfTheta) >= 1.0) {
			q1.copy(dest);

			return dest;
		}

		let halfTheta = Math.acos(cosHalfTheta),
			sinHalfTheta = Math.sqrt(1.0 - cosHalfTheta * cosHalfTheta);

		if (Math.abs(sinHalfTheta) < 0.001) {
			dest.x = q1.x * 0.5 + q2.x * 0.5;
			dest.y = q1.y * 0.5 + q2.y * 0.5;
			dest.z = q1.z * 0.5 + q2.z * 0.5;
			dest.w = q1.w * 0.5 + q2.w * 0.5;

			return dest;
		}

		let ratioA = Math.sin((1 - time) * halfTheta) / sinHalfTheta,
			ratioB = Math.sin(time * halfTheta) / sinHalfTheta;

		dest.x = q1.x * ratioA + q2.x * ratioB;
		dest.y = q1.y * ratioA + q2.y * ratioB;
		dest.z = q1.z * ratioA + q2.z * ratioB;
		dest.w = q1.w * ratioA + q2.w * ratioB;

		return dest;
	}

	private static readonly degToRad = Math.PI / 180;
	private static readonly radToDeg = 180 / Math.PI;

	/** Get quaternion from the supplied axis and angle (in degrees). */
	public static fromAxis(axis: Vec3, angle: number, dest?: Quat): Quat {
		if (!dest) { dest = new Quat(); }

		angle *= 0.5;
		let sin = Math.sin(angle * Quat.degToRad);

		dest.x = axis.x * sin;
		dest.y = axis.y * sin;
		dest.z = axis.z * sin;
		dest.w = Math.cos(angle * Quat.degToRad);

		return dest;
	}

	/** Get quaternion from the supplied euler angles (in degrees) with rotation order XYZ. */
	public static fromRotationXYZ(angleX: number, angleY: number, angleZ: number, dest?: Quat) {
		if (!dest) { dest = new Quat(); }

		angleX = angleX * Quat.degToRad;
		angleY = angleY * Quat.degToRad;
		angleZ = angleZ * Quat.degToRad;

		let sx = Math.sin(angleX * 0.5);
		let cx = Math.cos(angleX * 0.5);
		let sy = Math.sin(angleY * 0.5);
		let cy = Math.cos(angleY * 0.5);
		let sz = Math.sin(angleZ * 0.5);
		let cz = Math.cos(angleZ * 0.5);

		let cycz = cy * cz;
		let sysz = sy * sz;
		let sycz = sy * cz;
		let cysz = cy * sz;
		dest.w = (cx * cycz) - (sx * sysz);
		dest.x = (sx * cycz) + (cx * sysz);
		dest.y = (cx * sycz) - (sx * cysz);
		dest.z = (cx * cysz) + (sx * sycz);

		return dest;
	}

	/** Get quaternion from the supplied euler angles (in degrees) with rotation order ZYX. */
	public static fromRotationZYX(angleZ: number, angleY: number, angleX: number, dest?: Quat) {
		if (!dest) { dest = new Quat(); }

		angleX = angleX * Quat.degToRad;
		angleY = angleY * Quat.degToRad;
		angleZ = angleZ * Quat.degToRad;

		let sx = Math.sin(angleX * 0.5);
		let cx = Math.cos(angleX * 0.5);
		let sy = Math.sin(angleY * 0.5);
		let cy = Math.cos(angleY * 0.5);
		let sz = Math.sin(angleZ * 0.5);
		let cz = Math.cos(angleZ * 0.5);

		let cycz = cy * cz;
		let sysz = sy * sz;
		let sycz = sy * cz;
		let cysz = cy * sz;
		dest.w = (cx * cycz) + (sx * sysz);
		dest.x = (sx * cycz) - (cx * sysz);
		dest.y = (cx * sycz) + (sx * cysz);
		dest.z = (cx * cysz) - (sx * sycz);

		return dest;
	}

	/** Get quaternion from the supplied euler angles (in degrees) with rotation order YXZ. */
	public static fromRotationYXZ(angleY: number, angleX: number, angleZ: number, dest?: Quat): Quat {
		if (!dest) { dest = new Quat(); }

		angleX = angleX * Quat.degToRad;
		angleY = angleY * Quat.degToRad;
		angleZ = angleZ * Quat.degToRad;

		let sx = Math.sin(angleX * 0.5);
		let cx = Math.cos(angleX * 0.5);
		let sy = Math.sin(angleY * 0.5);
		let cy = Math.cos(angleY * 0.5);
		let sz = Math.sin(angleZ * 0.5);
		let cz = Math.cos(angleZ * 0.5);

		let x = cy * sx;
		let y = sy * cx;
		let z = sy * sx;
		let w = cy * cx;
		dest.x = (x * cz) + (y * sz);
		dest.y = (y * cz) - (x * sz);
		dest.z = (w * sz) - (z * cz);
		dest.w = (w * cz) + (z * sz);

		return dest;
	}


}



