import { Behavior } from "./Behavior";
import { DynamicEntity } from "../../core/Entities/DynamicEntity";
import { DartBehavior, DartBehaviorState } from "./DartBehavior";
import { ShakeBehavior } from "./ShakeBehavior";
import { DifficultyManager } from "../DifficultyManager";
import { EventSystem } from "../../core/EventSystem";
import { SoundManager } from "../../core/SoundManager";

/** Enemy Dart Behavior */
export class EnemyBerzerkerBehavior extends Behavior<DynamicEntity, DynamicEntity> {
	private baseDartBehavior: DartBehavior;
	private baseShakeBehavior: ShakeBehavior;

	private repeat = false;
	private target: DynamicEntity;
	private timeBetweenAttacks: number;
	private timeRemaining = 0;

	constructor(owner: DynamicEntity) {
		super(owner);

		this.baseDartBehavior = new DartBehavior(owner);
		this.baseShakeBehavior = new ShakeBehavior(owner);
		this.timeBetweenAttacks = DifficultyManager.getBerzerkerAttackDelay();

		this.baseDartBehavior.setDartSpeed(DifficultyManager.getBerzerkerDartSpeed());
	}

	public update(dt: number) {
		if (this.active === false) {
			return;
		}

		this.baseDartBehavior.update(dt);
		this.baseShakeBehavior.update(dt);

		if (this.repeat === false || this.target === undefined) {
			return;
		}

		this.timeRemaining -= dt;

		if (this.timeRemaining <= 0) {
			this.runAttackOnce();
			this.timeRemaining = this.timeBetweenAttacks;
		}
	}

	private async runAttackOnce() {
		if (this.active) {

			// Play Sound
			SoundManager.BerzerkerShake.play();

			let targetPos = this.target.getPosition(); // Get the position of the target before we do the full attack
			let shakeTime = DifficultyManager.getBerzerkerShakeTime();
			this.baseShakeBehavior.execute(shakeTime + 0.5);

			let timeOut = shakeTime * 1000;
			const timeInterval = 100;

			await this.owner.eventSystem.fnInterval(() => {
				if (this.active === false) {
					return;
				}

				timeOut -= timeInterval;
				if (timeOut <= 0) {
					return EventSystem.Break;
				}

				return;

			}, [], timeInterval);

			SoundManager.BerzerkerDash.play();
			this.baseDartBehavior.execute(targetPos);
		}
	}

	public execute(target: DynamicEntity) {
		this.target = target;
		this.timeRemaining = 0;

		if (this.repeat === false) {
			this.runAttackOnce();
		}
	}

	/** How long between each attack */
	public setRepeatAttack(repeat: boolean) {
		this.repeat = repeat;
	}

	/** Is this enemy currently darting */
	public isBerzerkActive(): boolean {
		let state = this.baseDartBehavior.getState();
		return (state === DartBehaviorState.DART || state === DartBehaviorState.SLOW);
	}
}
