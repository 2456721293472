import { Scene } from "../../core/Scene";
import { App } from "../../app";
import { EnvironmentContainer } from "../../containers/EnvironmentContainer";
import { Colors } from "../../utils/Colors";
import { WarpHoleEntity } from "../../entity/WarpHoleEntity";
import { Globals } from "../../Gameplay/Constants";
import { UIBorder, UIBorderMode } from "../../core/UI/UIBorder";
import { InputManager } from "../../core/InputManager";

export class WarpHoleScene extends Scene {

	public warpLoop: WarpHoleEntity;

	constructor() {
		super();

		App.onResizeEvent.addCallback(() => {
		});
	}

	public async init() {
		this.backgroundContainer.addChild(new EnvironmentContainer());
		this.uiManager.add(new UIBorder({ borderColor: Colors.Orange, borderWidth: 5, borderMode: UIBorderMode.Inner }));

		this.runOnce();
	}

	public keyUp(key: KeyboardEvent) {
		console.log(key.code);
		if (key.keyCode === InputManager.Space) {
			this.runOnce();
		}
	}

	private runOnce() {
		if (this.warpLoop) {
			this.warpLoop.removeFromWorld();
		}

		this.warpLoop = new WarpHoleEntity(this, Globals.WORLD_WIDTH / 2, Globals.WORLD_HEIGHT / 2, 2.5);
		this.addToWorld(this.warpLoop);
		this.entityManager.add(this.warpLoop);
	}
}
