
export interface GetParams {
	qs?: { [K: string]: any };
	headers?: { [K: string]: string };
}

export interface PostParams {
	body?: { [K: string]: any } | any[] | string;
	qs?: { [K: string]: any };
	headers?: { [K: string]: string };
}

export interface RequestParams {
	contentType?: string;
	body?: { [K: string]: any } | any[] | string;
	qs?: { [K: string]: any };
	headers?: { [K: string]: string };
}

export class BrowserRequest {
	public static get<T>(url: string, data: GetParams) {
		return this.request<T>("GET", url, data);
	}

	public static post<T>(url: string, data: PostParams) {
		return this.request<T>("POST", url, data);
	}

	private static sanityCheck(method: string, url: string, data: RequestParams) {
		if (method === "GET" && (<any>data).body !== undefined) {
			console.warn("Body is not supported in GET requests");
			(<any>data).body = undefined;
		}
	}

	public static request<T>(method: string, url: string, data: RequestParams) {
		return new Promise<{ error?: any, response: Response, body: T }>((resolve, reject) => {
			this.sanityCheck(method, url, data);

			if (data.qs) {
				let query = Object.keys(data.qs).map(k => encodeURIComponent(k) + "=" + encodeURIComponent(data.qs![k])).join("&");
				url = url + "?" + query;
			}

			let contentType;
			let body = undefined;
			if (data.contentType !== undefined) {
				contentType = data.contentType;
				body = data.body;
			} else if (data.body !== undefined) { // try to figure out the content type
				contentType = "plain/text";
				body = data.body;
				if (body !== undefined) {
					if (typeof (body) === "object") {
						if (Array.isArray(body)) {
							contentType = "application/json";
							body = JSON.stringify(body);
						} else {
							contentType = "application/json";
							body = JSON.stringify(body);
						}
					} else if (typeof (body) === "string") {
						if (body.startsWith("{") || body.endsWith("}")) {
							// JSON + warning
							contentType = "application/json";
							console.warn("Got JSON string without contentType to go with it!");
						} else {

						}
					}
				}
			}

			let headers = Object.assign({
				"user-agent": "AsyncAPI/0.1 H4X"
			}, data.headers);

			if (contentType) {
				headers["content-type"] = contentType;
			}

			// "content-type": "application/json"

			let requestData: RequestInit = {
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				credentials: "same-origin", // include, same-origin, *omit
				headers: headers,
				method: method, // *GET, POST, PUT, DELETE, etc.
				mode: "cors", // no-cors, cors, *same-origin
				redirect: "follow", // manual, *follow, error
				referrer: "no-referrer", // *client, no-referrer
			};

			if (body) {
				requestData.body = <any>body;
			}

			return fetch(url, requestData).then(async (response) => {
				let error = undefined;
				if (response.status < 200 || response.status >= 300) {
					error = new Error("Status code: " + response.status);
				}
				let responseType = response.headers.get("content-type");
				let responseBody;
				if (responseType && responseType.indexOf("application/json") === 0) {
					responseBody = await response.json();
				} else {
					responseBody = await response.text();
					if (responseBody.startsWith("{") && responseBody.endsWith("}")) {
						responseBody = JSON.parse(responseBody);
					}
				}

				resolve({ error: error, response: response, body: responseBody });
			}).catch((err) => {
				console.error(err);
				reject({ error: err });
			}); // parses response to JSON
		});

	}
}
