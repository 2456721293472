import { Behavior } from "./Behavior";
import pixi = require("pixi.js");
import { Vec2, Utils } from "@h4x/common";
import { Entity } from "../../core/Entities/Entity";

class ShakeChild {
	public pixiObj: pixi.DisplayObject;
	public originalPosition: {
		x: number,
		y: number
	} = { x: 0, y: 0 };
}

/** Shake the graphics object of the entity for a given amount of time */
export class ShakeBehavior extends Behavior<Entity, number> {
	private timeToShake = 5;
	private timeToLive = 0;
	private intensity = 5;
	private isShaking = false;
	private frequency = 0.01;
	private falloff = 0.0;
	private timeSinceLastShake = 0;

	public shakeChildren: ShakeChild[] = [];

	constructor(owner: Entity) {
		super(owner);
	}

	public update(dt: number): void {
		if (this.isShaking === false) {
			return;
		} else if (this.timeToLive <= 0) {
			this.reset();
			return;
		} else {
			this.timeToLive -= dt;
		}

		this.timeSinceLastShake += dt;

		if (this.timeSinceLastShake >= this.frequency) {
			let appliedFalloff = Math.max((this.timeToLive / this.timeToShake) * (1 - this.falloff), 0);
			let offset = new Vec2(Utils.randomInt(-1000, 1000), Utils.randomInt(-1000, 1000)).normalize().multiplyScalar(this.intensity * appliedFalloff);

			// Shake, Shake, Shake!
			for (let c of this.shakeChildren) {
				c.pixiObj.x = c.originalPosition.x + offset.x;
				c.pixiObj.y = c.originalPosition.y + offset.y;
			}

			this.timeSinceLastShake = 0;
		}
	}

	public execute(timeToShake: number): void {
		this.timeToShake = timeToShake;
		this.timeToLive = this.timeToShake;
		this.timeSinceLastShake = 0;
		this.isShaking = true;

		// Clear old value
		this.shakeChildren = [];

		let numChildren = this.owner.getGraphicsContainer().children.length;

		for (let i = 0; i < numChildren; i++) {
			let child = this.owner.getGraphicsContainer().getChildAt(i);

			let newChild = new ShakeChild();
			newChild.pixiObj = child;
			newChild.originalPosition.x = child.x;
			newChild.originalPosition.y = child.y;

			this.shakeChildren.push(newChild);
		}
	}

	/** Stop all shaking */
	private reset() {
		this.isShaking = false;
		this.timeSinceLastShake = 0;

		for (let shakeChild of this.shakeChildren) {
			shakeChild.pixiObj.x = shakeChild.originalPosition.x;
			shakeChild.pixiObj.y = shakeChild.originalPosition.y;
		}

		this.shakeChildren = [];
	}

	/** Set the intensity of the shaking */
	public setIntensity(intensity: number) {
		this.intensity = intensity;
	}

	/** Set the shake frequency (lower numbers mean the shake is applied more often) */
	public setFrequency(frequency: number) {
		this.frequency = frequency;
	}

	/** The falloff amount for the shake to "slow-down" */
	public setFalloff(falloff: number) {
		this.falloff = falloff;
	}
}
