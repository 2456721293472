import * as pixi from "pixi.js";
import { UIElement, UIElementProperties } from "../UIElement";
import { UIManager } from "../UIManager";
import { UISprite, UISpriteProperties } from "./UISprite";
import { BasicEvent } from "@h4x/common";

export class UISpriteButton extends UISprite {

	public readonly onClick = new BasicEvent<() => void>();

	constructor(texture: pixi.Texture, properties?: UISpriteProperties) {
		super(texture, properties);
		this.internal = pixi.Sprite.from(texture);

		this.internal.interactive = true;
		this.internal.buttonMode = true;
		this.internal.on("mousedown", this.handleMouseDown, this);
		this.internal.on("mouseup", this.handleMouseUp, this);
		this.internal.on("mouseupoutside", this.handleMouseUpOutside, this);
	}

	protected initialize(parent: UIElement | UIManager) {
		super.initialize(parent);

	}

	private mouseDown = false;
	private handleMouseDown() {
		this.mouseDown = true;
	}

	private handleMouseUp() {
		if (this.mouseDown === true) {
			this.mouseDown = false;
			this.onClick.execute();
		}
	}

	private handleMouseUpOutside() {
		this.mouseDown = false;
	}
}
