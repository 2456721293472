import { Behavior } from "./Behavior";
import { Utils } from "@h4x/common";
import pixi = require("pixi.js");
import { TextureManager } from "../../core/TextureManager";
import { Entity } from "../../core/Entities/Entity";

/** Have an Entity dart to a specific point */
export class VanishBehavior extends Behavior<Entity, boolean> {

	private burstGraphic: pixi.Sprite;
	private vanishElapsedTime: number = 0;
	private vanishActive = false;
	private vanishTime = 0.5;
	private appear = false;
	private ownerFade = true;

	constructor(owner: Entity) {
		super(owner);

		this.burstGraphic = pixi.Sprite.from(TextureManager.Burst);
		this.burstGraphic.anchor.set(0.5);
		this.burstGraphic.alpha = 0;
		this.burstGraphic.scale.set(0);
		this.owner.getScene().gameWorldContainer.addChild(this.burstGraphic);
	}

	public update(dt: number): void {
		if (this.vanishActive) {
			let ownerPos = this.owner.getPosition();
			this.burstGraphic.position.set(ownerPos.x, ownerPos.y);

			this.vanishElapsedTime = Math.min(this.vanishElapsedTime + dt, this.vanishTime);

			let i = Utils.remapRange(this.vanishElapsedTime, 0, this.vanishTime, 0, 1);
			let val = Math.sin(i * Math.PI);

			// Handle the burst graphic
			this.burstGraphic.scale.set(val);
			this.burstGraphic.alpha = val;

			// Handle Player Vanish/Appear
			if (this.ownerFade && i >= 0.5) {
				let pVal = Math.sin(i * Math.PI);

				if (this.appear) {
					this.owner.setScale(1 - pVal);
					this.owner.setAlpha(1 - pVal);
				} else {
					this.owner.setScale(pVal);
					this.owner.setAlpha(pVal);
				}
			}

			if (this.vanishElapsedTime === this.vanishTime) {
				this.vanishActive = false;
			}

		}
	}

	public execute(appear: boolean): void {
		this.vanishActive = true;
		this.vanishElapsedTime = 0;
		this.appear = appear;
		this.owner.setAlpha(appear ? 0 : 1);
	}

	/** Set the time it takes for the vanish to happen */
	public setVanishTime(timeSec: number) {
		this.vanishTime = timeSec;
	}

	/** Get the time it takes for the vanish to happen */
	public getVanishTime(): number {
		return this.vanishTime;
	}

	/** Set a flag to override the fading of the owner */
	public setOwnerFade(fade: boolean) {
		this.ownerFade = fade;
	}
}
