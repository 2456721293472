import { Scene } from "../../core/Scene";
import { App } from "../../app";
import pixi = require("pixi.js");
import { Colors } from "../../utils/Colors";
import { Globals } from "../../Gameplay/Constants";

export class ScreenScene extends Scene {
	constructor() {
		super();
	}

	protected onResize(appWidth: number, appHeight: number) {
		const offset = 50;

		this.gameWorldContainer.x = offset;
		this.gameWorldContainer.y = offset;

		this.gameWorldContainer.scale.x = (appWidth - (offset * 2)) / Globals.WORLD_WIDTH;
		this.gameWorldContainer.scale.y = (appHeight - (offset * 2)) / Globals.WORLD_HEIGHT;
	}

	public async init() {
		// Background
		let background = new pixi.Graphics();
		background.beginFill(Colors.Black);
		background.drawRect(0, 0, App.width, App.height);
		background.endFill();
		this.backgroundContainer.addChild(background);

		let backgroundBorder = new pixi.Graphics();
		backgroundBorder.lineStyle(11, Colors.White);
		backgroundBorder.drawRect(0, 0, App.width, App.height);
		this.backgroundContainer.addChild(backgroundBorder);

		this.onResize(App.width, App.height);

		// Draw Horizontal Lines
		let lineSpace = 50;
		for (let i = 0; i < Globals.WORLD_HEIGHT; i += lineSpace) {
			let l = new pixi.Graphics();
			l.lineStyle(3, Colors.LightBlue);
			l.moveTo(0, i);
			l.lineTo(Globals.WORLD_WIDTH, i);
			this.gameWorldContainer.addChild(l);
		}

		for (let i = 0; i < Globals.WORLD_WIDTH; i += lineSpace) {
			let l = new pixi.Graphics();
			l.lineStyle(3, Colors.LightBlue);
			l.moveTo(i, 0);
			l.lineTo(i, Globals.WORLD_HEIGHT);
			this.gameWorldContainer.addChild(l);
		}

		let gameBorder = new pixi.Graphics();
		gameBorder.lineStyle(3, Colors.Red);
		gameBorder.drawRect(0, 0, Globals.WORLD_WIDTH, Globals.WORLD_HEIGHT);
		this.gameWorldContainer.addChild(gameBorder);
	}

	public onMouseMove(x: number, y: number) {
	}
}
