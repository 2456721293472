import { Scene } from "../../core/Scene";
import { App } from "../../app";
import { EnvironmentContainer } from "../../containers/EnvironmentContainer";
import { Colors } from "../../utils/Colors";
import { WarpBackgroundEntity } from "../../entity/WarpBackgroundEntity";
import { Globals } from "../../Gameplay/Constants";
import { UIBorder, UIBorderMode } from "../../core/UI/UIBorder";

export class WarpTestScene extends Scene {
	constructor() {
		super();

		App.onResizeEvent.addCallback(() => {
		});
	}

	public async init() {
		let environment = new EnvironmentContainer();
		this.backgroundContainer.addChild(environment);

		// Orange Border
		this.uiManager.add(new UIBorder({ borderColor: Colors.Orange, borderWidth: 5, borderMode: UIBorderMode.Inner }));

		let wbe = new WarpBackgroundEntity(this, Globals.WORLD_WIDTH / 2, Globals.WORLD_HEIGHT / 2, Colors.Green);
		this.addToWorld(wbe);
		this.entityManager.add(wbe);
	}
}
