import { DynamicEntity } from "../../core/Entities/DynamicEntity";
import { Vec2 } from "@h4x/common";
import Matter = require("matter-js");
import { Behavior } from "./Behavior";

// The distance to start the "approach" logic and slow down
const APPROACH_DIST = 300;

export class NavigateToBehavior extends Behavior<DynamicEntity, Vec2> {
	private navTo: Vec2 = new Vec2();
	private approach: boolean = true;
	private speedOverrideFlag: boolean = false;
	private speedOverride: number = 0;
	private turnRateOverride: number = 0;
	private turnRateOverrideFlag: boolean = false;

	constructor(owner: DynamicEntity) {
		super(owner);
	}

	public update(dt: number): void {
		if (this.active === false) {
			return;
		}

		let speedToUse = this.speedOverrideFlag ? this.speedOverride : this.owner.getMaxSpeed();

		let physicsBody = this.owner.physicsBody;

		// Distance/Direction vector between where the ship is, and where it's going
		let distVec = Matter.Vector.sub(this.navTo, physicsBody.position);
		let distance = Matter.Vector.magnitude(distVec);

		// Update Rotation
		let dir = Matter.Vector.normalise(distVec);

		// Calcualte Foward Vector of Physics body
		let forward = Matter.Vector.rotate(Matter.Vector.create(0, -1), physicsBody.angle);

		// Threshold the rotation if the distance is under a certain threshold
		if (distance <= 2) {
			Matter.Body.setAngularVelocity(physicsBody, 0);
		} else {
			// Calculate the shortest angle between 2 vectors
			let angle = Math.atan2(dir.y, dir.x) - Math.atan2(forward.y, forward.x);

			// Apply thresholds to the angle
			if (angle > Math.PI) {
				angle -= (2 * Math.PI);
			} else if (angle < -Math.PI) {
				angle += (2 * Math.PI);
			}

			let turnRate = this.turnRateOverrideFlag ? this.turnRateOverride : this.owner.getTurnRate();

			// set an angular velocity to get the physics to turn to that direction at a certain rate
			Matter.Body.setAngularVelocity(physicsBody, angle * turnRate * dt);

			// Determine what hte max speed should be based on how large the angle is to slow it down when it has to make a larger turn
			speedToUse = (1 - (Math.abs(angle) / Math.PI)) * speedToUse;
		}

		// Update Position
		let speed = speedToUse;

		// If the distance is less than half a pixel, then just snap to this position
		if (distance <= 0.5) {
			Matter.Body.setPosition(physicsBody, this.navTo);
		} else {
			// If this distance is within a certain amount, slow down the ship
			if (this.approach) {
				if (distance <= APPROACH_DIST) {
					speed = (distance / APPROACH_DIST) * speedToUse;
				}
			}

			// Calculate a velocity  to push the ship in it's forward direction
			let velVec = Matter.Vector.mult(forward, speed * dt);

			// Set the velocity on the physics body
			Matter.Body.setVelocity(physicsBody, velVec);
		}
	}

	public execute(data: Vec2): void {
		this.navTo.x = data.x;
		this.navTo.y = data.y;
	}

	/** Flag to tell the ship to slow down during it's approach to the target or not */
	public setApproach(approach: boolean) {
		this.approach = approach;
	}

	public overrideSpeed(speed: number) {
		this.speedOverride = speed;
		this.speedOverrideFlag = true;
	}

	public overrideTurnRate(rate: number) {
		this.turnRateOverride = rate;
		this.turnRateOverrideFlag = true;
	}
}
