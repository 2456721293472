import * as pixi from "pixi.js";
import { CRTFilter } from "@pixi/filter-crt";
import { AdvancedBloomFilter } from "@pixi/filter-advanced-bloom";
import { Scene } from "./Scene";
import { Colors } from "../utils/Colors";
import { App } from "../app";

export class ScenesManager {
	// Current Scene being displayed to the user
	private static currentScene: Scene;

	// PixiJS
	public static app: pixi.Application;

	// Save last frame time for delta-time calculations
	public static lastFrameTime = -1;

	public static crtFilter: CRTFilter;
	public static bloomFilter: AdvancedBloomFilter;
	public static fxEnabled: boolean = true;

	public static create() {
		if (ScenesManager.app) { return this; }

		// Setup with a magenta background to make sure we don't have missing graphics
		ScenesManager.app = new pixi.Application({ resolution: 1/*window.devicePixelRatio*/, backgroundColor: Colors.Black, antialias: true });
		document.getElementById("app")!.appendChild(ScenesManager.app.view);

		ScenesManager.lastFrameTime = Date.now();

		window.addEventListener("resize", resize);
		function resize() {
			const parent = ScenesManager.app.view.parentNode! as HTMLDivElement;
			ScenesManager.app.renderer.resize(parent.clientWidth, parent.clientHeight);
			App.onResizeEvent.execute(parent.clientWidth, parent.clientHeight);
		}
		resize();

		ScenesManager.crtFilter = new CRTFilter({
			curvature: .5,
			lineWidth: 3,
			lineContrast: .25,
			noise: 0.0,
			noiseSize: 1.0,
			seed: 0.0,
			vignetting: 0.0,
			vignettingAlpha: .5,
			vignettingBlur: 0.3
		});

		ScenesManager.bloomFilter = new AdvancedBloomFilter({
			threshold: .24,
			bloomScale: 1,
			brightness: 1.5,
			blur: 4,
			quality: 4,
		});

		return this;
	}

	/** Called to update the scene manager and the current active scene */
	public static update(dt: number, showPhysics: boolean = false) {
		if (ScenesManager.currentScene !== undefined) {
			(ScenesManager.currentScene as any).updateScene(dt);
			if (showPhysics) {
				ScenesManager.currentScene.drawPhysics();
			}

			ScenesManager.app.renderer.render(ScenesManager.currentScene.stage);
		}

		// ScenesManager.filter.time += dt*3;
	}

	/** Instantly change a Scene */
	public static async changeScene(scene: Scene) {
		await scene.load();
		if (ScenesManager.currentScene !== undefined) {
			(ScenesManager.currentScene as any).deactivateScene();
		}
		ScenesManager.currentScene = scene;
		(ScenesManager.currentScene as any).activateScene();

		const parent = ScenesManager.app.view.parentNode! as HTMLDivElement;
		App.onResizeEvent.execute(parent.clientWidth, parent.clientHeight);

		if (ScenesManager.fxEnabled) {
			ScenesManager.currentScene.gameWorldContainerOuter.filters = [ScenesManager.bloomFilter, ScenesManager.crtFilter];
		} else {
			ScenesManager.currentScene.gameWorldContainerOuter.filters = [];
		}
	}

	// Funnel the "Key Down" event to the specific scene
	public static handleKeyDown(key: KeyboardEvent) {
		if (ScenesManager.currentScene !== undefined) {
			ScenesManager.currentScene.keyDown(key);
		}
	}

	// Funnel the "Key Up" event to the specific scene
	public static handleKeyUp(key: KeyboardEvent) {
		if (ScenesManager.currentScene !== undefined) {
			ScenesManager.currentScene.keyUp(key);
		}
	}

	public static setFullscreen(fullscreen: boolean): any {
		let current = document.getElementById("app")!.style.width;
		if (fullscreen === true && current !== "100%") {
			document.getElementById("app")!.style.width = "100%";
			const parent = ScenesManager.app.view.parentNode! as HTMLDivElement;
			ScenesManager.app.renderer.resize(parent.clientWidth, parent.clientHeight);
			App.onResizeEvent.execute(parent.clientWidth, parent.clientHeight);
		} else if (fullscreen === false && current !== null) {
			document.getElementById("app")!.style.width = null;
			const parent = ScenesManager.app.view.parentNode! as HTMLDivElement;
			ScenesManager.app.renderer.resize(parent.clientWidth, parent.clientHeight);
			App.onResizeEvent.execute(parent.clientWidth, parent.clientHeight);
		}
	}

	public static setEffectsEnabled(enabled: boolean) {
		ScenesManager.fxEnabled = enabled;
		if (ScenesManager.fxEnabled) {
			ScenesManager.currentScene.gameWorldContainerOuter.filters = [ScenesManager.bloomFilter, ScenesManager.crtFilter];
		} else {
			ScenesManager.currentScene.gameWorldContainerOuter.filters = [];
		}
	}
}
