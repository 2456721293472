import { HaxCompressor } from "./HaxCompressor";


const modulus = 0x80000000;
const multiplier = 1103515245;
const increment = 12345;

const floatUnit = 1.0 / modulus;

export class HaxRNG {
	public state: number;
	constructor(seed: number) {
		seed = (seed >>> 0);
		if (seed < 0) {
			seed = 2345679;
		}
		this.state = seed % modulus;
		this.nextInt(); // init
	}

	public nextUInt() {
		return (this.state = (multiplier * this.state + increment) % modulus);
	}

	public safeUInt() {
		return (
			31 * (
				(this.state = (multiplier * this.state + increment) % modulus) +
				31 * (this.state = (multiplier * this.state + increment) % modulus)
			) +
			(this.state = (multiplier * this.state + increment) % modulus)
		) >>> 0;
	}

	public nextInt() {
		return (
			31 * (this.state = (multiplier * this.state + increment) % modulus) +
			(this.state = (multiplier * this.state + increment) % modulus)
		) | 0;
	}

	public boolean(chance: number = 0.5) {
		return ((this.state = (multiplier * this.state + increment) % modulus) * (floatUnit)) < chance;
	}

	public nextFloat() {
		return (this.state = (multiplier * this.state + increment) % modulus) * (floatUnit);
	}

	public nextUByte() {
		return ((this.state = (multiplier * this.state + increment) % modulus) >>> 8) & 0xFF;
	}

	public nextUShort() {
		return ((this.state = (multiplier * this.state + increment) % modulus) >>> 8) & 0xFFFF;
	}

	public nextHChar() {
		return HaxCompressor.chars[this.nextUInt() % HaxCompressor.charsLength];
	}

	public rangeInt(min: number, max: number): number {
		min = (min) | 0;
		max = (max) | 0;
		return ((this.nextFloat() * (max - min)) + min) | 0;
	}

	public rangeFloat(min: number, max: number): number {
		min = +(min);
		max = +(max);
		return +((this.nextFloat() * (max - min)) + min);
	}

	public pickArraySafe<T>(array: T[]): T {
		if (array.length <= 0) {
			throw new Error("... it wasn't safe...");
		}
		return array[this.rangeInt(0, array.length)];
	}

	public pickArray<T>(array: T[]): T | undefined {
		if (array.length <= 0) {
			return undefined;
		}
		return array[this.rangeInt(0, array.length)];
	}
}
