import { UIContainer } from "../../core/UI/UIContainer";
import { UIPositionAlign, UIAlign, UISizePercentage, UIDirection } from "../../core/UIHelpers";
import { Scene } from "../../core/Scene";
import { ScrollingScoreUIElement } from "./ScrollingScoreUIElement";
import { ScoreManager } from "../ScoreManager";
import { HUD_SCORE_FONTSIZE, HUD_HEIGHT, HUD_SCORE_OFFSET } from "./HUD/HUDSizes";

export class PlayerScoreUIElement extends UIContainer {
	// Properties
	private scrollingScoreUI: ScrollingScoreUIElement;

	constructor(scene: Scene) {
		super({
			x: new UIPositionAlign(UIAlign.right, 350),
			y: new UIPositionAlign(UIAlign.top),
			width: new UISizePercentage(25),
			height: HUD_HEIGHT
			// width: 100,
			// height: 25,
			// backgroundColor: Colors.Red
		});

		let score = Math.round(ScoreManager.getCurrentPlayerScore());
		this.scrollingScoreUI = new ScrollingScoreUIElement(scene, score, 1, {
			x: new UIPositionAlign(UIAlign.right),
			y: new UIPositionAlign(UIAlign.middle),
			font: "Hyperspace",
			fontSize: HUD_SCORE_FONTSIZE
		}, -1, true);
		this.add(this.scrollingScoreUI);
	}

	public onResize(width: number, height: number) {
		super.onResize(width, height);
		let offset = HUD_SCORE_OFFSET.compute(UIDirection.Y, this);

		this.setPosition(new UIPositionAlign(UIAlign.right, offset), new UIPositionAlign(UIAlign.middle));
	}

	public setScore(score: number) {
		this.scrollingScoreUI.setScore(Math.round(score));
	}
}
