import { Entity, EntityType } from "./Entities/Entity";
import { Scene } from "./Scene";

export class EntityManager {
	private entities: Entity[] = [];
	private entitiesMap = new Map<number, Entity>();

	constructor(private scene: Scene) { }

	public update(dt: number) {
		for (let e of this.entities) {
			(e as any).internalUpdate(dt);
		}
	}

	/** Add an entity to the Entity Manager */
	public add(entity: Entity) {
		// make sure it wont be duplicate
		let index = this.entities.indexOf(entity);
		if (index === -1) {
			this.entities.push(entity);
			this.entitiesMap.set(entity.getID(), entity);
			// this.scene.gameWorldContainer.addChild(entity);
		}
		return entity;
	}

	/** Remove an entity from the Entity Manager */
	public remove(entity: Entity) {
		let index = this.entities.indexOf(entity);
		if (index !== -1) {
			this.entities.splice(index, 1);
			this.entitiesMap.delete(entity.getID());
			// this.scene.gameWorldContainer.removeChild(entity);
		}
	}

	public getEntityByID(id: number): Entity {
		return this.entitiesMap.get(id)!;
	}

	/** Count all the current entities of a specific type in the manager */
	public count(type: EntityType) {
		let c = 0;
		for (let e of this.entities) {
			if (e.type === type) {
				c++;
			}
		}

		return c;
	}

	/** Clear all entities and remove them from the world */
	public removeAllFromWorld() {
		for (let e of [...this.entities]) {
			e.removeFromWorld();
		}
	}
}
