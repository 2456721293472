
export abstract class Behavior<T, D> {
	protected owner: T;
	protected active: boolean = true;
	constructor(owner: T) {
		this.owner = owner;
	}

	/** Update the generic behavior */
	public abstract update(dt: number): void;

	/** Set the data for this behavior */
	public abstract execute(data: D): void;

	/** Set whether or not this behavior is active and should update */
	public setActive(active: boolean) {
		this.active = active;
	}

	/** Return whether or not this particular behavior is active */
	public isActive(): boolean {
		return this.active;
	}
}
