import { UIAbstractSlider, UISliderProperties } from "./UIAbstractSlider";

export interface UIHorizontalSliderProperties extends UISliderProperties {
	x?: undefined;
}

export class UIHorizontalSlider extends UIAbstractSlider<UIHorizontalSliderProperties> {

	protected updateSlider() {
		let barPadding = this.barSize - this.barInnerSize;
		let barLength = this.width - this.barInnerSize * 2 - barPadding * 2;
		this.value = (this.mouseX - this.barInnerSize - barPadding) / barLength;
		this.value = this.value < 0 ? 0 : (this.value > 1) ? 1 : this.value;
	}

	protected redrawBar() {
		let { width, height } = this;

		let barPadding = this.barSize - this.barInnerSize;

		let barLength = width - this.barInnerSize * 2 - barPadding * 2;

		this.bar.clear();
		this.bar.beginFill(this.barColor, 1);
		this.bar.drawCircle(this.barSize, (height / 2), this.barSize);
		this.bar.drawRect(this.barSize, (height / 2) - this.barSize, width - this.barSize * 2, this.barSize * 2);
		this.bar.drawCircle(width - this.barSize, (height / 2), this.barSize);
		this.bar.endFill();

		this.bar.beginFill(this.barInnerColor, 1);
		this.bar.drawCircle(this.barInnerSize + barPadding, (height / 2), this.barInnerSize);
		this.bar.drawRect(this.barInnerSize + barPadding, (height / 2) - this.barInnerSize, barLength, this.barInnerSize * 2);
		this.bar.drawCircle(width - this.barInnerSize - barPadding, (height / 2), this.barInnerSize);
		this.bar.endFill();
	}

	protected redrawSlider() {
		let { width, height } = this;

		let barPadding = this.barSize - this.barInnerSize;
		let barLength = width - this.barInnerSize * 2 - barPadding * 2;

		let barPosition = barLength * this.value;

		let sliderColor = this.mouseDown ? this.sliderActiveColor : this.sliderColor;
		let sliderInnerColor = (this.mouseOver || this.mouseDown) ? this.sliderHoverColor : this.sliderInnerColor;

		this.slider.clear();
		this.slider.beginFill(sliderColor, 1);
		this.slider.drawCircle(0, 0, this.barSize);
		this.slider.endFill();

		this.slider.beginFill(sliderInnerColor, 1);
		this.slider.drawCircle(0, 0, this.barInnerSize);
		this.slider.endFill();

		this.slider.x = barPosition + this.barInnerSize + barPadding;
		this.slider.y = (height / 2);
	}

	public get width() { return this.parent.width; }
	public get height() { return this.barSize * 2; }

	public updateSliderPosition(value: number) {
		let barPadding = this.barSize - this.barInnerSize;
		let barLength = this.width - this.barInnerSize * 2 - barPadding * 2;
		let barPosition = barLength * value;
		this.slider.x = barPosition + this.barInnerSize + barPadding;
	}
}
