import { Entity, EntityType } from "../core/Entities/Entity";
import { Scene } from "../core/Scene";
import pixi = require("pixi.js");
import { TextureManager } from "../core/TextureManager";
import { VanishBehavior } from "../Gameplay/Behaviors/VanishBehavior";
import { Colors } from "../utils/Colors";

const SPIN_SPEED = Math.PI;

export class WarpHoleEntity extends Entity {
	public type = EntityType.WarpHole;

	private sprite: pixi.Sprite;

	private readonly transitionLifetime: number;

	private vanishBehavior: VanishBehavior;

	constructor(scene: Scene, x: number, y: number, tansitionTime: number) {
		super(scene, x, y);

		this.transitionLifetime = tansitionTime;

		this.sprite = pixi.Sprite.from(TextureManager.WarpHole);
		this.sprite.tint = Colors.PlayerDefault;
		this.sprite.anchor.set(0.5);

		this.graphicsContainer.addChild(this.sprite);

		this.vanishBehavior = new VanishBehavior(this);
		this.vanishBehavior.setOwnerFade(false);
	}

	public update(dt: number) {
		this.vanishBehavior.update(dt);

		this.sprite.rotation += SPIN_SPEED * dt;
	}

	public async show() {
		await this.eventSystem.fnTick((percentage: number) => {
			this.sprite.alpha = percentage;
			this.sprite.scale.set(percentage);
		}, this.transitionLifetime * 1000);
	}

	public async hide() {
		await this.eventSystem.fnTick((percentage: number) => {
			this.sprite.alpha = 1 - percentage;
			this.sprite.scale.set(1 - percentage);
		}, this.transitionLifetime * 1000);

		this.vanishBehavior.execute(false);
	}
}
