import { Scene } from "../../../core/Scene";
import { EnvironmentContainer } from "../../../containers/EnvironmentContainer";
import { PauseUIElement } from "../../../Gameplay/UI/PauseUIElement";

export class TestPauseUIScene extends Scene {

	private pauseElement: PauseUIElement;

	constructor() {
		super();
	}

	public async init() {
		this.physicsWorld.gravity.y = 0;
		this.backgroundContainer.addChild(new EnvironmentContainer());

		this.pauseElement = this.uiManager.add(new PauseUIElement(this,
			() => {
				console.log("Back Callback!");
			},
			() => {
				console.log("Quit Callback");
			}));

			this.pauseElement.setVisible(true);
	}

	// Handle when a key on the keyboard is released
	public keyUp(key: KeyboardEvent) { }
}
