import pixi = require("pixi.js");
import { UIContainer } from "../../../core/UI/UIContainer";
import { TextureManager } from "../../../core/TextureManager";
import { App } from "../../../app";
import { Colors } from "../../../utils/Colors";
import { HUD_SCALE, HUD_HASH_OFFSET } from "./HUDSizes";
import { UIDirection } from "../../../core/UIHelpers";

export class HUDIndicatorHash extends UIContainer {
	private sprites: pixi.Sprite[] = [];

	constructor(private max: number) {
		super();
		for (let i = 0; i < this.max; i++) {
			let pip = pixi.Sprite.from(TextureManager.UIGlobalScoreHash);
			this.internal.addChild(pip);
			this.sprites.push(pip);
		}
	}

	public onResize(width: number, height: number) {
		super.onResize(width, height);
		let scale = HUD_SCALE.compute(UIDirection.Y, this);
		let offset = HUD_HASH_OFFSET.compute(UIDirection.Y, this);

		for (let i = 0; i < this.max; i++) {
			let pip = this.sprites[i];
			pip.x = (i * offset);
			pip.scale.set(scale);
		}
	}

	public set(value: number) {
		for (let i = 0; i < this.sprites.length; i++) {
			this.sprites[i].tint = i < value ? Colors.GlobalScoreHashOn : Colors.GlobalScoreHashOff;
		}
	}
}
