import { Behavior } from "./Behavior";
import { Entity } from "../../core/Entities/Entity";

/** Have an Entity dart to a specific point */
export class BlinkBehavior extends Behavior<Entity, number> {
	private originalState: boolean;
	private blinking: boolean = false;
	private lifetime: number;
	private blinkTTL: number;
	private blinkRate: number = 0.25;

	constructor(owner: Entity) {
		super(owner);
	}

	public update(dt: number): void {
		if (this.active === false || this.blinking === false) {
			return;
		}

		this.lifetime -= dt;
		this.blinkTTL -= dt;

		if (this.lifetime <= 0) {
			this.owner.setVisible(this.originalState);
			this.blinking = false;
		} else if (this.blinkTTL <= 0) {
			this.blinkTTL = this.blinkRate;
			this.owner.setVisible(!this.owner.getVisible());
		}
	}

	public execute(lifetimeSec: number): void {
		this.lifetime = lifetimeSec;
		this.blinkTTL = this.blinkRate;

		// If blinking is active and this is called again, don't override the orignial state, we're just resetting times
		if (this.blinking === false) {
			this.originalState = this.owner.getVisible();
			this.owner.setVisible(!this.owner.getVisible());
		}

		this.blinking = true;
	}

	public setRate(rate: number) {
		this.blinkRate = rate;
	}

	public isBlinking() {
		return this.blinking;
	}
}
