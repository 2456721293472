import { Entity, EntityType } from "../core/Entities/Entity";
import { Scene } from "../core/Scene";
import { ScenesManager } from "../core/SceneManager";
import pixi = require("pixi.js");
import { Colors } from "../utils/Colors";
import { Vec2 } from "@h4x/common";

const HALF_SPACE = 7500;
const HALF_SPACE_ALPHA = 0.1;

/** An entity that represents the clustering dividing line */
export class SectorDividerEntity extends Entity {
	public type: EntityType.Divider;

	// Divider Line
	private divider: pixi.Container;
	private dividorSpace: pixi.Container;

	// Testing
	constructor(scene: Scene, enableLine: boolean = true) {
		super(scene, 0, 0);

		this.isOverlay = true;

		this.divider = new pixi.Container();
		this.graphicsContainer.addChild(this.divider);

		this.dividorSpace = new pixi.Container();
		if (ScenesManager.fxEnabled) {
			this.dividorSpace.filters = [ScenesManager.crtFilter];
		} else {
			this.dividorSpace.filters = [];
		}
		this.divider.addChild(this.dividorSpace);

		// Create Negative Half-Space
		let halfSpaceNegative = new pixi.Graphics();
		halfSpaceNegative.beginFill(Colors.ClusterNegative, HALF_SPACE_ALPHA);
		halfSpaceNegative.drawRect(HALF_SPACE / -2, 0, HALF_SPACE, HALF_SPACE);
		halfSpaceNegative.endFill();
		this.dividorSpace.addChild(halfSpaceNegative);

		// Create Positive Half-Space
		let halfSpacePositive = new pixi.Graphics();
		halfSpacePositive.beginFill(Colors.ClusterPositive, HALF_SPACE_ALPHA);
		halfSpacePositive.drawRect(HALF_SPACE / -2, -HALF_SPACE, HALF_SPACE, HALF_SPACE);
		halfSpacePositive.endFill();
		this.dividorSpace.addChild(halfSpacePositive);

		if (enableLine) {
			// Draw Negative Divisor Line One
			let lineNegative = new pixi.Graphics();
			lineNegative.lineStyle(5, Colors.ClusterNegative);
			lineNegative.moveTo(-10000, 2);
			lineNegative.lineTo(10000, 2);
			this.divider.addChild(lineNegative);

			// Draw Negative Divisor Line Two
			let linePositive = new pixi.Graphics();
			linePositive.lineStyle(5, Colors.ClusterPositive);
			linePositive.moveTo(-10000, -2);
			linePositive.lineTo(10000, -2);
			this.divider.addChild(linePositive);
		}
	}

	public update(dt: number) {
		if (ScenesManager.fxEnabled && this.dividorSpace.filters.length == 0) {
			this.dividorSpace.filters = [ScenesManager.crtFilter];
		} else if (!ScenesManager.fxEnabled && this.dividorSpace.filters.length != 0) {
			this.dividorSpace.filters = [];
		}
	}


	/** Set the cluster locations to calculate line position */
	public setCluster(positive: Vec2, negative: Vec2) {
		let dir = negative.copy().subtract(positive).normalize();
		let perpDir = new Vec2(dir.y, dir.x * -1);
		let midpoint = Vec2.mix(positive, negative, 0.5);

		this.divider.position.set(midpoint.x, midpoint.y);

		let angle = Math.atan2(perpDir.y, perpDir.x);
		this.divider.rotation = angle;
	}
}
