import { EntityType, Entity } from "../core/Entities/Entity";
import pixi = require("pixi.js");
import Matter = require("matter-js");
import { Scene } from "../core/Scene";
import { TextureManager } from "../core/TextureManager";
import { Vec2 } from "@h4x/common";
import { Colors } from "../utils/Colors";

const TRACK_RADIUS = 400;

function rotateVec(vec: Vec2, angle: number) {
	let s = Math.sin(angle);
	let c = Math.cos(angle);

	return new Vec2(c * vec.x - s * vec.y, s * vec.x + c * vec.y);
}

/** Entity for the Player and related logic */
export class WarpPlayerEntity extends Entity {
	/** The type of entity */
	public type: EntityType = EntityType.WarpPlayer;

	/** Graphic Contrainers */
	private playerContainer: pixi.Container;
	private playerShip: pixi.Sprite;
	private playerThrust: pixi.Sprite;

	// Physics
	private physicsBody: Matter.Body;

	// Movement
	private trackPoint: Vec2 = new Vec2();

	/** Debug Logic */
	private debug: boolean = true;
	private debugTrack: pixi.Graphics;
	private debugTrackPoint: pixi.Graphics;

	constructor(scene: Scene, x: number, y: number) {
		super(scene, x, y);
		// Create Ship Container
		this.playerContainer = new pixi.Container();

		// Create Primary Ship Graphic
		this.playerShip = pixi.Sprite.from(TextureManager.PlayerWarp);
		this.playerShip.anchor.set(0.5);

		// Create Primary Ship Thrust
		this.playerThrust = pixi.Sprite.from(TextureManager.PlayerWarpThrust);
		this.playerThrust.anchor.set(0.5);
		this.playerThrust.x = 0;
		this.playerThrust.y = 45;

		this.playerContainer.y = TRACK_RADIUS;
		this.playerContainer.addChild(this.playerShip);
		this.playerContainer.addChild(this.playerThrust);

		// Add to the owning game entity
		this.graphicsContainer.addChild(this.playerContainer);

		// Setup Physics Body
		let verts: Matter.Vector[] = [];
		verts.push(Matter.Vector.create(0, 0));
		verts.push(Matter.Vector.create(-59, 77));
		verts.push(Matter.Vector.create(59, 77));

		this.physicsBody = Matter.Bodies.fromVertices(0, 0, [verts]);
		this.physicsBody.isSensor = true;
		this.physicsBody.label = "" + this.getID();

		Matter.Body.setStatic(this.physicsBody, true);
		Matter.World.add(this.scene.physicsEngine.world, this.physicsBody);

		if (this.debug) {
			this.setupDebug();
		}
	}

	// Create Debug Track
	private setupDebug() {
		// Ship Track
		this.debugTrack = new pixi.Graphics();
		this.debugTrack.lineStyle(5, Colors.Orange);
		this.debugTrack.drawCircle(0, 0, TRACK_RADIUS);
		this.graphicsContainer.addChild(this.debugTrack);

		// Tracking Point
		this.debugTrackPoint = new pixi.Graphics();
		this.debugTrackPoint.beginFill(Colors.Red);
		this.debugTrackPoint.drawCircle(0, 0, 25);
		this.debugTrackPoint.endFill();
		this.graphicsContainer.addChild(this.debugTrackPoint);
	}

	// Object Update Logic
	public update(dt: number) {
		super.update(dt);
		this.track(dt);
	}

	// Overriding this from the parent because we do not want the parent updating the graphics information
	public physicsUpdate() {
	}

	private track(dt: number) {
		// Calcualte Foward Vector of Physics body
		let playerDir = new Vec2(this.playerContainer.x, this.playerContainer.y).normalize();
		let trackDir = this.trackPoint.copy().subtract(this.getPosition()).normalize();

		// Calculate the shortest angle between 2 vectors
		let trackAngle = Math.atan2(trackDir.y, trackDir.x);

		let pivotAngle = trackAngle - Math.atan2(playerDir.y, playerDir.x);

		// Apply thresholds to the angle
		if (pivotAngle > Math.PI) {
			pivotAngle -= (2 * Math.PI);
		} else if (pivotAngle < -Math.PI) {
			pivotAngle += (2 * Math.PI);
		}

		let newPlayerDir = rotateVec(playerDir.copy(), pivotAngle * dt * 2.5);
		let newPlayerPos = newPlayerDir.copy().multiplyScalar(TRACK_RADIUS);

		this.playerContainer.position.set(newPlayerPos.x, newPlayerPos.y);

		// Ship Rotation
		// Calculate the shortest angle between 2 vectors
		let rotateAngle = Math.atan2(newPlayerDir.x, newPlayerDir.y);
		this.playerContainer.rotation = -rotateAngle;

		// Update Physics Body
		let pv = new Vec2(this.playerContainer.x, this.playerContainer.y);
		let boudPos = pv.copy().normalize().multiplyScalar(11).add(this.getPosition().add(pv));

		Matter.Body.setPosition(this.physicsBody, Matter.Vector.create(boudPos.x, boudPos.y));
		Matter.Body.setAngle(this.physicsBody, this.playerContainer.rotation);

		if (this.debug) {
			let point = trackDir.copy().multiplyScalar(TRACK_RADIUS);
			this.debugTrackPoint.position.set(point.x, point.y);
		}
	}

	// Update the track point where the ship should be
	public setTrackPoint(x: number, y: number) {
		this.trackPoint.x = x;
		this.trackPoint.y = y;
	}
}
