import { Behavior } from "./Behavior";
import { Vec2, Utils } from "@h4x/common";
import { Entity } from "../../core/Entities/Entity";

/** Have an Entity dart to a specific point */
export class ConstrainBehavior extends Behavior<Entity, Vec2[]> {
	public bounds: Vec2[] = [];

	constructor(owner: Entity) {
		super(owner);
	}

	public update(dt: number): void {
		if (this.bounds.length !== 2) {
			return;
		}

		let entityPos = this.owner.getPosition();
		let tl = this.bounds[0];
		let br = this.bounds[1];

		entityPos.x = Utils.remapRange(entityPos.x, tl.x, br.x, tl.x, br.x);
		entityPos.y = Utils.remapRange(entityPos.y, tl.y, br.y, tl.y, br.y);

		this.owner.setPosition(entityPos.x, entityPos.y);

	}

	public execute(bounds: Vec2[]): void {
		this.bounds = bounds.map((vec) => {
			return vec.copy();
		});
	}
}
