import { Scene } from "../core/Scene";
import { Colors } from "../utils/Colors";
import { EnvironmentContainer } from "../containers/EnvironmentContainer";
import { DataEntity } from "../entity/DataEntity";
import { Utils, Vec2 } from "@h4x/common";
import { TextureManager } from "../core/TextureManager";
import { GameplayLogicController } from "../Gameplay/GameLogicController";
import { MenuEnemyEntity } from "../entity/MenuEnemyEntity";
import { DifficultyManager } from "../Gameplay/DifficultyManager";
import { UITextButton } from "../core/UI/UITextButton";
import { UIPositionAlign, UIAlign, UISizePercentage, UISizeDynamic } from "../core/UIHelpers";
import { UIVerticalContainer } from "../core/UI/UIVerticalContainer";
import { UIContainer } from "../core/UI/UIContainer";
import { UIText } from "../core/UI/UIText";
import { UISprite } from "../core/UI/UISprite";
import { Globals } from "../Gameplay/Constants";
import { UIElement } from "../core/UIElement";
import { VolumeSlider } from "../Gameplay/UI/VolumeSlider";
import { SoundManager, SoundInstance } from "../core/SoundManager";
import { ScenesManager } from "../core/SceneManager";
import { SectorDividerEntity } from "../entity/SectorDividerEntity";
import { App } from "../app";
import pixi = require("pixi.js");
import { MENU_BUTTON_FONTSIZE, MENU_BUTTON_HEIGHT, MENU_SETTINGS_FONTSIZE, MENU_BACK_WIDTH, MENU_VISUALS_WIDTH } from "../Gameplay/UI/UISizes";

const MENU_CENTROID_NEG = new Vec2(250, 250);
const MENU_CENTROID_POS = new Vec2(750, 750);

export class GameSceneMenu extends Scene {
	private backgroundMusic: SoundInstance;
	private currentContainer: UIElement;
	private divider: SectorDividerEntity;

	public onActivated() {
		this.backgroundMusic = SoundManager.GameMenuMusic.play();
		this.backgroundMusic.loop(true);
	}

	public onDeactivated() {
		if (this.backgroundMusic) {
			this.backgroundMusic.stop();
		}
	}

	public init() {
		// Disable Gravity
		this.physicsWorld.gravity.y = 0;

		// Scene Setup
		this.backgroundContainer.addChild(new EnvironmentContainer());

		// Setup Some Fake Data for background Visuals
		for (let i = 0; i < 50; i++) {
			let dp = new DataEntity(this, Utils.randomInt(0, Globals.WORLD_WIDTH), Utils.randomInt(0, Globals.WORLD_HEIGHT), Math.random() < 0.5, Math.random() < 0.5);
			this.gameWorldContainer.addChild(dp.getGraphicsContainer());
			this.entityManager.add(dp);
			this.eventSystem.callInterval(this, this.runDataPointLogic, [dp], [500, 5000]);
		}

		// Spawn Some Enemies as background items
		for (let i = 0; i < 6; i++) {
			let en = new MenuEnemyEntity(this, Utils.randomInt(0, Globals.WORLD_WIDTH), Utils.randomInt(0, Globals.WORLD_HEIGHT));
			this.gameWorldContainer.addChild(en.getGraphicsContainer());
			this.entityManager.add(en);
		}

		// Button for Debug Menu
		if (Globals.DEBUG_MODE) {
			this.uiManager.add(new UITextButton("Debug Menu", {
				x: new UIPositionAlign(UIAlign.right, 10),
				y: 10,
				color: Colors.LightGray,
				fontSize: 18,
				borderWidth: 0
			})
			).onClick.addCallback(() => { ScenesManager.changeScene(App.debugMenuScene); });
		}

		this.divider = new SectorDividerEntity(this, false);
		this.addToWorld(this.divider);
		this.divider.setCluster(MENU_CENTROID_POS, MENU_CENTROID_NEG);
		this.attachGameMenu();
		// this.attachAboutMenu();
		// this.attachOptionsMenu();
	}

	private attachAboutMenu() {
		if (this.currentContainer !== undefined) {
			this.currentContainer.delete();
			this.currentContainer = undefined!;
		}

		/**
		 * About Page Container
		 */
		let container = this.uiManager.add(new UIVerticalContainer({
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center),
			width: new UISizePercentage(100),
			height: new UISizePercentage(100),
			margin: 0,
			padding: 0,
			fill: true
		}));
		this.currentContainer = container;

		let background = new pixi.Graphics();
		background.beginFill(Colors.Black);
		background.drawRect(-10000, -10000, 20000, 20000);
		background.endFill();
		background.alpha = 0.85;
		container.internal.addChild(background);

		/**
		 * Omega Cluster Title Container
		 */
		/*console.log(TextureManager.AboutPage);
		container.add(new UIContainer({
			height: new UISizePercentage(85),
			width: new UISizePercentage(100),
			y: new UIPositionAlign(UIAlign.top, 100),
		})).add(
			new UISprite(TextureManager.AboutPage, {
				x: new UIPositionAlign(UIAlign.center),
				y: new UIPositionAlign(UIAlign.center),
				fill: true,
				fit: true
			}
			));*/


		let text = container.add(new UIContainer({
			height: new UISizePercentage(85),
			width: new UISizePercentage(100),
			y: new UIPositionAlign(UIAlign.top, 100),
		})).add(
			new UIText(`
the omega cluster is copyright 2019
balanced media | technology

programming - cameron ferguson, sebastian
raszka, josh brookover, and tim ullrich
design, art and sound - jay halderman
special thanks to the entire balanced team

pixijs is licensed under the terms of the mit license
https://github.com/pixijs/pixi.js/blob/dev/license
howler.js is licensed under the terms of the mit license
https://github.com/goldfire/howler.js/blob/master/license.md
matter.js is licensed under the terms of the mit license
https://github.com/liabru/matter-js/blob/master/license

the music track pixel river by trevor lentz is used under the
cc by-sa 3.0 license
(https://creativecommons.org/licenses/by-sa/3.0/)

the music track bit space by beam theory is used under the
oga-by-3 license (http://static.opengameart.org/oga-by-3.0.txt)

the music track victory by celestialghost8 is used under the cc0
1.0 license (https://creativecommons.org/publicdomain/zero/1.0/)

for more information on the omega cluster and the hewmen
platform please visit http://bmt.world/omega
			`, {
					x: new UIPositionAlign(UIAlign.center),
					y: App.height,
					color: Colors.White,
					align: "center",
					font: "Hyperspace",
					fontSize: new UISizeDynamic([
						{ height: 950, size: 22 },
						{ height: 318, size: 8 }
					], { interpolate: true, floor: true })
				}
			));

		let y = 1;
		this.eventSystem.fnTick(() => {
			let h = text.internal.height / App.height;
			y -= this.deltaTime * 0.05;
			if (y < -h) {
				y = 1;
			}
			text.y = y * App.height;
		});
		/**
	  	* Button Container
	  	*/
		let buttonContainer = new UIContainer({
			width: new UISizePercentage(100),
			height: new UISizePercentage(15),
		});
		/**
		 * Website Button
		 */
		// let websiteButton = buttonContainer.add(
		// 	new UITextButton(`website`, {
		// 		x: new UIPositionAlign(UIAlign.left, 50),
		// 		y: new UIPositionAlign(UIAlign.center),
		// 		width: 150,
		// 		height: 50,
		// 		color: Colors.White,
		// 		font: "Hyperspace",
		// 		backgroundColor: Colors.Black,
		// 		hoverBorderColor: Colors.Yellow,
		// 		hoverColor: Colors.Yellow
		// 	}
		// 	));
		// websiteButton.onClick.addCallback(() => {
		// 	window.open("http://www.bmt.world/omega");
		// 	SoundManager.MenuSelect.play();
		// });
		// websiteButton.onHover.addCallback(() => {
		// 	SoundManager.MenuMouseover.play();
		// });

		/**
		 * Back Button
		 */
		let backButton = buttonContainer.add(
			new UITextButton(`back`, {
				x: new UIPositionAlign(UIAlign.right, 50),
				y: new UIPositionAlign(UIAlign.bottom, 10),
				width: 150,
				height: MENU_BUTTON_HEIGHT,
				color: Colors.White,
				font: "Hyperspace",
				fontSize: MENU_BUTTON_FONTSIZE,
				backgroundColor: Colors.Black,
				hoverBorderColor: Colors.Yellow,
				hoverColor: Colors.Yellow
			}
			));
		backButton.onClick.addCallback(() => {
			this.attachGameMenu();
			SoundManager.MenuBackButton.play();
		});
		backButton.onHover.addCallback(() => {
			SoundManager.MenuMouseover.play();
		});
		container.add(buttonContainer);
	}

	private attachGameMenu() {
		if (this.currentContainer !== undefined) {
			this.currentContainer.delete();
			this.currentContainer = undefined!;
		}
		let container = this.uiManager.add(new UIVerticalContainer({
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center),
			width: new UISizePercentage(95),
			height: new UISizePercentage(95),
			margin: 0,
			padding: 0,
			fill: true
		}));
		this.currentContainer = container;

		container.add(new UIContainer({
			height: new UISizePercentage(40)
		})).add(
			new UISprite(TextureManager.TitleOmegaCluster, {
				x: new UIPositionAlign(UIAlign.center),
				y: new UIPositionAlign(UIAlign.center),
				fill: true,
				fit: true
			}
			));

		// Debug Mode Difficulty Version
		if (Globals.DEBUG_MODE) {
			this.uiManager.add(
				new UIText(`Difficulty: ${DifficultyManager.version}`, {
					x: 10,
					y: 10,
					color: Colors.LightGray,
					fontSize: 18
				}
				));
		}

		let playButton = container.add(new UIContainer({
			height: new UISizePercentage(15)
		})).add(
			new UITextButton("play", {
				x: new UIPositionAlign(UIAlign.center),
				y: new UIPositionAlign(UIAlign.center),
				width: new UISizePercentage(50),
				height: MENU_BUTTON_HEIGHT,
				color: Colors.White,
				font: "Hyperspace",
				fontSize: MENU_BUTTON_FONTSIZE,
				backgroundColor: Colors.Black,
				backgroundAlpha: .6,
				hoverBorderColor: Colors.Yellow,
				hoverColor: Colors.Yellow
			}
			));
		playButton.onClick.addCallback(() => {
			GameplayLogicController.play();
			SoundManager.MenuSelect.play();
		});
		playButton.onHover.addCallback(() => {
			SoundManager.MenuMouseover.play();
		});

		let tutButton = container.add(new UIContainer({
			height: new UISizePercentage(15)
		})).add(
			new UITextButton("tutorial", {
				x: new UIPositionAlign(UIAlign.center),
				y: new UIPositionAlign(UIAlign.center),
				width: new UISizePercentage(50),
				height: MENU_BUTTON_HEIGHT,
				color: Colors.White,
				font: "Hyperspace",
				fontSize: MENU_BUTTON_FONTSIZE,
				backgroundColor: Colors.Black,
				backgroundAlpha: .6,
				hoverBorderColor: Colors.Yellow,
				hoverColor: Colors.Yellow
			}
			));
		tutButton.onClick.addCallback(() => {
			GameplayLogicController.playTutorial();
			SoundManager.MenuSelect.play();
		});
		tutButton.onHover.addCallback(() => {
			SoundManager.MenuMouseover.play();
		});

		let optionsButton = container.add(new UIContainer({
			height: new UISizePercentage(15)
		})).add(
			new UITextButton("options", {
				x: new UIPositionAlign(UIAlign.center),
				y: new UIPositionAlign(UIAlign.center),
				width: new UISizePercentage(50),
				height: MENU_BUTTON_HEIGHT,
				color: Colors.White,
				font: "Hyperspace",
				fontSize: MENU_BUTTON_FONTSIZE,
				backgroundColor: Colors.Black,
				backgroundAlpha: .6,
				hoverBorderColor: Colors.Yellow,
				hoverColor: Colors.Yellow
			}
			));
		optionsButton.onClick.addCallback(() => {
			this.attachOptionsMenu();
			SoundManager.MenuSelect.play();
		});
		optionsButton.onHover.addCallback(() => {
			SoundManager.MenuMouseover.play();
		});

		let aboutButton = container.add(new UIContainer({
			height: new UISizePercentage(15)
		})).add(
			new UITextButton("about", {
				x: new UIPositionAlign(UIAlign.center),
				y: new UIPositionAlign(UIAlign.center),
				width: new UISizePercentage(50),
				height: MENU_BUTTON_HEIGHT,
				color: Colors.White,
				font: "Hyperspace",
				fontSize: MENU_BUTTON_FONTSIZE,
				backgroundColor: Colors.Black,
				backgroundAlpha: .6,
				hoverBorderColor: Colors.Yellow,
				hoverColor: Colors.Yellow
			}
			));
		aboutButton.onClick.addCallback(() => {
			this.attachAboutMenu();
			SoundManager.MenuSelect.play();
		});
		aboutButton.onHover.addCallback(() => {
			SoundManager.MenuMouseover.play();
		});
	}

	private attachOptionsMenu() {
		if (this.currentContainer !== undefined) {
			this.currentContainer.delete();
			this.currentContainer = undefined!;
		}
		let container = this.uiManager.add(new UIVerticalContainer({
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center),
			width: new UISizePercentage(95),
			height: new UISizePercentage(95),
			margin: 0,
			padding: 0,
			fill: true,
		}));
		this.currentContainer = container;

		container.add(new UIContainer({
			height: new UISizePercentage(20)
		})).add(
			new UISprite(TextureManager.TitleOmegaCluster, {
				x: new UIPositionAlign(UIAlign.center),
				y: new UIPositionAlign(UIAlign.center),
				fill: true,
				fit: true
			}
			));


		container.add(new UIContainer({
			height: new UISizePercentage(10)
		})).add(
			new UIText(` settings `, {
				x: new UIPositionAlign(UIAlign.center),
				font: "Hyperspace",
				fontSize: MENU_SETTINGS_FONTSIZE,
				color: Colors.Black,
				strokeColor: Colors.Cyan,
				strokeSize: 2
			}));


		let effectsVolumeContainer = container.add(new UIContainer({ height: new UISizePercentage(20), width: new UISizePercentage(100) }));
		VolumeSlider.createVolumeSlider(effectsVolumeContainer, "sounds", SoundManager.Effects);

		let musicVolumeContainer = container.add(new UIContainer({ height: new UISizePercentage(20), width: new UISizePercentage(100) }));
		VolumeSlider.createVolumeSlider(musicVolumeContainer, "music", SoundManager.Background);

		let fxText = ScenesManager.fxEnabled ? "Enhanced Visuals: On" : "Enhanced Visuals: Off";
		let toggleFxButton = new UITextButton(fxText, {
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.bottom),
			width: MENU_VISUALS_WIDTH,
			height: MENU_BUTTON_HEIGHT,
			color: Colors.White,
			backgroundColor: Colors.Black,
			backgroundAlpha: .6,
			font: "Hyperspace",
			fontSize: MENU_BUTTON_FONTSIZE,
			borderColor: ScenesManager.fxEnabled ? Colors.Green : Colors.Red,
			hoverBorderColor: Colors.Yellow,
			hoverColor: Colors.Yellow
		}
		);
		toggleFxButton.onClick.addCallback(() => {
			SoundManager.MenuBackButton.play();
			ScenesManager.setEffectsEnabled(!ScenesManager.fxEnabled);
			toggleFxButton.text = ScenesManager.fxEnabled ? "Enhanced Visuals: On" : "Enhanced Visuals: Off";
			toggleFxButton.setBorderColor(ScenesManager.fxEnabled ? Colors.Green : Colors.Red);
		});
		let toggleFxContainer = new UIContainer({
			height: new UISizePercentage(15)
		});
		toggleFxContainer.add(toggleFxButton);
		container.add(toggleFxContainer);

		container.add(new UIContainer({
			height: new UISizePercentage(15)
		})).add(
			new UITextButton("back", {
				x: new UIPositionAlign(UIAlign.right),
				y: new UIPositionAlign(UIAlign.bottom),
				width: MENU_BACK_WIDTH,
				height: MENU_BUTTON_HEIGHT,
				color: Colors.White,
				font: "Hyperspace",
				fontSize: MENU_BUTTON_FONTSIZE,
				backgroundColor: Colors.Black,
				borderColor: Colors.White,
				hoverBorderColor: Colors.Yellow,
				hoverColor: Colors.Yellow
			}
			)).onClick.addCallback(() => {
				this.attachGameMenu();
				SoundManager.MenuBackButton.play();
			});
	}

	// Run Data Point Logic
	private runDataPointLogic(dataPoint: DataEntity) {
		dataPoint.setClustered(!dataPoint.isClustered());
	}
}
