import * as pixi from "pixi.js";
import { BasicEvent } from "@h4x/common";
import Text from "./Text";

export default class TextButton extends Text {

	public readonly onClick = new BasicEvent<(event: pixi.interaction.InteractionEvent) => void>();
	public readonly onMouseUp = new BasicEvent<(event: pixi.interaction.InteractionEvent) => void>();
	public readonly onMouseDown = new BasicEvent<(event: pixi.interaction.InteractionEvent) => void>();
	public readonly onMouseOver = new BasicEvent<(event: pixi.interaction.InteractionEvent) => void>();
	public readonly onMouseOut = new BasicEvent<(event: pixi.interaction.InteractionEvent) => void>();

	private mainColor: number;

	constructor(text: string, style?: any | pixi.TextStyle) {
		super(text, { fill: 0xFFFFFF, ...style });
		/*this.x = x;
		this.y = y;*/

		this.mainColor = this.style.fill;
		this.interactive = true;
		this.buttonMode = true;

		this.on("mousedown", this.handleMouseDown, this);
		this.on("mouseup", this.handleMouseUp, this);
		this.on("mouseover", this.handleMouseOver, this);
		this.on("mouseout", this.handleMouseOut, this);
	}

	public setMainColor(color: number) {
		this.style.fill = color;
		this.mainColor = color;
	}

	private mouseDown = false;
	private handleMouseDown(event: pixi.interaction.InteractionEvent) {
		this.mouseDown = true;
		this.onMouseDown.execute(event);
		this.style.fill = 0x00FF00;
	}

	private handleMouseUp(event: pixi.interaction.InteractionEvent) {
		if (this.mouseDown === true) {
			this.mouseDown = false;
			this.onClick.execute(event);
		}
		this.onMouseUp.execute(event);
		this.style.fill = this.mainColor;
	}

	private handleMouseOver(event: pixi.interaction.InteractionEvent) {
		this.onMouseOver.execute(event);
		this.style.fill = 0xFF0000;
	}

	private handleMouseOut(event: pixi.interaction.InteractionEvent) {
		this.onMouseOut.execute(event);
		this.style.fill = this.mainColor;
	}

	/*public get clicked() {
		return this._cb;
	}

	public set clicked(cb: Function) {
		this._cb = cb;
	}*/
}
