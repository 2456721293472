import { UIContainer } from "../../core/UI/UIContainer";
import { UIPositionAlign, UIAlign, UISizePercentage } from "../../core/UIHelpers";
import { UIText, UITextProperties } from "../../core/UI/UIText";
import { Scene } from "../../core/Scene";
import { Colors } from "../../utils/Colors";

export class ScrollingScoreUIElement extends UIContainer {
	// Properties
	private displayScore: number = 0;
	private currentScore: number = 0;
	private timeToScrollSec: number;
	private numDigits: number;

	// Display Properties
	private incrimentAmount: number = 0;

	// Elements
	private scoreTextUI: UIText;

	constructor(scene: Scene, startingScore: number, timeToScrollSec: number, textProperties: UITextProperties, numDigits: number = -1,
		fit = false) {
		super({
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center),
			// backgroundColor: Colors.Blue,
			...(fit ? { width: new UISizePercentage(100) } : {})
		});

		this.currentScore = startingScore;
		this.displayScore = startingScore;

		this.timeToScrollSec = timeToScrollSec;
		this.numDigits = numDigits;

		this.scoreTextUI = new UIText("", textProperties);

		this.add(this.scoreTextUI);
		this.updateScoreUI(this.currentScore);

		scene.eventSystem.fnTick(() => {
			if (this.currentScore === this.displayScore) {
				return;
			}

			let delta = this.currentScore - this.displayScore;
			let direction = delta > 0 ? 1 : -1;
			let incAmountAbs = Math.ceil(Math.abs(this.incrimentAmount * scene.deltaTime));
			let newScore = this.displayScore + (incAmountAbs * direction);

			if ((direction > 0 && newScore > this.currentScore) ||
				(direction < 0 && newScore < this.currentScore)) {
				newScore = this.currentScore;
			}

			this.updateScoreUI(Math.round(newScore));
		});
	}

	private updateScoreUI(score: number) {
		this.displayScore = score;
		let t = "" + score;

		if (this.numDigits > 0) {
			while (t.length < this.numDigits) {
				t = "0" + t;
			}
		}

		this.scoreTextUI.text = t;
	}

	/** Set the score to scroll too */
	public setScore(score: number) {
		this.currentScore = score;
		this.incrimentAmount = Math.ceil(Math.abs(this.currentScore - this.displayScore) / this.timeToScrollSec);

		let a = 0;
	}
}
