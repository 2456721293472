import { App } from "../app";
import pixi = require("pixi.js");
import { EventSystem } from "../core/EventSystem";

// Standardized Times for multiple areas to use throughout the game
export const FADE_ANIMATION_TIME = 1000;
export const FADE_ANIMATION_HOLD_TIME = 3000;

export class FadeAnimationLogic {
	/** Fade any object out so long as it as an "alpha" property */
	public static async runContainerFade(eventSystem: EventSystem, displayContainer: pixi.Container, fadeTime: number, holdTime: number, center: boolean = true) {
		if (displayContainer.alpha === undefined) {
			throw Error("FadeAnimationLogic:runContainerFade - Element given does not have 'alpha' property");
		}

		if (center) {
			if (displayContainer.x === undefined || displayContainer.y === undefined) {
				throw Error("FadeAnimationLogic:runContainerFade - Element given does not have 'x' or 'y' property");
			}
			displayContainer.x = App.width / 2;
			displayContainer.y = App.height / 2;
		}
		displayContainer.alpha = 0.0;
		await this.fadeIn(eventSystem, displayContainer, fadeTime);
		await eventSystem.timeout(holdTime);
		await this.fadeOut(eventSystem, displayContainer, fadeTime);
	}

	/** Fade any object in so long as it as an "alpha" property */
	public static async fadeIn(eventSystem: EventSystem, displayObject: any, fadeTime: number) {
		if (displayObject.alpha === undefined) {
			throw Error("FadeAnimationLogic:fadeIn - Element given does not have 'alpha' property");
		}

		displayObject.alpha = 0.0;
		await eventSystem.fnTick((percentage) => {
			displayObject.alpha = percentage;
		}, fadeTime);

		displayObject.alpha = 1.0;
	}

	/** Fade any object out so long as it as an "alpha" property */
	public static async fadeOut(eventSystem: EventSystem, displayObject: any, fadeTime: number) {
		if (displayObject.alpha === undefined) {
			throw Error("FadeAnimationLogic:fadeOut - Element given does not have 'alpha' property");
		}
		displayObject.alpha = 1.0;
		await eventSystem.fnTick((percentage) => {
			displayObject.alpha = 1 - percentage;
		}, fadeTime);
		displayObject.alpha = 0.0;
	}
}
