import { NavigateToBehavior } from "./NavigateToBehavior";
import { Behavior } from "./Behavior";
import { DynamicEntity } from "../../core/Entities/DynamicEntity";
import { DifficultyManager } from "../DifficultyManager";
import { Vec2 } from "@h4x/common";
import { Globals } from "../Constants";
import { SoundManager } from "../../core/SoundManager";

/** Behavior for the enemy to navigate to  */
export class EnemyChaserTutorialBehavior extends Behavior<DynamicEntity, DynamicEntity> {
	private navigateBehavior: NavigateToBehavior;
	private target: DynamicEntity;
	private startingSpeed: number;
	private speedIncRate: number = 0.01;
	private detectionRadius: number;

	private detected: boolean = false;
	private disableTime: number = 0;

	constructor(owner: DynamicEntity) {
		super(owner);

		this.navigateBehavior = new NavigateToBehavior(owner);
		this.navigateBehavior.setApproach(false);

		// Determine Screen Diagonal
		let diagonal = Math.sqrt(Math.pow(Globals.WORLD_WIDTH, 2) + Math.pow(Globals.WORLD_HEIGHT, 2));

		// TODO: These properties should live on the enemy entity
		// Set Properties
		this.startingSpeed = DifficultyManager.getChaserSpeed();
		this.speedIncRate = (DifficultyManager.getChaserMaxSpeed() - this.startingSpeed) / DifficultyManager.getChaserSpeedRate();
		this.detectionRadius = DifficultyManager.getChaserDetectionRadius() * diagonal;
	}

	public update(dt: number): void {
		if (this.active && this.target) {
			if (this.disableTime > 0) {
				this.disableTime -= dt;
				return;
			}

			// Check Radius
			let dist = Vec2.distance(this.owner.getPosition(), this.target.getPosition());
			if (dist > this.detectionRadius) {
				this.detected = false;

				this.owner.adjustRotation(0.75 * dt);
				this.owner.setMaxSpeed(this.startingSpeed);
				return;
			}

			if (this.detected === false) {
				SoundManager.ChaserAggroOn.play();
				this.detected = true;
			}


			this.navigateBehavior.execute(this.target.getPosition());
			this.navigateBehavior.update(dt);

			// Change Entity Speed Logic
			let newSpeed = Math.min(this.owner.getMaxSpeed() + (this.speedIncRate * dt), DifficultyManager.getChaserMaxSpeed());
			this.owner.setMaxSpeed(newSpeed);
		}
	}

	public execute(target: DynamicEntity): void {
		this.target = target;
		this.owner.setMaxSpeed(this.startingSpeed);

		this.owner.setTurnRate(DifficultyManager.getChaserTurnRate());
	}

	/** Is the player currently detected? */
	public detectedPlayer(): boolean {
		return this.detected;
	}

	public disable(timeSec: number) {
		this.disableTime = timeSec;
	}
}
