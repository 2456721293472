import * as pixi from "pixi.js";
import { UIManager } from "./UIManager";
import { UIPosition, UIDirection } from "./UIHelpers";
import { BasicEvent } from "@h4x/common";
import { UIContainer } from "./UI/UIContainer";
import { EventSystem } from "./EventSystem";

export interface UIElementProperties {
	x?: UIPosition;
	y?: UIPosition;
	alpha?: number;
}

export abstract class UIElement<T extends UIElementProperties = UIElementProperties> {

	protected internal: pixi.Container;
	protected readonly initialized: boolean = false;
	protected readonly destroyed: boolean = false;
	public readonly parent: UIContainer | UIManager;

	public readonly onResizeEvent = new BasicEvent<(width: number, height: number) => void>();

	constructor(protected properties?: T) {
	}

	public delete() {
		this.parent.remove(this);
	}

	public internalDestroy() {
		(this.destroyed as any) = true;
		this.destroy();
	}

	public destroy() { }

	protected internalInitialize(parent: UIContainer | UIManager) {
		(this.parent as any) = parent;
		this.initialize(parent);
		if (this.initialized === false) {
			console.error("UIElement didn't initialize internally... this can cause problem");
			(this.initialized as any) = true;
		}
	}
	protected initialize(parent: UIElement | UIManager) {
		this.applyProperty(this.properties, "x", 0);
		this.applyProperty(this.properties, "y", 0);
		this.applyProperty(this.properties, "alpha", 1);
		(this.initialized as any) = true;
	}


	public onResize(width: number, height: number) {
		this.updatePosition();
		this.onResizeEvent.execute(width, height);
	}

	public onMouseMove(x: number, y: number) { }

	private cachedManager: UIManager;
	public get manager(): UIManager | undefined {
		if (this.cachedManager !== undefined) { return this.cachedManager; } // cache
		let manager = this.parent;
		while (manager !== undefined && !(manager instanceof UIManager)) { manager = manager.parent; }
		if (manager instanceof UIManager) { (this.cachedManager as any) = manager; } // cache
		return manager;
	}

	private $x: UIPosition;
	public get x() { return this.internal.x; }
	public set x(value: number) {
		this.$x = value;
		this.internal.x = this.computeX(value);
	}

	private $y: UIPosition;
	public get y() { return this.internal.y; }
	public set y(value: number) {
		this.$y = value;
		this.internal.y = this.computeY(value);
	}

	public setX(value: UIPosition) { this.$x = value; this.internal.x = this.computeX(value); return this; }
	public setY(value: UIPosition) { this.$y = value; this.internal.y = this.computeY(value); return this; }
	public setPosition(x: UIPosition, y: UIPosition) {
		this.$x = x; this.internal.x = this.computeX(x);
		this.$y = y; this.internal.y = this.computeY(y);
		return this;
	}

	/* Alpha */
	private $alpha: number;
	public get alpha() { return this.$alpha; }
	public setAlpha(value: number) { this.alpha = value; return this; }
	public set alpha(value: number) {
		this.$alpha = value;
		if (this.hidden === false) {
			this.internal.alpha = value;
		}
	}

	public readonly hidden = false;
	public hide() { (this.hidden as any) = true; this.internal.alpha = 0; return this; }
	public show() { (this.hidden as any) = false; this.internal.alpha = this.$alpha; return this; }


	protected updatePosition() {
		this.internal.x = this.computeX(this.$x);
		this.internal.y = this.computeY(this.$y);
	}

	public get width() { return this.internal.width; }
	public get height() { return this.internal.height; }

	private computeX(value: UIPosition): number {
		if (typeof (value) === "number") {
			return value;
		} else {
			return value.compute(UIDirection.X, this);
		}
	}

	private computeY(value: UIPosition): number {
		if (typeof (value) === "number") {
			return value;
		} else {
			return value.compute(UIDirection.Y, this);
		}
	}

	protected applyProperty<P extends UIElementProperties, K extends keyof P>(properties: P | undefined, name: K, def?: P[K], rename?: string) {
		let target = rename !== undefined ? rename : name;
		if (properties !== undefined) {
			if (properties[name] !== undefined) {
				(this as any)[target] = properties[name];
				return;
			}
		}
		if (def !== undefined) {
			(this as any)[target] = def;
		}
	}

	public async fadeIn(eventSystem: EventSystem, fadeTime: number) {
		this.alpha = 0.0;
		await eventSystem.fnTick((percentage) => {
			this.alpha = percentage;
		}, fadeTime);

		this.alpha = 1.0;
	}

}
