import { Scene } from "../../../core/Scene";
import { EnvironmentContainer } from "../../../containers/EnvironmentContainer";
import { Colors } from "../../../utils/Colors";
import { UIBorder, UIBorderMode } from "../../../core/UI/UIBorder";
import { Utils } from "@h4x/common";
import { ScoreTallyUIElement } from "../../../Gameplay/UI/ScoreTallyUIElement";
import { ScoreManager, ScoreCategory } from "../../../Gameplay/ScoreManager";
import { InputManager } from "../../../core/InputManager";
import { PlayerScoreUIElement } from "../../../Gameplay/UI/PlayerScoreUIElement";
import { ScrollingScoreUIElement } from "../../../Gameplay/UI/ScrollingScoreUIElement";
import { UIPositionAlign, UIAlign } from "../../../core/UIHelpers";

export class PlayerScoreScene extends Scene {
	// private scrollingScoreText: ScrollingScoreUIElement;
	private playerScoreUI: PlayerScoreUIElement;
	private scrollingScoreText: ScrollingScoreUIElement;
	private scoreTallyUI: ScoreTallyUIElement;

	constructor() {
		super();
	}

	public async init() {
		this.physicsWorld.gravity.y = 0;

		// GameplayLogicController.play(15);

		this.backgroundContainer.addChild(new EnvironmentContainer());

		// this.playerScoreUI = this.uiManager.add(new PlayerScoreUIElement(this));

		this.scrollingScoreText = this.uiManager.add(new ScrollingScoreUIElement(this, 0, 10, {
			x: new UIPositionAlign(UIAlign.left),
			y: new UIPositionAlign(UIAlign.bottom),
			font: "Hyperspace",
			fontSize: 64
		}));
	}

	// Handle when a key on the keyboard is released
	public keyUp(key: KeyboardEvent) {
		if (key.keyCode === InputManager.KeyR) {
			// if (this.scoreTallyUI) {
			// 	this.scoreTallyUI.delete();
			// }

			// ScoreManager.newGame(15);
			// ScoreManager.addScore(ScoreCategory.MATTER_SEPARATED, Utils.rangeFloat(0, 1) * 100);
			// ScoreManager.addScore(ScoreCategory.WARP_METER_BONUS, Utils.randomInt(1, 100));
			// ScoreManager.addScore(ScoreCategory.SECTOR_BONUS, 15);
			// ScoreManager.addScore(ScoreCategory.SHIELD_BONUS, Utils.randomInt(0, 4));
			// ScoreManager.addScore(ScoreCategory.RESEARCH_BONUS, Utils.rangeFloat(0, 1) * 100);

			// this.scoreTallyUI = this.uiManager.add(new ScoreTallyUIElement(this));
			// this.scoreTallyUI.runScores();

			let r = Utils.randomInt(0, 10000000);
			console.log("R: " + r);
			this.scrollingScoreText.setScore(r);
		}
	}
}
