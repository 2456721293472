import { Scene } from "../../core/Scene";
import { GlobalCluster } from "../../cluster/GlobalCluster";
import * as pixi from "pixi.js";
import { centroidP, centroidN } from "../../test";
import TextButton from "../../core/TextButton";
import { PyramidContainer } from "../../containers/PyramidContainer";
import { BrowserRequest } from "../../utils/BrowserRequest";
import { ScenesManager } from "../../core/SceneManager";
import { App } from "../../app";
import { ResultRowContainer } from "../../containers/ResultRowContainer";
import { SolveRowContainer } from "../../containers/SolveRowContainer";
import { Utils, BasicEvent } from "@h4x/common";
import Text from "../../core/Text";
import { Colors } from "../../utils/Colors";
import { ClusterCentroid } from "../../cluster/ClusterCentroid";
import { EnvironmentContainer } from "../../containers/EnvironmentContainer";

type SingleRowFn = (row: number, centroids: ClusterCentroid[]) => void;

export class ClusterTestScene extends Scene {
	constructor() {
		super();
	}

	private progressText = new Text("LOADING", { fill: Colors.White });
	private opsText = new Text("LOADING", { fill: Colors.White });
	private totalStepsText = new Text("LOADING", { fill: Colors.White });

	private rows: ResultRowContainer[] = [];
	private cluster?: GlobalCluster;

	private datasetFile: string = "./static/linear_norm_csv.17.17.csv";
	private positive: number = 1;
	private negative: number = 1;
	private loops: number = 1;

	private totalSteps: number = 0;

	private async initDataset() {
		this.cluster = undefined;

		let data = await this.loadData(this.datasetFile);
		this.cluster = new GlobalCluster(data);

		this.updateCentroids();
	}

	protected onActivated() {
		ScenesManager.setFullscreen(true);
	}

	protected onDeactivated() {
		ScenesManager.setFullscreen(false);
	}

	private updateCentroids() {
		this.progressText.text = "LOADING";
		this.opsText.text = "LOADING";
		this.totalStepsText.text = "LOADING";

		this.lastBestScore = 0;
		this.lastBestTime = Date.now();
		this.totalSteps = 0;

		if (this.cluster === undefined) {
			throw new Error("Missing cluster!");
		}
		for (const centroid of this.cluster.getCentroids()) {
			this.cluster.removeCentroid(centroid);
		}

		for (let i = 0; i < this.positive; i++) {
			this.cluster.addCentroid(true);
		}
		for (let i = 0; i < this.negative; i++) {
			this.cluster.addCentroid(false);
		}
		this.cluster.calculate();
		this.temp = undefined!;
		this.tempValid = false;

		for (const row of this.rows) {
			this.uiContainer.removeChild(row);
		}
		this.rows = [];

		for (let i = 0; i < this.cluster.count; i++) {
			let rowContainer = new ResultRowContainer(this.cluster, i);
			rowContainer.x = 109;
			rowContainer.y = 50 + i * 10;
			this.uiContainer.addChild(rowContainer);
			this.rows.push(rowContainer);
		}
	}

	private lastBestScore = 0;
	private lastBestTime = Date.now();
	private resultsCount = 0;

	public async init() {
		let environment = new EnvironmentContainer(Colors.DarkGray);
		this.backgroundContainer.addChild(environment);

		{
			let button = new TextButton("Back").setPosition(5, 5);
			button.onClick.addCallback(() => { ScenesManager.changeScene(App.debugMenuScene); });
			this.uiContainer.addChild(button);
		}

		{
			let button = new TextButton("Restart").setPosition(100, 5);
			button.onClick.addCallback(() => { this.restart(); });
			this.uiContainer.addChild(button);
		}

		let updateEvent = new BasicEvent();

		this.uiContainer.addChild(new Text("Dataset", { fill: Colors.LightGray, fontSize: 13 }).setPosition(5, 50));
		{
			let button = new TextButton("Full", { fontSize: 13 }).setPosition(10, 70);
			button.onClick.addCallback(() => { this.datasetFile = "./static/linear_norm_csv.full.csv"; updateEvent.execute(); this.initDataset(); });
			updateEvent.addCallback(() => { button.setMainColor((this.datasetFile === "./static/linear_norm_csv.full.csv") ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}
		{
			let button = new TextButton("17-17", { fontSize: 13 }).setPosition(10, 90);
			button.onClick.addCallback(() => { this.datasetFile = "./static/linear_norm_csv.17.17.csv"; updateEvent.execute(); this.initDataset(); });
			updateEvent.addCallback(() => { button.setMainColor((this.datasetFile === "./static/linear_norm_csv.17.17.csv") ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}
		{
			let button = new TextButton("10-10", { fontSize: 13 }).setPosition(10, 110);
			button.onClick.addCallback(() => { this.datasetFile = "./static/linear_norm_csv.10.10.csv"; updateEvent.execute(); this.initDataset(); });
			updateEvent.addCallback(() => { button.setMainColor((this.datasetFile === "./static/linear_norm_csv.10.10.csv") ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}
		{
			let button = new TextButton("03-03", { fontSize: 13 }).setPosition(10, 130);
			button.onClick.addCallback(() => { this.datasetFile = "./static/linear_norm_csv.3.3.csv"; updateEvent.execute(); this.initDataset(); });
			updateEvent.addCallback(() => { button.setMainColor((this.datasetFile === "./static/linear_norm_csv.3.3.csv") ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}
		{
			let button = new TextButton("01-43", { fontSize: 13 }).setPosition(10, 150);
			button.onClick.addCallback(() => { this.datasetFile = "./static/linear_norm_csv.1.43.csv"; updateEvent.execute(); this.initDataset(); });
			updateEvent.addCallback(() => { button.setMainColor((this.datasetFile === "./static/linear_norm_csv.1.43.csv") ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}

		this.uiContainer.addChild(new Text("Random", { fill: Colors.LightGray, fontSize: 13 }).setPosition(65, 50));
		{
			let button = new TextButton("Full", { fontSize: 13 }).setPosition(70, 70);
			button.onClick.addCallback(() => { this.datasetFile = "*rand.full"; updateEvent.execute(); this.initDataset(); });
			updateEvent.addCallback(() => { button.setMainColor((this.datasetFile === "*rand.full") ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}
		{
			let button = new TextButton("17-17", { fontSize: 13 }).setPosition(70, 90);
			button.onClick.addCallback(() => { this.datasetFile = "*rand.17.17"; updateEvent.execute(); this.initDataset(); });
			updateEvent.addCallback(() => { button.setMainColor((this.datasetFile === "*rand.17.17") ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}
		{
			let button = new TextButton("10-10", { fontSize: 13 }).setPosition(70, 110);
			button.onClick.addCallback(() => { this.datasetFile = "*rand.10.10"; updateEvent.execute(); this.initDataset(); });
			updateEvent.addCallback(() => { button.setMainColor((this.datasetFile === "*rand.10.10") ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}
		{
			let button = new TextButton("03-03", { fontSize: 13 }).setPosition(70, 130);
			button.onClick.addCallback(() => { this.datasetFile = "*rand.3.3"; updateEvent.execute(); this.initDataset(); });
			updateEvent.addCallback(() => { button.setMainColor((this.datasetFile === "*rand.3.3") ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}
		{
			let button = new TextButton("01-43", { fontSize: 13 }).setPosition(70, 150);
			button.onClick.addCallback(() => { this.datasetFile = "*rand.1.43"; updateEvent.execute(); this.initDataset(); });
			updateEvent.addCallback(() => { button.setMainColor((this.datasetFile === "*rand.1.43") ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}

		this.uiContainer.addChild(new Text("Centroids", { fill: Colors.LightGray, fontSize: 13 }).setPosition(5, 250));
		this.uiContainer.addChild(new Text("P", { fill: Colors.LightGray, fontSize: 13 }).setPosition(10, 270));
		this.uiContainer.addChild(new Text("N", { fill: Colors.LightGray, fontSize: 13 }).setPosition(50, 270));

		for (let i = 1; i <= 8; i++) {
			let button = new TextButton("" + i, { fontSize: 13 }).setPosition(10, 270 + 20 * i);
			button.onClick.addCallback(() => { this.positive = i; updateEvent.execute(); this.updateCentroids(); });
			updateEvent.addCallback(() => { button.setMainColor((i === this.positive) ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}
		for (let i = 1; i <= 8; i++) {
			let button = new TextButton("" + i, { fontSize: 13 }).setPosition(50, 270 + 20 * i);
			button.onClick.addCallback(() => { this.negative = i; updateEvent.execute(); this.updateCentroids(); });
			updateEvent.addCallback(() => { button.setMainColor((i === this.negative) ? 0x00CC00 : 0xFFFFFF); });
			this.uiContainer.addChild(button);
		}

		this.uiContainer.addChild(new Text("Loops", { fill: Colors.LightGray, fontSize: 13 }).setPosition(5, 450));

		{
			let i = 0;
			for (const v of [1, 5, 10, 25, 50, 100, 200, 500, 1000]) {
				let button = new TextButton("" + v, { fontSize: 13 }).setPosition(10, 470 + 20 * i++);
				button.onClick.addCallback(() => { this.loops = v; updateEvent.execute(); });
				updateEvent.addCallback(() => { button.setMainColor((v === this.loops) ? 0x00CC00 : 0xFFFFFF); });
				this.uiContainer.addChild(button);
			}
		}
		updateEvent.execute();

		this.progressText.x = 300;
		this.progressText.y = 5;
		this.uiContainer.addChild(this.progressText);

		this.opsText.x = 500;
		this.opsText.y = 5;
		this.uiContainer.addChild(this.opsText);

		this.totalStepsText.x = 650;
		this.totalStepsText.y = 5;
		this.uiContainer.addChild(this.totalStepsText);

		await this.initDataset();

		let ops = 0;
		setInterval(() => {
			if (this.active && this.cluster) {
				if (this.lastBestTime + 60 * 1000 < Date.now()) {
					console.log(this.lastBestScore, JSON.parse(JSON.stringify(this.cluster.getCentroids())));

					// FIXME display the centroid counts and dataset
					let scoreText = new Text((this.lastBestScore * 100).toFixed(3) + "%", { fill: Colors.White, fontSize: 13 });
					scoreText.setPosition(930 + ((this.resultsCount / 50) | 0) * 50, 60 + ((this.resultsCount % 50) | 0) * 15);
					this.uiContainer.addChild(scoreText);
					this.resultsCount++;

					this.restart();
				}
				for (let loop = 0; loop < this.loops; loop++) {
					ops++;
					this.totalSteps++;

					let globalScore = this.optimizeStep(this.totalSteps);
					if (this.lastBestScore < globalScore) {
						this.lastBestScore = globalScore;
						this.lastBestTime = Date.now();
					}
				}

				this.progressText.text = (this.cluster.globalScore * 100).toFixed(4) + "%";
				this.rows.forEach(x => x.update());

				// text.text = (cluster.globalScore * 100).toFixed(4) + "%";

				// pyramidContainer.update();
				// solveContainer.update();
				// rows.forEach(x => x.update());
			}
		}, 1 * 1);

		setInterval(() => {
			if (this.active && this.cluster) {
				this.opsText.text = ops + " op/s";
				this.totalStepsText.text = "Total steps: " + this.totalSteps;
				ops = 0;
			}
		}, 1 * 1000);


		// this.uiContainer.addChild(pyramidContainer);
		// this.uiContainer.addChild(solveContainer);
	}

	private static rowOptimizers = [
		(row: number, centroids: ClusterCentroid[]) => { // randomize all centroids
			for (const centroid of centroids) { centroid.values[row] = Math.random(); }
		},
		(row: number, centroids: ClusterCentroid[]) => { // randomize single centroid
			Utils.pickArray(centroids)!.values[row] = Math.random();
		},
		(row: number, centroids: ClusterCentroid[]) => { // shift all centroids
			let maxDist = 0.01 + Math.random() * 0.2;
			for (const centroid of centroids) {
				let value = centroid.values[row] + (Math.random() * maxDist);
				value = Math.min(Math.max(0, value), 1);
				centroid.values[row] = value;
			}
		}
	];

	private static rowOptimizersLarge = [ // 4+
		...ClusterTestScene.rowOptimizers,
		(row: number, centroids: ClusterCentroid[]) => { // shift multiple centroids
			centroids = [...centroids].sort(() => .5 - Math.random());
			let maxDist = 0.01 + Math.random() * 0.2;
			for (const centroid of centroids.slice(0, Utils.rangeInt(2, centroids.length - 1))) {
				let value = centroid.values[row] + (Math.random() * maxDist);
				value = Math.min(Math.max(0, value), 1);
				centroid.values[row] = value;
			}
		}
	];

	private temp: GlobalCluster;
	private tempValid = false;
	private optimizeStep(step: number) {
		if (this.cluster === undefined) { throw new Error("Missing cluster!"); }

		if (this.tempValid === false) {
			this.temp = this.cluster.copy(this.temp);
		}
		this.tempValid = true;
		let centroids = this.temp.getCentroids();

		if (step % 2 === 1) {
			ClusterTestScene.optimizeSingleRow(this.temp, centroids,
				Utils.pickArray((centroids.length >= 4) ? ClusterTestScene.rowOptimizersLarge : ClusterTestScene.rowOptimizers)!
			);
		} else {
			ClusterTestScene.optimizeDoubleRow(this.temp, centroids,
				Utils.pickArray((centroids.length >= 4) ? ClusterTestScene.rowOptimizersLarge : ClusterTestScene.rowOptimizers)!,
				Utils.pickArray((centroids.length >= 4) ? ClusterTestScene.rowOptimizersLarge : ClusterTestScene.rowOptimizers)!
			);
		}

		let tempScore = this.temp.globalScore;
		let currentScore = this.cluster.globalScore;
		if (tempScore > currentScore) {
			this.temp.copy(this.cluster);
			return tempScore;
		} else if (tempScore !== currentScore) {
			this.tempValid = false;
		}
		return currentScore;
	}


	private static optimizeSingleRow(cluster: GlobalCluster, centroids: ClusterCentroid[], fn: SingleRowFn) {
		let row: number; // = Utils.rangeInt(0, cluster.count);
		if (Utils.rangeFloat(0, 1) > 0.5) {
			row = Utils.randomWeightedArray(cluster.scoresY.map((x) => { return { weight: Math.pow(1 - x, 5) }; }))!.key;
		} else {
			row = Utils.rangeInt(0, cluster.count);
		}

		fn(row, centroids);
		cluster.calculate(row);
	}

	private static optimizeDoubleRow(cluster: GlobalCluster, centroids: ClusterCentroid[], fn1: SingleRowFn, fn2: SingleRowFn) {
		let row1: number; // = Utils.rangeInt(0, cluster.count);
		if (Utils.rangeFloat(0, 1) > 0.5) {
			row1 = Utils.randomWeightedArray(cluster.scoresY.map((x) => { return { weight: Math.pow(1 - x, 5) }; }))!.key;
		} else {
			row1 = Utils.rangeInt(0, cluster.count);
		}

		let row2: number; // = Utils.rangeInt(0, cluster.count);
		if (Utils.rangeFloat(0, 1) > 0.5) {
			row2 = Utils.randomWeightedArray(cluster.scoresY.map((x) => { return { weight: Math.pow(1 - x, 5) }; }))!.key;
		} else {
			row2 = Utils.rangeInt(0, cluster.count);
		}

		fn1(row1, centroids);
		cluster.calculate(row1);
		fn2(row2, centroids);
		cluster.calculate(row2);
	}

	private restart() {
		if (this.cluster === undefined) { throw new Error("Missing cluster!"); }
		this.totalSteps = 0;
		this.lastBestScore = 0;
		this.lastBestTime = Date.now();
		for (const centroid of this.cluster.getCentroids()) {
			(centroid as any).values = centroid.values.map(() => Math.random());
		}
		this.cluster.calculate();
		this.temp = undefined!;
		this.tempValid = false;
	}

	private async loadData(file: string) {
		if (file.startsWith("*")) {
			let [p, n, c] = ({
				"*rand.full": [17, 43, 79],
				"*rand.17.17": [17, 17, 79],
				"*rand.10.10": [10, 10, 79],
				"*rand.3.3": [3, 3, 79],
				"*rand.1.43": [1, 43, 79],
			} as any)[file];
			return [
				...Utils.initArray(p, () => { return { type: true, values: Utils.initArray(c, () => Math.random()) }; }),
				...Utils.initArray(n, () => { return { type: false, values: Utils.initArray(c, () => Math.random()) }; }),
			];
		} else {
			let { body } = await BrowserRequest.get<string>(file, {});
			let data: {
				type: boolean;
				values: number[];
			}[] = [];
			for (const line of body.split(/\r|\n/).map(x => x.trim()).filter(x => x.length > 0)) {
				let parts = line.split(",");
				let type = parts.shift()!;
				data.push({ type: type === "Positive", values: parts.map(x => +x) });
			}
			return data;
		}
	}
}
