import { BasicEvent, Utils } from "@h4x/common";
import { Scene } from "./Scene";

type AnyEvent = BasicEvent<(...args: any[]) => any>;
type EventPropertyNames<T> = { [K in keyof T]: T[K] extends AnyEvent ? K : never }[keyof T];
type EventProperties<T> = Pick<T, EventPropertyNames<T>>;

type EventParameters<T extends BasicEvent<(...args: any[]) => any>> = T extends BasicEvent<(...args: infer P) => any> ? P : never;
type EventReturnType<T extends BasicEvent<(...args: any[]) => any>> = T extends BasicEvent<(...args: any[]) => infer R> ? R : any;

type AnyFunction = (...args: any[]) => any;
type FunctionPropertyNames<T> = { [K in keyof T]: T[K] extends AnyFunction ? K : never }[keyof T];
type FunctionProperties<T> = Pick<T, FunctionPropertyNames<T>>;

export class EventSystem {

	public static readonly Break = Symbol();

	public readonly time = 0;

	constructor(private scene: Scene) { }

	/*
	run for x amount of time
	clean interval

	*/

	public async event<T extends EventProperties<T>, S extends keyof T>(instance: T, name: S, args: EventParameters<T[S]>, delay?: number) {
		if (delay !== undefined) { await this.delay(delay); }
		return (instance[name] as AnyEvent).execute(...args as any) as EventReturnType<T[S]>;
	}

	public async call<T extends FunctionProperties<T>, S extends AnyFunction>(instance: T, fn: S, args: Parameters<S>, delay?: number) {
		if (delay !== undefined) { await this.delay(delay); }
		return fn.call(instance, ...args) as ReturnType<S>;
	}

	public async fn<S extends AnyFunction>(fn: S, args: Parameters<S>, delay?: number) {
		if (delay !== undefined) { await this.delay(delay); }
		return fn(...args) as ReturnType<S>;
	}

	public async eventInterval<T extends EventProperties<T>, S extends keyof T>(instance: T, name: S, args: EventParameters<T[S]>, interval: number | [number, number]) {
		while (true) {
			if (interval !== undefined) { await this.delay(interval); }
			let ret = (instance[name] as AnyEvent).execute(...args as any);
			if (ret === EventSystem.Break) { break; }
		}
	}

	public async callInterval<T extends FunctionProperties<T>, S extends AnyFunction>(instance: T, fn: S, args: Parameters<S>, interval: number | [number, number]) {
		while (true) {
			if (interval !== undefined) { await this.delay(interval); }
			let ret = fn.call(instance, ...args) as ReturnType<S>;
			if (ret === EventSystem.Break) { break; }
		}
	}

	public async fnInterval<S extends AnyFunction>(fn: S, args: Parameters<S>, interval: number | [number, number]) {
		while (true) {
			if (interval !== undefined) { await this.delay(interval); }
			let ret = fn(...args) as ReturnType<S>;
			if (ret === EventSystem.Break) { break; }
		}
	}

	public async eventTick<T extends EventProperties<T>, S extends keyof T>(instance: T, name: S, args: EventParameters<T[S]>, duration: number) {
		let start = this.time;
		while (start + duration > this.time) {
			let ret = (instance[name] as AnyEvent).execute(...args as any);
			await this.nextTick();
			if (ret === EventSystem.Break) { break; }
		}
	}

	public async callTick<T extends FunctionProperties<T>, S extends AnyFunction>(instance: T, fn: S, args: Parameters<S>, duration: number) {
		let start = this.time;
		while (start + duration > this.time) {
			let ret = fn.call(instance, ...args) as ReturnType<S>;
			await this.nextTick();
			if (ret === EventSystem.Break) { break; }
		}
	}

	public async fnTick(fn: (percentage: number) => void, duration?: number) {
		if (duration === undefined) {
			while (true) {
				let ret = fn(0) as any;
				if (ret === EventSystem.Break) { break; }
				await this.nextTick();
			}
		} else {
			let start = this.time;
			while (start + duration > this.time) {
				let ret = fn((this.time - start) / duration) as any;
				if (ret === EventSystem.Break) { break; }
				await this.nextTick();
			}
		}
	}

	private delay(delay: number | [number, number]) {
		if (typeof (delay) !== "number") {
			return this.timeout(Utils.randomInt(delay[0], delay[1]));
		} else if (delay > 0) {
			return this.timeout(delay);
		} else if (delay < 0) {
			return this.immediate();
		} else {
			return this.nextTick();
		}
	}

	private inTick = false;
	public startTick(dt: number) {
		this.inTick = true;
		(this as any).time += dt * 1000;
		let time = this.time;

		let i;
		for (i = 0; i < this.timeoutQueue.length; i++) {
			const timeout = this.timeoutQueue[i];
			if (timeout.time <= time) { timeout.callback(); } else { break; }
		}
		if (i > 0) { this.timeoutQueue.splice(0, i); }
	}

	public endTick() {
		let temp = this.immediateQueue;
		this.immediateQueue = this.nextQueue;
		temp.forEach((callback) => { callback(); });
		this.nextQueue = [];
		this.inTick = false;
	}

	public immediateQueue: (() => void)[] = [];
	public immediateCallback(callback: () => void) {
		this.immediateQueue.push(callback);
	}

	public immediate() {
		return new Promise((resolve) => { this.immediateCallback(() => { resolve(); }); });
	}

	public nextQueue: (() => void)[] = [];
	public nextCallback(callback: () => void) {
		if (this.inTick === true) {
			this.nextQueue.push(callback);
		} else {
			this.immediateQueue.push(callback);
		}
	}

	public nextTick() {
		return new Promise((resolve) => { this.nextCallback(() => { resolve(); }); });
	}

	private comparator(a: any, b: any) {
		return a.time - b.time;
	}

	public timeoutQueue: { time: number, callback: (() => void) }[] = [];
	public timeoutCallback(callback: () => void, ms: number) {
		let data = { time: this.time + ms, callback: callback };
		Utils.sortedInsertComparator(this.timeoutQueue, data, this.comparator);
	}

	public timeout(timeout: number) {
		return new Promise((resolve) => { this.timeoutCallback(() => { resolve(); }, timeout); });
	}
}
