import { EntityType } from "../core/Entities/Entity";
import pixi = require("pixi.js");
import Matter = require("matter-js");
import { Scene } from "../core/Scene";
import { BaseGameEntity } from "./BaseGameEntity";
import { TextureManager } from "../core/TextureManager";
import { Utils, Vec2 } from "@h4x/common";
import { NavigateToBehavior } from "../Gameplay/Behaviors/NavigateToBehavior";
import { VanishBehavior } from "../Gameplay/Behaviors/VanishBehavior";
import { ConstrainBehavior } from "../Gameplay/Behaviors/ConstrainBehavior";
import { BlinkBehavior } from "../Gameplay/Behaviors/BlinkBehavior";
import { Globals } from "../Gameplay/Constants";
import { Colors } from "../utils/Colors";
import { DifficultyManager } from "../Gameplay/DifficultyManager";
import { Sound, SoundManager, SoundInstance } from "../core/SoundManager";
import { WarpEffect } from "../Gameplay/Effects/WarpEffect";

// const WARP_SYMBOL_SCALE = 0.1275;


/** Entity for the Player and related logic */
export class SectorPlayerEntity extends BaseGameEntity {
	/** The type of entity */
	public type = EntityType.SectorPlayer;

	/** Graphic Contrainers */
	private playerThrust: pixi.Sprite;

	// Properties
	private invulnerable: boolean = false;

	// Player Behavior
	private constrainBehavior: ConstrainBehavior;
	private blinkBehavior: BlinkBehavior;
	private navigateBehavior: NavigateToBehavior;
	private vanishBehavior: VanishBehavior;

	// Warp Out Effect
	private warpEffect: WarpEffect;

	// Sounds
	private soundFlag: boolean = false;
	private thrustSound: SoundInstance;

	constructor(scene: Scene, x: number, y: number) {
		super(scene, x, y, (() => {
			let verts: Matter.Vector[] = [];

			verts.push(Matter.Vector.create(0, 20));
			verts.push(Matter.Vector.create(-25, 85));
			verts.push(Matter.Vector.create(25, 85));

			return Matter.Bodies.fromVertices(x, y, [verts]);
		})(), (() => {
			return TextureManager.Player;
		})());

		// Change Player Color
		this.graphicsSprite.tint = Colors.PlayerDefault;

		this.constrainBehavior = new ConstrainBehavior(this);
		this.blinkBehavior = new BlinkBehavior(this);
		this.navigateBehavior = new NavigateToBehavior(this);
		this.vanishBehavior = new VanishBehavior(this);

		this.constrainBehavior.execute([
			new Vec2(0, 0),
			new Vec2(Globals.WORLD_WIDTH, Globals.WORLD_HEIGHT)
		]);

		// this.navigateBehavior.setActive(false);
		this.navigateBehavior.execute(new Vec2(x, y));

		this.playerThrust = pixi.Sprite.from(TextureManager.PlayerThrust);
		this.playerThrust.anchor.set(0.5);
		this.playerThrust.x = 0;
		this.playerThrust.y = 45;
		this.playerThrust.alpha = 0.0;
		this.playerThrust.tint = this.graphicsSprite.tint;
		this.graphicsContainer.addChild(this.playerThrust);

		// Set Movement Properties
		this.physicsBody.isSensor = true;
		this.setMaxSpeed(DifficultyManager.getPlayerSpeed());
		this.setTurnRate(DifficultyManager.getPlayerTurnRate());
		this.setMass(100);

		// Player Health
		this.setHealth(Globals.SECTOR_PLAYER_HEALTH);

		this.warpEffect = new WarpEffect();
		this.graphicsContainer.addChildAt(this.warpEffect, 0);

		this.thrustSound = SoundManager.ShipThrust.play();
		this.thrustSound.pause();

		// TEST Center of player
		// Matter.Body.setAngularVelocity(this.physicsBody, 0.1);
		// let gfxCircle = new pixi.Graphics();
		// gfxCircle.lineStyle(5, Colors.Magenta);
		// gfxCircle.drawCircle(0, 0, 50);
		// this.addChild(gfxCircle);

		// let gfxCenter = new pixi.Graphics();
		// gfxCenter.beginFill(Colors.Magenta);
		// gfxCenter.drawCircle(0, 0, 5);
		// gfxCenter.endFill();
		// this.addChild(gfxCenter);

		// let l1 = new pixi.Graphics();
		// l1.lineStyle(3, Colors.Magenta);
		// l1.moveTo(TextureManager.Player.width / -2, 0);
		// l1.lineTo(TextureManager.Player.width / 2, 0);
		// this.addChild(l1);

		// let l2 = new pixi.Graphics();
		// l2.lineStyle(3, Colors.Magenta);
		// l2.moveTo(0, TextureManager.Player.height / -2);
		// l2.lineTo(0, TextureManager.Player.height / 2);
		// this.addChild(l2);
	}

	public update(dt: number) {
		super.update(dt);
		let speedFlag = Utils.remapRange(this.getSpeed() * (1 / dt), 1, this.getMaxSpeed(), 0, 5) / 5;
		this.playerThrust.alpha = speedFlag;

		if (speedFlag < 0.05) {
			this.soundFlag = false;
		} else if (speedFlag > 0.15 && this.soundFlag === false) {
			this.soundFlag = true;
			SoundManager.ShipThrust.play();
		}

		// Warp Symbol Logic
		this.warpEffect.update(dt);

		// Update Behaviors
		this.blinkBehavior.update(dt);
		this.navigateBehavior.update(dt);
		this.vanishBehavior.update(dt);
		this.constrainBehavior.update(dt);
	}

	public getTint() {
		return this.graphicsSprite.tint;
	}

	public setNavigateTo(x: number, y: number) {
		this.navigateBehavior.execute(new Vec2(x, y));
	}

	/** Tell the player to vanish or appear */
	public vanish(appear: boolean) {
		// Turn off navigation while the player is jumping to prevent drift
		// Re-enable once the vanish is done
		this.navigateBehavior.setActive(appear);
		if (appear === false) {
			this.setVelocity(0, 0);
		}

		// Perform Vanish Behavior
		this.vanishBehavior.execute(appear);
	}

	/** Set Vanish Time */
	public setVanishTime(timeSec: number) {
		this.vanishBehavior.setVanishTime(timeSec);
	}

	/** Get Vanish Time */
	public getVanishTime(): number {
		return this.vanishBehavior.getVanishTime();
	}

	/** Set Position and the Navagation point of the ship */
	public setPositionAndNaviation(x: number, y: number) {
		// Override behavior navigation if we're being set to a specific position
		this.navigateBehavior.execute(new Vec2(x, y));
		this.setPosition(x, y);
	}

	/** Start Blinking for a given amount of time (in seconds) */
	public blink(timeSec: number) {
		this.blinkBehavior.execute(timeSec);
	}

	/** Set the state of ship invulnerability */
	public setInvulnerable(invulnerable: boolean) {
		this.invulnerable = invulnerable;
	}

	/** Return wether or not the player is currently invulnerable */
	public isInvulnerable() {
		return this.invulnerable;
	}

	/** Set the color of the player */
	public setColor(color: number) {
		this.graphicsSprite.tint = color;
		this.playerThrust.tint = color;
	}

	/** Show/Hide the Warp Out Symbol */
	public setWarpOutActive(visible: boolean) {
		this.warpEffect.setActive(visible);
	}

	public isWarpOutActive(): boolean {
		return this.warpEffect.isActive();
	}
}
