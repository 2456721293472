
function now() {
	let hrTime = process.hrtime();
	return hrTime[0] * 1000 + hrTime[1] / 1000000.0;
}

export class Benchmark {
	public static benchmark(func: (iterations: number) => void, iterations: number) {
		const starTime = now();
		func(iterations);
		const endTime = now();
		return endTime - starTime;
	}

	public static benchmarkFull(tests: { [K: string]: { func: (iterations: number) => void; code: string } }, warmups: number, iterations: number, multiplier: number = 1, loops: number = 5) {
		const results: { [K: string]: any } = {};

		for (const testName in tests) {
			if (tests.hasOwnProperty(testName)) {
				const test = tests[testName];
				results[testName] = { code: test.code.trim(), results: [] };

				// warmup
				Benchmark.benchmark(test.func, warmups);
			}
		}

		for (let i = 0; i < loops; i++) {
			for (const testName in tests) {
				if (tests.hasOwnProperty(testName)) {
					const test = tests[testName];
					const time = Benchmark.benchmark(test.func, iterations);
					results[testName].results.push(time);
					console.log(testName, time);
				}
			}
		}

		return {
			"iterations": iterations,
			"multiplier": multiplier,
			"results": results
		};
	}
}
