import pixi = require("pixi.js");
import { UIContainer } from "../../../core/UI/UIContainer";
import { UIPositionAlign, UIAlign, UIDirection } from "../../../core/UIHelpers";
import { UIElement } from "../../../core/UIElement";
import { UIManager } from "../../../core/UIManager";
import { Globals } from "../../Constants";
import { TextureManager } from "../../../core/TextureManager";
import { Colors } from "../../../utils/Colors";
import { HUD_SCALE, PIP_RADIUS, HUD_SHIELD_SIZE } from "./HUDSizes";

// Constant Properties for the UI
const PIP_GAP_OFFSET = 0.1;
const PIP_NUM_BLINKS = 4;
const PIP_BLINK_RATE = 0.25;

// Represents a single shield pip in the UI
export class HUDPlayerShieldPip extends pixi.Graphics {
	public healthValue: number;
	public active: boolean = true;
	public blinking: boolean = false;
	public showing: boolean = true;
	public numBlinks: number = PIP_NUM_BLINKS;
	public blinkTTL: number = PIP_BLINK_RATE; // Time when the blinking starts to turn off visibility

	constructor(healthValue: number) {
		super();
		this.healthValue = healthValue;
	}

	public update(dt: number) {
		if (this.blinking === false || this.active === false) {
			return;
		}

		// Blinking Logic
		this.blinkTTL -= dt;

		if (this.blinkTTL <= 0) {
			this.visible = !this.visible;
			this.blinkTTL = PIP_BLINK_RATE;

			if (this.visible) {
				this.numBlinks--;

				if (this.numBlinks === 0) {
					this.active = false;
					this.visible = false;
				}
			}
		}
	}

	// Check the current player health and see what this pip needs to do
	public check(currentHealth: number) {
		if (this.healthValue >= currentHealth) {
			this.blinking = true;
			this.visible = false;
		}
	}
}

export class HUDPlayerShield extends UIContainer {
	// Health
	private blinkTTL: number = PIP_BLINK_RATE;
	private currentHealth: number;
	private healthShipIcon: pixi.Sprite;
	private healthIconContainer: pixi.Container;
	private pips: HUDPlayerShieldPip[] = [];

	constructor() {
		super({
			x: new UIPositionAlign(UIAlign.left, 5),
			y: 5,
			width: HUD_SHIELD_SIZE,
			height: HUD_SHIELD_SIZE,
		});

		// Remove Old Data if needed
		this.setupHealthIcon();
	}

	public onResize(width: number, height: number) {
		super.onResize(width, height);
		let scale = HUD_SCALE.compute(UIDirection.Y, this);
		let offset = Math.floor(10 * scale);

		this.internal.scale.set(scale);
		this.setPosition(new UIPositionAlign(UIAlign.left, offset), offset);
	}

	protected initialize(parent: UIElement | UIManager) {
		super.initialize(parent);
	}

	// Update the UI logic
	public update(dt: number) {
		for (let p of this.pips) { p.update(dt); }

		// Make the ship blink for our last health
		if (this.currentHealth === 1) {
			this.blinkTTL -= dt;

			if (this.blinkTTL <= 0) {
				this.blinkTTL = PIP_BLINK_RATE;
				this.healthShipIcon.visible = !this.healthShipIcon.visible;
			}
		}
	}

	// Reset and Create a new Health Icon from scratch
	public setupHealthIcon() {
		// Remove Old Data if needed
		this.pips = [];
		if (this.healthIconContainer !== undefined) {
			this.healthIconContainer.removeChildren();
		}
		if (this.healthShipIcon !== undefined) {
			this.healthShipIcon.removeChildren();
		}

		// Draw Master Segment to use as a template for others
		let segLength = (Math.PI * 2) / (Globals.SECTOR_PLAYER_HEALTH - 1);

		this.healthIconContainer = new pixi.Container();
		this.healthShipIcon = pixi.Sprite.from(TextureManager.UIPlayerShieldIcon);
		this.healthShipIcon.anchor.set(0.5);
		this.healthIconContainer.addChild(this.healthShipIcon);

		for (let i = 0; i < Globals.SECTOR_PLAYER_HEALTH - 1; i++) {
			// This logic considers a health of 4, will need to be changed for different health values
			let newPip = new HUDPlayerShieldPip(i + 1);
			newPip.lineStyle(5, Colors.White);
			newPip.arc(0, 0, PIP_RADIUS, 0 + PIP_GAP_OFFSET, segLength - PIP_GAP_OFFSET);
			newPip.rotation = Math.PI - (segLength * i);
			this.healthIconContainer.addChild(newPip);
			this.pips.push(newPip);
		}

		this.healthIconContainer.x = PIP_RADIUS;
		this.healthIconContainer.y = PIP_RADIUS;
		this.internal.addChild(this.healthIconContainer);

		this.setCurrentHealth(Globals.SECTOR_PLAYER_HEALTH);
	}

	public setCurrentHealth(currentHealth: number) {
		if (currentHealth === this.currentHealth) {
			return;
		}

		this.currentHealth = currentHealth;

		if (this.currentHealth === 1) {
			this.healthShipIcon.visible = false;
		} else if (this.currentHealth === 0) {
			this.healthIconContainer.visible = false;
		}

		// Calc Color
		let color: number;
		switch (currentHealth - 1) {
			case 2:
				color = Colors.HealthYellow;
				break;
			case 1:
				color = Colors.HealthRed;
				break;
			default:
				color = Colors.HealthBlue;
				break;
		}

		// Update Items
		for (let p of this.pips) {
			p.check(this.currentHealth);

			if (p.blinking === false) {
				p.tint = color;
			}
		}
	}
}
