

// WIP

class MetaDecorator {
}


export class ClassVariable {
	public readonly name: string;
	private data = new Map<symbol, any>();

	constructor(name: string) {
		this.name = name;
	}

	public register(clazz: ClassMeta) {
		this.data.forEach((it) => {
			if (it && typeof it.register === "function") {
				it.register(this);
			}
		});
	}

	public static createType<T>(key: string): symbol & { __variableData: T } {
		let symbol = Symbol.for("ClassVariable::" + key);
		return <any>symbol;
	}

	public getData<T>(key: symbol & { __variableData: T }): T | undefined {
		return this.data.get(key);
	}

	public attachData<T>(key: symbol & { __variableData: T }, value: T, override?: boolean) {
		let current = this.data.get(key);
		if (current === undefined || override) {
			this.data.set(key, value);
			return value;
		} else {
			throw new Error("[ClassVariable::attachData] Couldn't attach data, data already exists!");
		}
	}
}

export class ClassMeta {
	private $clazz: new () => Object;
	private $prototype: any;
	private parent: ClassMeta | undefined;
	private registered = false;

	private registerCallbacks: Array<(clazz: ClassMeta) => void> = [];
	private finalizeCallbacks: Array<(clazz: ClassMeta) => void> = [];

	private static registerDecorators: Array<(clazz: ClassMeta) => void> = [];
	private static finalizeDecorators: Array<(clazz: ClassMeta) => void> = [];

	constructor(clazz: new () => Object, prototype: any) {
		if (!clazz) {
			throw new Error("[ClassMeta::constructor] Something went wrong, trying to create a class with no prototype!");
		}
		this.$clazz = clazz;
		this.$prototype = prototype;

		this.parent = Object.getPrototypeOf(prototype).constructor["$ClassMeta"];
	}

	public register(singleton: boolean) {
		if (this.registered) {
			throw new Error("[ClassMeta::register] Class already registered!");
		}
		this.registered = true;
		this.singleton = singleton;

		this.callRegister(this);
		ClassMeta.registerDecorators.forEach((fn) => { fn(this); });

		this.callFinalize(this);
		ClassMeta.finalizeDecorators.forEach((fn) => { fn(this); });

		this.forEachVariable((variable) => {
			variable.register(this);
		});
	}

	private callRegister(target: MetaDecorator) {
		if (this.parent !== undefined) {
			this.parent.callRegister(target);
		}
		this.registerCallbacks.forEach((fn) => { fn(this); });
	}

	private callFinalize(target: MetaDecorator) {
		if (this.parent !== undefined) {
			this.parent.callFinalize(target);
		}
		this.finalizeCallbacks.forEach((fn) => { fn(this); });
	}

	public isRegistered() {
		return this.registered;
	}

	private singleton: any;
	private instance: any;
	public attachSingleton(instance: any) {
		if (this.singleton === false) {
			throw new Error("[ClassMeta::attachSingleton] This class wasn't registered as singleton class!");
		}
		this.instance = instance;
	}

	public getSingleton() {
		if (this.singleton === false) {
			throw new Error("[ClassMeta::attachSingleton] This class wasn't registered as singleton class!");
		}
		return this.instance;
	}

	private variables = new Map<string, ClassVariable>();
	public createVariable(key: string) {
		let variable = this.variables.get(key);
		if (variable === undefined) {
			variable = new ClassVariable(key);
			this.variables.set(key, variable);
		}
		return variable;
	}

	public getVariable(key: string): ClassVariable | undefined {
		let variable = this.variables.get(key);
		if (variable !== undefined) {
			return variable;
		}
		if (this.parent !== undefined) {
			return this.parent.getVariable(key);
		} else {
			return undefined;
		}
	}

	public forEachVariable(callback: (variable: ClassVariable, key: string) => void): void {
		if (this.parent !== undefined) {
			this.parent.forEachVariable(callback);
		}
		this.variables.forEach(callback);
	}

	public registerCallback(type: "Register" | "Finalize", fn: (clazz: ClassMeta) => void) {
		if (type === "Register") {
			this.registerCallbacks.push(fn);
		} else if (type === "Finalize") {
			this.finalizeCallbacks.push(fn);
		} else {
			throw new Error("[ClassMeta::RegisterCallback] Invalid callback type!");
		}
	}

	public static registerDecoratorCallback(type: "Register" | "Finalize", fn: (clazz: ClassMeta) => void) {
		if (type === "Register") {
			ClassMeta.registerDecorators.push(fn);
		} else if (type === "Finalize") {
			ClassMeta.finalizeDecorators.push(fn);
		} else {
			throw new Error("[ClassMeta::RegisterCallback] Invalid callback type!");
		}
	}

}

export function GetClassMeta(target: Object, create: true): ClassMeta;
export function GetClassMeta(target: Object, create: false): ClassMeta | undefined;
export function GetClassMeta(target: Object, create: boolean): ClassMeta | undefined {
	if (!target || !target.constructor) {
		throw new Error("[GetClassMeta] Something went wrong, invalid target!");
	}

	if (!target.constructor.hasOwnProperty("$ClassMeta")) {
		if (!create) { return undefined; }
		const classMeta = new ClassMeta((target.constructor as new () => Object), target);
		(target.constructor as any)["$ClassMeta"] = classMeta;
		return classMeta;
	} else {
		return (target.constructor as any)["$ClassMeta"];
	}
}

export function RegisterClass<T extends Function>(target: T, singleton: boolean): ClassMeta {
	let meta = GetClassMeta(target.prototype, true);
	meta.register(singleton);
	return meta;
}

export function RegisterClassVariable<T extends Function>(target: T, key: string): ClassVariable {
	let meta = GetClassMeta(target.prototype, true);
	return meta.createVariable(key);
}
