export class ColorUtils {
	public static hslToHex(h: number, s: number, l: number) {
		let r, g, b;
		if (s === 0) {
			r = g = b = l; // achromatic
		} else {
			const hue2rgb = (x: number, y: number, t: number) => {
				if (t < 0) { t += 1; }
				if (t > 1) { t -= 1; }
				if (t < 1 / 6) { return x + (y - x) * 6 * t; }
				if (t < 1 / 2) { return y; }
				if (t < 2 / 3) { return x + (y - x) * (2 / 3 - t) * 6; }
				return x;
			};
			const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
			const p = 2 * l - q;
			r = hue2rgb(p, q, h + 1 / 3);
			g = hue2rgb(p, q, h);
			b = hue2rgb(p, q, h - 1 / 3);
		}
		const hexChars = "0123456789ABCDEF".split("");
		function hex(x: number) { return hexChars[(x / 16) | 0] + hexChars[(x % 16) | 0]; }
		return `#${hex(((r * 255) | 0))}${hex(((g * 255) | 0))}${hex(((b * 255) | 0))}`;
	}
}
