// Test Scenes
import { DebugMenuScene } from "./scenes/test_scenes/DebugMenuScene";
import { ClusterTestScene } from "./scenes/test_scenes/ClusterTestScene";
import { SolverTestScene } from "./scenes/test_scenes/SolverTestScene";
import { WarpTestScene } from "./scenes/test_scenes/WarpTestScene";
import { WarpHoleScene } from "./scenes/test_scenes/WarpHoleScene";

// Game Scenes
import { GameSceneMenu } from "./scenes/GameSceneMenu";
import { GameSceneSector } from "./scenes/GameSceneSector";
import { GameSceneWarp } from "./scenes/GameSceneWarp";

// Other
import { BasicEvent } from "@h4x/common";
import { TestBehaviorScene } from "./scenes/test_scenes/TestBehaviorScene";
import { SynapseClusterTest } from "./scenes/test_scenes/SynapseClusterTest";
import { PlayerUIScene } from "./scenes/test_scenes/PlayerUIScene";
import { RoundSkipScene } from "./scenes/test_scenes/RoundSkipScene";
import { ScreenScene } from "./scenes/test_scenes/ScreenScene";

import { NewUIScene } from "./scenes/test_scenes/UI/NewUIScene";
import { NewUISoundScene } from "./scenes/test_scenes/UI/NewUISoundScene";
import { NewUIVerticalScene } from "./scenes/test_scenes/UI/NewUIVerticalScene";
import { NewUIHorizontalScene } from "./scenes/test_scenes/UI/NewUIHorizontalScene";
import { PlayerScoreScene } from "./scenes/test_scenes/UI/PlayerScoreScene";
import { TestWarpEffectScene } from "./scenes/test_scenes/TestWarpEffectScene";
import { TestPauseUIScene } from "./scenes/test_scenes/UI/TestPauseUSceneI";
import { GameSceneEnd } from "./scenes/GameSceneEnd";
import { EndUIStatsScene } from "./scenes/test_scenes/UI/EndUIStatsScene";

export namespace App {
	export let time = Date.now();

	export const onResizeEvent = new BasicEvent<(width: number, height: number) => void>();

	// Resolution of the application
	export const width: number = undefined!;
	export const height: number = undefined!;

	// Test Scenes
	export const debugMenuScene = new DebugMenuScene();
	export const clusterTestScene = new ClusterTestScene();
	export const solverTestScene = new SolverTestScene();
	export const synapseClusterTest = new SynapseClusterTest();
	export const warpTestScene = new WarpTestScene();
	export const warpHoleScene = new WarpHoleScene();
	export const testBehaviorScene = new TestBehaviorScene();
	export const playerUIScene = new PlayerUIScene();
	export const screenScene = new ScreenScene();
	export const roundSkipScene = new RoundSkipScene();
	export const testWarpEffectScene = new TestWarpEffectScene();

	// UI Test Scenes
	export const newUIScene = new NewUIScene();
	export const newUISoundScene = new NewUISoundScene();
	export const newUIVerticalScene = new NewUIVerticalScene();
	export const newUIHorizontalScene = new NewUIHorizontalScene();
	export const playerScoreScene = new PlayerScoreScene();
	export const testPauseUIScene = new TestPauseUIScene();
	export const endScoreUIScene = new EndUIStatsScene();

	// Game Scenes
	export const gameSceneMenu = new GameSceneMenu();
	export const gameSceneSector = new GameSceneSector();
	export const gameSceneWarp = new GameSceneWarp();
	export const gameSceneEnd = new GameSceneEnd();
}

App.onResizeEvent.addCallback((w, h) => { (App as any).width = w; (App as any).height = h; });
