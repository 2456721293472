export class BinaryUtils {
	public static stringToUint8Array(string: string) {

		let size = 0;

		for (let i = 0, len = string.length; i < len; i++) {
			const charcode = string.charCodeAt(i);
			if (charcode < 0x80) {
				size++;
			} else if (charcode < 0x800) {
				size += 2;
			} else if (charcode < 0xd800 || charcode >= 0xe000) {
				size += 3;
			} else {
				size += 4;
			}
		}

		const out = new Uint8Array(size);

		let index = 0;
		for (let i = 0, len = string.length; i < len; i++) {
			let charcode = string.charCodeAt(i);
			if (charcode < 0x80) {
				out[index++] = charcode;
			} else if (charcode < 0x800) {
				out[index++] = 0xc0 | (charcode >> 6);
				out[index++] = 0x80 | (charcode & 0x3f);
			} else if (charcode < 0xd800 || charcode >= 0xe000) {
				out[index++] = 0xe0 | (charcode >> 12);
				out[index++] = 0x80 | ((charcode >> 6) & 0x3f);
				out[index++] = 0x80 | (charcode & 0x3f);
			} else {
				i++;
				charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (string.charCodeAt(i) & 0x3ff));
				out[index++] = 0xf0 | (charcode >> 18);
				out[index++] = 0x80 | ((charcode >> 12) & 0x3f);
				out[index++] = 0x80 | ((charcode >> 6) & 0x3f);
				out[index++] = 0x80 | (charcode & 0x3f);
			}
		}
		return out;
	}

	public static uint8ToStringArray(array: Uint8Array) {
		let str = "";

		const end = array.length;

		let i = 0;
		while (i < end) {
			const value = array[i];
			i++;

			if (value < 0x80) {
				str += String.fromCharCode(value);
			} else if (value < 0xE0 && value > 0xBF) {
				str += String.fromCharCode((value & 0x1F) << 6 | array[i++] & 0x3F);
			} else if (value < 0xF0 && value > 0xDF) {
				str += String.fromCharCode((value & 0x0F) << 12 | (array[i++] & 0x3F) << 6 | array[i++] & 0x3F);
			} else {
				const charCode = ((value & 0x07) << 18 | (array[i++] & 0x3F) << 12 | (array[i++] & 0x3F) << 6 | array[i++] & 0x3F) - 0x010000;
				str += String.fromCharCode(charCode >> 10 | 0xD800, charCode & 0x03FF | 0xDC00);
			}
		}
		return str;
	}
}