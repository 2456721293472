import { UIContainer } from "../../core/UI/UIContainer";
import { UIPositionAlign, UIAlign, UISizePercentage } from "../../core/UIHelpers";
import { Colors } from "../../utils/Colors";
import { UIText } from "../../core/UI/UIText";
import { ScrollingScoreUIElement } from "./ScrollingScoreUIElement";
import { Scene } from "../../core/Scene";
import { UIHorizontalContainer } from "../../core/UI/UIHorizontalContainer";
import { UIVerticalContainer } from "../../core/UI/UIVerticalContainer";
import { UITextProperties } from "../../core/UI/UIText";
import { UIElement } from "../../core/UIElement";
import { UIManager } from "../../core/UIManager";
import { GameplayLogicController } from "../GameLogicController";
import { SoundManager, SoundInstance } from "../../core/SoundManager";
import { StatsManager, StatsCategory } from "../StatsManager";

const CATEGORY_TEXT_SIZE = 36;
const SCROLL_TIME = 0.5;

export class EndStatsUIElement extends UIVerticalContainer {
	// Properties
	private scores: {
		[k: string]: {
			nameElement: UIText,
			scoreElement: ScrollingScoreUIElement,
		}
	};

	private scene: Scene;
	private title: string;
	private titleColor: number;

	// UI Elements
	private categoryContainer: UIHorizontalContainer;
	private categoryNameContainer: UIVerticalContainer;
	private categoryScoreContainer: UIVerticalContainer;

	// Sounds
	private soundInstance: SoundInstance;

	constructor(scene: Scene, won: boolean) {
		super({
			height: new UISizePercentage(100),
			width: new UISizePercentage(60),
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center),
			padding: 10,
		});

		this.scene = scene;

		this.title = won ? " congratulations! " : " ship destroyed ";
		this.titleColor = won ? Colors.Cyan : Colors.Yellow;
	}

	protected initialize(parent: UIElement | UIManager) {
		super.initialize(parent);

		let titleContainer = new UIContainer({
			y: new UIPositionAlign(UIAlign.top),
			width: new UISizePercentage(100),
		});

		let round = GameplayLogicController.getCurrentRound() + 1;
		let inTutorial = GameplayLogicController.isInTutorial();

		let titleTextUI = new UIText(this.title, {
			x: new UIPositionAlign(UIAlign.center),
			font: "Hyperspace",
			fontSize: 64,
			color: Colors.Black,
			strokeColor: this.titleColor,
			strokeSize: 2
		});
		titleContainer.add(titleTextUI);
		this.add(titleContainer);

		let scoreProperties: UITextProperties = {
			fontSize: CATEGORY_TEXT_SIZE,
			font: "Hyperspace",
			color: Colors.White,
			x: new UIPositionAlign(UIAlign.right),
		};

		let totalProperties: UITextProperties = {
			fontSize: CATEGORY_TEXT_SIZE,
			font: "Hyperspace",
			color: Colors.Cyan,
			x: new UIPositionAlign(UIAlign.right),
		};

		this.scores = {
			bestRound: {
				nameElement: new UIText(`best round score`, scoreProperties),
				scoreElement: new ScrollingScoreUIElement(this.scene, 0, SCROLL_TIME, scoreProperties)
			},
			researchSolutions: {
				nameElement: new UIText(`research solutions found`, scoreProperties),
				scoreElement: new ScrollingScoreUIElement(this.scene, 0, SCROLL_TIME, scoreProperties)
			},
			highestSector: {
				nameElement: new UIText(`highest sector completed`, scoreProperties),
				scoreElement: new ScrollingScoreUIElement(this.scene, 0, SCROLL_TIME, scoreProperties)
			},
			final: {
				nameElement: new UIText(`final score`, totalProperties),
				scoreElement: new ScrollingScoreUIElement(this.scene, 0, SCROLL_TIME, totalProperties)
			},
		};

		// Create containers
		this.categoryContainer = new UIHorizontalContainer({
			width: new UISizePercentage(100),
			padding: 35,
		});

		this.categoryNameContainer = new UIVerticalContainer({
			width: new UISizePercentage(75),
			fill: true,
			padding: 5,
		});

		this.categoryScoreContainer = new UIVerticalContainer({
			width: new UISizePercentage(25),
			fill: true,
			padding: 5,
		});

		this.add(this.categoryContainer);
		this.categoryContainer.add(this.categoryNameContainer);
		this.categoryContainer.add(this.categoryScoreContainer);

		let keys = Object.keys(this.scores);

		for (let key of keys) {
			let obj = this.scores[key];

			let nameContainer = new UIContainer()
			nameContainer.add(obj.nameElement);
			this.categoryNameContainer.add(nameContainer);
			this.categoryScoreContainer.add(obj.scoreElement);
		}
	}

	public stopSounds() {
		if (this.soundInstance) {
			this.soundInstance.stop();
		}
	}

	public async runStats() {
		let es = this.scene.eventSystem;

		let scrollTime = SCROLL_TIME * 1000;

		let score = StatsManager.getStat(StatsCategory.BEST_ROUND);
		this.scores.bestRound.scoreElement.setScore(score);
		if (score !== 0) {
			this.soundInstance = SoundManager.Tally.play();
			this.soundInstance.loop(true);
			await es.timeout(scrollTime);
			this.soundInstance.stop();
		}

		score = StatsManager.getStat(StatsCategory.RESEARCH_SOLUTIONS);
		this.scores.researchSolutions.scoreElement.setScore(score);
		if (score !== 0) {
			this.soundInstance = SoundManager.Tally.play();
			this.soundInstance.loop(true);
			await es.timeout(scrollTime);
			this.soundInstance.stop();
		}

		score = StatsManager.getStat(StatsCategory.HIGHEST_SECTOR);
		this.scores.highestSector.scoreElement.setScore(score);
		if (score !== 0) {
			this.soundInstance = SoundManager.Tally.play();
			this.soundInstance.loop(true);
			await es.timeout(scrollTime);
			this.soundInstance.stop();
		}

		score = StatsManager.getStat(StatsCategory.FINAL_SCORE);
		this.scores.final.scoreElement.setScore(score);
		if (score !== 0) {
			this.soundInstance = SoundManager.Tally.play();
			this.soundInstance.loop(true);
			await es.timeout(scrollTime);
			this.soundInstance.stop();
		}
	}
}
