import pixi = require("pixi.js");
import { GlobalCluster } from "../cluster/GlobalCluster";
import { DataEntity } from "../entity/DataEntity";
import { Scene } from "../core/Scene";
import { ClusterCentroid } from "../cluster/ClusterCentroid";
import { Globals } from "../Gameplay/Constants";

export class LocalClusterContainer extends pixi.Container {

	public readonly dataPoint: DataEntity[] = [];

	constructor(private scene: Scene, private source: GlobalCluster, private clusterX: number, private clusterY: number) {
		super();

		const { data } = this.source;
		let centroids = this.source.getCentroids();
		for (const dat of data) {
			const pX = dat.values[this.clusterX];
			const pY = dat.values[this.clusterY];

			//let valid = this.isValid(centroids, dat);

			let point = new DataEntity(scene, pX * Globals.WORLD_WIDTH, pY * Globals.WORLD_HEIGHT, dat.type, false);
			this.dataPoint.push(point);
			this.addChild(point.getGraphicsContainer());
			this.scene.entityManager.add(point);
		}

		// setInterval(() => { this.update(Math.random(), Math.random(), Math.random(), Math.random()); }, 1000);
	}

	/** Return the number of points that are clustered correctly */
	public getNumClustered(): number {
		let num = 0;
		for (let dp of this.dataPoint) {
			if (dp.isClustered()) {
				num++;
			}
		}

		return num;
	}

	private isValid(centroids: ClusterCentroid[], data: { type: boolean; values: number[]; }) {
		let currentDist = Number.POSITIVE_INFINITY;
		let currentType = undefined as any as boolean;

		const pX = data.values[this.clusterX];
		const pY = data.values[this.clusterY];

		let valid = false;
		for (let c = 0; c < centroids.length; c++) {
			const centroid = centroids[c];
			let cX = centroid.values[this.clusterX];
			let cY = centroid.values[this.clusterY];
			let xDelta = cX - pX;
			let yDelta = cY - pY;
			let cDist = (xDelta * xDelta) + (yDelta * yDelta);
			if (cDist < currentDist) {
				currentDist = cDist;
				currentType = centroid.type;
			}
		}
		if (data.type === currentType) {
			valid = true;
		}
		return valid;
	}

	public update(pX: number, pY: number, nX: number, nY: number) {
		let centroids = this.source.getCentroids();
		centroids[0].values[this.clusterX] = pX / Globals.WORLD_WIDTH;
		centroids[0].values[this.clusterY] = pY / Globals.WORLD_HEIGHT;

		centroids[1].values[this.clusterX] = nX / Globals.WORLD_WIDTH;
		centroids[1].values[this.clusterY] = nY / Globals.WORLD_HEIGHT;
		this.source.calculate();


		const { data } = this.source;
		for (let i = 0; i < data.length; i++) {
			let dat = data[i];
			const point = this.dataPoint[i];
			let valid = this.isValid(centroids, dat);
			point.setClustered(valid);
		}

	}

	public reset() {
		for (let point of this.dataPoint) {
			point.setClustered(false);
		}
	}
}
