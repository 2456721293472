import { Entity, EntityType } from "../core/Entities/Entity";
import { Scene } from "../core/Scene";
import pixi = require("pixi.js");
import { TextureManager } from "../core/TextureManager";
import { DynamicEntity } from "../core/Entities/DynamicEntity";
import Matter = require("matter-js");
import { NavigateToBehavior } from "../Gameplay/Behaviors/NavigateToBehavior";
import { Vec2 } from "@h4x/common";

/** An entity that does the "impact" effect when damage is done */
export class DataEntity extends DynamicEntity {
	public type = EntityType.Data;

	private graphicsSprite: pixi.Sprite;

	// Cluster Data Information
	private readonly state: boolean = true;
	private clustered: boolean = true;

	private behavior: NavigateToBehavior;

	constructor(scene: Scene, x: number, y: number, state: boolean, clustered: boolean) {
		super(scene, x, y, (() => {
			return Matter.Bodies.circle(x, y, 5);
		})());

		this.physicsBody.isSensor = true;

		// Set whether this object is positive or negative
		this.state = state;

		// Setup Behavior
		this.behavior = new NavigateToBehavior(this);
		this.behavior.setActive(false);

		// this.graphicsSprite = pixi.Sprite.from(pixi.Texture.EMPTY);
		this.graphicsSprite = pixi.Sprite.from(TextureManager.PositiveDataClustered);
		this.graphicsSprite.anchor.set(0.5);

		this.setClustered(clustered);

		this.graphicsContainer.addChild(this.graphicsSprite);
	}

	public update(dt: number) {
		this.behavior.update(dt);
		// Test
		/*if (Utils.randomInt(0, 200) === 1) {
			this.setClustered(!this.clustered);
		}*/
	}

	/** Returns True if this entity represents a positive state */
	public isPositive(): boolean {
		return this.state;
	}

	/** Returns true if this entity represents a negative state */
	public isNegative(): boolean {
		return !this.state;
	}

	/** Returns true if this object is clustered correctly, false if not */
	public isClustered(): boolean {
		return this.clustered;
	}

	/** Set if this data point is correctly clustered */
	public setClustered(clustered: boolean) {
		this.clustered = clustered;
		if (this.state) {
			this.graphicsSprite.texture = clustered ? TextureManager.PositiveDataClustered : TextureManager.PositiveDataUnclustered;
		} else {
			this.graphicsSprite.texture = clustered ? TextureManager.NegativeDataClustered : TextureManager.NegativeDataUnclustered;
		}

		this.graphicsSprite.width = this.graphicsSprite.texture.width * 0.5;
		this.graphicsSprite.height = this.graphicsSprite.texture.height * 0.5;
	}

	/** When scoring occurs in the game, this will provide a "tallying" effect */
	public runScoreLogic(location: Vec2) {
		if (this.clustered === true) {
			this.behavior.setActive(true);
			this.behavior.overrideSpeed(1500);
			this.behavior.overrideTurnRate(5);
			this.behavior.execute(location);
		}
	}
}
