import * as pixi from "pixi.js";
import { BasicEvent } from "@h4x/common";
import { UIElementProperties, UIElement } from "../../core/UIElement";
import { UIManager } from "../../core/UIManager";
import { Colors } from "../../utils/Colors";
import { UIVerticalContainer } from "../../core/UI/UIVerticalContainer";
import { SoundGroup } from "../../core/SoundManager";
import { TextureManager } from "../../core/TextureManager";
import { UISizePercentage, UIPositionAlign, UIAlign } from "../../core/UIHelpers";
import { UIText } from "../../core/UI/UIText";
import { UIContainer } from "../../core/UI/UIContainer";
import { UISpriteButton } from "../../core/UI/UISpriteButton";
import { UIHorizontalContainer } from "../../core/UI/UIHorizontalContainer";
import { VOLUME_TEXT_FONTSIZE } from "./UISizes";


export interface UISliderProperties extends UIElementProperties {
	value: number;
}


export class VolumeSlider<T extends UISliderProperties = UISliderProperties>
	extends UIElement<T & UISliderProperties> {

	protected internal: pixi.Container;
	protected bar: pixi.Graphics;
	protected slider: pixi.Graphics;

	public readonly onValueChanged = new BasicEvent<(value: number) => void>();

	constructor(properties?: T) {
		super(properties);
		this.internal = new pixi.Container();
		this.bar = new pixi.Graphics();

		this.slider = new pixi.Graphics();
		this.slider.interactive = true;
		this.slider.buttonMode = true;

		this.slider.on("mousedown", this.sliderMouseDown, this);
		this.slider.on("mouseup", this.sliderMouseUp, this);
		this.slider.on("mouseupoutside", this.sliderMouseUp, this);
		this.slider.on("mouseover", this.sliderMouseOver, this);
		this.slider.on("mouseout", this.sliderMouseOut, this);

		this.bar.interactive = true;
		this.bar.on("mousedown", this.sliderMouseDown, this);
		this.bar.on("mouseup", this.sliderMouseUp, this);
		this.bar.on("mouseupoutside", this.sliderMouseUp, this);

		this.internal.addChild(this.bar);
		this.internal.addChild(this.slider);
	}

	protected initialize(parent: UIElement | UIManager) {
		super.initialize(parent);

		this.applyProperty(this.properties, "value");

		this.redraw();
	}

	public onResize(width: number, height: number) {
		super.onResize(width, height);
		this.redraw();
	}

	protected mouseDown = false;
	private sliderMouseDown() {
		this.mouseDown = true;
		this.updateSlider();
		this.redrawSlider();
	}

	private sliderMouseUp() {
		if (this.mouseDown === true) {
			this.mouseDown = false;
			this.redrawSlider();
		}
	}

	protected mouseOver = false;
	private sliderMouseOver() {
		this.mouseOver = true;
		this.redrawSlider();
	}

	private sliderMouseOut() {
		this.mouseOver = false;
		this.redrawSlider();
	}

	protected mouseX = 0;
	protected mouseY = 0;
	public onMouseMove(x: number, y: number) {
		this.mouseX = x;
		this.mouseY = y;
		if (this.mouseDown !== true) { return; }
		this.updateSlider();
	}

	public get width() { return this.parent.width; }
	public get height() { return 40; }

	private barSize = 3;
	private sliderInnerSize = 16;
	private sliderSize = 18;

	private barColor = Colors.Yellow;
	private sliderColor = Colors.Yellow;
	private sliderInnerColor = Colors.Black;
	private sliderHoverColor = Colors.DarkGray;
	private sliderActiveColor = Colors.Red;

	protected updateSlider() {
		let barPadding = this.sliderSize;
		let barLength = this.width - barPadding * 2;
		this.value = (this.mouseX - barPadding) / barLength;
	}

	protected redraw() {
		this.redrawBar();
		this.redrawSlider();
	}

	protected redrawBar() {
		let { width, height } = this;

		this.bar.clear();
		this.bar.beginFill(this.barColor, 1);
		this.bar.drawRect(0, (height / 2) - this.barSize, width, this.barSize * 2);
		this.bar.endFill();
	}

	protected redrawSlider() {
		let { width, height } = this;

		let barPadding = this.sliderSize;
		let barLength = this.width - barPadding * 2;

		let barPosition = barLength * this.value;

		let sliderColor = this.mouseDown ? this.sliderActiveColor : this.sliderColor;
		let sliderInnerColor = (this.mouseOver || this.mouseDown) ? this.sliderHoverColor : this.sliderInnerColor;

		this.slider.clear();
		this.slider.beginFill(sliderColor, 1);
		this.slider.drawCircle(0, 0, this.sliderSize);
		this.slider.endFill();

		this.slider.beginFill(sliderInnerColor, 1);
		this.slider.drawCircle(0, 0, this.sliderInnerSize);
		this.slider.endFill();

		this.slider.x = barPosition + barPadding;
		this.slider.y = (height / 2);
	}

	public updateSliderPosition(value: number) {
		let barPadding = this.sliderSize;
		let barLength = this.width - barPadding * 2;
		let barPosition = barLength * value;
		this.slider.x = barPosition + barPadding;
	}

	/* Value */
	private $value: number;
	public get value() { return this.$value; }
	public setValue(value: number) { this.value = value; return this; }
	public set value(value: number) {
		value = value < 0 ? 0 : (value > 1) ? 1 : value;
		this.$value = value;
		this.onValueChanged.execute(this.value);
		this.updateSliderPosition(this.value);
	}

	/** Static Wrapper to create a standard volume slider */
	public static createVolumeSlider(container: UIContainer, name: string, group: SoundGroup) {
		let vContainer = container.add(new UIVerticalContainer({
			// height: new UISizePercentage(20),
			y: new UIPositionAlign(UIAlign.center),
			width: new UISizePercentage(100),
			fill: true
		}));

		let titleContainer = vContainer.add(new UIContainer({}));
		titleContainer.add(new UIContainer({
			x: new UIPositionAlign(UIAlign.center), y: new UIPositionAlign(UIAlign.bottom),
			width: new UISizePercentage(60), height: new UISizePercentage(100)
		})).add(new UIText(name.toLowerCase(), {
			y: new UIPositionAlign(UIAlign.bottom), color: Colors.Yellow, font: "Hyperspace",
			fontSize: VOLUME_TEXT_FONTSIZE
		}));

		let volumeContainer = vContainer.add(new UIHorizontalContainer({ fill: true }));
		let muteButton = volumeContainer.add(new UIContainer({
			width: new UISizePercentage(20)
		})).add(new UISpriteButton(group.volume === 0 ? TextureManager.OptionsTitleMute : TextureManager.OptionsTitleUnmute, {
			x: new UIPositionAlign(UIAlign.right, 10)
		}));

		let slider = volumeContainer.add(new UIContainer({
			width: new UISizePercentage(60)
		})).add(new VolumeSlider({
			value: 0
		}));

		let text = volumeContainer.add(new UIContainer({
			width: new UISizePercentage(20)
		})).add(new UIText(" ??%", {
			x: 5, y: new UIPositionAlign(UIAlign.center), color: Colors.Yellow, font: "Hyperspace",
			fontSize: VOLUME_TEXT_FONTSIZE
		}));

		muteButton.onClick.addCallback(() => {
			if (group.volume === 0) {
				group.setVolumeMultiplier(group.lastVolume > 0.01 ? group.lastVolume : 0.5);
			} else {
				group.lastVolume = group.volume;
				group.setVolumeMultiplier(0);
			}
			slider.value = group.volume;
		});

		slider.onValueChanged.addCallback((value) => {
			text.text = ("  " + ((value * 100) | 0)).substr(-3) + "%";
			group.setVolumeMultiplier(value);
			muteButton.texture = TextureManager.OptionsTitleUnmute;
			if (value === 0) {
				muteButton.texture = TextureManager.OptionsTitleMute;
			} else {
				muteButton.texture = TextureManager.OptionsTitleUnmute;
			}
		});

		slider.value = group.volume;
	}
}
