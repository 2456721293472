import * as pixi from "pixi.js";
import { UIElement, UIElementProperties } from "../UIElement";
import { UIManager } from "../UIManager";

export enum UIBorderMode {
	Default, Inner, Outer
}

export type UIBorderProperties = UIElementProperties & {
	borderColor?: number;
	borderWidth?: number;
	borderMode?: UIBorderMode;
};

export class UIBorder extends UIElement<UIBorderProperties> {

	protected internal: pixi.Graphics;

	constructor(properties?: UIBorderProperties) {
		super(properties);
		this.internal = new pixi.Graphics();
	}

	protected initialize(parent: UIElement | UIManager) {
		this.applyProperty(this.properties, "borderColor", 0xFFFFFF);
		this.applyProperty(this.properties, "borderWidth", 1);
		this.applyProperty(this.properties, "borderMode");

		super.initialize(parent);

		this.redraw();
	}

	public onResize(width: number, height: number) {
		super.onResize(width, height);
		this.redraw();
	}

	protected redraw() {
		this.internal.clear();
		if (this.borderWidth > 0 && this.alpha > 0.0) {
			this.internal.alpha = this.alpha;
			this.internal.lineStyle(this.borderWidth, this.borderColor);

			let width = this.borderWidth;

			if (this.borderMode === UIBorderMode.Default) {
				this.internal.drawRect(0, 0, this.width, this.height);
			} else if (this.borderMode === UIBorderMode.Inner) {
				this.internal.drawRect(width / 2, width / 2, this.width - width, this.height - width);
			} else if (this.borderMode === UIBorderMode.Outer) {
				this.internal.drawRect(-width / 2, -width / 2, this.width + width, this.height + width);
			} else {
				throw new Error("Invalid border mode");
			}
		}
	}

	public get width() { return this.parent.width; }
	public get height() { return this.parent.height; }

	/* Color */
	private $borderColor: number;
	public get borderColor() { return this.$borderColor; }
	public setBorderColor(value: number) { this.borderColor = value; return this; }
	public set borderColor(value: number) {
		this.$borderColor = value;
		if (this.initialized === true) {
			this.redraw();
		}
	}

	/* Border Width */
	private $borderWidth: number;
	public get borderWidth() { return this.$borderWidth; }
	public setBorderWidth(value: number) { this.borderWidth = value; return this; }
	public set borderWidth(value: number) {
		this.$borderWidth = value;
		if (this.initialized === true) {
			this.redraw();
		}
	}

	/* Border Mode */
	private $borderMode: UIBorderMode = UIBorderMode.Default;
	public get borderMode() { return this.$borderMode; }
	public setBorderMode(value: UIBorderMode) { this.borderMode = value; return this; }
	public set borderMode(value: UIBorderMode) {
		this.$borderMode = value;
		if (this.initialized === true) {
			this.redraw();
		}
	}

}
