import { UIElement } from "./UIElement";
import { App } from "../app";

export type UIPosition = number | UIPositionFunction;

export enum UIDirection {
	X, Y
}
export abstract class UIPositionFunction {
	constructor() {

	}
	public abstract compute(direction: UIDirection, element: UIElement): number;
}

export enum UIAlign {
	left = 1, center = 2, right = 3,
	top = 1, middle = 2, bottom = 3
}
export class UIPositionAlign extends UIPositionFunction {
	constructor(public readonly align: UIAlign, public readonly offset: number = 0) {
		super();
	}

	public compute(direction: UIDirection, element: UIElement) {
		let currentSize = direction === UIDirection.X ? element.width : element.height;
		let parentSize = direction === UIDirection.X ? element.parent.availableWidth : element.parent.availableHeight;
		if (this.align === UIAlign.left) {
			return 0 + this.offset;
		} else if (this.align === UIAlign.center) {
			return parentSize / 2 - (currentSize / 2) + this.offset;
		} else if (this.align === UIAlign.right) {
			return parentSize - currentSize - this.offset;
		} else {
			throw new Error("Couldn't compute invalid align");
		}
	}
}

export type UISize = number | UISizeFunction;

export abstract class UISizeFunction {
	constructor() {

	}
	public abstract compute<T extends UIElement>(direction: UIDirection, element?: T): number;
}

export class UISizePercentage extends UISizeFunction {
	constructor(public readonly percentage: number) {
		super();
	}

	public compute(direction: UIDirection, element?: UIElement) {
		if (element === undefined) {
			let parentSize = direction === UIDirection.X ? App.width : App.height;
			return parentSize * (this.percentage / 100);
		} else {
			let parentSize = direction === UIDirection.X ? element.parent.availableWidth : element.parent.availableHeight;
			return parentSize * (this.percentage / 100);
		}
	}
}

export class UISizeDynamic extends UISizeFunction {
	constructor(public readonly config: {
		height: number; size: UISize;
	}[], public readonly meta: {
		interpolate: boolean, floor: boolean
	}) {
		super();
	}

	public compute(direction: UIDirection, element?: UIElement) {
		let id = this.config.findIndex((x) => x.height <= App.height);
		if (id === -1) { id = this.config.length - 1; }

		let out;

		let handler = this.config[id];
		if (id >= 1 && this.meta.interpolate === true) {
			let prev = this.config[id - 1];

			let current = (typeof handler.size === "number") ? handler.size : handler.size.compute(direction, element);
			let other = (typeof prev.size === "number") ? prev.size : prev.size.compute(direction, element);

			let diff = (App.height - handler.height) / (prev.height - handler.height);

			out = (1 - diff) * current + diff * other;
		} else {
			out = (typeof handler.size === "number") ? handler.size : handler.size.compute(direction, element);
		}

		if (this.meta.floor) { out = out | 0; }
		return out;
	}
}
