import * as pixi from "pixi.js";
import { EntityManager } from "./EntityManager";
import Matter = require("matter-js");
import { Entity, EntityType } from "./Entities/Entity";
import { EventSystem } from "./EventSystem";
import { FadeAnimationLogic, FADE_ANIMATION_TIME, FADE_ANIMATION_HOLD_TIME } from "../Gameplay/FadeAnimationLogic";
import { Colors } from "../utils/Colors";
import { SectorPlayerEntity } from "../entity/SectorPlayerEntity";
import { UIManager } from "./UIManager";
import { Globals } from "../Gameplay/Constants";
import { App } from "../app";

export abstract class Scene {
	public readonly stage: pixi.Container = new pixi.Container();
	public readonly backgroundContainer: pixi.Container = new pixi.Container();
	public readonly gameWorldContainerOuter: pixi.Container = new pixi.Container();
	public readonly gameWorldOverlayContainerOuter: pixi.Container = new pixi.Container();
	public readonly gameWorldContainer: pixi.Container = new pixi.Container();
	public readonly gameWorldOverlayContainer: pixi.Container = new pixi.Container();
	public readonly uiContainer: pixi.Container = new pixi.Container();

	private sceneInitialized = false;
	private scenePromise: Promise<void>;
	protected readonly active = false;
	private pause: boolean = false;
	private wasPaused: boolean = false;

	protected readonly margin: number;
	protected readonly worldYOffset: number;

	public readonly entityManager = new EntityManager(this);
	public readonly eventSystem = new EventSystem(this);
	public readonly uiManager = new UIManager(this);
	public readonly deltaTime = 0 as number;

	// MatterJS
	public readonly physicsEngine: Matter.Engine;
	public readonly physicsWorld: Matter.World;
	private debugBodies: pixi.Graphics[] = [];

	public async load() {
		if (this.sceneInitialized === true) { return; }
		if (this.scenePromise) { return this.scenePromise; }

		App.onResizeEvent.addCallback((width, height) => {
			this.onResize(width, height);
			this.uiManager.onResize(width, height);
		});

		this.uiManager.onResize(App.width, App.height);

		// Setup Container Sizes
		this.stage.width = App.width;
		this.stage.height = App.height;

		this.gameWorldContainerOuter.width = App.width;
		this.gameWorldContainerOuter.height = App.height;
		this.gameWorldOverlayContainerOuter.width = App.width;
		this.gameWorldOverlayContainerOuter.height = App.height;

		this.gameWorldContainer.width = Globals.WORLD_WIDTH;
		this.gameWorldContainer.height = Globals.WORLD_HEIGHT;

		this.gameWorldOverlayContainer.width = Globals.WORLD_WIDTH;
		this.gameWorldOverlayContainer.height = Globals.WORLD_HEIGHT;

		this.backgroundContainer.width = App.width;
		this.backgroundContainer.height = App.height;

		this.uiContainer.width = App.width;
		this.uiContainer.height = App.height;

		// Push Gameworld into Parent Container
		this.stage.addChild(this.backgroundContainer);
		this.stage.addChild(this.gameWorldContainerOuter);
		this.gameWorldContainerOuter.addChild(this.gameWorldContainer);
		this.stage.addChild(this.gameWorldOverlayContainerOuter);
		this.gameWorldOverlayContainerOuter.addChild(this.gameWorldOverlayContainer);
		this.stage.addChild(this.uiContainer);

		// Setup MatterJS
		(this as any).physicsEngine = Matter.Engine.create();
		(this as any).physicsWorld = this.physicsEngine.world;
		// Matter.Engine.run(this.physicsEngine);

		let initPromise = this.init();
		if (initPromise instanceof Promise) {
			this.scenePromise = initPromise;
			await initPromise;
		}
		this.sceneInitialized = true;

		// Let the mouse interact with the scene and give the X/Y coordinates of it
		this.stage.interactive = true;
		this.stage.on("mousemove", (event: pixi.interaction.InteractionEvent) => {
			this.uiManager.onMouseMove(event.data.global.x, event.data.global.y);
			this.onMouseMove(event.data.global.x, event.data.global.y);
		}, this);

		this.stage.on("click", (event: pixi.interaction.InteractionEvent) => {
			this.onMouseClick(event.data.global.x, event.data.global.y);
		}, this);

		// Handle Collision Events for game logic
		Matter.Events.on(this.physicsEngine, "collisionStart", (event: Matter.IEventCollision<Matter.Engine>) => {
			for (let p of event.pairs) {
				let idA = parseInt(p.bodyA.label, 10);
				let idB = parseInt(p.bodyB.label, 10);
				let entityA = this.entityManager.getEntityByID(idA);
				let entityB = this.entityManager.getEntityByID(idB);

				if (entityA === undefined || entityB === undefined) {
					continue;
				}

				this.onCollision(entityA, entityB);

				let entityTypeA = entityA.type;
				let entityTypeB = entityB.type;
				let swap = entityTypeB < entityTypeA;
				let key = (swap === false) ? ((entityTypeA << 16) + entityTypeB) : ((entityTypeB << 16) + entityTypeA);

				let obj = this.collisionEventMap.get(key);
				if (obj) {
					if (obj.swap === swap) {
						obj.callback(entityA, entityB);
					} else {
						obj.callback(entityB, entityA);
					}
				}
			}
		});

		this.onResize(App.width, App.height);
	}

	public abstract init(): void | Promise<void>;

	// Frame Update
	public update(dt: number) { }
	protected pauseUpdate(dt: number) { } // called if the scene is paused

	private readonly collisionEventMap = new Map<number, { swap: boolean, callback: (a: any, b: any) => void }>();

	// Handle Collisions Between Objects
	public onCollision(bodyA: Entity, bodyB: Entity) { }
	public onCollisionEvent<T1, T2>(callback: (entityA: T1, entityB: T2) => void, entityTypeA: EntityType, entityTypeB: EntityType) {
		let swap = entityTypeB < entityTypeA;
		let key = (swap === false) ? ((entityTypeA << 16) + entityTypeB) : ((entityTypeB << 16) + entityTypeA);

		if (this.collisionEventMap.has(key)) {
			throw Error("Scene:onCollisionEvent - Duplicate Collision Event Registered!");
		}

		this.collisionEventMap.set(key, { swap, callback });
	}

	// Functions to override for handing keyboard input
	public keyUp(key: KeyboardEvent) { }
	public keyDown(key: KeyboardEvent) { }
	public onMouseMove(x: number, y: number) { }
	public onMouseClick(x: number, y: number) { }

	protected onActivated() { }
	protected onDeactivated() { }

	// Can be overriden, covers the resize event in most cases
	protected onResize(appWidth: number, appHeight: number) {
		const offset = this.worldYOffset !== undefined ? this.worldYOffset : 50;
		const margin = this.margin !== undefined ? this.margin : 50;

		this.gameWorldContainer.x = margin;
		this.gameWorldContainer.y = margin + offset;

		this.gameWorldContainer.scale.x = (appWidth - (margin * 2)) / Globals.WORLD_WIDTH;
		this.gameWorldContainer.scale.y = (appHeight - (margin * 2)) / Globals.WORLD_HEIGHT;

		this.gameWorldOverlayContainer.x = margin;
		this.gameWorldOverlayContainer.y = margin + offset;

		this.gameWorldOverlayContainer.scale.x = (appWidth - (margin * 2)) / Globals.WORLD_WIDTH;
		this.gameWorldOverlayContainer.scale.y = (appHeight - (margin * 2)) / Globals.WORLD_HEIGHT;
	}

	// Debug
	public drawPhysics() {
		// Debug Physics
		// Remove Old bodies
		for (let a of this.debugBodies) {
			this.gameWorldOverlayContainer.removeChild(a);
		}

		this.debugBodies = [];

		for (let body of this.physicsWorld.bodies) {
			if (body === undefined || body.vertices.length === 0) {
				break;
			}

			let obj = new pixi.Graphics();
			obj.lineStyle(3, Colors.Green);

			let start = body.vertices[0];
			obj.moveTo(start.x, start.y);

			for (let i = 1; i < body.vertices.length; i++) {
				let v = body.vertices[i];
				obj.lineTo(v.x, v.y);
			}
			obj.lineTo(start.x, start.y);

			this.gameWorldOverlayContainer.addChild(obj);
			this.debugBodies.push(obj);
		}
	}

	// Helper function to add this entity to the scene and entity manager
	public addToWorld(entity: Entity) {
		if (entity === undefined) {
			throw Error("Scene:addToWorld - Undefined Entity");
		}

		if (!entity.isOverlay) {
			this.gameWorldContainer.addChild(entity.getGraphicsContainer());
		} else {
			this.gameWorldOverlayContainer.addChild(entity.getGraphicsContainer());
		}
		this.entityManager.add(entity);
	}

	// Another way of calling "entity.removeFromWorld() with a safety check"
	public removeFromWorld(entity: Entity) {
		if (entity === undefined) {
			throw Error("Scene:addToScene - Undefined Entity");
		}

		entity.removeFromWorld();
	}

	// Wrapper to run a fade-in animation on a container
	public async runFadeIn(container: any, fadeTime: number = FADE_ANIMATION_TIME) {
		await FadeAnimationLogic.fadeIn(this.eventSystem, container, fadeTime);
	}

	// Wrapper to run a fade-in animation on a container
	public async runFadeOut(container: any, fadeTime: number = FADE_ANIMATION_TIME) {
		await FadeAnimationLogic.fadeOut(this.eventSystem, container, fadeTime);
	}

	// Run a text fade in frame
	public async runFade(container: any, fadeTime: number = FADE_ANIMATION_TIME, holdTime: number = FADE_ANIMATION_HOLD_TIME) {
		await FadeAnimationLogic.runContainerFade(this.eventSystem, container, fadeTime, holdTime, false);
	}

	/** internal */
	private deactivateScene() {
		(this as any).active = false;
		this.onDeactivated();
	}

	/** internal */
	private activateScene() {
		(this as any).active = true;
		this.onActivated();
	}

	/** internal */
	private updateScene(dt: number) {
		(this as any).deltaTime = dt;
		if (this.pause) {
			this.pauseUpdate(dt);
		} else {
			this.eventSystem.startTick(dt);
			this.update(dt);
			this.entityManager.update(dt);
			Matter.Engine.update(this.physicsEngine, dt);
			this.eventSystem.endTick();
			this.wasPaused = false;
		}
	}



	/** Return the player for this scene, overriden by child classes */
	public getPlayerEntity(): SectorPlayerEntity | undefined {
		return undefined;
	}

	public setPause(pause: boolean) {
		this.pause = pause;
		if (this.pause) {
			this.wasPaused = true;
		}
	}

	public isPaused(): boolean {
		return this.pause;
	}

	// Hack to help determine if we were just paused so we don't do too many mouse-clicks in the gameplay.
	public wasJustPaused(): boolean {
		return this.wasPaused;
	}
}
