
export namespace Globals {
	export const DEBUG_MODE = true;
	export const DEFAULT_NUM_ROUNDS = 50;
	export const SECTOR_PLAYER_HEALTH = 5;
	export const SECTOR_PLAYER_SHIELD = SECTOR_PLAYER_HEALTH - 1;
	export const GLOBAL_SCORE_BONUS_TARGET = 0.004;
	export const WORLD_WIDTH = 1000;
	export const WORLD_HEIGHT = 1000;
}
