import { Scene } from "../core/Scene";
import Text from "../core/Text";
import { Colors } from "../utils/Colors";
import { EnvironmentContainer } from "../containers/EnvironmentContainer";
import { App } from "../app";
import { FADE_ANIMATION_TIME, FADE_ANIMATION_HOLD_TIME } from "../Gameplay/FadeAnimationLogic";
import { Entity, EntityType } from "../core/Entities/Entity";
import { WarpPlayerEntity } from "../entity/WarpPlayerEntity";
import { WarpEntity, WarpEntityType } from "../entity/WarpEntity";
import { Utils } from "@h4x/common";
import { WarpBackgroundEntity } from "../entity/WarpBackgroundEntity";
import { ImpactEntity } from "../entity/ImpactEntity";
import { GameplayLogicController, GameplayLogicState } from "../Gameplay/GameLogicController";
import { UIBorder, UIBorderMode } from "../core/UI/UIBorder";
import { InputManager } from "../core/InputManager";

const MAX_WARP_ENTITIES = 10;
const TITLE_Y = 150;

export class GameSceneWarp extends Scene {
	// Primary Entities
	private player: WarpPlayerEntity;

	// Warp Entity Logic
	private spawnTime: number = 0;
	private warpEntities: WarpEntity[] = [];

	// Text
	private titleText: Text;

	public init() {
		// Disable Gravity
		this.physicsWorld.gravity.y = 0;

		this.backgroundContainer.addChild(new EnvironmentContainer());
		this.uiManager.add(new UIBorder({ borderColor: Colors.Orange, borderWidth: 5, borderMode: UIBorderMode.Inner }));

		// Create Text
		this.titleText = new Text("", {
			fill: Colors.White,
			fontFamily: "Hyperspace"
		});
		this.titleText.anchor.set(0.5);
		this.titleText.y = TITLE_Y;
		this.uiContainer.addChild(this.titleText);

		this.createPrimaryEntities();

		this.runLogic();
	}

	// Create the primary entities
	private createPrimaryEntities() {
		let wbe = new WarpBackgroundEntity(this, App.width / 2, App.height / 2, Colors.Green);
		this.addToWorld(wbe);

		this.player = new WarpPlayerEntity(this, App.width / 2, App.height / 2);
		this.addToWorld(this.player);
	}

	// Run the logic for this scene
	private async runLogic() {
		await this.runPhaseIntro(true);
	}

	// Easy wrapper to set the title text and re-center it
	private setTitleText(text: string) {
		this.titleText.text = text.toLowerCase();
		this.titleText.x = (App.width / 2);
	}

	// Run a fade in on the title
	public async runTitleFadeIn(text: string, fadeTime: number = FADE_ANIMATION_TIME) {
		this.setTitleText(text);
		await this.runFadeIn(this.titleText, fadeTime);
	}

	// Run a fade in on the title
	public async runTitleFadeOut(fadeTime: number = FADE_ANIMATION_TIME) {
		await this.runFadeOut(this.titleText, fadeTime);
	}

	// Run a fade animation sequence on the title
	public async runTitleFade(text: string, fadeTime: number = FADE_ANIMATION_TIME, holdTime: number = FADE_ANIMATION_HOLD_TIME) {
		this.setTitleText(text);
		await this.runFade(this.titleText, fadeTime);
	}

	// Handle when a key on the keyboard is released
	public keyUp(key: KeyboardEvent) {
		if (key.keyCode === InputManager.KeyN) {
			GameplayLogicController.setState(GameplayLogicState.SECTOR);
		}
	}

	// Called when the mouse moves on the screen
	public onMouseMove(x: number, y: number) {
		if (this.player) {
			this.player.setTrackPoint(x, y);
		}
	}

	// Called when the mouse is clicked
	public onMouseClick(x: number, y: number) {
	}

	// On Collision
	public onCollision(entityA: Entity, entityB: Entity) {
		let player: WarpPlayerEntity = undefined as any;
		let warpEntity: WarpEntity = undefined as any;
		if (entityA.type === EntityType.WarpPlayer) {
			player = (entityA as WarpPlayerEntity);
			warpEntity = (entityB as WarpEntity);
		}

		if (entityB.type === EntityType.SectorPlayer) {
			player = (entityB as WarpPlayerEntity);
			warpEntity = (entityA as WarpEntity);
		}

		if (player! === undefined || warpEntity! === undefined) {
			return;
		}

		// Generate Impact
		// Create Impact
		let impact = new ImpactEntity(this, warpEntity.x, warpEntity.y);
		this.addToWorld(impact);

		this.removeWarpEntity(warpEntity);
	}

	// Phase: Intro
	private async runPhaseIntro(skip: boolean) {
		if (!skip) {
			await this.eventSystem.timeout(250);
			await this.runTitleFade("Prepare for Battle!");
		}
	}

	public update(dt: number) {
		this.warpEntitySpawnLogic(dt);

		for (let w of [...this.warpEntities]) {
			if (w.getTimeRemaining() <= 0) {
				this.removeWarpEntity(w);
			}
		}
	}

	// Destroy an enemy and play an impact animation
	private removeWarpEntity(warpEntity: WarpEntity) {
		let index = this.warpEntities.indexOf(warpEntity);
		if (index !== -1) {
			this.warpEntities.splice(index, 1);
		}

		this.removeFromWorld(warpEntity);
	}

	// Create More Enemies if needed
	private warpEntitySpawnLogic(dt: number) {
		// run movement
		this.spawnTime += dt;

		if (this.spawnTime >= 1.0) {
			this.spawnTime = 0;

			// Create an Enemy
			if (this.warpEntities.length < MAX_WARP_ENTITIES) {
				let type = Utils.randomInt(0, 1) === 1 ? WarpEntityType.POWERUP : WarpEntityType.ENEMY;
				let warpEntity = new WarpEntity(this, App.width / 2, App.height / 2, type);
				this.addToWorld(warpEntity);
				this.warpEntities.push(warpEntity);
			}
		}
	}
}
