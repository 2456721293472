import * as pixi from "pixi.js";

export default class Text extends pixi.Text {

	public setPosition(x: number, y: number) {
		this.x = x;
		this.y = y;
		return this;
	}

	public hide() {
		this.alpha = 0;
	}

	public show() {
		this.alpha = 1;
	}
}
