import { Howl, Howler } from "howler";
import { Utils, BasicEvent } from "@h4x/common";
import { Globals } from "../Gameplay/Constants";

export class SoundInstance {
	public readonly onEndEvent = new BasicEvent<(instance: SoundInstance) => void>();
	private _volume: number = 1.0;


	constructor(private sound: Sound, public readonly id: number) {
		this.updateVolume();

		// this.sound.howl.on("play", () => { console.log("SoundPlayback", "play", this.id); }, this.id);
		this.sound.howl.on("play", () => { }, this.id);
		this.sound.howl.on("end", () => {
			// console.log("SoundPlayback", "end", this.id, this.isLooping());
			if (this.isLooping() === false) {
				this.onEndEvent.execute(this);
			}
		}, this.id);
		this.sound.howl.on("stop", () => {
			// console.log("SoundPlayback", "stop", this.id);
			this.onEndEvent.execute(this);
		}, this.id);
	}

	public isLooping() {
		return this.sound.howl.loop(this.id as any) as any as boolean;
	}

	public loop(value: boolean) {
		this.sound.howl.loop(value, this.id);
		return this;
	}

	public pause() {
		this.sound.howl.pause(this.id);
		return this;
	}

	public play() {
		this.sound.howl.play(this.id);
		return this;
	}

	public stop() {
		this.sound.howl.stop(this.id);
		return this;
	}

	public volume(value: number) {
		this._volume = value;
		return this.updateVolume();
	}

	public updateVolume() {
		this.sound.howl.volume(SoundManager.volume * this.sound.group.volume * this.sound.volume * this._volume, this.id);
		return this;
	}

	public mute() {
		this.sound.howl.mute(true, this.id);
		return this;
	}

	public unmute() {
		this.sound.howl.mute(false, this.id);
		return this;
	}

	/*
	DOESN'T WORK...
	public fade(from: number, to: number, duration: number) {
		this.sound.howl.fade(this.sound.volume * from, this.sound.volume * to, duration, this.id);
		return this;
	}
	*/
}

export class SoundGroup {
	public readonly volume: number = 1.0;
	public lastVolume: number = 1.0;
	private sounds: Sound[] = [];

	public add(sound: Sound) {
		this.sounds.push(sound);
	}

	public setVolumeMultiplier(value: number) {
		(this as any).volume = value;
		this.updateVolumeMultiplier();
		return this;
	}

	public updateVolumeMultiplier() {
		for (const sound of this.sounds) {
			for (const instance of sound.instances) { instance.updateVolume(); }
		}
		return this;
	}
}

export class Sound {
	public readonly volume: number = 1.0;
	public howl: Howl;

	public readonly instances = new Set<SoundInstance>();

	constructor(public readonly group: SoundGroup, src: string[]) {
		this.howl = new Howl({ src: src, preload: true });
		this.group.add(this);
	}

	public play() {
		let instance = new SoundInstance(this, this.howl.play());
		this.instances.add(instance);
		instance.onEndEvent.addCallback(this.removeInstance, this);
		return instance;
	}

	private removeInstance(instance: SoundInstance) {
		this.instances.delete(instance);
	}

	public setVolumeMultiplier(value: number) {
		(this as any).volume = value;
		for (const instance of this.instances) { instance.updateVolume(); }
		return this;
	}
}

export class SoundManager {
	public static readonly Effects = new SoundGroup();
	public static readonly Background = new SoundGroup();

	public static readonly volume: number = 1.0;
	public static setVolumeMultiplayer(value: number) {
		(this as any).volume = value;
		SoundManager.Effects.updateVolumeMultiplier();
		SoundManager.Background.updateVolumeMultiplier();
		return this;
	}

	// TEST SOUNDS
	public static readonly TestExplosion = new Sound(SoundManager.Effects, ["./assets/sounds/Test-Explosion.mp3"]);
	public static readonly TestBomb = new Sound(SoundManager.Background, ["./assets/sounds/Test-Bomb.mp3"]);

	// SOUND FILES
	public static readonly ShipMoveToWarp = new Sound(SoundManager.Effects, ["./assets/sounds/retro_spaceship_jet_boost_01.mp3"]);
	public static readonly TallyClusterCollect = new Sound(SoundManager.Effects, ["./assets/sounds/retro_magic_spell_cast_sparkle_18.mp3"]);
	public static readonly WarpScene = new Sound(SoundManager.Effects, ["./assets/sounds/retro_spaceship_engine_03.mp3"]).setVolumeMultiplier(0.25);
	public static readonly Tally = new Sound(SoundManager.Effects, ["./assets/sounds/retro_beeps_success_01_fast_loop.wav"]);
	public static readonly WarpHoleGrowsUp = new Sound(SoundManager.Effects, ["./assets/sounds/retro_misc_various_sounds_01.mp3"]).setVolumeMultiplier(0.5);
	public static readonly BerzerkerShake = new Sound(SoundManager.Effects, ["./assets/sounds/sfx_sound_poweron.mp3"]);
	public static readonly BerzerkerDash = new Sound(SoundManager.Effects, ["./assets/sounds/sfx_sound_shutdown1.mp3"]);
	public static readonly ChaserAggroOn = new Sound(SoundManager.Effects, ["./assets/sounds/sfx_sounds_interaction15.mp3"]).setVolumeMultiplier(0.7);
	public static readonly EnemySpawn = new Sound(SoundManager.Effects, ["./assets/sounds/retro_alarm_siren_loop_05.mp3"]).setVolumeMultiplier(0.8);
	public static readonly Impact = new Sound(SoundManager.Effects, ["./assets/sounds/Blip_002.mp3"]);
	public static readonly JumpIn = new Sound(SoundManager.Effects, ["./assets/sounds/sfx_exp_cluster4.mp3"]);
	public static readonly JumpOut = new Sound(SoundManager.Effects, ["./assets/sounds/retro_teleport_warp_effect_35.mp3"]);
	public static readonly PatrollerSpin = new Sound(SoundManager.Effects, ["./assets/sounds/retro_collect_pickup_item_27.mp3"]);
	public static readonly ShipDestroyed = new Sound(SoundManager.Effects, ["./assets/sounds/Die.mp3"]).setVolumeMultiplier(0.5);
	public static readonly ShipThrust = new Sound(SoundManager.Effects, ["./assets/sounds/Noise_001.mp3"]).setVolumeMultiplier(0.5);
	public static readonly WarpOut = new Sound(SoundManager.Effects, ["./assets/sounds/sfx_sound_depressurizing.mp3"]).setVolumeMultiplier(0.25);
	public static readonly WarpholeSpin = new Sound(SoundManager.Effects, ["./assets/sounds/retro_teleport_warp_effect_08.wav"]).setVolumeMultiplier(0.5);
	public static readonly GameLoopMusic = new Sound(SoundManager.Background, ["./assets/sounds/Bit_Space_(loopable).mp3"]);
	public static readonly GameMenuMusic = new Sound(SoundManager.Background, ["./assets/sounds/Pixel_River.mp3"]);
	public static readonly GameOverMusic = new Sound(SoundManager.Background, ["./assets/sounds/retro__musical_stinger_03.mp3"]);
	public static readonly WarpOutMusic = new Sound(SoundManager.Effects, ["./assets/sounds/Victory.mp3"]);
	public static readonly ClusterScoreBelow = new Sound(SoundManager.Effects, ["./assets/sounds/retro_alarm_siren_loop_08.mp3"]).setVolumeMultiplier(0.5);
	public static readonly ClusterScoreAbove = new Sound(SoundManager.Effects, ["./assets/sounds/retro_alarm_siren_loop_07.mp3"]).setVolumeMultiplier(0.5);
	public static readonly GlobalScoreUp = new Sound(SoundManager.Effects, ["./assets/sounds/retro_beeps_collect_item_03.mp3"]);
	public static readonly GlobalScoreDown = new Sound(SoundManager.Effects, ["./assets/sounds/retro_beeps_collect_item_05.mp3"]);
	public static readonly GlobalScoreHigh = new Sound(SoundManager.Effects, ["./assets/sounds/retro_beeps_collect_item_04.mp3"]);
	public static readonly GlobalScoreLow = new Sound(SoundManager.Effects, ["./assets/sounds/retro_beeps_collect_item_08.mp3"]);
	public static readonly MenuBackButton = new Sound(SoundManager.Effects, ["./assets/sounds/retro_ui_menu_error_01.mp3"]);
	public static readonly MenuMouseover = new Sound(SoundManager.Effects, ["./assets/sounds/retro_ui_menu_popup_02.mp3"]);
	public static readonly MenuSelect = new Sound(SoundManager.Effects, ["./assets/sounds/retro_ui_menu_blip_click_01.mp3"]).setVolumeMultiplier(0.5);
	public static readonly PauseMenuExit = new Sound(SoundManager.Effects, ["./assets/sounds/sfx_sounds_pause5_out.mp3"]);
	public static readonly PauseMenuOn = new Sound(SoundManager.Effects, ["./assets/sounds/sfx_sounds_pause5_in.mp3"]);
	public static readonly PlaceWarphole = new Sound(SoundManager.Effects, ["./assets/sounds/retro_ui_menu_blip_click_19.mp3"]);
	public static readonly ShieldsDepleted = new Sound(SoundManager.Effects, ["./assets/sounds/sfx_alarm_loop2.mp3"]);
	public static readonly TimerRunningOut = new Sound(SoundManager.Effects, ["./assets/sounds/sfx_alarm_loop3.mp3"]);
	public static readonly MessageAppears = new Sound(SoundManager.Effects, ["./assets/sounds/retro_alarm_siren_loop_03.mp3"]).setVolumeMultiplier(0.25);
	public static readonly TimeCountdown = new Sound(SoundManager.Effects, ["./assets/sounds/retro_ui_menu_simple_click_02.mp3"]);
	public static readonly PointsTally = new Sound(SoundManager.Effects, ["./assets/sounds/retro_collect_pickup_coin_04.mp3"]);

}

/*setTimeout(async () => {
	let sound = SoundManager.TestExplosion.play();
	sound.loop(true);
	await Utils.timeout(2000);
	// sound.volume(0.333);
	SoundManager.Effects.setVolumeMultiplayer(0.5);
	await Utils.timeout(2000);
	SoundManager.TestExplosion.setVolumeMultiplayer(0.5);
	await Utils.timeout(2000);
	sound.stop();
	await Utils.timeout(3000);
	sound.loop(false);
}, 100);*/


// Set Initial Volumes

if (Globals.DEBUG_MODE) {
	SoundManager.Effects.setVolumeMultiplier(0.5);
	SoundManager.Background.setVolumeMultiplier(0.2);
} else {
	SoundManager.Effects.setVolumeMultiplier(0.0);
	SoundManager.Background.setVolumeMultiplier(0.0);
	SoundManager.Effects.lastVolume = 0.5;
	SoundManager.Background.lastVolume = 0.2;
}

