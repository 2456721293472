import * as pixi from "pixi.js";

export class TextureManager {

	private static loader = pixi.Loader.shared;

	// Menu Textures
	public static TitleOmegaCluster = TextureManager.createTexture("titleOmegaCluster", "./assets/Title-Omega-Cluster.png");
	public static OptionsTitleMute = TextureManager.createTexture("optionsTitleMute", "./assets/UI-Mute.png");
	public static OptionsTitleUnmute = TextureManager.createTexture("optionsTitleUnmute", "./assets/UI-Unmute.png");
	public static AboutPage = TextureManager.createTexture("aboutPage", "./assets/About.svg");

	// UI Textures
	public static UIPlayerShieldIcon = TextureManager.createTexture("uiPlayerShieldIcon", "./assets/UI-Shield-Ship.png");
	public static UIGlobalIndicatorArrow = TextureManager.createTexture("uiGlobalIndicatorArrow", "./assets/Global-Indicator-Arrow.png");
	public static UIGlobalIndicatorNeutral = TextureManager.createTexture("uiGlobalIndicatorNeutral", "./assets/Global-Indicator-Neutral.png");
	public static UIGlobalScoreHash = TextureManager.createTexture("uiGlobalScoreHash", "./assets/Global-Score-Hash.png");

	// Player Textures
	public static Player = TextureManager.createTexture("player", "./assets/Player.png");
	public static PlayerThrust = TextureManager.createTexture("playerThrust", "./assets/Player-Thrust.png");
	public static PlayerWarp = TextureManager.createTexture("playerWarp", "./assets/Player-Warp.png");
	public static PlayerWarpThrust = TextureManager.createTexture("playerWarpThrust", "./assets/Player-Warp-Thrust.png");

	// Powerup Textures
	public static PowerupTextureArray: pixi.Texture[] = [];
	public static PowerUp2k = TextureManager.createTexture("powerUp2k", "./assets/PowerUp-2k.png");
	public static PowerUp3k = TextureManager.createTexture("powerUp3k", "./assets/PowerUp-3k.png");
	public static PowerUpShield = TextureManager.createTexture("powerUpShield", "./assets/PowerUp-Shield.png");

	// Enemy Textures
	public static EnemyTextureArray: pixi.Texture[] = [];
	public static EnemyChaser = TextureManager.createTexture("enemyChaser", "./assets/EnemyChaser.png");
	public static EnemyBezerker = TextureManager.createTexture("enemyBerzerker", "./assets/EnemyBerzerker.png");
	public static EnemyPatroller = TextureManager.createTexture("enemyPatroller", "./assets/EnemyPatroller.png");
	public static EnemyWinder = TextureManager.createTexture("enemyWinder", "./assets/EnemyWinder.png");

	// Data Textures
	public static PositiveDataClustered = TextureManager.createTexture("positiveDataClustered", "./assets/PositiveData-Clustered.png");
	public static NegativeDataClustered = TextureManager.createTexture("negativeDataClustered", "./assets/NegativeData-Clustered.png");
	public static PositiveDataUnclustered = TextureManager.createTexture("positiveDataUnclustered", "./assets/PositiveData-Unclustered.png");
	public static NegativeDataUnclustered = TextureManager.createTexture("negativeDataUnclustered", "./assets/NegativeData-Unclustered.png");

	// Effect Textures
	public static Impact = TextureManager.createTexture("impact", "./assets/Impact.png");
	public static WarpHole = TextureManager.createTexture("warphole", "./assets/Warp-Hole.png");
	public static Burst = TextureManager.createTexture("burst", "./assets/Burst.png");

	// Tutorial textures
	public static TutorialArrowUp = TextureManager.createTexture("tutorialArrowUp", "./assets/TutorialArrowUp.png");
	public static TutorialBan = TextureManager.createTexture("tutorialBan", "./assets/TutorialBadPlacement.png");

	public static createTexture(name: string, url: string): pixi.Texture {
		return {
			$load: new Promise<pixi.Texture>((resolve) => {
				TextureManager.loader.add(name, url, { }, (resource: pixi.LoaderResource) => {
					if (resource.error) {
						console.log(name, url, resource);
						console.error(resource.error);
					}
					resolve(resource.texture);
				});
			})
		} as any;
	}

	public static init() {
		return new Promise<void>((resolve) => {

			pixi.Loader.shared.once("complete", async () => {
				// HAX OP
				for (const key in TextureManager) {
					if (TextureManager.hasOwnProperty(key)) {
						const element = (TextureManager as any)[key];
						if (element.$load !== undefined && element.$load instanceof Promise) {
							(TextureManager as any)[key] = await element.$load;
						}
					}
				}

				// Helper container to access all the Enemy textures
				this.EnemyTextureArray.push(TextureManager.EnemyChaser);
				this.EnemyTextureArray.push(TextureManager.EnemyBezerker);
				this.EnemyTextureArray.push(TextureManager.EnemyPatroller);
				this.EnemyTextureArray.push(TextureManager.EnemyWinder);

				// Helper container to access all the PowerUp textures
				this.PowerupTextureArray.push(TextureManager.PowerUp2k);
				this.PowerupTextureArray.push(TextureManager.PowerUp3k);
				this.PowerupTextureArray.push(TextureManager.PowerUpShield);

				resolve();
			}).load();
		});
	}
}
