import { Behavior } from "./Behavior";
import { DynamicEntity } from "../../core/Entities/DynamicEntity";
import { SectorPlayerEntity } from "../../entity/SectorPlayerEntity";
import { DartBehavior, DartBehaviorState } from "./DartBehavior";
import { Vec2, Utils } from "@h4x/common";
import { Globals } from "../Constants";

/** Shake the graphics object of the entity for a given amount of time */
export class EnemySectorSpawnBehavior extends Behavior<DynamicEntity, void> {
	private player: SectorPlayerEntity;

	private dartBehavior: DartBehavior;

	private travelToCoords: Vec2 = new Vec2();

	constructor(owner: DynamicEntity) {
		super(owner);

		this.dartBehavior = new DartBehavior(owner);
		this.dartBehavior.setDartSpeed(750);
	}

	public update(dt: number): void {
		if (this.active === false || this.isSpawning() === false) {
			return;
		}

		this.dartBehavior.update(dt);
		let playerDist = Vec2.distance(this.owner.getPosition(), this.player.getPosition());
		if (playerDist < 200) {

			if (this.dartBehavior.getState() === DartBehaviorState.DART) {
				this.dartBehavior.setState(DartBehaviorState.SLOW);
			}

			let ownerPos = this.owner.getPosition();
			if (ownerPos.x < 0 || ownerPos.x > Globals.WORLD_WIDTH || ownerPos.y < 0 || ownerPos.y > Globals.WORLD_HEIGHT) {
				this.execute();
			}
		}

	}

	// Helper function to get the outer init spawn location
	private getOuterBounds(lower: number, upper: number): number {
		const OFFSET = 300;

		let m = Utils.randomInt(0, 1);
		if (m === 0) {
			return lower - OFFSET;
		} else {
			return upper + OFFSET;
		}
	}

	public execute(): void {
		// get the player
		this.player = this.owner.getScene().getPlayerEntity()!;
		if (this.player === undefined) {
			throw Error("EnemySectorSpawnBehavior:execute - Invalid Player Entity!");
		}

		const MIN_INIT_DIST = 500;
		const MIN_TRAV_DIST = 300;
		let playerPos = this.player.getPosition();
		let initSpawn = new Vec2();
		let dist = 0;
		do {
			let m = Utils.randomInt(0, 2);
			if (m === 0) { // Mode 1
				initSpawn.x = this.getOuterBounds(0, Globals.WORLD_WIDTH);
				initSpawn.y = Utils.randomInt(0, Globals.WORLD_HEIGHT);
			} else if (m === 1) {
				initSpawn.x = Utils.randomInt(0, Globals.WORLD_WIDTH);
				initSpawn.y = this.getOuterBounds(0, Globals.WORLD_HEIGHT);
			} else {
				initSpawn.x = this.getOuterBounds(0, Globals.WORLD_WIDTH);
				initSpawn.y = this.getOuterBounds(0, Globals.WORLD_HEIGHT);
			}

			dist = Vec2.distance(playerPos, initSpawn);
		} while (dist <= MIN_INIT_DIST);

		let expectedLoc = this.player.getPosition().add(this.player.getVelocity().normalize().multiplyScalar(this.player.getMaxSpeed()));
		do {
			this.travelToCoords.x = Utils.randomInt(0, Globals.WORLD_WIDTH);
			this.travelToCoords.y = Utils.randomInt(0, Globals.WORLD_HEIGHT);

			dist = Vec2.distance(expectedLoc, this.travelToCoords);
		} while (dist <= MIN_TRAV_DIST);

		this.owner.setPosition(initSpawn.x, initSpawn.y);
		this.dartBehavior.execute(this.travelToCoords);
	}

	public isSpawning(): boolean {
		return (this.dartBehavior.getState() !== DartBehaviorState.INACTIVE);
	}

	public setTrajectory(from: Vec2, to: Vec2) {
		this.owner.setPosition(from.x, from.y);
		this.travelToCoords = to;
		this.dartBehavior.execute(this.travelToCoords);
	}
}
