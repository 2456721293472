import { UIContainer } from "../../core/UI/UIContainer";
import { UIPositionAlign, UIAlign, UISizePercentage } from "../../core/UIHelpers";
import { UIText, UITextProperties } from "../../core/UI/UIText";
import { Scene } from "../../core/Scene";
import { UIVerticalContainer } from "../../core/UI/UIVerticalContainer";
import { Colors } from "../../utils/Colors";
import pixi = require("pixi.js");
import { UIElement } from "../../core/UIElement";
import { UIManager } from "../../core/UIManager";
import { VolumeSlider } from "./VolumeSlider";
import { SoundManager } from "../../core/SoundManager";
import { UITextButton } from "../../core/UI/UITextButton";
import { ScenesManager } from "../../core/SceneManager";
import { MENU_VISUALS_WIDTH, MENU_BUTTON_HEIGHT, MENU_BUTTON_FONTSIZE, MENU_BACK_WIDTH, MENU_SETTINGS_FONTSIZE } from "./UISizes";

export class PauseUIElement extends UIContainer {
	private vertContainer: UIVerticalContainer;
	private backCallback: () => void;
	private quitCallback: () => void;

	constructor(scene: Scene, backCallback: () => void, quitCallback: () => void) {
		super({
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center),
			width: new UISizePercentage(100),
			height: new UISizePercentage(100),
		});

		this.backCallback = backCallback;
		this.quitCallback = quitCallback;
	}

	protected initialize(parent: UIElement | UIManager) {
		super.initialize(parent);
	}

	private detachPauseMenu() {
		if (this.vertContainer) {
			this.vertContainer.delete();
			this.internal.removeChildren();
		}
	}

	private attachPauseMenu() {
		let background = new pixi.Graphics();
		background.beginFill(Colors.Black);
		background.drawRect(-10000, -10000, 20000, 20000);
		background.endFill();
		background.alpha = 0.85;
		this.internal.addChild(background);

		this.vertContainer = new UIVerticalContainer({
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center),
			width: new UISizePercentage(90),
			height: new UISizePercentage(90),
			margin: 0,
			padding: 0,
			fill: true
		});
		this.add(this.vertContainer);

		let titleContainer = new UIContainer({
			width: new UISizePercentage(100),
			height: new UISizePercentage(30)
		});

		titleContainer.add(new UIText(" pause menu", {
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center),
			font: "Hyperspace",
			fontSize: MENU_SETTINGS_FONTSIZE,
			color: Colors.Black,
			strokeSize: 3,
			strokeColor: Colors.Cyan
		}));

		this.vertContainer.add(titleContainer);

		// Create volume sliders
		let effectsVolumeContainer = this.vertContainer.add(new UIContainer({ height: new UISizePercentage(20), width: new UISizePercentage(100) }));
		VolumeSlider.createVolumeSlider(effectsVolumeContainer, "sounds", SoundManager.Effects);

		let musicVolumeContainer = this.vertContainer.add(new UIContainer({ height: new UISizePercentage(20), width: new UISizePercentage(100) }));
		VolumeSlider.createVolumeSlider(musicVolumeContainer, "music", SoundManager.Background);

		let fxText = ScenesManager.fxEnabled ? "Enhanced Visuals: On" : "Enhanced Visuals: Off";
		let toggleFxButton = new UITextButton(fxText,
			{
				x: new UIPositionAlign(UIAlign.center),
				y: new UIPositionAlign(UIAlign.bottom),
				width: MENU_VISUALS_WIDTH,
				height: MENU_BUTTON_HEIGHT,
				color: Colors.White,
				font: "Hyperspace",
				fontSize: MENU_BUTTON_FONTSIZE,
				backgroundColor: Colors.Black,
				backgroundAlpha: .6,
				borderColor: ScenesManager.fxEnabled ? Colors.Green : Colors.Red,
				hoverBorderColor: Colors.Yellow,
				hoverColor: Colors.Yellow
			}
		);
		toggleFxButton.onClick.addCallback(() => {
			SoundManager.MenuBackButton.play();
			ScenesManager.setEffectsEnabled(!ScenesManager.fxEnabled);
			toggleFxButton.text = ScenesManager.fxEnabled ? "Enhanced Visuals: On" : "Enhanced Visuals: Off";
			toggleFxButton.setBorderColor(ScenesManager.fxEnabled ? Colors.Green : Colors.Red);
		});
		let toggleFxContainer = new UIContainer({
			height: new UISizePercentage(15),
		});
		toggleFxContainer.add(toggleFxButton);
		this.vertContainer.add(toggleFxContainer);

		let buttonContainer = this.vertContainer.add(new UIContainer({
			height: new UISizePercentage(15),
		}));

		// Create Buttons
		let quitButton = buttonContainer.add(new UIContainer({
			x: new UIPositionAlign(UIAlign.left),
			width: new UISizePercentage(50),
			height: new UISizePercentage(100),
		})).add(new UITextButton("quit", {
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.bottom),
			width: MENU_BACK_WIDTH,
			height: MENU_BUTTON_HEIGHT,
			color: Colors.White,
			font: "Hyperspace",
			fontSize: MENU_BUTTON_FONTSIZE,
			backgroundColor: Colors.Black,
			borderColor: Colors.White,
			hoverBorderColor: Colors.Yellow,
			hoverColor: Colors.Yellow
		}
		));

		let backButton = buttonContainer.add(new UIContainer({
			x: new UIPositionAlign(UIAlign.right),
			width: new UISizePercentage(50),
			height: new UISizePercentage(100),
		})).add(new UITextButton("back",
			{
				x: new UIPositionAlign(UIAlign.center),
				y: new UIPositionAlign(UIAlign.bottom),
				width: MENU_BACK_WIDTH,
				height: MENU_BUTTON_HEIGHT,
				color: Colors.White,
				font: "Hyperspace",
				fontSize: MENU_BUTTON_FONTSIZE,
				backgroundColor: Colors.Black,
				borderColor: Colors.White,
				hoverBorderColor: Colors.Yellow,
				hoverColor: Colors.Yellow
			}
		));

		backButton.onClick.addCallback(() => {
			SoundManager.MenuBackButton.play();
			this.backCallback();
		});

		quitButton.onClick.addCallback(() => {
			SoundManager.MenuBackButton.play();
			this.quitCallback();
		});
	}

	public setVisible(visible: boolean) {
		if (visible) {
			this.attachPauseMenu();
		} else {
			this.detachPauseMenu();
		}
	}
}
