import { Entity, EntityType } from "../core/Entities/Entity";
import { Scene } from "../core/Scene";
import pixi = require("pixi.js");
import { TextureManager } from "../core/TextureManager";

export class TutorialArrowUIEntity extends Entity {
	public type = EntityType.TutorialArrowUI;

	// Graphics
	private arrow: pixi.Sprite;
	private wuContainer: pixi.Container;

	// Bounce timer
	private bounceTime = 0;

	constructor(scene: Scene, x: number, y: number) {
		super(scene, x, y);

		this.isOverlay = true;

		// Create Player UI Containers
		this.createGraphics();
	}

	// Create the warp UI
	private createGraphics() {
		// Setup Arcs
		this.arrow = pixi.Sprite.from(TextureManager.TutorialArrowUp);
		this.arrow.scale.set(.4, .4);
		this.arrow.anchor.set(0);

		// Create Container
		this.wuContainer = new pixi.Container();
		this.wuContainer.angle = -45;
		this.wuContainer.addChild(this.arrow);

		// Add container to parent
		this.graphicsContainer.addChild(this.wuContainer);
	}

	public update(dt: number): void {
		this.bounceTime += dt*6.5;

		let offset = ((Math.sin(this.bounceTime) + 1)/2) * 8;
		this.arrow.y = offset;
	}

	public setAngle(angle: number) {
		this.wuContainer.angle = angle;
	}
}
