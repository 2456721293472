import pixi = require("pixi.js");
import { Scene } from "../core/Scene";
import { SectorPlayerEntity } from "../entity/SectorPlayerEntity";
import { SectorJumpSelectEntity } from "../entity/SectorJumpSelectEntity";
import { App } from "../app";
import { Colors } from "../utils/Colors";
import { FADE_ANIMATION_TIME, FADE_ANIMATION_HOLD_TIME } from "../Gameplay/FadeAnimationLogic";
import { EnvironmentContainer } from "../containers/EnvironmentContainer";
import { Utils, Vec2 } from "@h4x/common";
import { LocalClusterContainer } from "../containers/LocalClusterContainer";
import { EventSystem } from "../core/EventSystem";
import { SectorEnemyEntity } from "../entity/SectorEnemyEntity";
import { EntityType } from "../core/Entities/Entity";
import { ImpactEntity } from "../entity/ImpactEntity";
import { WarpHoleEntity } from "../entity/WarpHoleEntity";
import { GameplayLogicController, GameplayLogicState } from "../Gameplay/GameLogicController";
import { SectorDividerEntity } from "../entity/SectorDividerEntity";
import { SectorPlayerUIEntity } from "../entity/SectorPlayerUIEntity";
import { EnemyType } from "../Gameplay/EnemyType";
import { SoundManager } from "../core/SoundManager";
import { DifficultyManager } from "../Gameplay/DifficultyManager";
import { EnemySpawner } from "../Gameplay/EnemySpawner";
import { Globals } from "../Gameplay/Constants";
import { UIText } from "../core/UI/UIText";
import { UIPositionAlign, UIAlign, UIDirection } from "../core/UIHelpers";
import { ScoreTallyUIElement } from "../Gameplay/UI/ScoreTallyUIElement";
import { ScoreManager, ScoreCategory } from "../Gameplay/ScoreManager";
import { InputManager } from "../core/InputManager";
import { WarpBackgroundEntity } from "../entity/WarpBackgroundEntity";
import { PauseUIElement } from "../Gameplay/UI/PauseUIElement";
import { ScenesManager } from "../core/SceneManager";
import { GameSceneMenu } from "./GameSceneMenu";
import { StatsManager, StatsCategory } from "../Gameplay/StatsManager";
import { HUDContainer } from "../Gameplay/UI/HUD/HUDContainer";
import { HUD_PANEL_MARGIN, HUD_PANEL_OFFSET } from "../Gameplay/UI/HUD/HUDSizes";
import { TITLE_FONTSIZE, DEBUG_FONTSIZE } from "../Gameplay/UI/UISizes";

const WARP_SELECT_TIME_SEC = 20;
const WARP_TRANSITION_TIME = 1;
const PLAYER_CLICK_DIST = 35;

type InputCallback = () => void;
class InputEventEmitter {
	private cbs: InputCallback[] = [];

	constructor() { }

	public once(cb: InputCallback) {
		this.cbs.push(cb);
	}

	public fire() {
		let cbsCopy = this.cbs;
		this.cbs = [];
		for (let cb of cbsCopy) {
			cb();
		}
	}
}

export class GameSceneSector extends Scene {

	// Scene settings
	get margin() { return HUD_PANEL_MARGIN.compute(UIDirection.Y); }
	get worldYOffset() { return HUD_PANEL_OFFSET.compute(UIDirection.Y); }

	// UI Elements
	private hudContainer: HUDContainer;
	private pauseMenu: PauseUIElement;
	private titleText: UIText;
	private debugLocalScorePercent: UIText;

	// Primary Entities
	private player: SectorPlayerEntity;
	private playerUIEntity: SectorPlayerUIEntity;
	private jumpSelect: SectorJumpSelectEntity;
	private divider: SectorDividerEntity;

	// Cluster Containers
	private localClusterContainer: LocalClusterContainer;
	private clusterX: number = 1;
	private clusterY: number = 2;

	// Enemy Logic
	private enemySpawner: EnemySpawner;

	// Flags
	private flagMoveJumpPoint: boolean = false;
	private flagGameActive: boolean = false;
	private flagJumpingActive: boolean = false;
	private flagSwapCentroids: boolean = false;
	private flagCanWarpOut: boolean = false;
	private flagIntroSkipped: boolean = false;

	// Properties
	private mouseCoordinate: Vec2 = new Vec2();
	private inputs = new InputEventEmitter();

	// Setup UI
	private setupUI() {
		this.hudContainer = new HUDContainer(this);
		this.uiManager.add(this.hudContainer);
		let round = GameplayLogicController.getCurrentRound();

		// Push Global Score Indicator Properties
		let globalRoundScore = (round === 0) ? GameplayLogicController.baseGlobalScore : GameplayLogicController.lastRoundGlobalScore;
		this.hudContainer.setBaseGlobalPercent(GameplayLogicController.baseGlobalScore);
		this.hudContainer.setRoundStartingGlobalPercent(globalRoundScore);
		this.hudContainer.setRoundCurrentGlobalPercent(globalRoundScore);

		this.titleText = this.uiManager.add(new UIText("", {
			color: Colors.White,
			font: "Hyperspace",
			align: "center",
			fontSize: TITLE_FONTSIZE,
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.top, 200)
		}));

		// Create the PAUSE menu
		this.pauseMenu = new PauseUIElement(this, () => {
			// When the back button is hit
			this.setPause(false);
			this.pauseMenu.setVisible(false);
		}, () => {
			// When the quit button is hit
			GameplayLogicController.end();
			ScenesManager.changeScene(new GameSceneMenu());
		});
		this.pauseMenu.setVisible(false);
		this.uiManager.add(this.pauseMenu);

		// DEBUG UI
		if (Globals.DEBUG_MODE) {
			this.uiManager.add(new UIText(`Round ${round} of ${GameplayLogicController.getMaxRounds()}`, {
				color: Colors.White,
				font: "Hyperspace",
				fontSize: DEBUG_FONTSIZE,
				x: new UIPositionAlign(UIAlign.left, 10),
				y: new UIPositionAlign(UIAlign.bottom, 10)
			}));

			this.debugLocalScorePercent = this.uiManager.add(new UIText("Local Percent: 0.00%", {
				color: Colors.White,
				font: "Hyperspace",
				fontSize: DEBUG_FONTSIZE,
				x: new UIPositionAlign(UIAlign.right, 10),
				y: new UIPositionAlign(UIAlign.bottom, 10)
			}));
		}
	}

	public init() {
		// Disable Gravity
		this.physicsWorld.gravity.y = 0;

		// Setup Containers
		this.gameWorldContainer.sortableChildren = true; // need to reposition children in this container
		this.setupUI();
		this.onResize(App.width, App.height);

		// Scene Setup
		this.backgroundContainer.addChild(new EnvironmentContainer());

		// Enemy Spawner
		this.enemySpawner = new EnemySpawner(this);

		// Create the primary entities
		this.createPrimaryEntities();

		// Detection when an enemy hits a player
		this.onCollisionEvent<SectorPlayerEntity, SectorEnemyEntity>((player, enemy) => {
			if (player.isInvulnerable() === false) {
				if (enemy.enemyType === EnemyType.CHASER) {
					this.enemySpawner.disableChasers(DifficultyManager.getChaserDisableTime());
				}
				this.playerTakeDamage(1, enemy);
				this.destroyEnemy(enemy);
			}
		}, EntityType.SectorPlayer, EntityType.SectorEnemy);

		// Detection when a Darter collides with another enemy
		this.onCollisionEvent<SectorEnemyEntity, SectorEnemyEntity>((enemyA, enemyB) => {
			let berzerker: SectorEnemyEntity;
			let other: SectorEnemyEntity;
			if (enemyA.enemyType === EnemyType.BERZERKER) {
				berzerker = enemyA;
				other = enemyB;
			} else if (enemyB.enemyType === EnemyType.BERZERKER) {
				berzerker = enemyB;
				other = enemyA;
			}

			if (berzerker! === undefined || other! === undefined) {
				return;
			} else if (berzerker.canDestroyOtherEnemy()) {
				this.destroyEnemy(berzerker);
				this.destroyEnemy(other);
				ScoreManager.berzerkerKill();
				this.hudContainer.setScore(ScoreManager.getCurrentPlayerScore());
			}

		}, EntityType.SectorEnemy, EntityType.SectorEnemy);

		// Run the game
		this.runLogic();
	}

	// Create the primary entities
	private createPrimaryEntities() {
		this.player = new SectorPlayerEntity(this, 0, 0);
		this.player.setColor(Colors.ClusterPositive);

		this.playerUIEntity = new SectorPlayerUIEntity(this, 0, 0);

		this.jumpSelect = new SectorJumpSelectEntity(this, Globals.WORLD_WIDTH / 2, (Globals.WORLD_HEIGHT / 2) + 200);
		this.jumpSelect.setColor(Colors.ClusterNegative);

		this.divider = new SectorDividerEntity(this);
	}

	// Run the game logic for this scene!
	private async runLogic() {
		await this.setupData();

		this.waitForUserInput().then(() => this.flagIntroSkipped = true);
		await this.runPhaseWarp();
		await this.runPhaseIntro(false);
		await this.runPhaseSelect();
		await this.runPhasePlay();

		// If hte player is alive, then we go to the warp scene, else we died and need to end the current game
		if (this.player.isAlive()) {
			GameplayLogicController.submitResult(this.clusterX, this.clusterY); // TODO: do we want to wait for the result to submit?
			await this.runPhaseLeave();

			// Push us to the next round
			GameplayLogicController.nextRound();
		} else {
			GameplayLogicController.submitResult(this.clusterX, this.clusterY); // TODO: do we want to wait for the result to submit?
			await this.runPhaseDestroyed(); // ded
			GameplayLogicController.setState(GameplayLogicState.DEATH);
		}
	}

	// Easy wrapper to set the title text and re-center it
	private setTitleText(text: string) {
		this.titleText.text = text.toLowerCase();
	}

	// Run a fade in on the title
	public async runTitleFadeIn(text: string, fadeTime: number = FADE_ANIMATION_TIME) {
		this.setTitleText(text);
		await this.runFadeIn(this.titleText, fadeTime);
	}

	// Run a fade in on the title
	public async runTitleFadeOut(fadeTime: number = FADE_ANIMATION_TIME) {
		await this.runFadeOut(this.titleText, fadeTime);
	}

	// Run a fade animation sequence on the title
	public async runTitleFade(text: string, fadeTime: number = FADE_ANIMATION_TIME, holdTime: number = FADE_ANIMATION_HOLD_TIME) {
		this.setTitleText(text);
		await this.runFade(this.titleText, fadeTime, holdTime);
	}

	// Create a new scene
	public async setupData() {
		let size = GameplayLogicController.cluster.data[0].values.length;
		this.clusterX = Utils.rangeInt(0, size);
		this.clusterY = Utils.rangeInt(0, size);
		if (this.clusterY === this.clusterX) {
			if (this.clusterX > 0) { this.clusterY--; } else { this.clusterY++; }
		}

		this.localClusterContainer = new LocalClusterContainer(this, GameplayLogicController.cluster, this.clusterX, this.clusterY);
		this.localClusterContainer.alpha = 0; // this is faded in later on in the startup sequence
		this.gameWorldContainer.addChild(this.localClusterContainer);
	}

	// Handle when a key on the keyboard is released
	public keyUp(key: KeyboardEvent) {
		if (key.keyCode === InputManager.Escape) {
			this.setPause(!this.isPaused());
			this.pauseMenu.setVisible(this.isPaused());
		}

		if (this.isPaused()) {
			return;
		}

		if (Globals.DEBUG_MODE) {
			if (this.flagGameActive) {
				if (key.keyCode === InputManager.KeyL) {
					this.flagGameActive = false;
				}
			}
		}

		// Use space bar to warp out if possible
		if (this.flagCanWarpOut) {
			if (key.keyCode === InputManager.Space) {
				this.flagGameActive = false;
			}
		}
	}

	// Called when the mouse moves on the screen
	public onMouseMove(x: number, y: number) {
		if (this.isPaused()) {
			return;
		}

		if (x < 20) { x = 20; }
		if (x > App.width - 20) { x = App.width - 20; }
		if (y < 20) { y = 20; }
		if (y > App.height - 20) { y = App.height - 20; }

		// Calculate Game Coordinates
		let gameCoordinate = this.gameWorldContainer.worldTransform.applyInverse(new pixi.Point(x, y));

		if (this.flagMoveJumpPoint) {
			this.jumpSelect.setPosition(gameCoordinate.x, gameCoordinate.y);
		}

		// Save the Game Coordinate to use in various areas
		this.mouseCoordinate.set(gameCoordinate.x, gameCoordinate.y);
	}

	// Called when the mouse is clicked
	public onMouseClick(x: number, y: number) {
		if (this.isPaused() || this.wasJustPaused()) {
			return;
		}

		this.inputs.fire();

		if (x < 20) { x = 20; }
		if (x > App.width - 20) { x = App.width - 20; }
		if (y < 20) { y = 20; }
		if (y > App.height - 20) { y = App.height - 20; }

		// Calculate Game Coordinates
		let gameCoordinate = this.gameWorldContainer.worldTransform.applyInverse(new pixi.Point(x, y));

		if (this.flagMoveJumpPoint) {
			this.flagMoveJumpPoint = false;
			this.jumpSelect.setPosition(gameCoordinate.x, gameCoordinate.y);
		}

		// Determine if we should run the jump or warp-out logic
		if (this.flagGameActive && this.flagJumpingActive === false) {
			let clickDist = Vec2.distance(new Vec2(gameCoordinate.x, gameCoordinate.y), this.player.getPosition());
			if (clickDist <= PLAYER_CLICK_DIST && this.flagCanWarpOut) {
				this.flagGameActive = false;
			} else {
				this.runJumpLogic();
			}
		}
	}

	// Logic when player takes damage
	private async playerTakeDamage(damage: number, enemy: SectorEnemyEntity) {
		let health = this.player.applyDamage(damage);
		if (health === 1) {
			SoundManager.ShieldsDepleted.play();
		}
		this.hudContainer.setCurrentHealth(health);

		// Shield Conveyance
		let dir = enemy.getPosition().subtract(this.player.getPosition()).normalize();
		this.playerUIEntity.showShields(health, dir);

		// Player Died. Game Over
		if (health === 0) {
			this.flagGameActive = false;
		} else { // Go invulnerable for a little bit
			this.player.blink(3);
			this.player.setInvulnerable(true);
			await this.eventSystem.timeout(3000);
			this.player.setInvulnerable(false);
		}
	}

	// Destroy an enemy and play an impact animation
	private destroyEnemy(enemy: SectorEnemyEntity) {
		this.createImpactEffect(enemy.getPosition());
		this.enemySpawner.removeEnemy(enemy);
		// SoundManager.TestExplosion.play();
		SoundManager.Impact.play();
	}

	// Create the impact effect
	private createImpactEffect(position: Vec2) {
		let impact = new ImpactEntity(this, position.x, position.y);
		this.addToWorld(impact);
	}

	// Update the cluster positions and recalculate the scores
	private updateClusterPositions(): number {
		if (this.player === undefined || this.jumpSelect === undefined) {
			return 0;
		}

		let positive: Vec2;
		let negative: Vec2;
		if (this.flagSwapCentroids) {
			positive = this.jumpSelect.getPosition();
			negative = this.player.getPosition();

			this.player.setColor(Colors.ClusterNegative);
			this.jumpSelect.setColor(Colors.ClusterPositive);
		} else {
			positive = this.player.getPosition();
			negative = this.jumpSelect.getPosition();

			this.player.setColor(Colors.ClusterPositive);
			this.jumpSelect.setColor(Colors.ClusterNegative);
		}

		// Never allow the centroids to be placed on top of each other
		if (positive.equals(negative)) {
			positive.x += 0.0001;
		}

		this.divider.setCluster(positive, negative);

		// Update Cluster Scores
		if (this.localClusterContainer) {
			this.localClusterContainer.update(positive.x, positive.y, negative.x, negative.y);
			let localScore = GameplayLogicController.cluster.calculateLocalScore(this.clusterX, this.clusterY);

			GameplayLogicController.updateCluster(this.clusterX, this.clusterY);

			// Set the indicator to the current global score
			this.hudContainer.setRoundCurrentGlobalPercent(GameplayLogicController.cluster.globalScore);

			return localScore;
		} else {
			return 0;
		}
	}

	// Run Jump Logic
	private async runJumpLogic() {
		this.flagJumpingActive = true;
		let origPlayerPos = this.player.getPosition();
		let origJumpPos = this.jumpSelect.getPosition();

		this.player.setNavigateTo(origPlayerPos.x, origPlayerPos.y);
		this.player.setVelocity(0, 0);
		this.enemySpawner.setEnemiesActive(false);

		let newJump = new SectorJumpSelectEntity(this, origPlayerPos.x, origPlayerPos.y);
		newJump.setTint(this.player.getTint());
		this.addToWorld(newJump);

		this.player.vanish(false);
		SoundManager.JumpOut.play();
		newJump.jumpIn(1000);
		await this.eventSystem.fnTick((perc) => {
			this.player.adjustRotation(this.deltaTime * Math.PI * 2);
		}, 1000);

		// swap jumps
		this.flagSwapCentroids = !this.flagSwapCentroids;
		let oldJumpSelect = this.jumpSelect;
		this.jumpSelect = newJump;

		this.player.setPositionAndNaviation(oldJumpSelect.x, origJumpPos.y);
		this.player.vanish(true);
		SoundManager.JumpIn.play();

		await this.eventSystem.fnTick((perc) => {
			this.player.adjustRotation(this.deltaTime * Math.PI * 2);
		}, 1000);

		this.enemySpawner.setEnemiesActive(true);
		this.flagJumpingActive = false;

		await oldJumpSelect.jumpOut(1000);
		this.removeFromWorld(oldJumpSelect);
	}

	// Phase: Warp
	private async runPhaseWarp() {
		let location = new Vec2(Globals.WORLD_WIDTH / 2, Globals.WORLD_HEIGHT - 300);
		SoundManager.WarpScene.play();
		this.runTitleFadeIn("Warping to New Sector");
		let warpEffect = new WarpBackgroundEntity(this, location.x, location.y, Colors.LightGray);
		warpEffect.setZIndex(0);
		this.addToWorld(warpEffect);
		await this.eventSystem.timeout(2500);
		await this.runFadeOut(warpEffect);
		this.removeFromWorld(warpEffect);
		await this.runTitleFadeOut();
	}

	// Phase: Intro
	private async runPhaseIntro(skip: boolean) {
		await this.runFadeIn(this.localClusterContainer, 500);

		await this.eventSystem.timeout(1000);
		if (this.flagIntroSkipped) {
			this.titleText.alpha = 0;
			this.addToWorld(this.jumpSelect);
			return;
		}

		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("coming out of warp...", 400);

		this.addToWorld(this.jumpSelect);
		await this.runFadeIn(this.jumpSelect, 400);

		await this.runTitleFadeOut(1000);
		if (this.flagIntroSkipped) {
			this.titleText.alpha = 0;
			this.addToWorld(this.jumpSelect);
			return;
		}

		SoundManager.MessageAppears.play();
		let sectorName: string = `${this.clusterX}-${this.clusterY}`;
		await this.runTitleFadeIn("now entering zone " + sectorName, 400);

		await this.eventSystem.timeout(1000);
		if (this.flagIntroSkipped) {
			this.titleText.alpha = 0;
			this.addToWorld(this.jumpSelect);
			return;
		}

		await this.runTitleFadeOut(400);
	}

	// Phase: Select Warp Location
	private async runPhaseSelect() {
		this.jumpSelect.setPosition(this.mouseCoordinate.x, this.mouseCoordinate.y);
		this.flagMoveJumpPoint = true;
		SoundManager.MessageAppears.play();
		let prefix = "move and click\n to place warp point";
		await this.runTitleFadeIn(`${prefix}\n${WARP_SELECT_TIME_SEC.toFixed(2)} remaining until warp`, 500);

		let soundTTL = 1;
		await this.eventSystem.fnTick((percentage) => {
			// Sound Logic
			soundTTL -= this.deltaTime;
			if (soundTTL <= 0) {
				SoundManager.TimeCountdown.play();
				soundTTL = 1;
			}

			let timeLeft = (1 - percentage) * WARP_SELECT_TIME_SEC;
			this.titleText.text = `${prefix}\n${timeLeft.toFixed(2)} remaining until warp`;

			if (this.flagMoveJumpPoint === false) {
				this.setTitleText(`${prefix}\n0.00 remaining until warp`);
				return EventSystem.Break;
			} else {
				return undefined;
			}
		}, WARP_SELECT_TIME_SEC * 1000);

		if (this.flagMoveJumpPoint === true) {
			SoundManager.TimerRunningOut.play();
		}

		// No longer move the jump point around
		this.flagMoveJumpPoint = false;

		// await this.eventSystem.timeout(1500);
		await this.runTitleFadeOut(100);

		await this.eventSystem.timeout(1000);

		this.player.setPositionAndNaviation(this.jumpSelect.x, this.jumpSelect.y);
		this.addToWorld(this.player);

		this.player.setAlpha(0);
		this.player.vanish(true);

		this.addToWorld(this.playerUIEntity);
		this.addToWorld(this.divider);

		let playerPos = this.player.getPosition();
		this.playerUIEntity.setPosition(playerPos.x, playerPos.y);

		this.updateClusterPositions();

		this.runFadeIn(this.hudContainer.internal, 100);
		this.runFadeIn(this.playerUIEntity, 100);
		await this.runFadeIn(this.divider, 100);
	}

	// Phase: Play
	private async runPhasePlay() {
		this.flagGameActive = true;
		this.enemySpawner.start(); // Start the enemy spawner

		this.eventSystem.timeoutCallback(() => {
			this.enemySpawner.setEnemiesActive(true);
		}, 2500);

		// Wait for the game to be over
		await this.eventSystem.fnTick(() => {
			return this.flagGameActive ? undefined : EventSystem.Break;
		});

		// Destroy all the enimies and Stop the spawner!
		this.enemySpawner.stop();
		this.enemySpawner.setEnemiesActive(false);
		this.enemySpawner.removeAllEnemies();
	}

	// Phase: Leave
	private async runPhaseLeave() {
		// Calculate Final Scores
		let localScore = GameplayLogicController.cluster.calculateLocalScore(this.clusterX, this.clusterY);
		let minWarpPerc = DifficultyManager.getWarpOutPercent();
		let percentAboveThreshold = Math.max(0, localScore - minWarpPerc);

		// Tally Up Scores
		ScoreManager.addScore(ScoreCategory.MATTER_SEPARATED, this.localClusterContainer.getNumClustered());
		ScoreManager.addScore(ScoreCategory.WARP_METER_BONUS, percentAboveThreshold * 100);
		ScoreManager.addScore(ScoreCategory.RESEARCH_BONUS, this.hudContainer.getNumHashesLit());
		ScoreManager.addScore(ScoreCategory.SHIELD_BONUS, this.player.getHealth() - 1);
		ScoreManager.addScore(ScoreCategory.SECTOR_BONUS, GameplayLogicController.getCurrentRound() + 1);

		// Calculate Stats (Must be after Scores since we use scores as stats)
		StatsManager.setStat(StatsCategory.HIGHEST_SECTOR, GameplayLogicController.getCurrentRound() + 1, true);
		StatsManager.setStat(StatsCategory.BEST_ROUND, ScoreManager.getCategoryTotalScore(), true);

		// Stats: Check if research solution was gained for Stats
		if (GameplayLogicController.cluster.globalScore > GameplayLogicController.baseGlobalScore &&
			GameplayLogicController.cluster.globalScore > GameplayLogicController.lastRoundGlobalScore) {
			StatsManager.addToStat(StatsCategory.RESEARCH_SOLUTIONS, 1);
		}

		let location = new Vec2(Globals.WORLD_WIDTH / 2, Globals.WORLD_HEIGHT - 300);

		// Tell player to go to the warp location and wait for the journey to complete
		this.player.setNavigateTo(location.x, location.y);
		SoundManager.ShipMoveToWarp.play();
		this.player.setWarpOutActive(false);

		this.removeUI();

		await this.eventSystem.fnTick(() => {
			let distSq = Vec2.squaredDistance(this.player.getPosition(), location);
			if (distSq <= 250) {
				return EventSystem.Break;
			} else {
				return undefined;
			}
		});

		let whe = new WarpHoleEntity(this, location.x, location.y, WARP_TRANSITION_TIME);
		whe.setZIndex(0);
		this.player.setZIndex(1);
		this.localClusterContainer.zIndex = 2;

		this.addToWorld(whe);
		this.eventSystem.fnTick((percentage) => {
			if (this.player === undefined) {
				return EventSystem.Break;
			}
			// let rotation = percentage * Math.PI * 4;
			this.player.setRotation(this.player.getRotation() + (Math.PI * this.deltaTime * 1.5));
			return;
		});

		// Show the Warphole
		SoundManager.WarpHoleGrowsUp.play();
		await whe.show();

		let whsSound = SoundManager.WarpholeSpin.play();
		whsSound.loop(true);

		// Create the score logic
		let score = this.uiManager.add(new ScoreTallyUIElement(this));
		await this.runFadeIn(score);

		// Data Point Movement Logic
		SoundManager.TallyClusterCollect.play();
		(async () => {
			for (let dp of this.localClusterContainer.dataPoint) {
				dp.runScoreLogic(location);
				this.runFadeOut(dp, 2500);
				await this.eventSystem.timeout(30);
			}
		})();

		await this.eventSystem.timeout(1000);
		await score.runScores();
		SoundManager.WarpOutMusic.play();
		this.hudContainer.setScore(ScoreManager.getCurrentScore());
		await this.eventSystem.timeout(3000);
		this.runFadeOut(this.hudContainer.score.internal);
		this.runFadeOut(score);
		await this.runFadeOut(this.player);
		this.runFadeOut(this.localClusterContainer);

		// remove ui
		this.hudContainer.delete();

		// hide the wormhole
		SoundManager.WarpOut.play();
		await whe.hide();
		whsSound.loop(false);
		whsSound.stop();

		this.gameWorldContainer.removeChild(this.localClusterContainer);
		this.removeFromWorld(whe);
		this.removeFromWorld(this.player);
		this.player = undefined!;
	}

	// Phase: Destroyed
	private async runPhaseDestroyed() {

		// Remove the UI
		this.runFadeOut(this.hudContainer.internal);
		await this.removeUI();
		this.hudContainer.delete();

		let hasDied = false;
		SoundManager.ShipDestroyed.play();
		this.eventSystem.fnInterval(() => {
			if (hasDied) {
				return EventSystem.Break;
			}

			this.createImpactEffect(this.player.getPosition());

			return;
		}, [], 500);

		await this.eventSystem.timeout(1500);
		this.runFadeOut(this.player, 500);
		await this.eventSystem.timeout(500);

		hasDied = true;
		await this.runFadeOut(this.localClusterContainer, 2500);
		await this.eventSystem.timeout(250);

		this.removeFromWorld(this.player);
	}


	// Fade out the UI
	private async removeUI() {
		// Fade Out some entities at the same time
		this.runFadeOut(this.jumpSelect, 500);
		this.runFadeOut(this.playerUIEntity, 500);
		this.runFadeOut(this.hudContainer.shield.internal, 500);
		this.runFadeOut(this.hudContainer.indicator.internal, 500);

		await this.runFadeOut(this.divider, 500);

		this.removeFromWorld(this.jumpSelect);
		this.removeFromWorld(this.playerUIEntity);
		this.removeFromWorld(this.divider);
	}

	// Update loop for the main game
	public update(dt: number) {
		if (this.flagGameActive) {
			// Update UI Elements as needed
			this.hudContainer.update(dt);

			let score = this.updateClusterPositions();

			// Show or hide the warp-out symbol on the ship
			this.flagCanWarpOut = score >= DifficultyManager.getWarpOutPercent();
			this.player.setWarpOutActive(this.flagCanWarpOut);

			// Only update this in DEBUG mode
			if (Globals.DEBUG_MODE) {
				this.debugLocalScorePercent.text = "Local Percent: " + (score * 100).toFixed(2) + "%";
			}

			// Set Warp UI
			let playerPos = this.player.getPosition();
			this.playerUIEntity.setPosition(playerPos.x, playerPos.y);
			this.playerUIEntity.setWarpCurrentPercent(score);

			// Set Player Navigation Position
			if (!this.flagJumpingActive) {
				this.player.setNavigateTo(this.mouseCoordinate.x, this.mouseCoordinate.y);
			}
		}
	}

	/** Return the player entity for other systems to use */
	public getPlayerEntity() {
		return this.player;
	}

	public waitForUserInput(): Promise<void> {
		return new Promise<void>((accept: any) => {
			this.inputs.once(() => {
				accept();
			});
		});
	}
}
