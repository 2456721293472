import { Scene } from "../core/Scene";
import { Colors } from "../utils/Colors";
import { EnvironmentContainer } from "../containers/EnvironmentContainer";
import { App } from "../app";
import { GameplayLogicController } from "../Gameplay/GameLogicController";
import { ScenesManager } from "../core/SceneManager";
import { SoundManager } from "../core/SoundManager";
import { UIVerticalContainer } from "../core/UI/UIVerticalContainer";
import { UIPositionAlign, UIAlign, UISizePercentage } from "../core/UIHelpers";
import { UIContainer } from "../core/UI/UIContainer";
import { UITextButton } from "../core/UI/UITextButton";
import { ScoreManager } from "../Gameplay/ScoreManager";
import { StatsManager, StatsCategory } from "../Gameplay/StatsManager";
import { EndStatsUIElement } from "../Gameplay/UI/EndStatsTallyUIElement";

export class GameSceneEnd extends Scene {

	private container: UIContainer;

	public init() {
		// Disable Gravity
		this.physicsWorld.gravity.y = 0;

		// Scene Setup
		this.backgroundContainer.addChild(new EnvironmentContainer());


	}

	protected onActivated() {
		StatsManager.setStat(StatsCategory.FINAL_SCORE, ScoreManager.getCurrentScore());
		SoundManager.GameOverMusic.play();
		this.runLogic();
		GameplayLogicController.end();
	}

	private async runLogic() {
		if (this.container) {
			this.container.delete();
		}

		this.container = this.uiManager.add(new UIContainer({
			width: new UISizePercentage(100),
			height: new UISizePercentage(45),
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center),
		}));

		let vertContainer = this.container.add(new UIVerticalContainer({
			x: new UIPositionAlign(UIAlign.center),
			width: new UISizePercentage(100),
			margin: 0,
			padding: 40,
		}));

		let endStats = vertContainer.add(new UIContainer({
			width: new UISizePercentage(100),
		})).add(new EndStatsUIElement(this, GameplayLogicController.hasWon()));
		endStats.hide();

		let playButton = vertContainer.add(new UIContainer({
			width: new UISizePercentage(100),
		})).add(
			new UITextButton("play again?", {
				x: new UIPositionAlign(UIAlign.center),
				y: new UIPositionAlign(UIAlign.center),
				width: new UISizePercentage(50),
				height: 50,
				color: Colors.White,
				font: "Hyperspace",
				backgroundColor: Colors.Black,
				hoverBorderColor: Colors.Yellow,
				hoverColor: Colors.Yellow
			}
			));
		playButton.onClick.addCallback(() => {
			GameplayLogicController.play();
			SoundManager.MenuSelect.play();
		});
		playButton.onHover.addCallback(() => {
			SoundManager.MenuMouseover.play();
		});
		playButton.hide();


		let menuButton = vertContainer.add(new UIContainer({
			width: new UISizePercentage(100),
		})).add(
			new UITextButton("menu", {
				x: new UIPositionAlign(UIAlign.center),
				y: new UIPositionAlign(UIAlign.center),
				width: new UISizePercentage(50),
				height: 50,
				color: Colors.White,
				font: "Hyperspace",
				backgroundColor: Colors.Black,
				hoverBorderColor: Colors.Yellow,
				hoverColor: Colors.Yellow
			}
			));
		menuButton.onClick.addCallback(() => {
			ScenesManager.changeScene(App.gameSceneMenu);
			SoundManager.MenuBackButton.play();
			endStats.stopSounds();
		});
		menuButton.onHover.addCallback(() => {
			SoundManager.MenuMouseover.play();
		});
		menuButton.hide();

		endStats.show();
		await endStats.fadeIn(this.eventSystem, 500);
		playButton.show();
		await playButton.fadeIn(this.eventSystem, 500);
		menuButton.show();
		await menuButton.fadeIn(this.eventSystem, 500);

		endStats.runStats();
	}
}
