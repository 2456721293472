import { SectorEnemyEntity } from "../entity/SectorEnemyEntity";
import { EventSystem } from "../core/EventSystem";
import { DifficultyManager } from "./DifficultyManager";
import { Scene } from "../core/Scene";
import { Utils } from "@h4x/common";
import { EnemyType } from "./EnemyType";
import { App } from "../app";
import { SoundManager } from "../core/SoundManager";

/** Holds all the logic for the active enemies in a scene */
export class EnemySpawner {
	// Parent Scene Elements
	private parentScene: Scene;
	private eventSystem: EventSystem;

	// Internal Flags
	private active: boolean = false;

	// Containers
	private chasers: SectorEnemyEntity[] = [];
	private bezerkers: SectorEnemyEntity[] = [];
	private patrollers: SectorEnemyEntity[] = [];

	/** Create a new Enemy Spawner within a specific scene */
	constructor(parentScene: Scene) {
		this.parentScene = parentScene;
		this.eventSystem = parentScene.eventSystem;
	}

	/** Start the enemy spawn logic for the current round */
	public async start() {
		// Check if this is already active, if it is, report and error
		if (this.active) {
			throw Error("EnemySpawner:start - Can't start the spawner! It's already started!");
		}

		this.active = true;

		// Start a timer based on the difficulty system for when enemies should actually start spawning
		let firstSpawnDelay = DifficultyManager.getFirstEnemySpawnDelaySec();
		console.log(`EnemySpawner:start - First Enemy Spawn in ${firstSpawnDelay} seconds!`);
		await this.eventSystem.timeout(firstSpawnDelay * 1000);

		// Start the spawning
		this.spawnEnemyNow();
		let spawnDelay = DifficultyManager.getEnemySpawnDelaySec();

		this.eventSystem.fnInterval(() => {
			if (this.active === false) {
				return EventSystem.Break;
			}

			console.log(`EnemySpawner:spawnEnemyNow - Spawning a new enemy now! ${spawnDelay} seconds until new spawn!`);
			this.spawnEnemyNow();

			return undefined;
		}, [], spawnDelay * 1000);
	}

	/** Stop the enemy spawner */
	public stop() {
		this.active = false;
	}

	// Spawn a new enemy right now
	public spawnEnemyNow() {
		if (this.active === false) {
			return;
		}

		// Do we already have too many enemies for this round?
		if (this.getNumEnemies() >= DifficultyManager.getMaxEnemies()) {
			console.log(`EnemySpawner:spawnEnemyNow - Too many enemies are already active! Not spawning!`);
			return;
		}

		// Create Spawn Container
		let availableChasers = this.chasers.length < DifficultyManager.getChaserMaxSpawn();
		let availableBezerkers = this.bezerkers.length < DifficultyManager.getBerzerkerMaxSpawn();
		let availablePatrollers = this.patrollers.length < DifficultyManager.getPatrollerMaxSpawn();

		let chanceChaser = availableChasers ? DifficultyManager.getChaserSpawnChance() : 0;
		let chanceBezerker = availableBezerkers ? DifficultyManager.getBerzerkerSpawnChance() : 0;
		let chancePatrollerOne = availablePatrollers ? DifficultyManager.getPatrollerOneSpawnChance() : 0;
		let chancePatrollerTwo = availablePatrollers ? DifficultyManager.getPatrollerTwoSpawnChance() : 0;
		let chancePatrollerThree = availablePatrollers ? DifficultyManager.getPatrollerThreeSpawnChance() : 0;

		let sumChance = chanceChaser + chanceBezerker + chancePatrollerOne + chancePatrollerTwo + chancePatrollerThree;
		if (sumChance === 0) {
			console.log(`EnemySpawner:spawnEnemyNow - Can't spawn any enemy! 0 chance to spawn any type!`);
			return;
		}

		let enemyToSpawn = Utils.randomWeightedArray([{
			type: EnemyType.CHASER,
			weight: chanceChaser
		},
		{
			type: EnemyType.BERZERKER,
			weight: chanceBezerker
		},
		{
			type: EnemyType.PATROLLER_ONE,
			weight: chancePatrollerOne
		},
		{
			type: EnemyType.PATROLLER_TWO,
			weight: chancePatrollerTwo
		},
		{
			type: EnemyType.PATROLLER_THREE,
			weight: chancePatrollerThree
		}]);

		if (enemyToSpawn === undefined) {
			console.log(`EnemySpawner:spawnEnemyNow - Attempted to spawn an enemy, but it was undefined!`);
			return;
		}

		let newEnemy = new SectorEnemyEntity(this.parentScene, Utils.randomInt(0, App.width), Utils.randomInt(0, App.height), enemyToSpawn.value.type);
		this.parentScene.addToWorld(newEnemy);

		SoundManager.EnemySpawn.play();

		switch (enemyToSpawn.value.type) {
			case EnemyType.CHASER:
				this.chasers.push(newEnemy);
				break;
			case EnemyType.BERZERKER:
				this.bezerkers.push(newEnemy);
				break;
			case EnemyType.PATROLLER_ONE:
			case EnemyType.PATROLLER_TWO:
			case EnemyType.PATROLLER_THREE:
				this.patrollers.push(newEnemy);
				break;
			default:
				throw Error("EnemySpawner:spawnEnemyNow - Unrecognized Enemy Chosen to Spawn! This should not have happened!");
		}
	}

	/** Is this spawner currently spawning enemies */
	public isSpawning(): boolean {
		return this.active;
	}

	/** Return the currrent number of enemies active from this spawner */
	public getNumEnemies(): number {
		return this.chasers.length + this.bezerkers.length + this.patrollers.length;
	}

	/** Remove an Enemey from the current scene and spawner logic */
	public removeEnemy(enemy: SectorEnemyEntity) {
		this.parentScene.removeFromWorld(enemy);

		switch (enemy.enemyType) {
			case EnemyType.CHASER:
				this.chasers.splice(this.chasers.indexOf(enemy), 1);
				break;
			case EnemyType.BERZERKER:
				this.bezerkers.splice(this.bezerkers.indexOf(enemy), 1);
				break;
			case EnemyType.PATROLLER_ONE:
			case EnemyType.PATROLLER_TWO:
			case EnemyType.PATROLLER_THREE:
				this.patrollers.splice(this.patrollers.indexOf(enemy), 1);
				break;
			default:
				throw Error("EnemySpawner:removeEnemy - Unrecognized Enemy Chosen to Remove! This should not have happened!");
		}
	}

	/** Remove all the enemies from this system and the scene */
	public removeAllEnemies() {
		for (let e of this.chasers) {
			this.parentScene.removeFromWorld(e);
		}

		for (let e of this.bezerkers) {
			this.parentScene.removeFromWorld(e);
		}

		for (let e of this.patrollers) {
			this.parentScene.removeFromWorld(e);
		}

		this.chasers = [];
		this.bezerkers = [];
		this.patrollers = [];
	}

	/** Set all the enemies to a specific active state */
	public setEnemiesActive(active: boolean) {
		for (let e of this.chasers) {
			e.setActive(active);
			e.setVelocity(0, 0);
		}

		for (let e of this.bezerkers) {
			e.setActive(active);
			e.setVelocity(0, 0);
		}

		for (let e of this.patrollers) {
			e.setActive(active);
			e.setVelocity(0, 0);
		}
	}

	public disableChasers(timeSec: number) {
		for (let e of this.chasers) {
			e.disable(timeSec);
		}
	}
}
