import { Scene } from "../../core/Scene";
import { App } from "../../app";
import { EnvironmentContainer } from "../../containers/EnvironmentContainer";
import { Colors } from "../../utils/Colors";
import { SectorPlayerEntity } from "../../entity/SectorPlayerEntity";
import { Entity, EntityType } from "../../core/Entities/Entity";
import { SectorPlayerUIEntity } from "../../entity/SectorPlayerUIEntity";
import { Utils, Vec2 } from "@h4x/common";
import { Globals } from "../../Gameplay/Constants";
import { SectorEnemyEntity } from "../../entity/SectorEnemyEntity";
import { EnemyType } from "../../Gameplay/EnemyType";
import { ImpactEntity } from "../../entity/ImpactEntity";
import Text from "../../core/Text";
import { UIBorder, UIBorderMode } from "../../core/UI/UIBorder";
import { InputManager } from "../../core/InputManager";

export class PlayerUIScene extends Scene {
	private player: SectorPlayerEntity;
	private playerUI: SectorPlayerUIEntity;

	private percentageText: Text;

	constructor() {
		super();

		App.onResizeEvent.addCallback(() => { });
	}

	public async init() {
		this.physicsWorld.gravity.y = 0;

		this.backgroundContainer.addChild(new EnvironmentContainer());
		this.uiManager.add(new UIBorder({ borderColor: Colors.Orange, borderWidth: 5, borderMode: UIBorderMode.Inner }));

		this.player = new SectorPlayerEntity(this, Globals.WORLD_WIDTH / 2, Globals.WORLD_HEIGHT / 2);
		this.player.setHealth(Globals.SECTOR_PLAYER_HEALTH);
		this.addToWorld(this.player);

		this.playerUI = new SectorPlayerUIEntity(this, this.player.x, this.player.y);
		this.addToWorld(this.playerUI);

		{
			this.percentageText = new Text("<percentage>", { fill: Colors.LightGray });
			this.percentageText.anchor.set(0.5);
			this.percentageText.x = App.width / 2;
			this.percentageText.y = 250;
			this.uiContainer.addChild(this.percentageText);
		}

		this.player.setWarpOutActive(true);

		this.updatePercentageText();
	}

	public update(dt: number) {
		this.playerUI.setPosition(this.player.x, this.player.y);
	}

	public onMouseMove(x: number, y: number) {
		this.player.setNavigateTo(x, y);
	}

	public onMouseClick(x: number, y: number) {
		// Create Enemy Chaser
		let enemy = new SectorEnemyEntity(this, x, y, EnemyType.CHASER);
		this.addToWorld(enemy);
	}

	private updatePercentageText() {
		let displayPercent = (this.playerUI.getWarpCurrentPercent() * 100).toFixed(2);
		this.percentageText.text = `${displayPercent}%`;
	}

	// Handle when a key on the keyboard is released
	public keyUp(key: KeyboardEvent) {
		if (key.keyCode === InputManager.KeyW) {
			this.playerUI.setWarpCurrentPercent(this.playerUI.getWarpCurrentPercent() + 0.01);
			this.updatePercentageText();
		} else if (key.keyCode === InputManager.KeyS) {
			this.playerUI.setWarpCurrentPercent(this.playerUI.getWarpCurrentPercent() - 0.01);
			this.updatePercentageText();
		} else if (key.keyCode === InputManager.KeyF) {
			let health = this.player.applyDamage(1);

			// Random Direction of Damage
			let damageDir = new Vec2(Utils.randomInt(-10000, 10000), Utils.randomInt(-10000, 10000)).normalize();

			this.playerUI.showShields(health - 1, damageDir);

			if (health === 0) {
				// Do Stuff
				console.log("He's dead Jim!");
			}
		} else if (key.keyCode === InputManager.KeyR) {
			this.player.setHealth(Globals.SECTOR_PLAYER_HEALTH);
		} else if (key.keyCode === InputManager.KeyG) {
			this.player.setWarpOutActive(!this.player.isWarpOutActive());
		}
	}

	// On Collision
	public onCollision(entityA: Entity, entityB: Entity) {
		let player: SectorPlayerEntity = undefined as any;
		let enemy: SectorEnemyEntity = undefined as any;
		if (entityA.type === EntityType.SectorPlayer) {
			player = (entityA as SectorPlayerEntity);
			enemy = (entityB as SectorEnemyEntity);
		}

		if (entityB.type === EntityType.SectorPlayer) {
			player = (entityB as SectorPlayerEntity);
			enemy = (entityA as SectorEnemyEntity);
		}

		if (player! === undefined || enemy! === undefined) {
			return;
		}

		// Create Impact Effect
		let impact = new ImpactEntity(this, enemy.x, enemy.y);
		this.addToWorld(impact);

		// Impact Player
		let dir = enemy.getPosition().subtract(this.player.getPosition()).normalize();
		let health = this.player.applyDamage(1);
		this.playerUI.showShields(health, dir);

		// Remove Enemy from scene
		this.removeFromWorld(enemy);
	}
}
