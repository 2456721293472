const decomp = require("poly-decomp");
(window as any).decomp = decomp;

require("@pixi/unsafe-eval").install(require("@pixi/core"));

import { ScenesManager } from "./core/SceneManager";
import { App } from "./app";
import { InputManager } from "./core/InputManager";
import { TextureManager } from "./core/TextureManager";
import { GameplayLogicController } from "./Gameplay/GameLogicController";
import { DifficultyManager } from "./Gameplay/DifficultyManager";
import { Globals } from "./Gameplay/Constants";


function isPopout() {
	let parts = window.location.search.substring(1).split("&");
	for (const part of parts) {
		let pair = part.split("=");
		if (decodeURIComponent(pair[0]) === "popout") {
			return decodeURIComponent(pair[1]) === "true";
		}
	}
	return false;
}

function hasWebGL() {
	const contextOptions = { stencil: true, failIfMajorPerformanceCaveat: true };
	try {
		if (!(window as any).WebGLRenderingContext) { return false; }
		const canvas = document.createElement("canvas");
		let gl = canvas.getContext("webgl", contextOptions) || canvas.getContext("experimental-webgl", contextOptions);
		const success = !!(gl && (gl as any).getContextAttributes().stencil);
		if (gl) {
			const loseContext = (gl as any).getExtension("WEBGL_lose_context");
			if (loseContext) { loseContext.loseContext(); }
		}
		gl = null;
		return success;
	} catch (e) {
		return false;
	}
}

(async () => {
	if (!isPopout() && document.getElementById("info")) {
		console.log("Not Popout...");
		document.getElementById("info")!.style.display = "block";
		document.getElementById("app")!.style.display = "none";
		return;
	}

	if (!hasWebGL()) {
		console.log("No WebGL...");
		document.getElementById("warning")!.style.display = "block";
		document.getElementById("app")!.style.display = "none";
		return;
	}

	if (Globals.DEBUG_MODE) {
		let overlay = document.getElementById("overlay");
		if (overlay !== null) {
			overlay.style.display = null;
			let debugSize = document.getElementById("debug-size");
			if (debugSize !== null) {
				App.onResizeEvent.addCallback((width, height) => { debugSize!.innerText = `${width} x ${height}`; });
			}
		}
	}

	// Create Gameplay Logic Controller
	ScenesManager.create();
	InputManager.init();

	let lastFrameTime = Date.now();
	App.time = lastFrameTime;

	// Executive Gameplay Loop
	function loop() {
		let frameDelta = Math.min(100.0, Date.now() - lastFrameTime) / 1000.0;
		lastFrameTime = Date.now();
		requestAnimationFrame(loop);

		// Update Systems and Managers
		App.time = lastFrameTime;
		GameplayLogicController.update(frameDelta);
		ScenesManager.update(frameDelta, false);
	}

	requestAnimationFrame(loop);

	await TextureManager.init();
	await DifficultyManager.init();

	// Go to initial scene
	ScenesManager.changeScene(App.gameSceneMenu);
	// ScenesManager.changeScene(App.solverTestScene);
	// ScenesManager.changeScene(App.endScoreUIScene);
	// ScenesManager.changeScene(App.testPauseUIScene);
	// ScenesManager.changeScene(App.testWarpEffectScene);
	// ScenesManager.changeScene(App.gameSceneSector);
	// ScenesManager.changeScene(App.playerScoreScene);
	// ScenesManager.changeScene(App.globalScoreUIScene);
	// ScenesManager.changeScene(App.screenScene);
	// ScenesManager.changeScene(App.gameSceneEnd);
	// ScenesManager.changeScene(App.playerUIScene);
	// ScenesManager.changeScene(App.warpTestScene);
	// ScenesManager.changeScene(App.playerScene);
	// ScenesManager.changeScene(App.gameSceneConcept);
	// ScenesManager.changeScene(App.gameScene);
	// GameplayLogicController.play();

})();

