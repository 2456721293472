import { Entity, EntityType } from "../core/Entities/Entity";
import { Vec2, Utils } from "@h4x/common";
import { Scene } from "../core/Scene";
import pixi = require("pixi.js");
import { Colors } from "../utils/Colors";

const PARTICLE_MAX_LENGTH = 65;
const PARTICLE_TIME_TO_LIVE = 1.5;
const PARTICLE_FADE_TIME = 0.9;
const TIME_BETWEEN_PARTICLES = 0.015;

// Individual Warp Particle
class WarpParticle extends pixi.Container {
	private parentContainer: pixi.Container;
	private tail: pixi.Graphics;
	private head: pixi.Graphics;
	private speed: number;
	private direction: Vec2;
	private timeToLive: number = PARTICLE_TIME_TO_LIVE;
	private timeToFade: number = PARTICLE_FADE_TIME;

	constructor(parent: pixi.Container, direction: Vec2, speed: number) {
		super();

		// Save some physics properties for the particle movement
		this.parentContainer = parent;
		this.speed = speed;
		this.direction = direction.copy();

		// Particle Tail
		this.tail = new pixi.Graphics();

		// Particle Head
		// this.head = new pixi.Graphics();
		// this.head.beginFill(Colors.Gray);
		// this.head.drawCircle(0, 0, 6);

		this.setParticleLength(1);

		// Add Children to owners and containers
		this.addChild(this.tail);
		// this.addChild(this.head);
		this.parentContainer.addChild(this);
	}

	public update(dt: number) {
		this.timeToLive -= dt;

		this.timeToFade -= dt;

		this.tail.alpha = Utils.remapRange(1 - this.timeToFade, 0, PARTICLE_FADE_TIME, 0, 1);

		if (this.timeToLive <= 0) {
			this.parentContainer.removeChild(this); // Kill Particle
		} else {
			let next = this.speed * dt;
			let nextPosition = new Vec2(this.x, this.y).add(this.direction.copy().multiplyScalar(next));
			this.x = nextPosition.x;
			this.y = nextPosition.y;

			// Determine Particle Length
			let timeElapsed = PARTICLE_TIME_TO_LIVE - this.timeToLive;
			let cLength = Utils.remapRange(timeElapsed, 0, PARTICLE_TIME_TO_LIVE, 5, PARTICLE_MAX_LENGTH);
			this.setParticleLength(cLength);
		}
	}

	private setParticleLength(length: number) {
		// Calculate the tail endpoint
		let ep = this.direction.copy().multiplyScalar(length);
		this.tail.clear();
		this.tail.lineStyle(5, Colors.WarpParticle);
		this.tail.moveTo(0, 0);
		this.tail.lineTo(ep.x, ep.y);

		// Particle Head
		// this.head.x = ep.x;
		// this.head.y = ep.y;
	}
}

/** Warp Background Effect */
export class WarpBackgroundEntity extends Entity {
	public type: EntityType = EntityType.WarpBackground;
	private center: Vec2;

	private particleSpawnTime = TIME_BETWEEN_PARTICLES;

	// Particles
	private particles: WarpParticle[] = [];

	constructor(scene: Scene, centerX: number, centerY: number, color: number) {
		super(scene, 0, 0);

		this.center = new Vec2(centerX, centerY);
	}

	public update(dt: number) {
		this.particleSpawnTime -= dt;

		if (this.particleSpawnTime <= 0) {
			this.particleSpawnTime = TIME_BETWEEN_PARTICLES;
			this.spawnParticle();
		}

		// Update Particles
		for (let p of this.particles) {
			p.update(dt);
		}
	}

	// Spawn a single new particle
	private spawnParticle() {
		let td = new Vec2(Utils.rangeFloat(-1000, 1000), Utils.randomInt(-1000, 1000)).normalize();
		let tp = new WarpParticle(this.graphicsContainer, td, Utils.randomInt(350, 1000));
		tp.x = this.center.x;
		tp.y = this.center.y;
		this.particles.push(tp);
	}
}
