import * as pixi from "pixi.js";
import { UIElement, UIElementProperties } from "../UIElement";
import { UIManager } from "../UIManager";
import { UISize, UIDirection } from "../UIHelpers";
import { UIHorizontalContainer } from "./UIHorizontalContainer";
import { UIVerticalContainer } from "./UIVerticalContainer";


export type UIContainers = UIContainer | UIHorizontalContainer | UIVerticalContainer;

export interface UIContainerProperties extends UIElementProperties {
	width?: UISize;
	height?: UISize;
	backgroundColor?: number | undefined;
}

export class UIContainer<T extends UIContainerProperties = UIContainerProperties, K extends UIElement = UIElement>
	extends UIElement<T & UIContainerProperties> {

	public readonly internal: pixi.Container;
	protected background: pixi.Graphics;
	protected elements = new Set<K>();
	public readonly internalWidth: number;
	public readonly internalHeight: number;

	constructor(properties?: UIContainerProperties) {
		super(properties as T);
		this.internal = new pixi.Container();
	}

	protected initialize(parent: UIElement | UIManager) {
		this.applyProperty(this.properties, "width", 0);
		this.applyProperty(this.properties, "height", 0);
		this.applyProperty(this.properties, "backgroundColor", undefined);

		super.initialize(parent);

		this.updateSize();
		this.updatePosition();
		this.onResize(this.width, this.height);
	}

	public onResize(width: number, height: number) {
		super.onResize(width, height);
		this.updateSize();
		this.updatePosition();
		for (const element of this.elements) {
			element.onResize(this.width, this.height);
		}
		this.redrawContainer();
	}

	public onMouseMove(x: number, y: number) {
		for (const element of this.elements) {
			element.onMouseMove(x - element.x, y - element.y);
		}
	}

	public add<R extends K>(element: R) {
		this.internal.addChild((element as any).internal);
		(element as any).internalInitialize(this);
		this.elements.add(element);
		return element;
	}

	public remove<R extends K>(element: R) {
		this.internal.removeChild((element as any).internal);
		(element as any).internalDestroy();
		this.elements.delete(element);
	}

	public redrawContainer() {
		if (this.backgroundColor === undefined) {
			if (this.background !== undefined) {
				this.internal.removeChild(this.background);
			}
			return;
		}

		if (this.background === undefined) {
			this.background = new pixi.Graphics();
			this.internal.addChildAt(this.background, 0);
		} else {
			this.background.clear();
		}

		this.background.beginFill(this.backgroundColor);
		this.background.drawRect(0, 0, this.width, this.height);
		this.background.endFill();
	}

	public get availableWidth() { return this.width; }
	public get availableHeight() { return this.height; }

	private $width: UISize;
	public get width() { return this.internalWidth > 0 ? this.internalWidth : this.internal.width; }
	public set width(value: number) {
		this.$width = value;
		(this.internalWidth as any) = this.computeWidth(value);
	}

	private $height: UISize;
	public get height() { return this.internalHeight > 0 ? this.internalHeight : this.internal.height; }
	public set height(value: number) {
		this.$height = value;
		(this.internalHeight as any) = this.computeHeight(value);
	}

	public setWidth(value: UISize) {
		this.$width = value;
		this.updateSize();
		this.updatePosition();
		for (const element of this.elements) {
			element.onResize(this.width, this.height);
		}
		this.redrawContainer();
	}

	public setHeight(value: UISize) {
		this.$height = value;
		this.updateSize();
		this.updatePosition();
		for (const element of this.elements) {
			element.onResize(this.width, this.height);
		}
		this.redrawContainer();
	}

	public setSize(width: UISize, height: UISize) {
		this.$width = width;
		this.$height = height;
		this.updateSize();
		this.updatePosition();
		for (const element of this.elements) {
			element.onResize(this.width, this.height);
		}
		this.redrawContainer();
	}

	private computeWidth(value: UISize): number {
		if (typeof (value) === "number") {
			return value;
		} else {
			return value.compute(UIDirection.X, this);
		}
	}

	private computeHeight(value: UISize): number {
		if (typeof (value) === "number") {
			return value;
		} else {
			return value.compute(UIDirection.Y, this);
		}
	}

	protected updateSize() {
		(this.internalWidth as any) = this.computeWidth(this.$width);
		(this.internalHeight as any) = this.computeHeight(this.$height);
	}

	/* Background Color */
	private $backgroundColor: number | undefined;
	public get backgroundColor() { return this.$backgroundColor; }
	public setBackgroundColor(value: number | undefined) { this.backgroundColor = value; return this; }
	public set backgroundColor(value: number | undefined) {
		this.$backgroundColor = value;

		if (this.initialized === true) {
			this.redrawContainer();
		}
	}
}
