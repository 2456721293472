import pixi = require("pixi.js");
import { Colors } from "../utils/Colors";
import { App } from "../app";

export class EnvironmentContainer extends pixi.Container {
	private background: pixi.Graphics;

	constructor(private color = Colors.Black) {
		super();

		this.background = new pixi.Graphics();
		this.setup();
	}

	private setup() {
		// Background
		this.update(App.width, App.height);
		App.onResizeEvent.addCallback(this.update, this);
	}

	private update(width: number, height: number) {
		this.removeChildren();
		this.background.beginFill(this.color);
		this.background.drawRect(0, 0, width, height);
		this.background.endFill();
		this.addChild(this.background);
	}
}
