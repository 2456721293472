import { App } from "../../app";
import { EnvironmentContainer } from "../../containers/EnvironmentContainer";
import { Scene } from "../../core/Scene";
import TextButton from "../../core/TextButton";
import { UIBorder, UIBorderMode } from "../../core/UI/UIBorder";
import { DifficultyManager } from "../../Gameplay/DifficultyManager";
import { GameplayLogicController } from "../../Gameplay/GameLogicController";
import { Colors } from "../../utils/Colors";

export class RoundSkipScene extends Scene {
	constructor() {
		super();

		App.onResizeEvent.addCallback(() => { });
	}

	public async init() {
		this.backgroundContainer.addChild(new EnvironmentContainer());

		let numRounds = DifficultyManager.getNumDifficultyRounds();

		let count = 0;
		let xVal = 10;
		let yVal = 20;
		for (let i = 0; i < numRounds; i++) {
			let textBtn = new TextButton(`Round: ${i}`, {
				fill: Colors.White,
				fontSize: 28,
			});
			textBtn.x = xVal;
			textBtn.y = yVal;

			this.uiContainer.addChild(textBtn);

			// Placement Values
			count++;
			if (count >= 10) {
				count = 0;
				xVal += 150;
				yVal = 20;
			} else {
				yVal += 40;
			}

			textBtn.onClick.addCallback(() => {
				GameplayLogicController.play(i);
			});
		}
	}
}
