import { Entity, EntityType } from "../core/Entities/Entity";
import { Scene } from "../core/Scene";
import pixi = require("pixi.js");
import { TextureManager } from "../core/TextureManager";
import { Colors } from "../utils/Colors";

export class TutorialBanUIEntity extends Entity {
	public type = EntityType.TutorialArrowUI;

	// Graphics
	private ban: pixi.Sprite;
	private wuContainer: pixi.Container;

	constructor(scene: Scene, x: number, y: number) {
		super(scene, x, y);

		// Create Player UI Containers
		this.createGraphics();
	}

	// Create the warp UI
	private createGraphics() {
		// Setup Arcs
		this.ban = pixi.Sprite.from(TextureManager.TutorialBan);
		this.ban.scale.set(1);
		this.ban.anchor.set(0.5);
		this.ban.tint = Colors.Red;

		// Create Container
		this.wuContainer = new pixi.Container();
		this.wuContainer.addChild(this.ban);

		// Add container to parent
		this.graphicsContainer.addChild(this.wuContainer);
	}

	public update(dt: number): void {
	}
}

