import * as pixi from "pixi.js";
import { EntityType } from "../../core/Entities/Entity";
import { TextureManager } from "../../core/TextureManager";
import { Scene } from "../../core/Scene";
import { DynamicEntity } from "../../core/Entities/DynamicEntity";
import Matter = require("matter-js");
import { EnemySectorSpawnBehavior } from "../../Gameplay/Behaviors/SpawnBehavior";
import { ConstrainBehavior } from "../../Gameplay/Behaviors/ConstrainBehavior";
import { Vec2 } from "@h4x/common";
import { Globals } from "../../Gameplay/Constants";

export class TestBehaviorEntity extends DynamicEntity {
	public readonly type = EntityType.SectorEnemy;
	private sprite: pixi.Sprite;
	private constrain = new ConstrainBehavior(this);
	private behaviour = new EnemySectorSpawnBehavior(this);

	constructor(scene: Scene, x: number, y: number, behavior: number) {
		super(scene, x, y, Matter.Bodies.circle(x, y, 30));

		this.sprite = pixi.Sprite.from(TextureManager.EnemyBezerker);
		this.sprite.anchor.set(0.5);
		this.graphicsContainer.addChild(this.sprite);


		this.setMass(1000);
		this.setMaxSpeed(500);
		this.setTurnRate(50);

		this.physicsBody.isSensor = true;

		this.behaviour.execute();
		this.constrain.execute([
			new Vec2(0, 0),
			new Vec2(Globals.WORLD_WIDTH, Globals.WORLD_HEIGHT)
		]);

		/*
		this.enemyPatrollerOneBehavior = new EnemyPatrollerTwoBehavior(this);
		this.enemyPatrollerOneBehavior.execute(x);
		*/
	}

	public update(dt: number) {
		this.behaviour.update(dt);

		if (this.behaviour.isSpawning() === false) {
			this.constrain.update(dt);
		}
	}
}
