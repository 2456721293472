import { Scene } from "./Scene";
import { UIElement } from "./UIElement";

export class UIManager {
	private uiContainer: PIXI.Container;
	private elements = new Set<UIElement>();

	constructor(private scene: Scene) {
		this.uiContainer = this.scene.uiContainer;
	}

	public readonly width: number;
	public readonly height: number;

	public get availableWidth() { return this.width; }
	public get availableHeight() { return this.height; }

	public onResize(width: number, height: number) {
		(this.width as any) = width;
		(this.height as any) = height;
		for (const element of this.elements) {
			element.onResize(width, height);
		}
	}

	public onMouseMove(x: number, y: number) {
		for (const element of this.elements) {
			element.onMouseMove(x - element.x, y - element.y);
		}
	}

	public add<T extends UIElement>(element: T) {
		this.uiContainer.addChild((element as any).internal);
		(element as any).internalInitialize(this);
		this.elements.add(element);
		return element;
	}

	public remove<T extends UIElement>(element: T) {
		this.uiContainer.removeChild((element as any).internal);
		(element as any).internalDestroy();
		this.elements.delete(element);
	}
}
