import { Behavior } from "./Behavior";
import { DynamicEntity } from "../../core/Entities/DynamicEntity";
import { Vec2 } from "@h4x/common";

export enum DartBehaviorState {
	INACTIVE,
	DART,
	SLOW
}

/** Have an Entity dart to a specific point */
export class DartBehavior extends Behavior<DynamicEntity, Vec2> {
	private state: DartBehaviorState = DartBehaviorState.INACTIVE;

	// Behavior Properties
	private target: Vec2;
	private speed: number = 2500;

	constructor(owner: DynamicEntity) {
		super(owner);
	}

	public update(dt: number): void {

		switch (this.state) {
			case DartBehaviorState.DART:
				this.processDart(dt);
				break;
			case DartBehaviorState.SLOW:
				this.processSlow(dt);
				break;
		}
	}

	// Process the dart movement behavior
	private processDart(dt: number) {
		let distVec = this.target.copy().subtract(this.owner.getPosition());
		let dir = distVec.copy().normalize();
		let currentDist = distVec.length();
		let velocity = dir.multiplyScalar(dt * this.speed);

		// Project Entity Position
		let projectDist = currentDist - velocity.length();

		if (projectDist <= 0) {
			this.owner.setPosition(this.target.x, this.target.y);
			this.state = DartBehaviorState.SLOW;
		} else {
			this.owner.setVelocity(velocity.x, velocity.y);
		}
	}

	// Process the slow-down part of the dart behavior
	private processSlow(dt: number) {
		let curVel = this.owner.getVelocity();
		if (curVel.length() < 0.01) {
			this.state = DartBehaviorState.INACTIVE;
			this.owner.setVelocity(0, 0);
		} else {
			curVel.multiplyScalar(0.8);
			this.owner.setVelocity(curVel.x, curVel.y);
		}
	}

	public execute(target: Vec2): void {
		this.target = target.copy();
		this.state = DartBehaviorState.DART;
	}

	/** Set the movement speed on this dart behavior (default: 2500) */
	public setDartSpeed(speed: number) {
		this.speed = speed;
	}

	/** Return the current state of the dart behavior */
	public getState(): DartBehaviorState {
		return this.state;
	}

	public setState(state: DartBehaviorState) {
		this.state = state;
	}
}
