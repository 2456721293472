import { UISizeDynamic, UISizePercentage } from "../../core/UIHelpers";

export const TITLE_FONTSIZE = new UISizeDynamic([
	{ height: 800, size: 32 },
	{ height: 500, size: 24 },
	{ height: 400, size: 18 },
	{ height: 300, size: 16 }
], { interpolate: true, floor: true });

export const DEBUG_FONTSIZE = new UISizeDynamic([
	{ height: 500, size: 18 },
	{ height: 400, size: 14 },
	{ height: 300, size: 12 }
], { interpolate: true, floor: true });

/* Volume */
export const VOLUME_TEXT_FONTSIZE = new UISizeDynamic([
	{ height: 800, size: 32 },
	{ height: 500, size: 28 },
	{ height: 300, size: 20 }
], { interpolate: true, floor: true });

/* Score */
export const SCORE_TITLE_FONTSIZE = new UISizeDynamic([
	{ height: 800, size: 64 },
	{ height: 500, size: 36 },
	{ height: 400, size: 28 },
	{ height: 300, size: 22 }
], { interpolate: true, floor: true });

export const SCORE_TEXT_FONTSIZE = new UISizeDynamic([
	{ height: 800, size: 36 },
	{ height: 500, size: 26 },
	{ height: 400, size: 20 },
	{ height: 300, size: 16 }
], { interpolate: true, floor: true });

export const SCORE_WIDTH = new UISizeDynamic([
	{ height: 800, size: 640 },
	{ height: 500, size: new UISizePercentage(90) },
	{ height: 400, size: new UISizePercentage(95) },
	{ height: 300, size: new UISizePercentage(100) }
], { interpolate: true, floor: true });

/* Menu */
export const MENU_BUTTON_HEIGHT = new UISizeDynamic([
	{ height: 800, size: 45 },
	{ height: 500, size: 35 },
	{ height: 300, size: 25 }
], { interpolate: true, floor: false });

export const MENU_BUTTON_FONTSIZE = new UISizeDynamic([
	{ height: 800, size: 42 },
	{ height: 500, size: 32 },
	{ height: 300, size: 22 }
], { interpolate: true, floor: true });

export const MENU_SETTINGS_FONTSIZE = new UISizeDynamic([
	{ height: 800, size: 72 },
	{ height: 500, size: 64 },
	{ height: 400, size: 48 },
	{ height: 300, size: 24 }
], { interpolate: true, floor: true });

export const MENU_BACK_WIDTH = new UISizeDynamic([
	{ height: 800, size: 150 },
	{ height: 500, size: 125 },
	{ height: 300, size: 100 }
], { interpolate: true, floor: false });

export const MENU_VISUALS_WIDTH = new UISizeDynamic([
	{ height: 800, size: 650 },
	{ height: 500, size: 475 },
	{ height: 300, size: 280 }
], { interpolate: true, floor: false });
