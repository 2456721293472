import { UIContainer } from "../../../core/UI/UIContainer";
import { UIHorizontalContainer } from "../../../core/UI/UIHorizontalContainer";
import { UIVerticalContainer } from "../../../core/UI/UIVerticalContainer";
import { HUDIndicatorHash } from "./HUDIndicatorHash";
import { HUDIndicatorGlobal } from "./HUDIndicatorGlobal";
import { UIPositionAlign, UIAlign } from "../../../core/UIHelpers";
import { UIText } from "../../../core/UI/UIText";
import { Colors } from "../../../utils/Colors";
import { UIElement } from "../../../core/UIElement";
import { UIManager } from "../../../core/UIManager";
import { Utils } from "@h4x/common";
import { Globals } from "../../Constants";
import { HUD_HEIGHT, HUD_BONUS_FONTSIZE } from "./HUDSizes";

const NUM_SCORE_HASHES = 40;
const SCORE_PADDING = 5;

enum IndicatorState {
	NEUTRAL = 0,
	SINGLE_POSITIVE = 1,
	DOUBLE_POSITIVE = 2,
	SINGLE_NEGATIVE = -1,
	DOUBLE_NEGATIVE = -2
}

export class HUDIndicatorContainer extends UIContainer {
	// Properties
	private baseGlobalPerc: number = 0;
	private rndStartGlobalPerc: number = 0;
	private rndCurGlobalPerc: number = 0;

	// UI Alignment Elements
	private globalHorizontalContainer: UIHorizontalContainer;
	private scoreVerticalContainer: UIVerticalContainer;

	// UI Elements
	private scoreTextUIContainer: UIContainer;

	private scoreHashUIContainer: HUDIndicatorHash;

	private numHashesLit: number = 0;
	private globalIndicator: HUDIndicatorGlobal;

	constructor() {
		super({
			x: new UIPositionAlign(UIAlign.right, 5),
			y: new UIPositionAlign(UIAlign.top),
			// width: 315,
			height: HUD_HEIGHT
		});

		// Create Top-Level UI Container
		this.globalHorizontalContainer = new UIHorizontalContainer({ height: HUD_HEIGHT, fill: true, padding: SCORE_PADDING });
		this.add(this.globalHorizontalContainer);

		// Setup Indicator Container Wrapper
		this.globalIndicator = this.globalHorizontalContainer.add(new HUDIndicatorGlobal());

		// Create the scoring vertical container
		let scoreWrapper = this.globalHorizontalContainer.add(new UIContainer({}));
		this.scoreVerticalContainer = scoreWrapper.add(new UIVerticalContainer({ y: new UIPositionAlign(UIAlign.center) }));

		this.scoreTextUIContainer = this.scoreVerticalContainer.add(new UIContainer());

		// Create Score Text
		this.scoreTextUIContainer.add(new UIText("research bonus", {
			color: Colors.White,
			font: "Hyperspace",
			fontSize: HUD_BONUS_FONTSIZE
		}));

		this.scoreHashUIContainer = this.scoreVerticalContainer.add(new HUDIndicatorHash(NUM_SCORE_HASHES));

		// Set the current indicator state to Neutral
		this.updateUI();
	}

	protected initialize(parent: UIElement | UIManager) {
		super.initialize(parent);
	}

	public setBaseGlobalPercent(percent: number) {
		this.baseGlobalPerc = percent;
		this.updateUI();
	}

	// Set the starting point for the Global Percent
	public setRoundStartingGlobalPercent(percent: number) {
		this.rndStartGlobalPerc = percent;
		this.updateUI();
	}

	/** Set the new Global Percent */
	public setRoundCurrentGlobalPercent(percent: number) {
		this.rndCurGlobalPerc = percent;
		this.updateUI();
	}

	// Change the global indicator to represent the data properly
	private updateUI() {
		// console.log("===============");
		// console.log("BAS: " + this.baseGlobalPerc);
		// console.log("RND: " + this.rndStartGlobalPerc);
		// console.log("CUR: " + this.rndCurGlobalPerc);
		// console.log("===============");

		if (this.rndCurGlobalPerc === undefined || this.rndStartGlobalPerc === undefined || this.baseGlobalPerc === undefined) {
			return;
		}

		// Determine Hash mark State
		let hashesScore = Utils.remapRange(this.rndCurGlobalPerc - this.rndStartGlobalPerc, 0, Globals.GLOBAL_SCORE_BONUS_TARGET, 0, NUM_SCORE_HASHES);
		this.numHashesLit = Math.ceil(hashesScore);
		this.scoreHashUIContainer.set(this.numHashesLit);

		// Determine Indicator State
		if (this.isStateNeutral()) {
			this.globalIndicator.set(IndicatorState.NEUTRAL);
		} else if (this.isStateSinglePositive()) {
			this.globalIndicator.set(IndicatorState.SINGLE_POSITIVE);
		} else if (this.isStateDoublePositive()) {
			this.globalIndicator.set(IndicatorState.DOUBLE_POSITIVE);
		} else if (this.isStateSingleNegative()) {
			this.globalIndicator.set(IndicatorState.SINGLE_NEGATIVE);
		} else if (this.isStateDoubleNegative()) {
			this.globalIndicator.set(IndicatorState.DOUBLE_NEGATIVE);
		} else {
			throw Error(`State Not Handled (current: ${this.rndCurGlobalPerc}, starting: ${this.rndStartGlobalPerc}, base: ${this.baseGlobalPerc})`);
		}
	}

	// Should the indicator be NEUTRAL
	private isStateNeutral() {
		return (this.rndCurGlobalPerc === this.rndStartGlobalPerc);
	}

	// Should the indicator be SINGLE POSITIVE
	private isStateSinglePositive() {
		return (this.rndCurGlobalPerc <= this.baseGlobalPerc && this.rndCurGlobalPerc > this.rndStartGlobalPerc);
	}

	// Should the indicator be DOUBLE POSITIVE
	private isStateDoublePositive() {
		return (this.rndCurGlobalPerc > this.baseGlobalPerc && this.rndCurGlobalPerc > this.rndStartGlobalPerc);
	}

	// Should the indicator be SINGLE NEGATIVE
	private isStateSingleNegative() {
		return (this.rndCurGlobalPerc >= this.baseGlobalPerc && this.rndCurGlobalPerc < this.rndStartGlobalPerc);
	}

	// Should the indicator be DOUBLE NEGATIVE
	private isStateDoubleNegative() {
		return (this.rndCurGlobalPerc < this.baseGlobalPerc && this.rndCurGlobalPerc < this.rndStartGlobalPerc);
	}

	public getNumHashesLit() {
		return this.numHashesLit;
	}
}
