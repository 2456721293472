import { EntityType } from "../core/Entities/Entity";
import Matter = require("matter-js");
import { Scene } from "../core/Scene";
import { BaseGameEntity } from "./BaseGameEntity";
import { TextureManager } from "../core/TextureManager";
import { EnemyType } from "../Gameplay/EnemyType";
import { Behavior } from "../Gameplay/Behaviors/Behavior";
import { ConstrainBehavior } from "../Gameplay/Behaviors/ConstrainBehavior";
import { Vec2 } from "@h4x/common";
import { EnemyBerzerkerBehavior } from "../Gameplay/Behaviors/EnemyBerzerkBehavior";
import { EnemyChaserBehavior } from "../Gameplay/Behaviors/EnemyChaserBehavior";
import { EnemyChaserTutorialBehavior } from "../Gameplay/Behaviors/EnemyChaserTutorialBehavior";
import { EnemyPatrollerOneBehavior } from "../Gameplay/Behaviors/EnemyPatrollerOneBehavior";
import { EnemyPatrollerTwoBehavior } from "../Gameplay/Behaviors/EnemyPatrollerTwoBehavior";
import { Globals } from "../Gameplay/Constants";
import { EnemyWinderBehavior } from "../Gameplay/Behaviors/EnemyWinderBehavior";
import { EnemySectorSpawnBehavior } from "../Gameplay/Behaviors/SpawnBehavior";

export class SectorEnemyEntity extends BaseGameEntity {
	public type = EntityType.SectorEnemy;
	public readonly enemyType: EnemyType;

	private spawnBehavior: EnemySectorSpawnBehavior;
	private constrainBehavior: ConstrainBehavior;
	private enemyBehavior: Behavior<any, any>;
	private active: boolean = false;
	private activateTimer: number = 1;

	// private enemyGraphic: pixi.Graphics;

	constructor(scene: Scene, x: number, y: number, enemyType: EnemyType) {
		super(scene, x, y, (() => {
			return Matter.Bodies.circle(x, y, 30);
		})(), (() => {
			switch (enemyType) {
				case EnemyType.CHASER: return TextureManager.EnemyChaser;
				case EnemyType.CHASER_TUTORIAL: return TextureManager.EnemyChaser;
				case EnemyType.BERZERKER: return TextureManager.EnemyBezerker;
				case EnemyType.PATROLLER_ONE: return TextureManager.EnemyPatroller;
				case EnemyType.PATROLLER_TWO: return TextureManager.EnemyPatroller;
				case EnemyType.PATROLLER_THREE: return TextureManager.EnemyWinder;
			}
		})());
		this.enemyType = enemyType;

		// Set Initial Movement Properties
		this.setMaxSpeed(1);
		this.setTurnRate(0.1);
		this.setMass(50);

		// set behavior
		switch (this.enemyType) {
			case EnemyType.CHASER:
				this.enemyBehavior = new EnemyChaserBehavior(this);
				this.enemyBehavior.execute(scene.getPlayerEntity());
				break;
			case EnemyType.CHASER_TUTORIAL:
				this.enemyBehavior = new EnemyChaserTutorialBehavior(this);
				this.enemyBehavior.execute(scene.getPlayerEntity());
				break;
			case EnemyType.BERZERKER:
				let dart = new EnemyBerzerkerBehavior(this);
				this.enemyBehavior = dart;
				dart.setRepeatAttack(true);
				this.setMass(5000);
				this.enemyBehavior.execute(scene.getPlayerEntity());
				break;
			case EnemyType.PATROLLER_ONE:
				this.enemyBehavior = new EnemyPatrollerOneBehavior(this);
				this.enemyBehavior.execute(y);
				break;
			case EnemyType.PATROLLER_TWO:
				this.enemyBehavior = new EnemyPatrollerTwoBehavior(this);
				this.enemyBehavior.execute(x);
				break;
			case EnemyType.PATROLLER_THREE:
				this.enemyBehavior = new EnemyWinderBehavior(this);
				this.enemyBehavior.execute(undefined);
				break;
		}

		this.spawnBehavior = new EnemySectorSpawnBehavior(this);
		this.spawnBehavior.execute();
		this.constrainBehavior = new ConstrainBehavior(this);
		this.constrainBehavior.execute([
			new Vec2(0, 0),
			new Vec2(Globals.WORLD_WIDTH, Globals.WORLD_HEIGHT)
		]);

		if (this.enemyType == EnemyType.CHASER_TUTORIAL) {
			this.spawnBehavior.setTrajectory(new Vec2(1000, 200), new Vec2(500, 600));
		}
	}

	public update(dt: number) {
		super.update(dt);
		if (this.spawnBehavior.isSpawning() === false) {
			this.activateTimer -= dt;
			if (this.activateTimer <= 0) {
				this.enemyBehavior.update(dt);
				this.constrainBehavior.update(dt);
			}

		} else {
			this.spawnBehavior.update(dt);
		}
	}

	/** Set whether or not this enemy is active */
	public setActive(active: boolean) {
		this.active = active;
		this.enemyBehavior.setActive(active);
	}

	/** Returns if the enemy is active or not */
	public isActive(): boolean {
		return this.active;
	}

	/** Returns TRUE if this player should destroy another entity on collision */
	public canDestroyOtherEnemy(): boolean {
		if (this.enemyType === EnemyType.BERZERKER) {
			return (this.enemyBehavior as EnemyBerzerkerBehavior).isBerzerkActive();
		} else {
			return false;
		}
	}

	public disable(timeSec: number) {
		if (this.enemyType === EnemyType.CHASER) {
				(this.enemyBehavior as EnemyChaserBehavior).disable(timeSec);
		}
	}
}
