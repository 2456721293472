import { EntityType } from "../core/Entities/Entity";
import pixi = require("pixi.js");
import Matter = require("matter-js");
import { Scene } from "../core/Scene";
import { BaseGameEntity } from "./BaseGameEntity";
import { TextureManager } from "../core/TextureManager";
import { Utils, Vec2 } from "@h4x/common";
import { Behavior } from "../Gameplay/Behaviors/Behavior";
import { NavigateToBehavior } from "../Gameplay/Behaviors/NavigateToBehavior";

const CHASE_POINT_VAL = 30000;
const TIME_TO_LIVE = 10;

export enum WarpEntityType {
	ENEMY,
	POWERUP,
}

export class WarpEntity extends BaseGameEntity {
	public type = EntityType.Warp;

	private timeToLive: number = TIME_TO_LIVE;
	private warpEntityType: WarpEntityType;
	private chasePoint: Vec2;

	public behavior: Behavior<any, any>;

	// private enemyGraphic: pixi.Graphics;
	constructor(scene: Scene, x: number, y: number, warpEntityType: WarpEntityType) {
		super(scene, x, y, (() => {
			return Matter.Bodies.circle(x, y, 30);
		})(), (() => {
			if (warpEntityType === WarpEntityType.ENEMY) {
				return (Utils.pickArray(TextureManager.EnemyTextureArray) as pixi.Texture);
			} else if (warpEntityType === WarpEntityType.POWERUP) {
				return (Utils.pickArray(TextureManager.PowerupTextureArray) as pixi.Texture);
			} else {
				return undefined;
			}
		})());

		// Set the warp entity type
		this.warpEntityType = warpEntityType;

		// Do not physically collide, but still fire collision events
		this.physicsBody.isSensor = true;

		// Set Movement Properties
		this.setMaxSpeed(2.0);
		this.setMass(50);
		this.setTurnRate(1.0);

		this.chasePoint = new Vec2(Utils.randomInt(-1, 1), Utils.randomInt(-1, 1)).multiplyScalar(CHASE_POINT_VAL);


		this.behavior = new NavigateToBehavior(this);
		(this.behavior as NavigateToBehavior).execute(
			new Vec2(Utils.rangeFloat(-1, 1), Utils.rangeFloat(-1, 1)).normalize().multiplyScalar(CHASE_POINT_VAL)
		);
	}

	public update(dt: number) {
		super.update(dt);
		this.behavior.update(dt);

		this.timeToLive -= dt;
	}

	public getTimeRemaining(): number {
		return this.timeToLive;
	}
}
