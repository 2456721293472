import pixi = require("pixi.js");
import { GlobalCluster } from "../cluster/GlobalCluster";
import { Colors as Colors } from "../utils/Colors";
import { ClusterUtils } from "../cluster/ClusterUtils";

let size = 9;
let padding = 1;

export class ResultRowContainer extends pixi.Container {

	private grid = new pixi.Container();
	private gridShapes: pixi.Graphics[] = [];
	private gridIndicators: pixi.Graphics[][] = [];

	constructor(private source: GlobalCluster, private row: number) {
		super();

		this.grid.x = 10;
		this.grid.y = 10;

		let { count } = source;
		let scores = this.source.getRowScores(this.row);
		let centroids = this.source.getCentroids();

		for (let i = 0; i < count - 1; i++) {
			let startX = i * (size + padding) + (i >= this.row ? (size + padding) : 0);
			let shape = new pixi.Graphics();
			shape.tint = ClusterUtils.GetScoreColor(scores[i]);
			shape.beginFill(0xFFFFFF, 1.0).drawRect(startX, 0, size, size).endFill();
			this.grid.addChild(shape);
			this.gridShapes[i] = shape;

			this.gridIndicators[i] = [];
			for (const centroid of centroids) {
				let indicator = new pixi.Graphics();
				if (centroid.type === true) {
					indicator.beginFill(0x4444FF, 1.0).drawRect(0, 0, 3, 3).endFill();
				} else {
					indicator.beginFill(0xFF4400, 1.0).drawRect(0, 0, 3, 3).endFill();
				}
				indicator.alpha = 0.75;
				this.grid.addChild(indicator);
				this.gridIndicators[i].push(indicator);
				indicator.x = startX + centroid.values[i < this.row ? i : i + 1] * size - 1;
				indicator.y = 0 + centroid.values[this.row] * size - 1;
			}
		}

		this.addChild(this.grid);
	}

	public update() {
		let { count } = this.source;
		let scores = this.source.getRowScores(this.row);
		let centroids = this.source.getCentroids();

		for (let i = 0; i < count - 1; i++) {
			let shape = this.gridShapes[i];
			shape.tint = ClusterUtils.GetScoreColor(scores[i]);

			let startX = i * (size + padding) + (i >= this.row ? (size + padding) : 0);

			for (let j = 0; j < centroids.length; j++) {
				const centroid = centroids[j];
				let indicator = this.gridIndicators[i][j];
				indicator.x = startX + centroid.values[i < this.row ? i : i + 1] * size - 1;
				indicator.y = 0 + centroid.values[this.row] * size - 1;
			}
		}
	}
}
