import * as pixi from "pixi.js";
import { UIElement, UIElementProperties } from "../UIElement";
import { UIManager } from "../UIManager";
import { UIBorderMode } from "./UIBorder";
import { BasicEvent } from "@h4x/common";
import { UISize, UIDirection } from "../UIHelpers";

export type UITextButtonProperties = UIElementProperties & {
	color?: number;
	fontSize?: UISize;
	font?: string;


	hoverColor?: number | undefined;
	activeColor?: number | undefined;
	hoverBorderColor?: number | undefined;
	activeBorderColor?: number | undefined;
	hoverBackgroundColor?: number | undefined;
	activeBackgroundColor?: number | undefined;

	width?: UISize;
	height?: UISize;

	borderColor?: number;
	borderWidth?: number;
	borderMode?: UIBorderMode;
	backgroundColor?: number | undefined;
	backgroundAlpha?: number | undefined;

	// TODO FIXME check alpha
};

export class UITextButton extends UIElement<UITextButtonProperties> {

	protected internal: pixi.Container;
	protected background: pixi.Graphics;
	protected textContainer: pixi.Text;
	protected border: pixi.Graphics;
	public readonly internalWidth: number;
	public readonly internalHeight: number;

	public readonly onClick = new BasicEvent<() => void>();
	public readonly onHover = new BasicEvent<() => void>();

	constructor(text: string, properties?: UITextButtonProperties) {
		super(properties);
		this.internal = new pixi.Container();
		this.background = new pixi.Graphics();
		this.textContainer = new pixi.Text(text);
		this.border = new pixi.Graphics();

		this.internal.interactive = true;
		this.internal.buttonMode = true;

		this.internal.on("mousedown", this.handleMouseDown, this);
		this.internal.on("mouseup", this.handleMouseUp, this);
		this.internal.on("mouseupoutside", this.handleMouseUpOutside, this);
		this.internal.on("mouseover", this.handleMouseOver, this);
		this.internal.on("mouseout", this.handleMouseOut, this);


		this.internal.addChild(this.background);
		this.internal.addChild(this.border);
		this.internal.addChild(this.textContainer);
	}

	protected initialize(parent: UIElement | UIManager) {
		this.applyProperty(this.properties, "fontSize", 24);
		this.applyProperty(this.properties, "color", 0xFFFFFF);
		this.applyProperty(this.properties, "font");

		this.applyProperty(this.properties, "width", 0);
		this.applyProperty(this.properties, "height", 0);
		this.applyProperty(this.properties, "backgroundColor", undefined);
		this.applyProperty(this.properties, "backgroundAlpha", 1);

		this.applyProperty(this.properties, "borderColor", 0xFFFFFF);
		this.applyProperty(this.properties, "borderWidth", 1);
		this.applyProperty(this.properties, "borderMode");

		this.applyProperty(this.properties, "hoverColor", 0xAA0000);
		this.applyProperty(this.properties, "hoverBackgroundColor", undefined);
		this.applyProperty(this.properties, "hoverBorderColor", undefined);

		this.applyProperty(this.properties, "activeColor", 0x00AA00);
		this.applyProperty(this.properties, "activeBackgroundColor", undefined);
		this.applyProperty(this.properties, "activeBorderColor", undefined);

		super.initialize(parent);

		this.textContainer.style.fontWeight = "bold";

		this.updatePosition();
		this.updateText();
		this.redraw();
	}

	public onResize(width: number, height: number) {
		super.onResize(width, height);
		this.updateSize();
		this.updateText();
		this.updatePosition();
		this.redraw();
	}

	private mouseDown = false;
	private handleMouseDown() {
		this.mouseDown = true;
		this.updateText();
		this.redraw();
	}

	private handleMouseUp() {
		if (this.mouseDown === true) {
			this.mouseDown = false;
			this.onClick.execute();
		}
		this.updateText();
		this.redraw();
	}

	private handleMouseUpOutside() {
		this.mouseDown = false;
		this.updateText();
		this.redraw();
	}

	private mouseOver = false;
	private handleMouseOver() {
		this.mouseOver = true;
		this.updateText();
		this.redraw();
		this.onHover.execute();
	}

	private handleMouseOut() {
		this.mouseOver = false;
		this.updateText();
		this.redraw();
	}

	protected updateText() {
		this.textContainer.style.fontSize = this.computeFontSize(this.$fontSize);

		this.textContainer.x = this.width / 2 - (this.textContainer.width / 2);
		this.textContainer.y = this.height / 2 - (this.textContainer.height / 2);

		if (this.mouseDown === true && this.activeColor !== undefined) {
			this.textContainer.style.fill = this.activeColor;
		} else if (this.mouseOver === true && this.hoverColor !== undefined) {
			this.textContainer.style.fill = this.hoverColor;
		} else {
			this.textContainer.style.fill = this.color;
		}
	}

	protected redraw() {
		this.background.clear();
		if (this.mouseDown === true && this.activeBackgroundColor !== undefined) {
			this.background.beginFill(this.activeBackgroundColor);
			this.background.drawRect(0, 0, this.width, this.height);
			this.background.endFill();
		} else if (this.mouseOver === true && this.hoverBackgroundColor !== undefined) {
			this.background.beginFill(this.hoverBackgroundColor);
			this.background.drawRect(0, 0, this.width, this.height);
			this.background.endFill();
		} else if (this.backgroundColor !== undefined) {
			this.background.beginFill(this.backgroundColor, this.backgroundAlpha);
			this.background.drawRect(0, 0, this.width, this.height);
			this.background.endFill();
		}

		this.border.clear();
		if (this.borderWidth > 0 && this.alpha > 0.0) {
			if (this.mouseDown === true && this.activeBorderColor !== undefined) {
				let width = this.borderWidth;
				this.border.lineStyle(width, this.activeBorderColor);
				if (this.borderMode === UIBorderMode.Default) {
					this.border.drawRect(0, 0, this.width, this.height);
				} else if (this.borderMode === UIBorderMode.Inner) {
					this.border.drawRect(width / 2, width / 2, this.width - width, this.height - width);
				} else if (this.borderMode === UIBorderMode.Outer) {
					this.border.drawRect(-width / 2, -width / 2, this.width + width, this.height + width);
				} else {
					throw new Error("Invalid border mode");
				}
			} else if (this.mouseOver === true && this.hoverBorderColor !== undefined) {
				let width = this.borderWidth;
				this.border.lineStyle(width, this.hoverBorderColor);
				if (this.borderMode === UIBorderMode.Default) {
					this.border.drawRect(0, 0, this.width, this.height);
				} else if (this.borderMode === UIBorderMode.Inner) {
					this.border.drawRect(width / 2, width / 2, this.width - width, this.height - width);
				} else if (this.borderMode === UIBorderMode.Outer) {
					this.border.drawRect(-width / 2, -width / 2, this.width + width, this.height + width);
				} else {
					throw new Error("Invalid border mode");
				}
			} else if (this.borderColor !== undefined) {
				let width = this.borderWidth;
				this.border.lineStyle(width, this.borderColor);
				if (this.borderMode === UIBorderMode.Default) {
					this.border.drawRect(0, 0, this.width, this.height);
				} else if (this.borderMode === UIBorderMode.Inner) {
					this.border.drawRect(width / 2, width / 2, this.width - width, this.height - width);
				} else if (this.borderMode === UIBorderMode.Outer) {
					this.border.drawRect(-width / 2, -width / 2, this.width + width, this.height + width);
				} else {
					throw new Error("Invalid border mode");
				}
			}

		}
	}


	private $width: UISize;
	public get width() { return this.internalWidth > 0 ? this.internalWidth : this.textContainer.width; }
	public set width(value: number) {
		this.$width = value;
		(this.internalWidth as any) = this.computeWidth(value);
	}

	private $height: UISize;
	public get height() { return this.internalHeight > 0 ? this.internalHeight : this.textContainer.height; }
	public set height(value: number) {
		this.$height = value;
		(this.internalHeight as any) = this.computeHeight(value);
	}

	public setWidth(value: number) {
		this.$width = value;
		this.internal.x = this.computeWidth(value);
		this.updateSize();
		this.updatePosition();
		this.redraw();
	}

	public setHeight(value: number) {
		this.$height = value;
		this.internal.y = this.computeHeight(value);
		this.updateSize();
		this.updatePosition();
		this.redraw();
	}

	public setSize(width: number, height: number) {
		this.$width = width;
		this.$height = height;
		this.updateSize();
		this.updatePosition();
		this.redraw();
	}

	private computeWidth(value: UISize): number {
		if (typeof (value) === "number") {
			return value;
		} else {
			return value.compute(UIDirection.X, this);
		}
	}

	private computeHeight(value: UISize): number {
		if (typeof (value) === "number") {
			return value;
		} else {
			return value.compute(UIDirection.Y, this);
		}
	}

	/* Font Size */
	private $fontSize: UISize;
	public get fontSize() { return this.textContainer.style.fontSize; }
	public setFontSize(value: number) { this.fontSize = value; return this; }
	public set fontSize(value: number) {
		this.$fontSize = value;
		if (this.initialized === true) {
			this.textContainer.style.fontSize = this.computeFontSize(value);
		}
	}

	private computeFontSize(value: UISize): number {
		if (typeof (value) === "number") {
			return value;
		} else {
			return value.compute(UIDirection.Y, this);
		}
	}

	protected updateSize() {
		(this.internalWidth as any) = this.computeWidth(this.$width);
		(this.internalHeight as any) = this.computeHeight(this.$height);
	}

	public get text() { return this.textContainer.text; }
	public set text(value: string) {
		this.textContainer.text = value;
		if (this.initialized === true) {
			this.updateText();
		}
	}

	/* Color */
	private $color: number;
	public get color() { return this.$color; }
	public setColor(value: number) { this.color = value; return this; }
	public set color(value: number) {
		this.$color = value;
		if (this.initialized === true) {
			this.updateText();
		}
	}

	/* Hover Color */
	private $hoverColor: number;
	public get hoverColor() { return this.$hoverColor; }
	public setHoverColor(value: number) { this.hoverColor = value; return this; }
	public set hoverColor(value: number) {
		this.$hoverColor = value;
		if (this.initialized === true) {
			this.updateText();
		}
	}

	/* Active Color */
	private $activeColor: number;
	public get activeColor() { return this.$activeColor; }
	public setActiveColor(value: number) { this.activeColor = value; return this; }
	public set activeColor(value: number) {
		this.$activeColor = value;
		if (this.initialized === true) {
			this.updateText();
		}
	}

	/* Font */
	public get font() { return this.textContainer.style.fontFamily; }
	public setFont(value: number) { this.font = value; return this; }
	public set font(value: number) {
		this.textContainer.style.fontFamily = value;
		if (this.initialized === true) {
			this.updatePosition();
			this.updateText();
		}
	}

	/* Color */
	private $borderColor: number;
	public get borderColor() { return this.$borderColor; }
	public setBorderColor(value: number) { this.borderColor = value; return this; }
	public set borderColor(value: number) {
		this.$borderColor = value;
		if (this.initialized === true) {
			this.redraw();
		}
	}

	/* Hover Border Color */
	private $hoverBorderColor: number;
	public get hoverBorderColor() { return this.$hoverBorderColor; }
	public setHoverBorderColor(value: number) { this.hoverBorderColor = value; return this; }
	public set hoverBorderColor(value: number) {
		this.$hoverBorderColor = value;
		if (this.initialized === true) {
			this.updateText();
		}
	}

	/* Hover Border Color */
	private $activeBorderColor: number | undefined;
	public get activeBorderColor() { return this.$activeBorderColor; }
	public setActiveBorderColor(value: number | undefined) { this.activeBorderColor = value; return this; }
	public set activeBorderColor(value: number | undefined) {
		this.$activeBorderColor = value;
		if (this.initialized === true) {
			this.redraw();
		}
	}

	/* Background Color */
	private $backgroundColor: number | undefined;
	public get backgroundColor() { return this.$backgroundColor; }
	public setBackgroundColor(value: number | undefined) { this.backgroundColor = value; return this; }
	public set backgroundColor(value: number | undefined) {
		this.$backgroundColor = value;
		if (this.initialized === true) {
			this.redraw();
		}
	}

	/* Hover Background Color */
	private $hoverBackgroundColor: number | undefined;
	public get hoverBackgroundColor() { return this.$hoverBackgroundColor; }
	public setHoverBackgroundColor(value: number | undefined) { this.hoverBackgroundColor = value; return this; }
	public set hoverBackgroundColor(value: number | undefined) {
		this.$hoverBackgroundColor = value;
		if (this.initialized === true) {
			this.redraw();
		}
	}

	/* Active Background Color */
	private $activeBackgroundColor: number;
	public get activeBackgroundColor() { return this.$activeBackgroundColor; }
	public setActiveBackgroundColor(value: number) { this.activeBackgroundColor = value; return this; }
	public set activeBackgroundColor(value: number) {
		this.$activeBackgroundColor = value;
		if (this.initialized === true) {
			this.updateText();
		}
	}

	private $backgroundAlpha: number;
	public get backgroundAlpha() { return this.$backgroundAlpha; }
	public setBackgroundAlpha(value: number) { this.backgroundAlpha = value; return this; }
	public set backgroundAlpha(value: number) {
		this.$backgroundAlpha = value;
		if (this.initialized === true) {
			this.updateText();
		}
	}

	/* Border Width */
	private $borderWidth: number;
	public get borderWidth() { return this.$borderWidth; }
	public setBorderWidth(value: number) { this.borderWidth = value; return this; }
	public set borderWidth(value: number) {
		this.$borderWidth = value;
		if (this.initialized === true) {
			this.redraw();
		}
	}

	/* Border Mode */
	private $borderMode: UIBorderMode = UIBorderMode.Outer;
	public get borderMode() { return this.$borderMode; }
	public setBorderMode(value: UIBorderMode) { this.borderMode = value; return this; }
	public set borderMode(value: UIBorderMode) {
		this.$borderMode = value;
		if (this.initialized === true) {
			this.redraw();
		}
	}
}
