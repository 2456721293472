import { Scene } from "../../core/Scene";
import TextButton from "../../core/TextButton";
import { ScenesManager } from "../../core/SceneManager";
import { App } from "../../app";
import { Colors } from "../../utils/Colors";
import { EnvironmentContainer } from "../../containers/EnvironmentContainer";

export class DebugMenuScene extends Scene {
	private listNumber = 0;

	public init() {
		let environment = new EnvironmentContainer(Colors.DarkGray);
		this.backgroundContainer.addChild(environment);

		this.addScene("Game", App.gameSceneSector);
		this.addScene("Solver Test", App.solverTestScene);
		this.addScene("Cluster Test", App.clusterTestScene);
		this.addScene("Synapse Cluster Test", App.synapseClusterTest);
		this.addScene("Warp Effect", App.warpTestScene);
		this.addScene("Warp Hole Effect", App.warpHoleScene);
		this.addScene("Warp Scene", App.gameSceneWarp);
		this.addScene("Test Behavior Scene", App.testBehaviorScene);
		this.addScene("Player UI Scene", App.playerUIScene);
		this.addScene("End Scene", App.gameSceneEnd);
		this.addScene("Screen Scene", App.screenScene);
		this.addScene("Round Skip Scene", App.roundSkipScene);
		this.addScene("New UI Scene", App.newUIScene);
		this.addScene("Player Score Scene", App.gameSceneSector);
		this.addScene("End Score Test Scene", App.playerScoreScene);
		this.addScene("Pause UI Scene", App.testPauseUIScene);
	}

	public addScene(text: string, scene: Scene) {
		let button = new TextButton(text);
		button.onClick.addCallback(() => { ScenesManager.changeScene(scene); });
		button.x = 10;
		button.y = 10 + (this.listNumber++) * 30;
		this.uiContainer.addChild(button);
	}
}
