import { EntityType } from "../core/Entities/Entity";
import pixi = require("pixi.js");
import Matter = require("matter-js");
import { Scene } from "../core/Scene";
import { BaseGameEntity } from "./BaseGameEntity";
import { TextureManager } from "../core/TextureManager";
import { Utils, Vec2 } from "@h4x/common";
import { NavigateToBehavior } from "../Gameplay/Behaviors/NavigateToBehavior";
import { App } from "../app";
import { Globals } from "../Gameplay/Constants";

export class MenuEnemyEntity extends BaseGameEntity {
	public type: EntityType = EntityType.SectorEnemy;

	private behavior: NavigateToBehavior;
	private timeToSwitch: number = 0;

	constructor(scene: Scene, x: number, y: number) {
		super(scene, x, y, (() => {
			return Matter.Bodies.circle(x, y, 30);
		})(), (() => {
			return (Utils.pickArray(TextureManager.EnemyTextureArray) as pixi.Texture);
		})());

		this.behavior = new NavigateToBehavior(this);
		this.runLogic();

		// Set Movement Properties
		this.setMaxSpeed(300);
		this.setMass(50);
		this.setTurnRate(5);
	}

	public update(dt: number) {
		this.behavior.update(dt);
		super.update(dt);

		this.timeToSwitch -= dt;
		if (this.timeToSwitch <= 0) {
			this.runLogic();
		}
	}

	private runLogic() {
		this.timeToSwitch = Utils.randomInt(1, 5);
		this.behavior.execute(new Vec2(Utils.randomInt(0, Globals.WORLD_WIDTH), Utils.randomInt(0, Globals.WORLD_HEIGHT)));
	}
}
