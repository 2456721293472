
export class Color {
	public r: number;
	public g: number;
	public b: number;
	public a: number;

	constructor(r: number, g: number, b: number, a: number) {
		this.r = r | 0;
		this.g = g | 0;
		this.b = b | 0;
		this.a = a | 0;
	}

	public copy() {
		return new Color(this.r, this.g, this.b, this.a);
	}

	public static fromHSL(h: number, s: number, l: number, a: number) {
		let r, g, b;
		if (s === 0) {
			r = g = b = l; // achromatic
		} else {
			const hue2rgb = (x: number, y: number, t: number) => {
				if (t < 0) { t += 1; }
				if (t > 1) { t -= 1; }
				if (t < 1 / 6) { return x + (y - x) * 6 * t; }
				if (t < 1 / 2) { return y; }
				if (t < 2 / 3) { return x + (y - x) * (2 / 3 - t) * 6; }
				return x;
			};
			const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
			const p = 2 * l - q;
			r = hue2rgb(p, q, h + 1 / 3);
			g = hue2rgb(p, q, h);
			b = hue2rgb(p, q, h - 1 / 3);
		}
		return new Color((r * 255) | 0, (g * 255) | 0, (b * 255) | 0, a);
	}

	public mix(other: Color, t: number) {
		return new Color(
			(this.r * t) + (other.r * (1 - t)) | 0,
			(this.g * t) + (other.g * (1 - t)) | 0,
			(this.b * t) + (other.b * (1 - t)) | 0,
			(this.a * t) + (other.a * (1 - t)) | 0
		);
	}

	public toHTML() {
		return `rgba(${this.r}, ${this.g}, ${this.b}, ${(this.a / 255).toFixed(4)})`;
	}
}
