export const TutorialClusterData: any = {
	"problemUUID": "TUTORIAL_DATA_UUID",
	"balancedMode": true,
	"data": [
		{
			"type": false,
			"values": [
				0.10932664,
				0.26931755
			]
		},
		{
			"type": false,
			"values": [
				0.11506471,
				0.07114414
			]
		},
		{
			"type": false,
			"values": [
				0.14730657,
				0.09565366
			]
		},
		{
			"type": false,
			"values": [
				0.05950958,
				0.1068093
			]
		},
		{
			"type": true,
			"values": [
				0.07611208,
				0.88240424
			]
		},
		{
			"type": false,
			"values": [
				0.11951111,
				0.35692727
			]
		},
		{
			"type": false,
			"values": [
				0.01581519,
				0.00562302
			]
		},
		{
			"type": true,
			"values": [
				0.22964032,
				0.86025604
			]
		},
		{
			"type": true,
			"values": [
				0.13342184,
				0.82177932
			]
		},
		{
			"type": true,
			"values": [
				0.159215,
				0.88078541
			]
		},
		{
			"type": false,
			"values": [
				0.16944467,
				0.18343308
			]
		},
		{
			"type": true,
			"values": [
				0.09607485,
				0.88392293
			]
		},
		{
			"type": false,
			"values": [
				0.17175454,
				0.10361869
			]
		},
		{
			"type": true,
			"values": [
				0.10797897,
				0.88922473
			]
		},
		{
			"type": true,
			"values": [
				0.25942509,
				0.81609185
			]
		},
		{
			"type": false,
			"values": [
				0.84308229,
				0.77825352
			]
		},
		{
			"type": false,
			"values": [
				0.10797001,
				0.072243
			]
		},
		{
			"type": true,
			"values": [
				0.122935,
				0.90169437
			]
		},
		{
			"type": false,
			"values": [
				0.10580408,
				0.08050889
			]
		},
		{
			"type": true,
			"values": [
				0.14574795,
				0.82992801
			]
		},
		{
			"type": false,
			"values": [
				0.1105005,
				0.09779055
			]
		},
		{
			"type": false,
			"values": [
				0.09282063,
				0.06899716
			]
		},
		{
			"type": false,
			"values": [
				0.10346687,
				0.08895138
			]
		},
		{
			"type": false,
			"values": [
				0.08120776,
				0.10373563
			]
		},
		{
			"type": false,
			"values": [
				0.81995683,
				0.98542256
			]
		},
		{
			"type": false,
			"values": [
				0.10927506,
				0.08073931
			]
		},
		{
			"type": true,
			"values": [
				0.17033188,
				0.75131428
			]
		},
		{
			"type": false,
			"values": [
				1,
				0.98745291
			]
		},
		{
			"type": false,
			"values": [
				0.6940808,
				0.36426161
			]
		},
		{
			"type": false,
			"values": [
				0.17638235,
				0.33849278
			]
		},
		{
			"type": true,
			"values": [
				0.17688615,
				0.79336625
			]
		},
		{
			"type": false,
			"values": [
				0.54999664,
				0.44783792
			]
		},
		{
			"type": true,
			"values": [
				0.30183211,
				0.56214171
			]
		},
		{
			"type": false,
			"values": [
				0,
				0.04291267
			]
		},
		{
			"type": true,
			"values": [
				0.10864114,
				0.4836839
			]
		},
		{
			"type": true,
			"values": [
				0.20334533,
				0.73990613
			]
		},
		{
			"type": false,
			"values": [
				0.16807141,
				0.33344878
			]
		},
		{
			"type": false,
			"values": [
				0.07790216,
				0.08676712
			]
		},
		{
			"type": false,
			"values": [
				0.50514831,
				0.65515566
			]
		},
		{
			"type": false,
			"values": [
				0.09917832,
				0.08751308
			]
		},
		{
			"type": false,
			"values": [
				0.04305947,
				0.05639336
			]
		},
		{
			"type": true,
			"values": [
				0.16929978,
				1
			]
		},
		{
			"type": true,
			"values": [
				0.25140852,
				0.59735781
			]
		},
		{
			"type": false,
			"values": [
				0.44117425,
				0.33806991
			]
		},
		{
			"type": false,
			"values": [
				0.13232853,
				0.09730215
			]
		},
		{
			"type": false,
			"values": [
				0.12784598,
				0.29172874
			]
		},
		{
			"type": false,
			"values": [
				0.06798535,
				0
			]
		},
		{
			"type": false,
			"values": [
				0.10366143,
				0.05141108
			]
		},
		{
			"type": false,
			"values": [
				0.81323605,
				0.57264084
			]
		},
		{
			"type": false,
			"values": [
				0.18650477,
				0.16877412
			]
		},
		{
			"type": false,
			"values": [
				0.19221982,
				0.13901096
			]
		},
		{
			"type": false,
			"values": [
				0.13629607,
				0.11109759
			]
		},
		{
			"type": false,
			"values": [
				0.10361933,
				0.12772726
			]
		},
		{
			"type": false,
			"values": [
				0.09759233,
				0.0770247
			]
		},
		{
			"type": false,
			"values": [
				0.08946058,
				0.08689565
			]
		},
		{
			"type": false,
			"values": [
				0.11533456,
				0.10258591
			]
		},
		{
			"type": false,
			"values": [
				0.163478,
				0.30528919
			]
		},
		{
			"type": true,
			"values": [
				0.25871696,
				0.9828112
			]
		},
		{
			"type": false,
			"values": [
				0.06293028,
				0.46143144
			]
		},
		{
			"type": false,
			"values": [
				0.13592903,
				0.27998746
			]
		}
	],
	"centroids": [
		{
			"type": true,
			"values": [0.0, 0.0]
		},
		{
			"type": false,
			"values": [0.0, 0.0]
		}
	]
};
