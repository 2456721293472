import { Behavior } from "./Behavior";
import { DynamicEntity } from "../../core/Entities/DynamicEntity";
import { PathBehavior } from "./PathBehavior";
import { Vec2 } from "@h4x/common";
import { App } from "../../app";
import { DifficultyManager } from "../DifficultyManager";
import { Globals } from "../Constants";
import { SoundManager } from "../../core/SoundManager";

/** Enemy Patroller One (Horizontal) Behavior */
export class EnemyPatrollerOneBehavior extends Behavior<DynamicEntity, number> {
	private pathBehavior: PathBehavior;

	constructor(owner: DynamicEntity) {
		super(owner);

		this.pathBehavior = new PathBehavior(owner);
	}

	public update(dt: number) {
		if (this.active === false) {
			return;
		}

		this.pathBehavior.update(dt);
	}

	public execute(yLocation: number) {
		let path: Vec2[] = [];

		// Horizontal Behavior
		path.push(
			new Vec2(0, yLocation),
			new Vec2(Globals.WORLD_WIDTH, yLocation)
		);

		this.owner.setMaxSpeed(DifficultyManager.getPatrollerOneSpeed());

		this.pathBehavior.setSoundToPlayOnPathChange(SoundManager.PatrollerSpin);
		this.pathBehavior.execute(path);
	}
}
