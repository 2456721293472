import { DifficultyManager } from "./DifficultyManager";
import { GameplayLogicController } from "./GameLogicController";

type ScoreMap = Map<ScoreCategory, Score>;

export class Score {
	public score: number = 0;
	public items: number = 0;
}

/** Scoring Categories */
export enum ScoreCategory {
	MATTER_SEPARATED,
	WARP_METER_BONUS,
	RESEARCH_BONUS,
	SHIELD_BONUS,
	SECTOR_BONUS,

	// Special Enum for Iteration, leave last
	NUM_CATEGORIES,
}

// Manager for holding the scores of the game
export class ScoreManager {
	private static currentPlayerScore: number = 0;
	private static allScores: Map<number, ScoreMap> = new Map();
	private static currentRound: ScoreMap = new Map();

	public static newGame(round: number) {
		this.currentPlayerScore = 0;
		this.allScores.clear();
		this.currentRound.clear();

		// Init all round scores to 0
		for (let i = 0; i < Object.keys(ScoreCategory).length; i++) {
			this.currentRound.set(i, new Score());
		}
	}

	// Return the base value of the score
	private static getScoreBaseValue(category: ScoreCategory) {
		let scoreBase = 0;
		switch (category) {
			case ScoreCategory.MATTER_SEPARATED:
				scoreBase = DifficultyManager.getScoreMatterClustered();
				break;
			case ScoreCategory.WARP_METER_BONUS:
				scoreBase = DifficultyManager.getScoreClusteringBonus();
				break;
			case ScoreCategory.RESEARCH_BONUS:
				scoreBase = DifficultyManager.getScoreGlobalScoreBonus();
				break;
			case ScoreCategory.SHIELD_BONUS:
				scoreBase = DifficultyManager.getScoreShieldBonus();
				break;
			case ScoreCategory.SECTOR_BONUS:
				scoreBase = DifficultyManager.getScoreLevelBonus();
				break;
			default:
				throw Error("ScoreManager:getScoreBaseValue - Invalid Category Specified!");
		}

		return scoreBase;
	}

	/** Add to the current score for a particular category. Num Items will be rounded by default unless "round" flag is false  */
	public static addScore(category: ScoreCategory, numItems: number, round: boolean = true) {
		let val = this.currentRound.get(category);

		numItems = round ? Math.round(numItems) : numItems;

		let score = (numItems * this.getScoreBaseValue(category));

		if (val === undefined) {
			val = new Score();
			val.score = score;
			val.items = numItems;
		} else {
			val.score += score;
			val.items += numItems;

		}

		this.currentRound.set(category, val);
	}

	/** Returns the total score for the categories */
	public static getCategoryTotalScore(round = -1): number {
		let sum = 0;
		for (let i = 0; i < ScoreCategory.NUM_CATEGORIES; i++) {
			sum += this.getScore(i, round).score;
		}

		return sum;
	}

	/** Get the current score for a particular category with the supplied round number (or current round if no number is supplied) */
	public static getScore(category: ScoreCategory, round: number = -1): Score {
		let curRound = GameplayLogicController.getCurrentRound();
		let rnd = (round === -1) ? curRound : round;

		if (rnd < 0 || rnd > GameplayLogicController.getMaxRounds()) {
			throw Error(`ScoreManager:getScore - Invalid Round Specified '${round}'`);
		}

		let ret = new Score();
		if (rnd === curRound) {
			let sCat = this.currentRound.get(category);
			if (sCat !== undefined) {
				ret.score = sCat.score;
				ret.items = sCat.items;
			}
		} else {
			let rndScore = this.allScores.get(rnd);
			if (rndScore !== undefined) {
				ret = rndScore.get(category)!;
			}
		}

		return ret;
	}

	public static getCurrentScore() {
		return this.getCategoryTotalScore() + this.getCurrentPlayerScore();
	}

	/** Get the current PLAYER score */
	public static getCurrentPlayerScore(): number {
		return this.currentPlayerScore;
	}

	/** Push to a new round */
	public static pushNewRound(round: number) {
		this.addLivePlayerScore(this.getCategoryTotalScore());

		if (this.allScores.has(round)) {
			throw Error("ScoreManager:pushNewRound - This round already exists!");
		}

		this.allScores.set(round, this.currentRound);
		this.currentRound.clear();

		// Init all round scores to 0
		for (let i = 0; i < Object.keys(ScoreCategory).length; i++) {
			this.currentRound.set(i, new Score());
		}
	}

	/** Add a score immediately to the player score score */
	public static addLivePlayerScore(score: number, round: boolean = true) {
		score = round ? Math.round(score) : score;
		this.currentPlayerScore += score;
	}

	/** Shortcut to tally a berzerker kill (default kills = 1) */
	public static berzerkerKill(kills = 1) {
		this.addLivePlayerScore(DifficultyManager.getScoreBezerkerKill() * kills);
	}
}
