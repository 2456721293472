import * as pixi from "pixi.js";
import { UIElement, UIElementProperties } from "../UIElement";
import { UIManager } from "../UIManager";
import { UIContainer, UIContainerProperties, UIContainers } from "./UIContainer";
import { UISize, UIDirection } from "../UIHelpers";

export interface UIHorizontalProperties extends UIContainerProperties {
	margin?: UISize;
	padding?: UISize;
	fit?: boolean;
	fill?: boolean;
}

export class UIHorizontalContainer<T extends UIHorizontalProperties = UIHorizontalProperties>
	extends UIContainer<T & UIHorizontalProperties, UIContainers> {

	constructor(properties?: UIHorizontalProperties) {
		super(properties as T);
		(this.internal as any) = new pixi.Container();
	}

	public get availableWidth() { return this.width - this.computePadding() * Math.max(0, this.elements.size - 1); }
	public get availableHeight() { return this.height; }

	protected initialize(parent: UIElement | UIManager) {
		this.applyProperty(this.properties, "margin", 0);
		this.applyProperty(this.properties, "padding", 0);
		this.applyProperty(this.properties, "fit", false);
		this.applyProperty(this.properties, "fill", false);
		super.initialize(parent);
		this.applyPositions();
	}

	public add<R extends UIContainers>(element: R) {
		super.add(element);
		this.applyPositions();
		return element;
	}

	public remove<R extends UIContainers>(element: R) {
		super.remove(element);
		this.applyPositions();
	}

	public onResize(width: number, height: number) {

		super.onResize(width, height);
		this.applyPositions();
	}

	public setWidth(width: UISize) {
		super.setWidth(width);
		this.applyPositions();
	}

	public setHeight(width: UISize) {
		super.setHeight(width);
		this.applyPositions();
	}

	public setSize(width: UISize, height: UISize) {
		super.setSize(width, height);
		this.applyPositions();
	}

	public applyPositions() {
		let margin = this.computeMargin();
		let padding = this.computePadding();

		let x = 0 + margin;

		let count = this.elements.size;
		let width = this.width;
		let totalPadding = padding * (count - 1);
		let elementWidth = (width - margin * 2 - totalPadding) / count;
		if (this.fit) {
			for (const element of this.elements) {
				element.y = margin;
				if (this.fill) {
					element.setSize(elementWidth, this.height - margin * 2);
				} else {
					element.setWidth(elementWidth);
				}
				element.x = x;
				x += element.width + padding;
			}
		} else {
			for (const element of this.elements) {
				element.y = margin;
				if (this.fill) {
					element.setHeight(this.height - margin * 2);
				}
				// element.setSize(this.width - this.margin * 2, elementHeight);
				element.x = x;
				x += element.width + padding;
			}
		}
	}
	/* Margin */
	private $margin: UISize;
	public get margin() { return this.$margin; }
	public setMargin(value: number) { this.margin = value; return this; }
	public set margin(value: UISize) {
		this.$margin = value;
		if (this.initialized === true) {
			this.applyPositions();
		}
	}

	/* Padding */
	private $padding: UISize;
	public get padding() { return this.$padding; }
	public setPadding(value: number) { this.padding = value; return this; }
	public set padding(value: UISize) {
		this.$padding = value;
		if (this.initialized === true) {
			this.applyPositions();
		}
	}

	private computeMargin(): number {
		if (typeof (this.margin) === "number") {
			return this.margin;
		} else {
			return this.margin.compute(UIDirection.X, this);
		}
	}

	private computePadding(): number {
		if (typeof (this.padding) === "number") {
			return this.padding;
		} else {
			return this.padding.compute(UIDirection.Y, this);
		}
	}

	/* Fit */
	private $fit: boolean;
	public get fit() { return this.$fit; }
	public setFit(value: boolean) { this.fit = value; return this; }
	public set fit(value: boolean) {
		this.$fit = value;
		if (this.initialized === true) {
			this.applyPositions();
		}
	}

	/* Fit */
	private $fill: boolean;
	public get fill() { return this.$fill; }
	public setFill(value: boolean) { this.fill = value; return this; }
	public set fill(value: boolean) {
		this.$fill = value;
		if (this.initialized === true) {
			this.applyPositions();
		}
	}
}
