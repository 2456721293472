import { Quat } from "./Quat";
import { Mat4 } from "./Mat4";

export class Vec3 {

	constructor()
	constructor(x: number, y: number, z: number)
	constructor(public x = 0, public y = 0, public z = 0) {
	}

	public static get zero() { return new Vec3(0, 0, 0); }

	public static get up() { return new Vec3(0, 1, 0); }
	public static get right() { return new Vec3(1, 0, 0); }
	public static get forward() { return new Vec3(0, 0, 1); }

	public toString() {
		return `Vec3<${this.x.toFixed(5)},${this.y.toFixed(5)},${this.z.toFixed(5)}>`;
	}

	public set(x: number, y: number, z: number) {
		this.x = x; this.y = y; this.z = z; return this;
	}

	public toXYZ() {
		return { x: this.x, y: this.y, z: this.z };
	}

	public reset() {
		this.x = 0; this.y = 0; this.z = 0; return this;
	}

	public copy(dest?: Vec3) {
		if (!dest) {
			return new Vec3(this.x, this.y, this.z);
		} else {
			dest.x = this.x;
			dest.y = this.y;
			dest.z = this.z;
			return dest;
		}
	}

	public negate(dest?: Vec3) {
		if (!dest) { dest = this; }

		dest.x = -this.x;
		dest.y = -this.y;
		dest.z = -this.z;

		return dest;
	}

	public equals(vector: Vec3, threshold: number = 0.000001): boolean {
		if (Math.abs(this.x - vector.x) > threshold) { return false; }
		if (Math.abs(this.y - vector.y) > threshold) { return false; }
		if (Math.abs(this.z - vector.z) > threshold) { return false; }

		return true;
	}

	public length(): number {
		return Math.sqrt(this.squaredLength());
	}

	public squaredLength(): number {
		const x = this.x, y = this.y, z = this.z;
		return (x * x + y * y + z * z);
	}

	public add(vector: Vec3): Vec3 {
		this.x += vector.x;
		this.y += vector.y;
		this.z += vector.z;

		return this;
	}

	public addScalar(value: number): Vec3 {
		this.x += value;
		this.y += value;
		this.z += value;

		return this;
	}

	public addXYZ(x: number, y: number, z: number): Vec3 {
		this.x += x;
		this.y += y;
		this.z += z;

		return this;
	}

	public subtract(vector: Vec3): Vec3 {
		this.x -= vector.x;
		this.y -= vector.y;
		this.z -= vector.z;

		return this;
	}

	public subtractScalar(value: number): Vec3 {
		this.x -= value;
		this.y -= value;
		this.z -= value;

		return this;
	}

	public subtractXYZ(x: number, y: number, z: number): Vec3 {
		this.x -= x;
		this.y -= y;
		this.z -= z;

		return this;
	}

	public multiply(vector: Vec3): Vec3 {
		this.x *= vector.x;
		this.y *= vector.y;
		this.z *= vector.z;

		return this;
	}

	public multiplyScalar(value: number): Vec3 {
		this.x *= value;
		this.y *= value;
		this.z *= value;

		return this;
	}

	public multiplyXYZ(x: number, y: number, z: number): Vec3 {
		this.x *= x;
		this.y *= y;
		this.z *= z;

		return this;
	}

	public divide(vector: Vec3): Vec3 {
		this.x /= vector.x;
		this.y /= vector.y;
		this.z /= vector.z;

		return this;
	}

	public divideScalar(value: number): Vec3 {
		this.x /= value;
		this.y /= value;
		this.z /= value;

		return this;
	}

	public divideXYZ(x: number, y: number, z: number): Vec3 {
		this.x /= x;
		this.y /= y;
		this.z /= z;

		return this;
	}

	public static normalize(source: Vec3, dest: Vec3): Vec3 {
		let length = source.length();

		if (length === 1) {
			return source.copy(dest);
		}

		if (length === 0) {
			dest.x = 0;
			dest.y = 0;
			dest.z = 0;

			return dest;
		}

		length = 1.0 / length;

		dest.x *= length;
		dest.y *= length;
		dest.z *= length;

		return dest;
	}

	public normalize(): Vec3 {
		let length = this.length();

		if (length === 1) {
			return this;
		}

		if (length === 0) {
			this.x = 0;
			this.y = 0;
			this.z = 0;

			return this;
		}

		length = 1.0 / length;

		this.x *= length;
		this.y *= length;
		this.z *= length;

		return this;
	}

	public multiplyByQuat(quat: Quat, dest?: Vec3): Vec3 {
		if (!dest) { dest = this; }

		return quat.multiplyVec3(this, dest);
	}

	public static cross(vector: Vec3, vector2: Vec3, dest?: Vec3): Vec3 {
		if (!dest) { dest = new Vec3(); }

		const x = vector.x;
		const y = vector.y;
		const z = vector.z;

		const x2 = vector2.x;
		const y2 = vector2.y;
		const z2 = vector2.z;

		dest.x = y * z2 - z * y2;
		dest.y = z * x2 - x * z2;
		dest.z = x * y2 - y * x2;

		return dest;
	}

	public static dot(vector: Vec3, vector2: Vec3): number {
		const x = vector.x;
		const y = vector.y;
		const z = vector.z;

		const x2 = vector2.x;
		const y2 = vector2.y;
		const z2 = vector2.z;

		return (x * x2 + y * y2 + z * z2);
	}

	public static distance(vector: Vec3, vector2: Vec3): number {
		return Math.sqrt(this.squaredDistance(vector, vector2));
	}

	public static squaredDistance(vector: Vec3, vector2: Vec3): number {
		let x = vector2.x - vector.x;
		let y = vector2.y - vector.y;
		let z = vector2.z - vector.z;

		return (x * x + y * y + z * z);
	}

	public static direction(vector: Vec3, vector2: Vec3, dest?: Vec3): Vec3 {
		if (!dest) { dest = new Vec3(); }

		const x = vector.x - vector2.x;
		const y = vector.y - vector2.y;
		const z = vector.z - vector2.z;

		let length = Math.sqrt(x * x + y * y + z * z);

		if (length === 0) {
			dest.x = 0;
			dest.y = 0;
			dest.z = 0;

			return dest;
		}

		length = 1 / length;

		dest.x = x * length;
		dest.y = y * length;
		dest.z = z * length;

		return dest;
	}

	public static mix(vector: Vec3, vector2: Vec3, time: number, dest?: Vec3): Vec3 {
		if (!dest) { dest = new Vec3(); }

		dest.x = vector.x + time * (vector2.x - vector.x);
		dest.y = vector.y + time * (vector2.y - vector.y);
		dest.z = vector.z + time * (vector2.z - vector.z);

		return dest;
	}

	public static sum(vector: Vec3, vector2: Vec3, dest?: Vec3): Vec3 {
		if (!dest) { dest = new Vec3(); }

		dest.x = vector.x + vector2.x;
		dest.y = vector.y + vector2.y;
		dest.z = vector.z + vector2.z;

		return dest;
	}

	public static difference(vector: Vec3, vector2: Vec3, dest?: Vec3): Vec3 {
		if (!dest) { dest = new Vec3(); }

		dest.x = vector.x - vector2.x;
		dest.y = vector.y - vector2.y;
		dest.z = vector.z - vector2.z;

		return dest;
	}

	public static product(vector: Vec3, vector2: Vec3, dest?: Vec3): Vec3 {
		if (!dest) { dest = new Vec3(); }

		dest.x = vector.x * vector2.x;
		dest.y = vector.y * vector2.y;
		dest.z = vector.z * vector2.z;

		return dest;
	}

	public static quotient(vector: Vec3, vector2: Vec3, dest?: Vec3): Vec3 {
		if (!dest) { dest = new Vec3(); }

		dest.x = vector.x / vector2.x;
		dest.y = vector.y / vector2.y;
		dest.z = vector.z / vector2.z;

		return dest;
	}

	public toQuat(dest?: Quat): Quat {
		if (!dest) { dest = new Quat(); }

		let cx = Math.cos(this.x * 0.5);
		let sx = Math.sin(this.x * 0.5);

		let cy = Math.cos(this.y * 0.5);
		let sy = Math.sin(this.y * 0.5);

		let cz = Math.cos(this.z * 0.5);
		let sz = Math.sin(this.z * 0.5);

		dest.x = sx * cy * cz - cx * sy * sz;
		dest.y = cx * sy * cz + sx * cy * sz;
		dest.z = cx * cy * sz - sx * sy * cz;
		dest.w = cx * cy * cz + sx * sy * sz;

		return dest;
	}

	public static unproject(vec: Vec3, view: Mat4, proj: Mat4, viewport: number[]) {
		// NOTE not tested

		// build and invert viewproj matrix
		let matrix = proj.copy().multiply(view);
		let invMat = matrix.inverse();
		if (invMat === null) {
			return null;
		}

		// apply viewport transform
		let x = (vec.x - viewport[0]) * 2.0 / viewport[2] - 1.0;
		let y = (vec.y - viewport[1]) * 2.0 / viewport[3] - 1.0;
		let z = (vec.z * 2.0) - 1.0;
		let w = 1.0;


		let tx = invMat.at(0) * x + invMat.at(4) * y + invMat.at(8) * z + invMat.at(12) * w;
		let ty = invMat.at(1) * x + invMat.at(5) * y + invMat.at(9) * z + invMat.at(13) * w;
		let tz = invMat.at(2) * x + invMat.at(6) * y + invMat.at(10) * z + invMat.at(14) * w;
		let tw = invMat.at(3) * x + invMat.at(7) * y + invMat.at(11) * z + invMat.at(15) * w;

		if (tw === 0.0) { return null; }

		return new Vec3(tx / tw, ty / tw, tz / tw);
	}



}



