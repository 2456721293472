import { Scene } from "../../core/Scene";
import * as pixi from "pixi.js";
import TextButton from "../../core/TextButton";
import { BrowserRequest } from "../../utils/BrowserRequest";
import { ScenesManager } from "../../core/SceneManager";
import { App } from "../../app";
import { Utils } from "@h4x/common";
import Text from "../../core/Text";
import { Colors } from "../../utils/Colors";
import GraphicsButton from "../../core/GraphicsButton";
import { EnvironmentContainer } from "../../containers/EnvironmentContainer";

export namespace SolverUtils {

	export function LerpColorInt(a: number, b: number, amount: number) {

		let ar = a >> 16, ag = a >> 8 & 0xff, ab = a & 0xff,
			br = b >> 16, bg = b >> 8 & 0xff, bb = b & 0xff,
			rr = ar + amount * (br - ar),
			rg = ag + amount * (bg - ag),
			rb = ab + amount * (bb - ab);

		return ((rr << 16) + (rg << 8) + rb | 0);
	}

	function normalize(value: number, min: number, max: number) {
		return (value - min) / (max - min);
	}

	const colors = [
		[Colors.DarkRed, 0.0],
		[Colors.Orange, 0.25],
		[Colors.DarkYellow, 0.5],
		[Colors.Green, 0.75],
		[Colors.DarkGreen, 1.0],
		[Colors.DarkGreen, 1.01]
	];

	export function GetScoreColor(score: number) {
		for (let i = 0; i < colors.length; i++) {
			const [color2, max] = colors[i];
			if (max > score) {
				const [color, min] = colors[i - 1];
				return LerpColorInt(color, color2, normalize(score, min, max));
			}
		}
		return 0xFF00FF;
	}
}

export class SolverTestScene extends Scene {
	constructor() {
		super();
	}

	private progressText = new Text("LOADING", { fill: Colors.White });
	private opsText = new Text("LOADING", { fill: Colors.White });
	private totalStepsText = new Text("LOADING", { fill: Colors.White });

	private datasetFile: string = "./static/linear_norm_csv.full.csv"; // "*rand.full";

	private totalSteps: number = 0;

	private data: {
		type: boolean;
		values: number[];
		custom: (number | undefined)[];
	}[];

	private gridTypes: pixi.Graphics[] = [];
	private gridIndicators: pixi.Graphics[] = [];
	private sortFn = (
		a: { type: boolean; values: number[]; custom: (number | undefined)[]; },
		b: { type: boolean; values: number[]; custom: (number | undefined)[]; }) => (b.type ? 1 : 0) - (a.type ? 1 : 0);

	private async initDataset() {
		this.data = (await this.loadData(this.datasetFile)).map(x => { return { type: x.type, values: x.values, custom: Utils.initArray(10, () => undefined) }; });
	}

	protected onActivated() {
		ScenesManager.setFullscreen(true);
	}

	protected onDeactivated() {
		ScenesManager.setFullscreen(false);
	}

	private a = 0;
	private op = "+";
	private b = 0;
	private op2 = "+";
	private c = 0;

	private solutionText: Text;

	private updateSolutionText() {
		this.solutionText.text = `(${this.a}${this.op}${this.b})${this.op2}${this.c}`;
	}

	public async init() {
		let environment = new EnvironmentContainer(Colors.DarkGray);
		this.backgroundContainer.addChild(environment);

		{
			let button = new TextButton("Back").setPosition(5, 5);
			button.onClick.addCallback(() => { ScenesManager.changeScene(App.debugMenuScene); });
			this.uiContainer.addChild(button);
		}

		{
			let button = new TextButton("Restart").setPosition(100, 5);
			button.onClick.addCallback(() => { /*this.restart();*/ });
			this.uiContainer.addChild(button);
		}

		this.progressText.x = 300;
		this.progressText.y = 5;
		this.uiContainer.addChild(this.progressText);

		this.opsText.x = 500;
		this.opsText.y = 5;
		this.uiContainer.addChild(this.opsText);

		this.totalStepsText.x = 650;
		this.totalStepsText.y = 5;
		this.uiContainer.addChild(this.totalStepsText);

		this.solutionText = new Text(`(${this.a}${this.op}${this.b})${this.op2}${this.c}`, { fontSize: 12 });
		this.solutionText.anchor.set(0.5, 0.5);
		this.uiContainer.addChild(this.solutionText.setPosition(700, 580));

		await this.initDataset();
		console.log(this.data[0].values.length);

		/*

		Cluster a single cell? this would basically provide us with enough data to figure out which 2 properties allow us to get good clustering?

		*/

		const barHeight = 6;
		const barMargin = 0;
		const barVBorder = 1;
		const valueWidth = 18;
		const valueHBorder = 1;

		const ySize = (barHeight + (barVBorder * 2) + barMargin);

		let sorted = [...this.data].sort((a, b) => this.sortFn(a, b));

		let background = new pixi.Graphics();
		background.beginFill(0x000000, 1.0).drawRect(
			8, 60 - barVBorder,
			20 + (this.data[0].values.length + 11) * (valueWidth + valueHBorder) + 4, (barHeight + (barVBorder * 2)) * this.data.length
		).endFill();
		this.uiContainer.addChild(background);

		for (let y = 0; y < sorted.length; y++) {
			const values = sorted[y].values;
			const custom = sorted[y].custom;

			let type = new pixi.Graphics();
			type.beginFill(0xFFFFFF, 1.0).drawRect(10, 60 + y * ySize, 16, barHeight).endFill();
			type.tint = sorted[y].type ? 0x00FF00 : 0xFF0000;
			this.uiContainer.addChild(type);
			this.gridTypes.push(type);

			let x = 0;
			for (const value of values) {
				let indicator = new pixi.Graphics();
				indicator.beginFill(0xFFFFFF, 1.0).drawRect(20 + 10 + x * (valueWidth + valueHBorder), 60 + y * ySize, valueWidth, barHeight).endFill();
				indicator.tint = SolverUtils.GetScoreColor(value);
				indicator.alpha = 0.85;
				this.uiContainer.addChild(indicator);
				this.gridIndicators.push(indicator);
				x++;
			}
			for (const value of custom) {
				let indicator = new pixi.Graphics();
				indicator.beginFill(0xFFFFFF, 1.0).drawRect(20 + 10 + (x + 1) * (valueWidth + valueHBorder), 60 + y * ySize, valueWidth, barHeight).endFill();
				indicator.tint = value !== undefined ? SolverUtils.GetScoreColor(value) : Colors.Blue;
				indicator.alpha = 0.85;
				this.uiContainer.addChild(indicator);
				this.gridIndicators.push(indicator);
				x++;
			}
		}

		const selectorOffset = 60 + 60 * ySize;
		let length = this.data[0].values.length;
		for (let x = 0; x < length; x++) {

			let scoreText = new Text("" + x, { fontSize: 12 });
			scoreText.setPosition(20 + 10 + x * (valueWidth + valueHBorder), selectorOffset + 5);
			this.uiContainer.addChild(scoreText);


			let sortProperty = new GraphicsButton().setMainColor(0x888888).setHoverColor(0xFFFFFF);
			sortProperty.beginFill(0xFFFFFF, 1.0).drawRect(20 + 10 + x * (valueWidth + valueHBorder), selectorOffset, valueWidth, barHeight).endFill();
			this.uiContainer.addChild(sortProperty);
			let i = x;
			sortProperty.onMouseUp.addCallback(() => {
				this.sortFn = (a, b) => a.values[i] - b.values[i];
				this.updateCluster();
			});

			{
				let label = new Text("" + x, { fontSize: 12 });
				label.setPosition(20 + 10 + x * (valueWidth + valueHBorder), selectorOffset + 60 + 5);
				this.uiContainer.addChild(label);

				let button = new GraphicsButton().setMainColor(0x888888).setHoverColor(0xFFFFFF);
				button.beginFill(0xFFFFFF, 1.0).drawRect(20 + 10 + x * (valueWidth + valueHBorder), selectorOffset + 60, valueWidth, barHeight).endFill();
				this.uiContainer.addChild(button);
				button.onMouseUp.addCallback(() => { this.a = i; this.updateSolutionText(); });
			}
			{
				let label = new Text("" + x, { fontSize: 12 });
				label.setPosition(20 + 10 + x * (valueWidth + valueHBorder), selectorOffset + 120 + 5);
				this.uiContainer.addChild(label);

				let button = new GraphicsButton().setMainColor(0x888888).setHoverColor(0xFFFFFF);
				button.beginFill(0xFFFFFF, 1.0).drawRect(20 + 10 + x * (valueWidth + valueHBorder), selectorOffset + 120, valueWidth, barHeight).endFill();
				this.uiContainer.addChild(button);
				button.onMouseUp.addCallback(() => { this.b = i; this.updateSolutionText(); });
			}
			{
				let label = new Text("" + x, { fontSize: 12 });
				label.setPosition(20 + 10 + x * (valueWidth + valueHBorder), selectorOffset + 180 + 5);
				this.uiContainer.addChild(label);

				let button = new GraphicsButton().setMainColor(0x888888).setHoverColor(0xFFFFFF);
				button.beginFill(0xFFFFFF, 1.0).drawRect(20 + 10 + x * (valueWidth + valueHBorder), selectorOffset + 180, valueWidth, barHeight).endFill();
				this.uiContainer.addChild(button);
				button.onMouseUp.addCallback(() => { this.c = i; this.updateSolutionText(); });
			}
		}
		{
			let i = 0;
			for (const op of ["+", "-", "*", "/"]) {
				{
					let label = new Text("" + op, { fontSize: 14 });
					label.setPosition(20 + 10 + i * (valueWidth + valueHBorder) + 5, selectorOffset + 90 + 5);
					this.uiContainer.addChild(label);

					let button = new GraphicsButton().setMainColor(0x888888).setHoverColor(0xFFFFFF);
					button.beginFill(0xFFFFFF, 1.0).drawRect(20 + 10 + i * (valueWidth + valueHBorder), selectorOffset + 90, valueWidth, barHeight).endFill();
					this.uiContainer.addChild(button);
					button.onMouseUp.addCallback(() => { this.op = op; this.updateSolutionText(); });
				}
				{
					let label = new Text("" + op, { fontSize: 14 });
					label.setPosition(20 + 10 + i * (valueWidth + valueHBorder) + 5, selectorOffset + 150 + 5);
					this.uiContainer.addChild(label);

					let button = new GraphicsButton().setMainColor(0x888888).setHoverColor(0xFFFFFF);
					button.beginFill(0xFFFFFF, 1.0).drawRect(20 + 10 + i * (valueWidth + valueHBorder), selectorOffset + 150, valueWidth, barHeight).endFill();
					this.uiContainer.addChild(button);
					button.onMouseUp.addCallback(() => { this.op2 = op; this.updateSolutionText(); });
				}
				i++;
			}
		}

		for (let i = 0; i < 10; i++) {
			{
				let label = new Text("C" + i, { fontSize: 14 });
				label.setPosition(20 + 10 + (length + 1 + i) * (valueWidth + valueHBorder), selectorOffset + 5);
				this.uiContainer.addChild(label);

				let button = new GraphicsButton().setMainColor(0x888888).setHoverColor(0xFFFFFF);
				button.beginFill(0xFFFFFF, 1.0).drawRect(20 + 10 + (length + 1 + i) * (valueWidth + valueHBorder), selectorOffset, valueWidth, barHeight).endFill();
				this.uiContainer.addChild(button);
				button.onMouseUp.addCallback(() => {
					this.sortFn = (a, b) => a.custom[i]! - b.custom[i]!;
					this.updateCluster();
				});
			}

			{
				let label = new Text("C" + i, { fontSize: 14 });
				label.setPosition(20 + 10 + i * (valueWidth + valueHBorder), selectorOffset + 210 + 5);
				this.uiContainer.addChild(label);

				let button = new GraphicsButton().setMainColor(0x888888).setHoverColor(0xFFFFFF);
				button.beginFill(0xFFFFFF, 1.0).drawRect(20 + 10 + i * (valueWidth + valueHBorder), selectorOffset + 210, valueWidth, barHeight).endFill();
				this.uiContainer.addChild(button);
				let y = i;
				button.onMouseUp.addCallback(() => {
					let a = this.a;
					let b = this.b;
					let c = this.c;
					let op = this.op;
					let op2 = this.op2;

					let max = Number.MIN_SAFE_INTEGER;
					let min = Number.MAX_SAFE_INTEGER;
					for (const x of this.data) {
						let value = x.values[a];
						if (op === "+") {
							value += x.values[b];
						} else if (op === "-") {
							value -= x.values[b];
						} else if (op === "*") {
							value *= x.values[b];
						} else if (op === "/") {
							value /= x.values[b] > 0.0001 ? x.values[b] : 0.0001;
						}
						if (op2 === "+") {
							value += x.values[c];
						} else if (op2 === "-") {
							value -= x.values[c];
						} else if (op2 === "*") {
							value *= x.values[c];
						} else if (op2 === "/") {
							value /= x.values[c] > 0.0001 ? x.values[c] : 0.0001;
						}
						x.custom[y] = value;
						if (max < value) { max = value; }
						if (min > value) { min = value; }
					}

					for (const x of this.data) {
						x.custom[y]! = (x.custom[y]! - min) / (max - min);
					}
					this.updateCluster();
				});
			}
		}

		let sortType = new GraphicsButton().setMainColor(0x888888).setHoverColor(0xFFFFFF);
		sortType.beginFill(0xFFFFFF, 1.0).drawRect(10, selectorOffset, 16, barHeight).endFill();
		this.uiContainer.addChild(sortType);
		sortType.onMouseUp.addCallback(() => {
			this.sortFn = (a, b) => (b.type ? 1 : 0) - (a.type ? 1 : 0);
			this.updateCluster();
		});

		this.start();
	}

	private async start() {
		let ops = 0;
		let best = 0;
		setInterval(() => {
			if (this.active) {
				this.opsText.text = ops + " op/s";
				this.totalStepsText.text = "Total steps: " + this.totalSteps;
				ops = 0;
			}
		}, 1 * 1000);

		let it = this.iterator();

		let tests3d = [];

		let done = false;
		while (!done) {
			for (let i = 0; i < 1000; i++) {
				let next = it.next();
				if (next.done === false) {
					ops++;
					this.totalSteps++;
					let { a, op, b, op2, c } = next.value;

					let test = this.test(x => {
						let value = x.values[a];
						if (op === "+") {
							value += x.values[b];
						} else if (op === "-") {
							value -= x.values[b];
						} else if (op === "*") {
							value *= x.values[b];
						} else if (op === "/") {
							value /= x.values[b] > 0.0001 ? x.values[b] : 0.0001;
						}
						if (op2 === "+") {
							value += x.values[c];
						} else if (op2 === "-") {
							value -= x.values[c];
						} else if (op2 === "*") {
							value *= x.values[c];
						} else if (op2 === "/") {
							value /= x.values[c] > 0.0001 ? x.values[c] : 0.0001;
						}
						return value;
					});

					tests3d.push({ test: `(${a}${op}${b})${op2}${c}`, value: test });

					if (best <= test) {
						console.log(`(${a}${op}${b})${op2}${c}`, test.toFixed(2));
						best = test;
					}
				} else {
					done = true;
					break;
				}
			}
			await Utils.timeout(1);
		}

		tests3d.sort((a, b) => b.value - a.value);

		console.log(tests3d.slice(0, 100));

		console.log(tests3d.reduce((out, val) => { out[val.value] ? out[val.value]++ : out[val.value] = 1; return out; }, {} as any));
	}

	private *iterator() {
		let length = this.data[0].values.length;
		for (let a = 0; a < length; a++) {
			for (let b = 0; b < length; b++) {
				if (a === b) { continue; }
				for (const op of ["+", "-", "*", "/"]) {
					for (let c = 0; c < length; c++) {
						if (b === c || a === c) { continue; }
						// yield { a, op, b, op2: "+", c: 0 };
						for (const op2 of ["+", "-", "*", "/"]) {
							yield { a, op, b, op2, c };
						}
					}
				}
			}
		}
	}

	private updateCluster() {
		let sorted = [...this.data].sort((a, b) => this.sortFn(a, b));

		for (let y = 0; y < sorted.length; y++) {
			const values = sorted[y].values;
			const custom = sorted[y].custom;

			let type = this.gridTypes[y];
			type.tint = sorted[y].type ? 0x00FF00 : 0xFF0000;

			let x = 0;
			for (const value of values) {
				let indicator = this.gridIndicators[y * (values.length + 10) + x];
				indicator.tint = SolverUtils.GetScoreColor(value);
				x++;
			}
			for (const value of custom) {
				let indicator = this.gridIndicators[y * (values.length + 10) + x];
				indicator.tint = value !== undefined ? SolverUtils.GetScoreColor(value) : Colors.Blue;
				x++;
			}
		}

		this.calculate();
	}


	private test(getter: (value: { type: boolean; values: number[]; }) => number) {
		let sorted = [...this.data].map(x => { return { type: x.type, value: getter(x) }; }).sort((a, b) => a.value - b.value);

		let max = 0;
		let total = sorted.length;
		for (let s = 1; s < total; s++) {
			let a = sorted.slice(0, s).reduce((p, c) => p + ((c.type === true) ? 1 : 0), 0);
			let b = sorted.slice(s).reduce((p, c) => p + ((c.type === false) ? 1 : 0), 0);
			max = max > (a + b) ? max : (a + b);
			max = max > (total - (a + b)) ? max : (total - (a + b));
		}

		return (max * 100 / total);
	}

	private calculate() {
		let sorted = [...this.data].sort((a, b) => this.sortFn(a, b));

		let max = 0;
		let total = sorted.length;
		for (let s = 1; s < total; s++) {
			let a = sorted.slice(0, s).reduce((p, c) => p + ((c.type === true) ? 1 : 0), 0);
			let b = sorted.slice(s).reduce((p, c) => p + ((c.type === false) ? 1 : 0), 0);
			max = max > (a + b) ? max : (a + b);
			max = max > (total - (a + b)) ? max : (total - (a + b));
		}

		this.progressText.text = (max * 100 / total).toFixed(2) + "%";
	}

	private async loadData(file: string) {
		if (file.startsWith("*")) {
			let [p, n, c] = ({
				"*rand.full": [17, 43, 79],
				"*rand.17.17": [17, 17, 79],
				"*rand.10.10": [10, 10, 79],
				"*rand.3.3": [3, 3, 79],
				"*rand.1.43": [1, 43, 79],
			} as any)[file];
			return [
				...Utils.initArray(p, () => { return { type: true, values: Utils.initArray(c, () => Math.random()) }; }),
				...Utils.initArray(n, () => { return { type: false, values: Utils.initArray(c, () => Math.random()) }; }),
			];
		} else {
			let { body } = await BrowserRequest.get<string>(file, {});
			let data: {
				type: boolean;
				values: number[];
			}[] = [];
			for (const line of body.split(/\r|\n/).map(x => x.trim()).filter(x => x.length > 0)) {
				let parts = line.split(",");
				let type = parts.shift()!;
				data.push({ type: type === "Positive", values: parts.map(x => +x) });
			}
			return data;
		}
	}
}
