import * as pixi from "pixi.js";
import { UIElement, UIElementProperties } from "../UIElement";
import { UIManager } from "../UIManager";
import { BasicEvent } from "@h4x/common";


export interface UISliderProperties extends UIElementProperties {
	value: number;

	barSize?: number;
	barInnerSize?: number;

	barColor?: number;
	barInnerColor?: number;

	sliderColor?: number;
	sliderInnerColor?: number;

	sliderHoverColor?: number;
	sliderActiveColor?: number;
};


export abstract class UIAbstractSlider<T extends UISliderProperties = UISliderProperties>
	extends UIElement<T & UISliderProperties> {

	protected internal: pixi.Container;
	protected bar: pixi.Graphics;
	protected slider: pixi.Graphics;

	public readonly onValueChanged = new BasicEvent<(value: number) => void>();

	constructor(properties?: T) {
		super(properties);
		this.internal = new pixi.Container();
		this.bar = new pixi.Graphics();

		this.slider = new pixi.Graphics();
		this.slider.interactive = true;
		this.slider.buttonMode = true;

		this.slider.on("mousedown", this.sliderMouseDown, this);
		this.slider.on("mouseup", this.sliderMouseUp, this);
		this.slider.on("mouseupoutside", this.sliderMouseUp, this);
		this.slider.on("mouseover", this.sliderMouseOver, this);
		this.slider.on("mouseout", this.sliderMouseOut, this);

		this.bar.interactive = true;
		this.bar.on("mousedown", this.sliderMouseDown, this);
		this.bar.on("mouseup", this.sliderMouseUp, this);
		this.bar.on("mouseupoutside", this.sliderMouseUp, this);

		this.internal.addChild(this.bar);
		this.internal.addChild(this.slider);
	}

	protected initialize(parent: UIElement | UIManager) {
		super.initialize(parent);

		this.applyProperty(this.properties, "barSize", 10);
		this.applyProperty(this.properties, "barInnerSize", 7);

		this.applyProperty(this.properties, "barColor", 0xFFFFFF);
		this.applyProperty(this.properties, "barInnerColor", 0xAAAAAA);

		this.applyProperty(this.properties, "sliderColor", 0x008800);
		this.applyProperty(this.properties, "sliderInnerColor", 0x00AA00);

		this.applyProperty(this.properties, "sliderHoverColor", 0xAAAAFF);
		this.applyProperty(this.properties, "sliderActiveColor", 0x0000FF);

		this.applyProperty(this.properties, "value");

		this.redraw();
	}

	public onResize(width: number, height: number) {
		super.onResize(width, height);
		this.redraw();
	}

	protected mouseDown = false;
	private sliderMouseDown() {
		this.mouseDown = true;
		this.updateSlider();
		this.redrawSlider();
	}

	private sliderMouseUp() {
		if (this.mouseDown === true) {
			this.mouseDown = false;
			this.redrawSlider();
		}
	}

	protected mouseOver = false;
	private sliderMouseOver() {
		this.mouseOver = true;
		this.redrawSlider();
	}

	private sliderMouseOut() {
		this.mouseOver = false;
		this.redrawSlider();
	}

	protected mouseX = 0;
	protected mouseY = 0;
	public onMouseMove(x: number, y: number) {
		this.mouseX = x;
		this.mouseY = y;
		if (this.mouseDown !== true) { return; }
		this.updateSlider();
	}

	protected updateSlider() {
		let barPadding = this.barSize - this.barInnerSize;
		let barLength = this.width - this.barInnerSize * 2 - barPadding * 2;
		this.value = (this.mouseX - this.barInnerSize - barPadding) / barLength;
		this.value = this.value < 0 ? 0 : (this.value > 1) ? 1 : this.value;
	}

	protected redraw() {
		this.redrawBar();
		this.redrawSlider();
	}

	protected abstract redrawBar(): void;
	protected abstract redrawSlider(): void;

	/* Value */
	private $value: number;
	public get value() { return this.$value; }
	public setValue(value: number) { this.value = value; return this; }
	public set value(value: number) {
		this.$value = value;
		this.onValueChanged.execute(this.value);
		this.updateSliderPosition(this.value);
	}

	protected abstract updateSliderPosition(value: number): void;

	/* Bar Size */
	private $barSize: number;
	public get barSize() { return this.$barSize; }
	public setBarSize(value: number) { this.barSize = value; return this; }
	public set barSize(value: number) {
		this.$barSize = value;
		if (this.initialized === true) {
			this.redraw();
		}
	}

	/* Bar Inner */
	private $barInnerSize: number;
	public get barInnerSize() { return this.$barInnerSize; }
	public setBarInnerSize(value: number) { this.barInnerSize = value; return this; }
	public set barInnerSize(value: number) {
		this.$barInnerSize = value;
		if (this.initialized === true) {
			this.redraw();
		}
	}


	/* Bar Color */
	private $barColor: number;
	public get barColor() { return this.$barColor; }
	public setBarColor(value: number) { this.barColor = value; return this; }
	public set barColor(value: number) {
		this.$barColor = value;
		if (this.initialized === true) {
			this.redrawBar();
		}
	}

	/* Bar Inner Color */
	private $barInnerColor: number;
	public get barInnerColor() { return this.$barInnerColor; }
	public setBarInnerColor(value: number) { this.barInnerColor = value; return this; }
	public set barInnerColor(value: number) {
		this.$barInnerColor = value;
		if (this.initialized === true) {
			this.redrawBar();
		}
	}

	/* Slider Color */
	private $sliderColor: number;
	public get sliderColor() { return this.$sliderColor; }
	public setSliderColor(value: number) { this.sliderColor = value; return this; }
	public set sliderColor(value: number) {
		this.$sliderColor = value;
		if (this.initialized === true) {
			this.redrawSlider();
		}
	}

	/* Slider Inner Color */
	private $sliderInnerColor: number;
	public get sliderInnerColor() { return this.$sliderInnerColor; }
	public setSliderInnerColor(value: number) { this.sliderInnerColor = value; return this; }
	public set sliderInnerColor(value: number) {
		this.$sliderInnerColor = value;
		if (this.initialized === true) {
			this.redrawSlider();
		}
	}

	/* Slider Hover Color */
	private $sliderHoverColor: number;
	public get sliderHoverColor() { return this.$sliderHoverColor; }
	public setSliderHoverColor(value: number) { this.sliderHoverColor = value; return this; }
	public set sliderHoverColor(value: number) {
		this.$sliderHoverColor = value;
		if (this.initialized === true) {
			this.redrawSlider();
		}
	}

	/* Slider Active Color */
	private $sliderActiveColor: number;
	public get sliderActiveColor() { return this.$sliderActiveColor; }
	public setSliderActiveColor(value: number) { this.sliderActiveColor = value; return this; }
	public set sliderActiveColor(value: number) {
		this.$sliderActiveColor = value;
		if (this.initialized === true) {
			this.redrawSlider();
		}
	}

}
