import { PhysicsEntity } from "./PhysicsEntity";
import Matter = require("matter-js");
import { Scene } from "../Scene";
import { Vec2 } from "@h4x/common";

export abstract class DynamicEntity extends PhysicsEntity {
	private maxSpeed: number = 100;
	private turnRate: number = Math.PI;

	constructor(scene: Scene, x: number, y: number, body: Matter.Body) {
		super(scene, x, y, body);
	}

	public internalUpdate(dt: number) {
		// Process base physics class
		super.internalUpdate(dt);
	}

	/** Set the mass on the entity */
	public setMass(mass: number) {
		Matter.Body.setDensity(this.physicsBody, mass);
	}

	/** Set the maximum speed this object can move */
	public setMaxSpeed(speed: number) {
		this.maxSpeed = speed;
	}

	/** Get the current MAX speed of the object */
	public getMaxSpeed(): number {
		return this.maxSpeed;
	}

	/** Get the current speed of the object */
	public getSpeed(): number {
		return this.physicsBody.speed;
	}

	public setTurnRate(rate: number) {
		this.turnRate = rate;
	}

	public getTurnRate(): number {
		return this.turnRate;
	}

	/** Force the position of the physics entity to a specific location */
	public setPosition(x: number, y: number) {
		super.setPosition(x, y);
	}

	/** Set the velocity on this physics body */
	public setVelocity(x: number, y: number) {
		Matter.Body.setVelocity(this.physicsBody, Matter.Vector.create(x, y));
	}

	/** Get the current velocity of the physics body */
	public getVelocity(): Vec2 {
		return new Vec2(this.physicsBody.velocity.x, this.physicsBody.velocity.y);
	}
}
