import { Behavior } from "./Behavior";
import { DynamicEntity } from "../../core/Entities/DynamicEntity";
import { Vec2 } from "@h4x/common";
import { Sound } from "../../core/SoundManager";

/** Shake the graphics object of the entity for a given amount of time */
export class PathBehavior extends Behavior<DynamicEntity, Vec2[]> {
	// Path Tracking
	private path: Vec2[] = [];
	private currentPathIdx: number = 0;

	// Properties
	private repeat: boolean = true;
	private traveling: boolean = false;

	private speedOverride: number = 0;
	private speedOverrideFlag: boolean = false;

	private sound: Sound;

	constructor(owner: DynamicEntity) {
		super(owner);
	}

	public update(dt: number): void {
		if (this.active === false || this.path.length === 0 || this.traveling === false) {
			return;
		}

		let speedToUse = this.speedOverrideFlag ? this.speedOverride : this.owner.getMaxSpeed();

		let curPoint = this.path[this.currentPathIdx];

		let distVec = curPoint.copy().subtract(this.owner.getPosition());
		let currentDist = distVec.length();
		let dir = distVec.normalize();
		let velocity = dir.multiplyScalar(speedToUse * dt);

		let projectDist = currentDist - velocity.length();

		if (projectDist <= 0) {
			if (this.sound !== undefined) {
				this.sound.play();
			}

			this.owner.setPosition(curPoint.x, curPoint.y);
			this.currentPathIdx++;

			if (this.currentPathIdx >= this.path.length) {
				if (this.repeat) {
					this.currentPathIdx = 0;
				} else {
					this.traveling = false;
				}
			}
		} else {
			this.owner.setVelocity(velocity.x, velocity.y);
		}
	}

	public execute(path: Vec2[]): void {
		// Reset the current path index
		this.currentPathIdx = 0;
		this.traveling = true;

		// Copy the supplied array into our own arra
		this.path = [...path];
	}

	// Set whether or not this path repeats
	public setRepeat(repeat: boolean) {
		this.repeat = repeat;
	}

	// Get whether or not this behavior is actively traveling the paths
	public isTraveling(): boolean {
		return this.traveling;
	}

	public overrideSpeed(speed: number) {
		this.speedOverride = speed;
		this.speedOverrideFlag = true;
	}

	public setSoundToPlayOnPathChange(sound: Sound) {
		this.sound = sound;
	}
}
