import { ScenesManager } from "./SceneManager";

// Manager to handle input from the user
export class InputManager {
	public static init() {
		window.onload = () => {
			console.log("ONLOAD");
			window.addEventListener("keydown", (key) => {
				InputManager.keyDownListener(key);
			}, false);

			window.addEventListener("keyup", (key) => {
				InputManager.keyUpListener(key);
			}, false);
		}
	}

	public static keyDownListener(key: KeyboardEvent): any {
		ScenesManager.handleKeyDown(key);
	}

	public static keyUpListener(key: KeyboardEvent): any {
		ScenesManager.handleKeyUp(key);
	}

	public static readonly Key0 =  48;
	public static readonly Key1 =  49;
	public static readonly Key2 =  50;
	public static readonly Key3 =  51;
	public static readonly Key4 =  52;
	public static readonly Key5 =  53;
	public static readonly Key6 =  54;
	public static readonly Key7 =  55;
	public static readonly Key8 =  56;
	public static readonly Key9 =  57;

	public static readonly KeyA = 65;
	public static readonly KeyB = 66;
	public static readonly KeyC = 67;
	public static readonly KeyD = 68;
	public static readonly KeyE = 69;
	public static readonly KeyF = 70;
	public static readonly KeyG = 71;
	public static readonly KeyH = 72;
	public static readonly KeyI = 73;
	public static readonly KeyJ = 74;
	public static readonly KeyK = 75;
	public static readonly KeyL = 76;
	public static readonly KeyM = 77;
	public static readonly KeyN = 78;
	public static readonly KeyO = 79;
	public static readonly KeyP = 80;
	public static readonly KeyQ = 81;
	public static readonly KeyR = 82;
	public static readonly KeyS = 83;
	public static readonly KeyT = 84;
	public static readonly KeyU = 85;
	public static readonly KeyV = 86;
	public static readonly KeyW = 87;
	public static readonly KeyX = 88;
	public static readonly KeyY = 89;
	public static readonly KeyZ = 90;

	public static readonly Space = 32;
	public static readonly Escape = 27;

}
