import * as pixi from "pixi.js";
import { UIElement, UIElementProperties } from "../UIElement";
import { UIManager } from "../UIManager";

export type UISpriteProperties = UIElementProperties & {
	fit?: boolean;
	fill?: boolean;
	scale?: number;
};

export class UISprite extends UIElement<UISpriteProperties> {

	protected internal: pixi.Sprite;

	constructor(texture: pixi.Texture, properties?: UISpriteProperties) {
		super(properties);
		this.internal = pixi.Sprite.from(texture);
	}

	protected initialize(parent: UIElement | UIManager) {
		this.applyProperty(this.properties, "fit", false);
		this.applyProperty(this.properties, "fill", false);
		this.applyProperty(this.properties, "scale", 1);

		super.initialize(parent);

		this.updatePosition();
	}

	public onResize(width: number, height: number) {
		super.onResize(width, height);
		this.updateTexture();
		this.updatePosition();
	}

	public get texture() { return this.internal.texture; }
	public set texture(value: pixi.Texture) {
		this.internal.texture = value;
		if (this.initialized === true) {
			this.updateTexture();
			this.updatePosition();
		}
	}

	protected updateTexture() {
		if (this.fit === true || this.fill === true) {
			let textureWidth = this.internal.texture.width;
			let textureHeight = this.internal.texture.height;

			let parentWidth = this.parent.width;
			let parentHeight = this.parent.height;

			let ratioWidth = parentWidth / textureWidth;
			let ratioHeight = parentHeight / textureHeight;

			let ratio = Math.min(ratioWidth, ratioHeight);
			if (this.fit !== true) {
				ratio = Math.max(ratio, this.scale);
			}
			if (this.fill !== true) {
				ratio = Math.min(ratio, this.scale);
			}

			this.internal.scale = new pixi.Point(ratio, ratio);
		} else {
			this.internal.scale = new pixi.Point(this.scale, this.scale);
		}
	}

	/* Scale */
	private $scale: number;
	public get scale() { return this.$scale; }
	public setScale(value: number) { this.scale = value; return this; }
	public set scale(value: number) {
		this.$scale = value;
		if (this.initialized === true) {
			this.updateTexture();
			this.updatePosition();
		}
	}

	/* Fit */
	private $fit: boolean;
	public get fit() { return this.$fit; }
	public setFit(value: boolean) { this.fit = value; return this; }
	public set fit(value: boolean) {
		this.$fit = value;
		if (this.initialized === true) {
			this.updateTexture();
			this.updatePosition();
		}
	}

	/* Fit */
	private $fill: boolean;
	public get fill() { return this.$fill; }
	public setFill(value: boolean) { this.fill = value; return this; }
	public set fill(value: boolean) {
		this.$fill = value;
		if (this.initialized === true) {
			this.updateTexture();
			this.updatePosition();
		}
	}
}
