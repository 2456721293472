export namespace Colors {

	// Common Colors
	export const Black = 0x000000;
	export const White = 0xFFFFFF;
	export const Red = 0xFF0000;
	export const Green = 0x00FF00;
	export const Blue = 0x0000FF;
	export const Orange = 0xFFA500;
	export const Cyan = 0x00FFFF;
	export const Yellow = 0xFFFF00;
	export const Magenta = 0xFF00FF;

	// Cluster Colors
	export const ClusterNegative = 0xDA66EB;
	export const ClusterPositive = 0x92D836;

	// Grays
	export const LightGray = 0xCCCCCC;
	export const Gray = 0x888888;
	export const DarkGray = 0x444444;

	// Color Shades
	export const LightBlue = 0x4444FF;

	export const DarkRed = 0xAA0000;
	export const DarkYellow = 0xAAAA00;
	export const DarkGreen = 0x00AA00;
	export const DarkBlue = 0x0000AA;

	// Health Colors
	export const HealthRed = 0xEE203B;
	export const HealthYellow = 0xFBED26;
	export const HealthBlue = 0x2AAAE2;

	// Entity Colors
	export const PlayerDefault = 0x3365D3;
	export const PlayerShields = 0x00BCBC;
	export const Enemy = 0xE50000;
	export const Impact = 0xFFFF00;
	export const WarpParticle = 0x6387d6;

	// Global Score UI Colors
	export const IndicatorPositiveArrowOne = 0x00FF00;
	export const IndicatorPositiveArrowTwo = 0x00FF87;
	export const IndicatorNegativeArrowOne = 0xED1C24;
	export const IndicatorNegativeArrowTwo = 0xC1272D;
	export const IndicatorNeutral = 0xFFFF00;
	export const IndicatorOff = 0x333333;
	export const GlobalScoreHashOn = 0x00FFFF;
	export const GlobalScoreHashOff = 0x333333;
}
