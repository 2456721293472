import pixi = require("pixi.js");
import { Scene } from "../core/Scene";
import { SectorPlayerEntity } from "../entity/SectorPlayerEntity";
import { SectorJumpSelectEntity } from "../entity/SectorJumpSelectEntity";
import { App } from "../app";
import { Colors } from "../utils/Colors";
import { FADE_ANIMATION_TIME, FADE_ANIMATION_HOLD_TIME } from "../Gameplay/FadeAnimationLogic";
import { EnvironmentContainer } from "../containers/EnvironmentContainer";
import { Vec2 } from "@h4x/common";
import { LocalClusterContainer } from "../containers/LocalClusterContainer";
import { EventSystem } from "../core/EventSystem";
import { SectorEnemyEntity } from "../entity/SectorEnemyEntity";
import { EntityType } from "../core/Entities/Entity";
import { ImpactEntity } from "../entity/ImpactEntity";
import { WarpHoleEntity } from "../entity/WarpHoleEntity";
import { GameplayLogicController, GameplayLogicState } from "../Gameplay/GameLogicController";
import { SectorDividerEntity } from "../entity/SectorDividerEntity";
import { SectorPlayerUIEntity } from "../entity/SectorPlayerUIEntity";
import { TutorialArrowUIEntity } from "../entity/TutorialArrowUIEntity";
import { EnemyType } from "../Gameplay/EnemyType";
import { SoundManager } from "../core/SoundManager";
import { DifficultyManager } from "../Gameplay/DifficultyManager";
import { Globals } from "../Gameplay/Constants";
import { UIText } from "../core/UI/UIText";
import { UIPositionAlign, UIAlign, UISizeDynamic, UISizePercentage, UIDirection } from "../core/UIHelpers";
import { ScoreManager, ScoreCategory } from "../Gameplay/ScoreManager";
import { InputManager } from "../core/InputManager";
import { WarpBackgroundEntity } from "../entity/WarpBackgroundEntity";
import { ClusterUtils } from "../cluster/ClusterUtils";
import { GlobalCluster } from "../cluster/GlobalCluster";
import { TutorialClusterData } from "../cluster/TutorialCluster";
import { ScenesManager } from "../core/SceneManager";
import { GameSceneMenu } from "./GameSceneMenu";
import { PauseUIElement } from "../Gameplay/UI/PauseUIElement";
import { UIContainer } from "../core/UI/UIContainer";
import { TutorialBanUIEntity } from "../entity/TutorialBanUIEntity";
import { HUDContainer } from "../Gameplay/UI/HUD/HUDContainer";
import { HUD_PANEL_MARGIN, HUD_PANEL_OFFSET } from "../Gameplay/UI/HUD/HUDSizes";
import { TITLE_FONTSIZE, DEBUG_FONTSIZE } from "../Gameplay/UI/UISizes";

const FAST_TUTORIAL: boolean = false;

const WARP_TRANSITION_TIME = 1;
const PLAYER_CLICK_DIST = 35;
const TITLE_BACKGROUND_ALPHA = 0.75;

type InputCallback = () => void;
class InputEventEmitter {
	private cbs: InputCallback[] = [];

	constructor() { }

	public once(cb: InputCallback) {
		this.cbs.push(cb);
	}

	public fire() {
		let cbsCopy = this.cbs;
		this.cbs = [];
		for (let cb of cbsCopy) {
			cb();
		}
	}
}

enum TutorialStage {
	START = 0,
	SECTOR_POPULATED,
	DIVIDER_LINE_SPAWNED,
	MOVING_DIVIDOR,
	STOPPED_DIVIDOR,
	WARP_POINT_SPAWNING,
	WARP_POINT_SPAWNED,
	PLAYER_SPAWNED,
	PLAYER_WARPED,
	PLAYER_WARP_INDICATOR,
	PLAYER_MOVE
}

const TUTORIAL_CENTROID_POS = new Vec2(500, 650);
const TUTORIAL_CENTROID_NEG = new Vec2(500, 250);

export class GameSceneTutorial extends Scene {

	// Scene settings
	get margin() { return HUD_PANEL_MARGIN.compute(UIDirection.Y); }
	get worldYOffset() { return HUD_PANEL_OFFSET.compute(UIDirection.Y); }

	private hudContainer: HUDContainer;

	// UI Elements
	private debugLocalScorePercent: UIText;
	private pauseMenu: PauseUIElement;

	// Primary Entities
	private player: SectorPlayerEntity;
	private playerUIEntity: SectorPlayerUIEntity;
	private jumpSelect: SectorJumpSelectEntity;
	private divider: SectorDividerEntity;
	private tutorialArrowUIEntity: TutorialArrowUIEntity;
	private tutorialBanUIEntity: TutorialBanUIEntity;

	// Cluster Containers
	private localClusterContainer: LocalClusterContainer;
	private cluster: GlobalCluster;
	private clusterX: number = 1;
	private clusterY: number = 2;

	// Text
	private titleBackground: UIContainer;
	private titleText: UIText;
	private clickToContinueText: UIText;

	// Flags
	private flagMoveJumpPoint: boolean = false;
	private flagGameActive: boolean = false;
	private flagJumpingActive: boolean = false;
	private flagSwapCentroids: boolean = false;
	private flagCanWarpOut: boolean = false;

	// Properties
	private mouseCoordinate: Vec2;

	// Tutorial Stage
	private tutorialStage: TutorialStage = TutorialStage.START;
	private tutorialDividorMoveTime = 0;
	private clickToContinueTimer = 0;

	private inputs = new InputEventEmitter();

	// Setup UI
	private setupUI() {
		this.hudContainer = this.uiManager.add(new HUDContainer(this));

		this.hudContainer.indicator.alpha = 0;
		this.hudContainer.score.alpha = 0;
		this.hudContainer.shield.alpha = 0;

		// Push Global Score Indicator Properties
		this.hudContainer.setBaseGlobalPercent(0);
		this.hudContainer.setRoundStartingGlobalPercent(0);
		this.hudContainer.setRoundCurrentGlobalPercent(0);

		this.titleBackground = this.uiManager.add(new UIContainer({
			width: new UISizePercentage(100),
			height: 0,
			backgroundColor: Colors.Black,
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center, -50)
		}));
		this.titleBackground.alpha = 0.45;

		this.titleText = this.uiManager.add(new UIText("", {
			color: Colors.White,
			font: "Hyperspace",
			fontSize: TITLE_FONTSIZE,
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center, -50)
		}));
		this.titleText.textAlign = "center";

		this.clickToContinueText = this.uiManager.add(new UIText("click to continue", {
			color: Colors.White,
			font: "Hyperspace",
			fontSize: 24,
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.bottom, 50)
		}));
		this.clickToContinueText.hide();
		this.clickToContinueText.textAlign = "center";

		// Create the PAUSE menu
		this.pauseMenu = new PauseUIElement(this, () => {
			// When the back button is hit
			this.setPause(false);
			this.pauseMenu.setVisible(false);
		}, () => {
			// When the quit button is hit
			GameplayLogicController.end();
			ScenesManager.changeScene(new GameSceneMenu());
		});
		this.pauseMenu.setVisible(false);
		this.uiManager.add(this.pauseMenu);

		// DEBUG UI
		if (Globals.DEBUG_MODE) {
			this.uiManager.add(new UIText(`Tutorial`, {
				color: Colors.White,
				font: "Hyperspace",
				fontSize: DEBUG_FONTSIZE,
				x: new UIPositionAlign(UIAlign.left, 10),
				y: new UIPositionAlign(UIAlign.bottom, 10)
			}));

			this.debugLocalScorePercent = this.uiManager.add(new UIText("Local Percent: 0.00%", {
				color: Colors.White,
				font: "Hyperspace",
				fontSize: DEBUG_FONTSIZE,
				x: new UIPositionAlign(UIAlign.right, 10),
				y: new UIPositionAlign(UIAlign.bottom, 10)
			}));
		}
	}

	public init() {
		this.gameWorldContainer.sortableChildren = true; // need to reposition children in this container
		this.gameWorldOverlayContainer.sortableChildren = true; // need to reposition children in this container
		this.setupUI();

		// Disable Gravity
		this.physicsWorld.gravity.y = 0;

		// Scene Setup
		this.backgroundContainer.addChild(new EnvironmentContainer());
		// this.uiManager.add(new UIBorder({ borderColor: Colors.Orange, borderWidth: 5, borderMode: UIBorderMode.Inner }));

		this.onResize(App.width, App.height);

		this.mouseCoordinate = new Vec2();

		// Create the primary entities
		this.createPrimaryEntities();

		// Create UI Entities
		this.tutorialArrowUIEntity = new TutorialArrowUIEntity(this, 0, 0);
		this.tutorialArrowUIEntity.setVisible(false);
		this.tutorialArrowUIEntity.setZIndex(100);
		this.addToWorld(this.tutorialArrowUIEntity);

		this.tutorialBanUIEntity = new TutorialBanUIEntity(this, 0, 0);
		this.tutorialBanUIEntity.setVisible(false);
		this.tutorialBanUIEntity.setZIndex(100);
		this.addToWorld(this.tutorialBanUIEntity);

		// Detection when an enemy hits a player
		this.onCollisionEvent<SectorPlayerEntity, SectorEnemyEntity>((player, enemy) => {
			if (player.isInvulnerable() === false) {
				this.playerTakeDamage(1, enemy);
				this.destroyEnemy(enemy);
			}
		}, EntityType.SectorPlayer, EntityType.SectorEnemy);

		// Detection when a Darter collides with another enemy
		this.onCollisionEvent<SectorEnemyEntity, SectorEnemyEntity>((enemyA, enemyB) => {
			let berzerker: SectorEnemyEntity;
			let other: SectorEnemyEntity;
			if (enemyA.enemyType === EnemyType.BERZERKER) {
				berzerker = enemyA;
				other = enemyB;
			} else if (enemyB.enemyType === EnemyType.BERZERKER) {
				berzerker = enemyB;
				other = enemyA;
			}

			if (berzerker! === undefined || other! === undefined) {
				return;
			} else if (berzerker.canDestroyOtherEnemy()) {
				this.destroyEnemy(berzerker);
				this.destroyEnemy(other);
				ScoreManager.berzerkerKill();
				this.hudContainer.setScore(ScoreManager.getCurrentPlayerScore());
			}

		}, EntityType.SectorEnemy, EntityType.SectorEnemy);

		// Create Temporary Gameplay Border
		// let gameplayBorder = new pixi.Graphics();
		// gameplayBorder.lineStyle(3, Colors.Red);
		// gameplayBorder.drawRect(0, 0, Globals.WORLD_WIDTH, Globals.WORLD_HEIGHT);
		// this.gameWorldContainer.addChild(gameplayBorder);

		// Run the game
		this.runTutorial();
	}

	// Create the primary entities
	private createPrimaryEntities() {
		this.player = new SectorPlayerEntity(this, 0, 0);
		this.player.setColor(Colors.ClusterPositive);

		this.playerUIEntity = new SectorPlayerUIEntity(this, 0, 0);

		this.jumpSelect = new SectorJumpSelectEntity(this, Globals.WORLD_WIDTH / 2, (Globals.WORLD_HEIGHT / 2) + 200);
		this.jumpSelect.setColor(Colors.ClusterNegative);

		this.divider = new SectorDividerEntity(this);
	}

	// Run the game logic for this scene!
	private async runTutorial() {
		await this.setupData();

		await this.runPhaseIntro();

		// If hte player is alive, then we go to the warp scene, else we died and need to end the current game
		if (this.player.isAlive()) {
			await this.runPhaseLeave();
			await this.runPhaseEnd();

			// Back to menu
			GameplayLogicController.end();
			ScenesManager.changeScene(new GameSceneMenu());
		} else {
			await this.runPhaseDead(); // ded
			GameplayLogicController.setState(GameplayLogicState.DEATH);
		}
	}

	private hideTutorialArrow() {
		this.tutorialArrowUIEntity.setVisible(false);
	}

	private pointTutorialArrowAt(x: number, y: number, angle: number = -45) {
		this.tutorialArrowUIEntity.setPosition(x, y);
		this.tutorialArrowUIEntity.setAngle(angle);
		this.tutorialArrowUIEntity.setVisible(true);
	}

	private hideTutorialBan() {
		this.tutorialBanUIEntity.setVisible(false);
	}

	private showTutorialBan(x: number, y: number) {
		x = Math.min(Math.max(x, 0), 1000);
		y = Math.min(Math.max(y, 0), 1000);
		this.tutorialBanUIEntity.setPosition(x, y);
		this.tutorialBanUIEntity.setVisible(true);
	}

	// Easy wrapper to set the title text and re-center it
	private setTitleText(text: string) {
		this.titleText.text = text.toLowerCase();
		this.setTitleBackgroundSize(text.split("\n").length);
	}

	// Run a fade in on the title
	public async runTitleFadeIn(text: string, fadeTime: number = FADE_ANIMATION_TIME) {
		this.setTitleText(text);
		this.eventSystem.fnTick((percentage) => {
			this.titleBackground.alpha = percentage * TITLE_BACKGROUND_ALPHA;
		}, fadeTime);
		await this.runFadeIn(this.titleText, fadeTime);
	}

	// Run a fade out on the title
	public async runTitleFadeOut(fadeTime: number = FADE_ANIMATION_TIME) {
		this.eventSystem.fnTick((percentage) => {
			this.titleBackground.alpha = (1 - percentage) * TITLE_BACKGROUND_ALPHA;
		}, fadeTime);
		await this.runFadeOut(this.titleText, fadeTime);
		this.titleBackground.alpha = 0;
	}

	// Run a fade animation sequence on the title
	public async runTitleFade(text: string, fadeTime: number = FADE_ANIMATION_TIME, holdTime: number = FADE_ANIMATION_HOLD_TIME) {
		this.setTitleText(text);
		await this.runFade(this.titleText, fadeTime, holdTime);
	}

	public setTitleBackgroundSize(lines: number) {
		this.titleBackground.setHeight(new UISizeDynamic([
			{ height: 800, size: 50 + lines * 32 },
			{ height: 500, size: 40 + lines * 24 },
			{ height: 400, size: 30 + lines * 18 },
			{ height: 300, size: 20 + lines * 16 }
		], { interpolate: true, floor: false }));
	}

	public waitForUserInput(): Promise<void> {
		if (FAST_TUTORIAL === true) { return Promise.resolve(); }
		this.clickToContinueText.show();
		this.clickToContinueTimer = 0;
		return new Promise<void>((accept: any) => {
			this.inputs.once(() => {
				this.clickToContinueText.hide();
				accept();
			});
		});
	}

	private setResearchBonus(percent: number) {
		this.hudContainer.setRoundCurrentGlobalPercent(percent * 0.005);
	}

	// Create a new scene
	public async setupData() {
		let data = JSON.parse(JSON.stringify(TutorialClusterData));
		this.cluster = new GlobalCluster(data.data, data.balancedMode);
		ClusterUtils.updateClusterCentroids(this.cluster, data.centroids);
		this.cluster.calculate();
		let size = this.cluster.data[0].values.length;
		this.clusterX = 0;
		this.clusterY = 1;
		this.localClusterContainer = new LocalClusterContainer(this, this.cluster, this.clusterX, this.clusterY);
		this.localClusterContainer.alpha = 0;
		this.gameWorldContainer.addChild(this.localClusterContainer);
	}

	// Handle when a key on the keyboard is released
	public keyUp(key: KeyboardEvent) {
		if (key.keyCode === InputManager.Escape) {
			this.setPause(!this.isPaused());
			this.pauseMenu.setVisible(this.isPaused());
			return;
		}

		if (this.isPaused()) {
			return;
		}

		// Use space bar to warp out if possible
		if (this.flagCanWarpOut && this.tutorialStage >= TutorialStage.PLAYER_MOVE) {
			if (key.keyCode === InputManager.Space) {
				this.flagGameActive = false;
			}
		}
	}

	// Called when the mouse moves on the screen
	public onMouseMove(x: number, y: number) {
		if (x < 20) { x = 20; }
		if (x > App.width - 20) { x = App.width - 20; }
		if (y < 20) { y = 20; }
		if (y > App.height - 20) { y = App.height - 20; }

		// Calculate Game Coordinates
		let gameCoordinate = this.gameWorldContainer.worldTransform.applyInverse(new pixi.Point(x, y));

		if (this.flagMoveJumpPoint) {
			this.jumpSelect.setPosition(gameCoordinate.x, gameCoordinate.y);
			if (this.isValidWarpPosition(gameCoordinate.x, gameCoordinate.y)) {
				this.hideTutorialBan();
				this.jumpSelect.setAlpha(1);
			} else {
				this.showTutorialBan(gameCoordinate.x, gameCoordinate.y);
				this.jumpSelect.setAlpha(0.6);
			}
		}

		// Save the Game Coordinate to use in various areas
		this.mouseCoordinate.set(gameCoordinate.x, gameCoordinate.y);
	}

	// Called when the mouse is clicked
	public onMouseClick(x: number, y: number) {
		if (this.wasJustPaused()) {
			return;
		}

		this.inputs.fire();

		if (x < 20) { x = 20; }
		if (x > App.width - 20) { x = App.width - 20; }
		if (y < 20) { y = 20; }
		if (y > App.height - 20) { y = App.height - 20; }

		// Calculate Game Coordinates
		let gameCoordinate = this.gameWorldContainer.worldTransform.applyInverse(new pixi.Point(x, y));

		if (this.flagMoveJumpPoint && this.isValidWarpPosition(gameCoordinate.x, gameCoordinate.y)) {
			this.flagMoveJumpPoint = false;
			this.jumpSelect.setPosition(gameCoordinate.x, gameCoordinate.y);
		}

		// Determine if we should run the jump or warp-out logic
		if (this.flagGameActive && this.flagJumpingActive === false && this.tutorialStage >= TutorialStage.PLAYER_MOVE) {
			let clickDist = Vec2.distance(new Vec2(gameCoordinate.x, gameCoordinate.y), this.player.getPosition());
			if (clickDist <= PLAYER_CLICK_DIST && this.flagCanWarpOut) {
				this.flagGameActive = false;
			} else {
				this.runJumpLogic();
			}
		}
	}

	private isValidWarpPosition(x: number, y: number) {
		let center = new Vec2(500, 750);
		let angle = Math.PI * 0.65;

		let diff = new Vec2(x, y);
		diff.subtract(center);
		let angleVec = new Vec2(Math.cos(angle), Math.sin(angle));

		return (Vec2.dot(diff, angleVec) < 0);
	}

	// Logic when player takes damage
	private async playerTakeDamage(damage: number, enemy: SectorEnemyEntity) {
		let health = this.player.applyDamage(damage);
		if (health === 1) {
			SoundManager.ShieldsDepleted.play();
		}
		this.hudContainer.setCurrentHealth(health);

		// Shield Conveyance
		let dir = enemy.getPosition().subtract(this.player.getPosition()).normalize();
		this.playerUIEntity.showShields(health, dir);

		// Player Died. Game Over
		if (health === 0) {
			this.flagGameActive = false;
		} else { // Go invulnerable for a little bit
			this.player.blink(3);
			this.player.setInvulnerable(true);
			await this.eventSystem.timeout(3000);
			this.player.setInvulnerable(false);
		}
	}

	// Destroy an enemy and play an impact animation
	private destroyEnemy(enemy: SectorEnemyEntity) {
		this.createImpactEffect(enemy.getPosition());
		// SoundManager.TestExplosion.play();
		this.removeFromWorld(enemy);
		SoundManager.Impact.play();
	}

	// Create the impact effect
	private createImpactEffect(position: Vec2) {
		let impact = new ImpactEntity(this, position.x, position.y);
		this.addToWorld(impact);
	}

	// Update the cluster positions and recalculate the scores
	private updateClusterPositions(): number {
		if (this.player === undefined || this.jumpSelect === undefined) {
			return 0;
		}

		let positive: Vec2;
		let negative: Vec2;
		if (this.flagSwapCentroids) {
			positive = this.jumpSelect.getPosition();
			negative = this.player.getPosition();

			this.player.setColor(Colors.ClusterNegative);
			this.jumpSelect.setColor(Colors.ClusterPositive);
		} else {
			positive = this.player.getPosition();
			negative = this.jumpSelect.getPosition();

			this.player.setColor(Colors.ClusterPositive);
			this.jumpSelect.setColor(Colors.ClusterNegative);
		}

		// Never allow the centroids to be placed on top of each other
		if (positive.equals(negative)) {
			positive.x += 0.0001;
		}

		if (this.tutorialStage >= TutorialStage.PLAYER_SPAWNED) {
			this.divider.setCluster(positive, negative);
		}

		// Update Cluster Scores
		if (this.localClusterContainer) {
			if (this.tutorialStage >= TutorialStage.PLAYER_SPAWNED) {
				this.localClusterContainer.update(positive.x, positive.y, negative.x, negative.y);
			}
			let localScore = this.cluster.calculateLocalScore(this.clusterX, this.clusterY);

			this.cluster.calculate(this.clusterX);
			this.cluster.calculate(this.clusterY);

			// Set the indicator to a fake global score
			let playerPos1 = this.player.getPosition();
			let playerPos2 = this.jumpSelect.getPosition();
			if (this.flagSwapCentroids) {
				let swap = playerPos1;
				playerPos1 = playerPos2;
				playerPos2 = swap;
			}
			let sweetSpot1 = new Vec2(300, 800);
			let sweetSpot2 = new Vec2(400, 300);
			let distance1 = Vec2.distance(sweetSpot1, playerPos1);
			let distance2 = Vec2.distance(sweetSpot2, playerPos2);
			distance1 /= 150;
			distance2 /= 150;
			let score = (1 / (Math.max(distance1 + 1, 1))) * 0.15 - 0.05;
			score += (1 / (Math.max(distance2 + 1, 1))) * 0.15 - 0.05;
			this.setResearchBonus(score);

			return localScore;
		} else {
			return 0;
		}
	}

	// Run Jump Logic
	private async runJumpLogic() {
		this.flagJumpingActive = true;
		let origPlayerPos = this.player.getPosition();
		let origJumpPos = this.jumpSelect.getPosition();

		this.player.setNavigateTo(origPlayerPos.x, origPlayerPos.y);
		this.player.setVelocity(0, 0);

		let newJump = new SectorJumpSelectEntity(this, origPlayerPos.x, origPlayerPos.y);
		newJump.setTint(this.player.getTint());
		this.addToWorld(newJump);

		this.player.vanish(false);
		SoundManager.JumpOut.play();
		newJump.jumpIn(1000);
		await this.eventSystem.fnTick((perc) => {
			this.player.adjustRotation(this.deltaTime * Math.PI * 2);
		}, 1000);

		// swap jumps
		this.flagSwapCentroids = !this.flagSwapCentroids;
		let oldJumpSelect = this.jumpSelect;
		this.jumpSelect = newJump;

		this.player.setPositionAndNaviation(oldJumpSelect.x, origJumpPos.y);
		this.player.vanish(true);
		SoundManager.JumpIn.play();

		await this.eventSystem.fnTick((perc) => {
			this.player.adjustRotation(this.deltaTime * Math.PI * 2);
		}, 1000);

		this.flagJumpingActive = false;

		await oldJumpSelect.jumpOut(1000);
		this.removeFromWorld(oldJumpSelect);
	}

	// Phase: Intro
	private async runPhaseIntro() {
		this.tutorialStage = TutorialStage.START;
		this.flagGameActive = true;

		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("welcome to the\nomega cluster tutorial", 500);
		await this.waitForUserInput();


		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("here you will learn how\nto pilot your ship and\ncontribute to cancer research", 500);
		await this.waitForUserInput();
		await this.runTitleFadeOut(500);
		this.tutorialStage = TutorialStage.SECTOR_POPULATED;
		this.runFadeIn(this.localClusterContainer, 500);

		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("The galaxy of Omega Cluster\nis divided into sectors", 500);
		await this.waitForUserInput();

		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("each sector contains\ntwo types of particles...", 500);
		await this.waitForUserInput();

		SoundManager.MessageAppears.play();
		this.pointTutorialArrowAt(286, 614);
		await this.runTitleFadeIn("matter...", 500);
		await this.waitForUserInput();

		SoundManager.MessageAppears.play();
		this.pointTutorialArrowAt(535, 506);
		await this.runTitleFadeIn("...and antimatter", 500);
		await this.waitForUserInput();
		this.hideTutorialArrow();

		this.tutorialStage = TutorialStage.DIVIDER_LINE_SPAWNED;
		this.divider.setAlpha(0);
		this.addToWorld(this.divider);
		this.divider.setCluster(TUTORIAL_CENTROID_POS, TUTORIAL_CENTROID_NEG);
		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("you contribute by\nseparating these particles\nby their color", 500);
		await this.runFadeIn(this.divider, 800);
		await this.runFadeOut(this.localClusterContainer, 400);
		this.localClusterContainer.update(TUTORIAL_CENTROID_POS.x, TUTORIAL_CENTROID_POS.y, TUTORIAL_CENTROID_NEG.x, TUTORIAL_CENTROID_NEG.y);
		await this.runFadeIn(this.localClusterContainer, 400);
		await this.waitForUserInput();
		await this.runTitleFadeOut(100);


		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("a particle whose color\nmatches it's side is\nconsidered correctly separated", 500);
		this.tutorialStage = TutorialStage.MOVING_DIVIDOR;
		this.pointTutorialArrowAt(286, 614);
		await this.waitForUserInput();
		this.hideTutorialArrow();

		this.tutorialStage = TutorialStage.STOPPED_DIVIDOR;
		this.runFadeOut(this.divider, 400);
		await this.runFadeOut(this.localClusterContainer, 400);
		this.localClusterContainer.reset();
		await this.runFadeIn(this.localClusterContainer, 400);
		await this.runTitleFadeOut(100);

		this.tutorialStage = TutorialStage.WARP_POINT_SPAWNING;
		this.jumpSelect.setAlpha(0);
		this.addToWorld(this.jumpSelect);
		this.jumpSelect.setPosition(500, 425);

		this.flagMoveJumpPoint = true;
		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("to begin, place a wormhole near\nantimatter with left click", 500);
		await this.runFadeIn(this.jumpSelect, 1000);

		if (FAST_TUTORIAL === false) {
			// Wait for jump point
			while (this.flagMoveJumpPoint) {
				await this.eventSystem.timeout(100);
			}
		}
		this.hideTutorialBan();
		this.jumpSelect.setAlpha(1);
		await this.runTitleFadeOut(100);
		this.flagMoveJumpPoint = false;
		this.tutorialStage = TutorialStage.WARP_POINT_SPAWNED;
		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("your ship will enter the\nsector through this wormhole", 500);
		await this.eventSystem.timeout(400);
		this.addToWorld(this.player);
		this.player.setAlpha(0);
		this.player.vanish(true);
		this.player.setPositionAndNaviation(this.jumpSelect.x, this.jumpSelect.y);
		await this.waitForUserInput();

		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("moving your ship\nrelative to the wormhole\npositions the separating line", 1000);
		await this.eventSystem.timeout(1000);
		this.tutorialStage = TutorialStage.PLAYER_SPAWNED;
		this.player.setNavigateTo(400, 800);
		this.runFadeIn(this.divider, 400);
		await this.waitForUserInput();
		await this.runTitleFadeOut(100);

		this.tutorialStage = TutorialStage.PLAYER_WARPED;
		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("you can click\nto jump back to the wormhole\nand readjust its position", 1000);
		await this.waitForUserInput();
		this.runTitleFadeOut(100);
		this.player.setNavigateTo(this.jumpSelect.x, this.jumpSelect.y);
		await this.runJumpLogic();

		this.player.setNavigateTo(550, 300);
		await this.eventSystem.timeout(2000);
		await this.runJumpLogic();

		this.player.setNavigateTo(800, 500);
		await this.eventSystem.timeout(1000);

		this.addToWorld(this.playerUIEntity);
		let playerPos = this.player.getPosition();
		this.playerUIEntity.setPosition(playerPos.x, playerPos.y);

		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("the white bar indicates\nhow accurately the particles\nin this sector have been grouped", 1000);
		this.pointTutorialArrowAt(670, 500, 45);
		await this.waitForUserInput();
		await this.runTitleFadeOut(100);
		this.hideTutorialArrow();

		this.tutorialStage = TutorialStage.PLAYER_WARP_INDICATOR;

		await this.eventSystem.timeout(1000);
		this.player.setNavigateTo(180, 555);
		await this.eventSystem.timeout(2000);

		SoundManager.MessageAppears.play();
		this.pointTutorialArrowAt(150, 600);
		await this.runTitleFadeIn("a visual indicator appears\non the ship when it\ncan warp out of the sector", 1000);
		await this.waitForUserInput();
		this.hideTutorialArrow();

		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("particle grouping in\nthis sector affects grouping\nin other sectors", 500);
		await this.waitForUserInput();
		await this.runTitleFadeOut(100);
		this.hideTutorialArrow();

		this.runFadeIn(this.hudContainer.indicator, 800);
		await this.runFadeIn(this.hudContainer.score, 800);
		this.pointTutorialArrowAt(510, 0, 0);
		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("these indicators show\nhow well particles are\ngrouped throughout all sectors", 500);
		await this.waitForUserInput();

		SoundManager.MessageAppears.play();
		this.pointTutorialArrowAt(780, 0, 0);
		await this.runTitleFadeIn("try to choose\nwarp out locations that\nmaximize your research bonus", 500);
		await this.waitForUserInput();
		this.hideTutorialArrow();

		this.pointTutorialArrowAt(-20, 20);
		this.runFadeIn(this.hudContainer.shield, 800);
		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("this shows your\nship's remaining shields", 1000);
		await this.waitForUserInput();
		this.hideTutorialArrow();

		let enemy = new SectorEnemyEntity(this, 0, 0, EnemyType.CHASER_TUTORIAL);
		this.addToWorld(enemy);
		enemy.setActive(false);
		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("enemies will home in on you\nif you linger in their\npart of space");
		await this.waitForUserInput();
		enemy.setActive(true);

		this.pointTutorialArrowAt(-20, 20);
		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("impact with them will result\nin the loss of shields", 1000);
		await this.eventSystem.timeout(1500);
		await this.waitForUserInput();
		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("once depleted, your ship\nis vulnerable", 1000);
		await this.waitForUserInput();
		this.hideTutorialArrow();
		this.runTitleFadeOut(100);

		await this.runTitleFadeOut(100);
		this.player.setNavigateTo(800, 500);
		await this.eventSystem.timeout(2000);
		this.tutorialStage = TutorialStage.PLAYER_MOVE;

		let titleVisible = false;
		SoundManager.MessageAppears.play();
		await this.runTitleFadeIn("move the ship", 1000);
		await this.eventSystem.fnTick(() => {
			if (this.flagCanWarpOut !== titleVisible) {
				if (this.flagCanWarpOut) {
					this.setTitleText("click on the ship or\npress spacebar to warp\nout of the sector");
					this.titleText.alpha = 1;
					this.titleBackground.alpha = TITLE_BACKGROUND_ALPHA;
				} else {
					this.titleText.alpha = 0;
					this.titleBackground.alpha = 0;
				}
			}
			titleVisible = this.flagCanWarpOut;
			return this.flagGameActive ? undefined : EventSystem.Break;
		});
		await this.runTitleFadeOut(100);
	}

	// Phase: Leave
	private async runPhaseLeave() {
		let localScore = this.cluster.calculateLocalScore(this.clusterX, this.clusterY);
		let minWarpPerc = DifficultyManager.getWarpOutPercent();
		let percentAboveThreshold = Math.max(0, localScore - minWarpPerc);

		// Calculate Final Scores
		ScoreManager.addScore(ScoreCategory.MATTER_SEPARATED, this.localClusterContainer.getNumClustered());
		ScoreManager.addScore(ScoreCategory.WARP_METER_BONUS, percentAboveThreshold * 100);
		ScoreManager.addScore(ScoreCategory.RESEARCH_BONUS, this.hudContainer.getNumHashesLit());
		ScoreManager.addScore(ScoreCategory.SHIELD_BONUS, this.player.getHealth() - 1);
		ScoreManager.addScore(ScoreCategory.SECTOR_BONUS, GameplayLogicController.getCurrentRound() + 1);

		let location = new Vec2(Globals.WORLD_WIDTH / 2, Globals.WORLD_HEIGHT - 300);
		this.removeUI();

		// Tell player to go to the warp location and wait for the journey to complete
		this.player.setNavigateTo(location.x, location.y);
		SoundManager.ShipMoveToWarp.play();
		this.player.setWarpOutActive(false);

		await this.eventSystem.fnTick(() => {
			let distSq = Vec2.squaredDistance(this.player.getPosition(), location);
			if (distSq <= 250) {
				return EventSystem.Break;
			} else {
				return undefined;
			}
		});

		let whe = new WarpHoleEntity(this, location.x, location.y, WARP_TRANSITION_TIME);
		whe.setZIndex(10000);
		this.player.setZIndex(10001);

		this.addToWorld(whe);
		this.eventSystem.fnTick((percentage) => {
			if (this.player === undefined) {
				return EventSystem.Break;
			}
			// let rotation = percentage * Math.PI * 4;
			this.player.setRotation(this.player.getRotation() + (Math.PI * this.deltaTime * 1.5));
			return;
		});

		// Show the Warphole
		SoundManager.WarpHoleGrowsUp.play();
		await whe.show();

		let whsSound = SoundManager.WarpholeSpin.play();
		whsSound.loop(true);

		// Data Point Movement Logic
		SoundManager.TallyClusterCollect.play();
		(async () => {
			for (let dp of this.localClusterContainer.dataPoint) {
				dp.runScoreLogic(location);
				await this.eventSystem.timeout(30);
			}

			await this.eventSystem.timeout(1000);
			for (let dp of this.localClusterContainer.dataPoint) {
				if (dp.isClustered()) {
					dp.setAlpha(0);
				}
			}
		})();

		// Cleared!
		await this.runTitleFadeIn("tutorial completed", 500);
		await this.eventSystem.timeout(1500);
		await this.runTitleFadeOut(500);
		await this.runFadeOut(this.player);
		this.runFadeOut(this.localClusterContainer);

		// hide the wormhole
		SoundManager.WarpOut.play();
		await whe.hide();
		whsSound.loop(false);
		whsSound.stop();

		this.gameWorldContainer.removeChild(this.localClusterContainer);
		this.removeFromWorld(whe);
		this.removeFromWorld(this.player);
		this.player = undefined!;
	}

	// Phase: End
	private async runPhaseEnd() {
		SoundManager.WarpScene.play();
		this.runTitleFadeIn("Returning to Menu");
		let warpEffect = new WarpBackgroundEntity(this, Globals.WORLD_WIDTH / 2, Globals.WORLD_HEIGHT / 2, Colors.LightGray);
		warpEffect.setZIndex(0);
		this.addToWorld(warpEffect);
		await this.eventSystem.timeout(2500);
		await this.runFadeOut(warpEffect);
		this.removeFromWorld(warpEffect);
		await this.runTitleFadeOut();
	}

	// Phase: Dead
	private async runPhaseDead() {
		await this.removeUI();

		let hasDied = false;
		SoundManager.ShipDestroyed.play();
		this.eventSystem.fnInterval(() => {
			if (hasDied) {
				return EventSystem.Break;
			}

			this.createImpactEffect(this.player.getPosition());

			return;
		}, [], 500);

		await this.eventSystem.timeout(1500);
		this.runFadeOut(this.player, 500);
		await this.eventSystem.timeout(500);

		hasDied = true;
		await this.runFadeOut(this.localClusterContainer, 2500);
		await this.eventSystem.timeout(250);

		this.removeFromWorld(this.player);
	}


	// Fade out the UI
	private async removeUI() {
		// Fade Out some entities at the same time
		this.runFadeOut(this.jumpSelect, 500);
		this.runFadeOut(this.playerUIEntity, 500);
		this.runFadeOut(this.hudContainer.shield.internal, 500);
		this.runFadeOut(this.hudContainer.indicator.internal, 500);

		await this.runFadeOut(this.divider, 500);

		this.hudContainer.shield.delete();
		this.hudContainer.indicator.delete();

		this.removeFromWorld(this.jumpSelect);
		this.removeFromWorld(this.playerUIEntity);
		this.removeFromWorld(this.divider);
	}

	// Update loop for the main game
	public update(dt: number) {
		this.clickToContinueTimer += dt * 2.6;
		this.clickToContinueText.alpha = -Math.cos(Math.max(this.clickToContinueTimer, 0) / 2 + .5) * .65;


		if (this.flagGameActive) {
			// Update UI Elements as needed
			this.hudContainer.update(dt);

			if (this.tutorialStage === TutorialStage.MOVING_DIVIDOR) {
				this.tutorialDividorMoveTime += dt;

				let positive: Vec2;
				let negative: Vec2;

				if (false) {
					let angle = (Math.PI / 180) * Math.sin(this.tutorialDividorMoveTime / 2) * 55;
					// let angle = 10 * (Math.PI / 180) * this.tutorialDividorMoveTime;

					const HALF_WORLD = new Vec2(Globals.WORLD_WIDTH / 2, Globals.WORLD_HEIGHT / 2);
					positive = TUTORIAL_CENTROID_POS.copy().subtract(HALF_WORLD);
					positive = new Vec2(
						positive.x * Math.cos(angle) + positive.y * Math.sin(angle),
						positive.y * Math.cos(angle) + positive.x * Math.sin(angle)
					).add(HALF_WORLD);

					negative = TUTORIAL_CENTROID_NEG.copy().subtract(HALF_WORLD);
					negative = new Vec2(
						negative.x * Math.cos(angle) + negative.y * Math.sin(angle),
						negative.y * Math.cos(angle) + negative.x * Math.sin(angle)
					).add(HALF_WORLD);
				} else {
					let offset = Math.cos(this.tutorialDividorMoveTime) * 100 - 100;
					positive = TUTORIAL_CENTROID_POS.copy().subtract(new Vec2(0, offset));
					negative = TUTORIAL_CENTROID_NEG.copy().subtract(new Vec2(0, offset));
				}

				this.divider.setCluster(positive, negative);
				this.localClusterContainer.update(positive.x, positive.y, negative.x, negative.y);
			}

			let score = this.updateClusterPositions();

			// Show or hide the warp-out symbol on the ship
			if (this.tutorialStage >= TutorialStage.PLAYER_WARP_INDICATOR) {
				this.flagCanWarpOut = score >= DifficultyManager.getWarpOutPercent();
				this.player.setWarpOutActive(this.flagCanWarpOut);
			}

			// Only update this in DEBUG mode
			if (Globals.DEBUG_MODE) {
				this.debugLocalScorePercent.text = "Local Percent: " + (score * 100).toFixed(2) + "%";
			}

			// Set Warp UI
			let playerPos = this.player.getPosition();
			this.playerUIEntity.setPosition(playerPos.x, playerPos.y);
			this.playerUIEntity.setWarpCurrentPercent(score);

			// Set Player Navigation Position
			if (this.tutorialStage >= TutorialStage.PLAYER_MOVE) {
				this.player.setNavigateTo(this.mouseCoordinate.x, this.mouseCoordinate.y);
			}
		}
	}

	/** Return the player entity for other systems to use */
	public getPlayerEntity() {
		return this.player;
	}
}
