import { Colors } from "../utils/Colors";
import { BrowserRequest } from "../utils/BrowserRequest";
import { GlobalCluster } from "./GlobalCluster";

export namespace ClusterUtils {

	export const ResultAPI = "https://synapse.hewmen.com/api/v1/process/oDzDWzs52xsVcfw5beZCbOAt/result";
	export const ProblemAPI = "https://synapse.hewmen.com/api/v1/process/oDzDWzs52xsVcfw5beZCbOAt/problem";

	// export const ResultAPI = "https://synapse.dev.hewmen.com/api/v1/process/7Upe1ja4rj8Dpb0fPvXwbPAR/result"";
	// export const ProblemAPI = "https://synapse.dev.hewmen.com/api/v1/process/7Upe1ja4rj8Dpb0fPvXwbPAR/problem";

	export function LerpColorInt(a: number, b: number, amount: number) {

		let ar = a >> 16, ag = a >> 8 & 0xff, ab = a & 0xff,
			br = b >> 16, bg = b >> 8 & 0xff, bb = b & 0xff,
			rr = ar + amount * (br - ar),
			rg = ag + amount * (bg - ag),
			rb = ab + amount * (bb - ab);

		return ((rr << 16) + (rg << 8) + rb | 0);
	}

	function normalize(value: number, min: number, max: number) {
		return (value - min) / (max - min);
	}

	const colors = [
		[Colors.DarkGray, 0.0],
		[Colors.Red, 0.3],
		[Colors.DarkRed, 0.4],
		[Colors.Orange, 0.5],
		[Colors.Yellow, 0.6],
		[Colors.Green, 0.7],
		[Colors.DarkGreen, 0.8],
		[Colors.Blue, 1.0]
	];

	export function GetScoreColor(score: number) {
		for (let i = 0; i < colors.length; i++) {
			const [color2, max] = colors[i];
			if (max > score) {
				const [color, min] = colors[i - 1];
				// console.log(score, min, max, normalize(score, min, max));
				return LerpColorInt(color, color2, normalize(score, min, max));
			}
		}
		return 0xFFFFFF;

		/*if (score < 0.5) {
			return LerpColorInt(Colors.DarkRed, Colors.DarkYellow, score * 2);
		} else {
			return LerpColorInt(Colors.DarkYellow, Colors.DarkGreen, (score - 0.5) * 2);
		}*/
	}

	export async function loadData() {
		let { body } = await BrowserRequest.get<{
			result: {
				balancedMode: boolean;
				problemUUID: string;
				data: { type: boolean; values: number[]; }[];
				centroids: { type: boolean; values: number[]; }[];
			};
		}>(ProblemAPI, {});
		let result = body.result;
		console.log("loadData", result);
		return { problemUUID: result.problemUUID, data: result.data, centroids: result.centroids, balancedMode: result.balancedMode };
	}

	export function updateClusterCentroids(cluster: GlobalCluster, centroids?: { type: boolean; values: number[]; }[], positive = 1, negative = 1) {
		for (const centroid of cluster.getCentroids()) {
			cluster.removeCentroid(centroid);
		}
		if (centroids === undefined) {
			for (let i = 0; i < positive; i++) {
				cluster.addCentroid(true);
			}
			for (let i = 0; i < negative; i++) {
				cluster.addCentroid(false);
			}
		} else {
			for (const centroid of centroids) {
				cluster.addCentroid(centroid);
			}
		}
	}
}
