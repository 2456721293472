export class Time {

	public static readonly HOUR_MS = 60 * 60 * 1000;
	public static readonly MINUTE_MS = 60 * 1000;
	public static readonly SECOND_MS = 1000;

	public static getReadableTime(timeToPrintMS: number) {
		const second = 1000;
		const minute = 60 * second;
		const hour = 60 * minute;
		const day = 24 * hour;
		const month = 30 * day; // approximately
		const year = 365 * day; // approximately

		const time = [{ year }, { month }, { day }, { hour }, { minute }, { second }].map((item: any, i, a) => {
			const unitName = Object.keys(item)[0];

			const units = timeToPrintMS / item[unitName] | 0;
			timeToPrintMS -= item[unitName] * units;
			const maybePlural = units === 1 ? "" : "s";
			return units > 0 ? units + " " + unitName + maybePlural : "";
		}).filter(x => x)

		const formattedTime = time.length > 1 ? [...time.slice(0, -1), "and", time.slice(-1)].join(" ") : time[0];
		return formattedTime;
	}

	public static getTimeBreakdown(timeToPrintMS: number) {
		const seconds = 1000;
		const minutes = 60 * seconds;
		const hours = 60 * minutes;
		const days = 24 * hours;
		const months = 30 * days; // approximately
		const years = 365 * days; // approximately

		let retObj: any = {};

		[{ years }, { months }, { days }, { hours }, { minutes }, { seconds }].forEach((item: any, i, a) => {
			const unitName = Object.keys(item)[0];

			const units = timeToPrintMS / item[unitName] | 0;
			timeToPrintMS -= item[unitName] * units;

			retObj[unitName] = (units > 0) ? units : 0;
		});

		return retObj;
	}

	public static getTimeBreakdownHMS(timeToPrintMS: number) {
		const seconds = 1000;
		const minutes = 60 * seconds;
		const hours = 60 * minutes;

		let retObj: any = {};

		[{ hours }, { minutes }, { seconds }].forEach((item: any, i, a) => {
			const unitName = Object.keys(item)[0];

			const units = timeToPrintMS / item[unitName] | 0;
			timeToPrintMS -= item[unitName] * units;

			retObj[unitName] = (units > 0) ? units : 0;
		});

		return retObj;
	}

	public static calculateDifference(then: Date, now: Date): string {
		return this.getReadableTime(now.getTime() - then.getTime());
	}

	public static getTimeMDY(time?: number): string {
		let dateObj: Date;

		if (time) {
			dateObj = new Date(time);
		} else {
			dateObj = new Date();
		}

		let month = dateObj.getUTCMonth() + 1; // months from 1-12
		let day = dateObj.getUTCDate();
		let year = dateObj.getUTCFullYear();

		let newDate = month + "/" + day + "/" + year;

		return newDate;
	}

	public static getTimestamp(time?: number): string {
		let date: Date;

		if (time) {
			date = new Date(time);
		} else {
			date = new Date();
		}

		let year = date.getFullYear();
		let month = ("0" + (date.getMonth() + 1)).substr(-2);
		let day = ("0" + date.getDate()).substr(-2);
		let hours = ("0" + date.getHours()).substr(-2);
		let minutes = ("0" + date.getMinutes()).substr(-2);
		let seconds = ("0" + date.getSeconds()).substr(-2);
		return "" + year + "-" + month + "-" + day + "_" + hours + "-" + minutes + "-" + seconds;
	}

	public static calculateMilliseconds(hours: number, minutes?: number, seconds?: number): number {
		let ms = hours * this.HOUR_MS;

		if (minutes !== undefined) {
			ms += minutes * this.MINUTE_MS;
		}

		if (seconds !== undefined) {
			ms += seconds * this.SECOND_MS;
		}

		return ms;
	}
}
