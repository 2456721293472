import pixi = require("pixi.js");
import { Colors } from "../../utils/Colors";
import { Vec2, Utils } from "@h4x/common";
import { TextureManager } from "../../core/TextureManager";

// Particals
const MAX_NUM_PARTICLES = 100;
const PARTICLE_TIME_TO_LIVE_SEC = 1;
const PARTICLE_SPAWN_RATE = 0.05;
const PARTICLE_SPAWN_RADIUS = 50;
const PARTICLE_SIZE = 2;

// Symbol
const WARP_SYMBOL_SCALE = 0.15;
const WARP_SYMBOL_ROTATION = Math.PI * 2;

function RandomPointInCircle(radius: number): Vec2 {
	let angle = Math.random() * Math.PI * 2;
	let x = Math.cos(angle) * radius;
	let y = Math.sin(angle) * radius;

	return new Vec2(x, y);
}

// An individual Warp Effect Particle
class WarpEffectParticle extends pixi.Graphics {
	private timeToLive: number = PARTICLE_TIME_TO_LIVE_SEC;
	private startPos: Vec2;
	private direction: Vec2;
	constructor() {
		super();

		this.startPos = RandomPointInCircle(PARTICLE_SPAWN_RADIUS);
		this.direction = Vec2.direction(this.startPos, new Vec2(0, 0));
		this.beginFill(Colors.White);
		this.drawCircle(0, 0, PARTICLE_SIZE);
		this.endFill();

		this.x = this.startPos.x;
		this.y = this.startPos.y;
	}

	public update(dt: number) {
		let percLeft = Utils.remapRange(this.timeToLive, 0, PARTICLE_TIME_TO_LIVE_SEC, 0, 1);

		// console.log(percLeft);

		let dist = percLeft * PARTICLE_SPAWN_RADIUS;
		let newPos = this.direction.copy().multiplyScalar(dist);

		this.x = newPos.x;
		this.y = newPos.y;

		this.alpha = percLeft;

		this.timeToLive -= dt;

	}

	public isDead(): boolean {
		return (this.timeToLive <= 0);
	}
}

export class WarpEffect extends pixi.Container {
	private active: boolean = false;
	private particles: WarpEffectParticle[] = [];
	private timeToNextParticle = PARTICLE_SPAWN_RATE;
	private warpOutSymbol: pixi.Sprite;

	constructor() {
		super();

		this.warpOutSymbol = pixi.Sprite.from(TextureManager.WarpHole);
		this.warpOutSymbol.anchor.set(0.5);
		this.warpOutSymbol.scale.set(WARP_SYMBOL_SCALE, -WARP_SYMBOL_SCALE);
		this.warpOutSymbol.tint = Colors.Cyan;
		this.warpOutSymbol.y -= 5;
		this.warpOutSymbol.visible = false;
		this.addChildAt(this.warpOutSymbol, 0);
	}

	public update(dt: number) {
		for (let i = 0; i < this.particles.length;) {
			let p = this.particles[i];
			p.update(dt);

			if (p.isDead()) {
				this.particles.splice(i, 1);
				this.removeChild(p);
			} else {
				i++;
			}
		}

		this.warpOutSymbol.rotation -= (WARP_SYMBOL_ROTATION * dt);

		this.timeToNextParticle -= dt;

		if (this.timeToNextParticle <= 0) {
			this.createNewParticle();
			this.timeToNextParticle = PARTICLE_SPAWN_RATE;
		}
	}

	private createNewParticle() {
		if (this.active === false || this.particles.length >= MAX_NUM_PARTICLES) {
			return;
		}

		let newParticle = new WarpEffectParticle();
		this.addChild(newParticle);
		this.particles.push(newParticle);
	}

	public setActive(active: boolean) {
		this.active = active;
		this.warpOutSymbol.visible = active;
	}

	public isActive() {
		return this.active;
	}

	public toggle() {
		this.active = !this.active;
	}
}
