import { DifficultyConfigDefaults, DifficultyBaseDefaults, DifficultyBaseType, DifficultyConfigType, DifficultyScoreType, DifficultyScoreDefaults } from "./DifficultyConfig";
import { BrowserRequest } from "../utils/BrowserRequest";
import { Globals } from "./Constants";

// Enable the Google Sheet pulling of difficulty Information
const GOOGLE_SHEET_ENABLE = true;

// Google API Config
const GOOGLEAPI = {
	url: "https://sheets.googleapis.com/v4/spreadsheets",
	sheetID: "1dLdAmWI_xPAoLdjchvAaFnX5AHL5v9qQrumhcpPnE4c",
	apiKey: "AIzaSyBY-jRQwWIXvDjaTvAj2tgR6tpnsACPebE",
};

export class DifficultyManager {
	private static currentRound = 0;
	private static maxRounds = Globals.DEFAULT_NUM_ROUNDS;
	private static difficultyConfig: DifficultyConfigType = DifficultyConfigDefaults;
	private static difficultyBase: DifficultyBaseType = DifficultyBaseDefaults;
	private static difficultyScores: DifficultyScoreType = DifficultyScoreDefaults;
	public static version = "NOT INITIALIZED";

	// Get Initialize the Data
	public static async init() {
		if ((GOOGLE_SHEET_ENABLE as any) === false) {
			DifficultyManager.version = "GOOGLE SHEETS DISABLED";
			let validDefault = this.validateData();
			if (validDefault === false) {
				throw Error("DifficultyManager:init - Error Parsing Difficulty Defaults!");
			}
			return;
		}

		console.log("DifficultyManager:init - Retrieving Google Sheet Data for Difficulty Information...");
		let configData = (await DifficultyManager.getGoogleSheetData("Config"));
		let sectorScoreData = (await DifficultyManager.getGoogleSheetData("SectorSCoreValues"));
		let sectorBaseData = (await DifficultyManager.getGoogleSheetData("SectorBaseValues"));
		let sectorDifficultyData = await DifficultyManager.getGoogleSheetData("SectorDifficulty");
		let warpBaseData = await DifficultyManager.getGoogleSheetData("WarpBaseValues");
		let warpDifficultyData = await DifficultyManager.getGoogleSheetData("WarpDifficulty");

		console.log("DifficultyManager:init - Finished Retrieving Google Sheet Data. Validating Request...");
		if (configData === undefined ||
			sectorBaseData === undefined ||
			sectorDifficultyData === undefined ||
			warpBaseData === undefined ||
			warpDifficultyData === undefined) {
			console.warn("DifficultyManager:init - Error Retrieving Data!");
			console.warn("Config: ", configData);
			console.warn("SectorBaseValues: ", sectorBaseData);
			console.warn("SectorDifficulty: ", sectorDifficultyData);
			console.warn("WarpBaseValues: ", warpBaseData);
			console.warn("WarpDifficulty: ", warpDifficultyData);
			this.version = "GOOGLE SHEET ERROR";
			return;
		}

		// Parse the Version Data
		console.log("DifficultyManager:init - Parsing Config Data...");
		this.version = this.getSheetValue<string>(configData, "userIdent", 1, ["COULD NOT FIND"])[0];
		this.maxRounds = this.getSheetValue<number>(configData, "rounds", 1, [Globals.DEFAULT_NUM_ROUNDS])[0];

		// Parse Sector Score Values
		console.log("DifficultyManager:init - Parsing Sector Score Values...");
		let difficultyScoreKeys = Object.keys(this.difficultyScores);
		for (let key of difficultyScoreKeys) {
			(this.difficultyScores as any)[key] = this.getSheetValue<number>(sectorScoreData, key, 1, [(DifficultyScoreDefaults as any)[key]])[0];
		}

		// Parse Sector Base Values
		console.log("DifficultyManager:init - Parsing Sector Base Values...");
		let difficultyBaseKeys = Object.keys(this.difficultyBase);
		for (let key of difficultyBaseKeys) {
			(this.difficultyBase as any)[key] = this.getSheetValue<number>(sectorBaseData, key, 1, [(DifficultyBaseDefaults as any)[key]])[0];
		}

		// Parse Sector Difficulty Data
		console.log("DifficultyManager:init - Parsing Sector Difficulty Values...");
		let difficultyConfigKeys = Object.keys(this.difficultyConfig);
		for (let key of difficultyConfigKeys) {
			(this.difficultyConfig as any)[key] = this.getSheetValue<number>(sectorDifficultyData, key, 1, (DifficultyConfigDefaults as any)[key]);
		}

		console.log("DifficultyManager:init - Finished Parsing Google Sheet, validating data...");
		// VALIDATE DATA!
		let valid = this.validateData();

		if (valid === false) {
			this.version = "GOOGLE SHEET PARSE FAIL! USING DEFAULTS!";
			this.maxRounds = Globals.DEFAULT_NUM_ROUNDS;
			this.difficultyConfig = DifficultyConfigDefaults;
			this.difficultyBase = DifficultyBaseDefaults;
			this.difficultyScores = DifficultyScoreDefaults;
		}

		console.log("DifficultyManager:init - Difficulty System Loaded");
	}

	private static validateData() {
		let keys = Object.keys(this.difficultyConfig);
		let valid = true;
		for (let k of keys) {
			let cfg = (this.difficultyConfig as any)[k];

			if (this.isValidSectorArray(cfg) === false) {
				valid = false;
				break;
			}
		}

		return valid;
	}

	// Is an array valid number data
	private static isValidSectorArray(array: number[]): boolean {
		if (array === undefined || array.length < this.maxRounds - 1) {
			return false;
		}

		let ret = true;
		for (let v of array) {
			if (v === undefined) {
				ret = false;
				break;
			}
		}
		return ret;
	}

	// Wrapper to read a value from the google sheet object
	private static getSheetValue<T>(from: any, variableName: string, variableColumn: number, defaults?: T[]): T[] {
		let ret: T[] = [];

		for (let val of from.values) {
			if (val[variableColumn] === variableName) {
				for (let i = variableColumn + 1; i < val.length; i++) {
					ret.push(val[i]);
				}
				break;
			}
		}

		if (ret.length === 0 && defaults) {
			ret = defaults;
		}

		return ret;
	}

	// Wrapper to get the Google Sheet Data
	private static async getGoogleSheetData(page: string) {
		try {
			let data = await BrowserRequest.get<{
				range: string,
				majorDimension: string,
				values: any[],
			}>(`${GOOGLEAPI.url}/${GOOGLEAPI.sheetID}/values/${page}!A:ZZ`, {
				qs: {
					key: GOOGLEAPI.apiKey,
					majorDimension: "ROWS",
					valueRenderOption: "UNFORMATTED_VALUE"
				}
			});
			return data.body;
		} catch (e) {
			return undefined;
		}
	}

	/** Push to the next round and return the next difficulty */
	public static nextDifficultyRound(): number {
		this.setDifficultyRound(Math.min(this.maxRounds, this.currentRound + 1));

		return this.currentRound;
	}

	/** Set to a specific round number */
	public static setDifficultyRound(round: number) {
		if (round >= this.maxRounds) {
			throw Error("DifficultyManager:setRound - Round Exceeded Max Rounds");
		}
		this.currentRound = round;
	}

	/** Get the current difficulty round being used */
	public static getCurrentDifficultyRound() {
		return this.currentRound;
	}

	/** Get the max number of rounds */
	public static getMaxDifficultyRounds() {
		return this.maxRounds - 1;
	}

	/** Get the total number of rounds */
	public static getNumDifficultyRounds() {
		return this.maxRounds;
	}

	/************************
	 * Begin Score Properties
	 ************************/
	/** Return the value for MATTER CLUSTERED */
	public static getScoreMatterClustered() {
		return this.difficultyScores.matterClustered;
	}

	/** Return the value for CLUSTERING BONUS */
	public static getScoreClusteringBonus() {
		return this.difficultyScores.clusteringBonus;
	}

	/** Return the value for GLOBAL SCORE BONUS */
	public static getScoreGlobalScoreBonus() {
		return this.difficultyScores.globalScoreBonus;
	}

	/** Return the value for SHIELD BONUS */
	public static getScoreShieldBonus() {
		return this.difficultyScores.shieldBonus;
	}

	/** Return the value for BEZERKER KILL */
	public static getScoreBezerkerKill() {
		return this.difficultyScores.bezerkerKill;
	}

	/** Return the value for LEVEL BONUS */
	public static getScoreLevelBonus() {
		return this.difficultyScores.levelBonus;
	}


	/*****************************
	 * Begin Difficulty Properties
	 *****************************/
	/** Return the time before the first enemy spawns in seconds */
	public static getFirstEnemySpawnDelaySec() {
		return this.difficultyConfig.firstEnemySpawnDelay[this.currentRound];
	}

	/** Return the current enemy spawn delay in seconds */
	public static getEnemySpawnDelaySec() {
		return this.difficultyConfig.enemySpawnDelay[this.currentRound];
	}

	/** Return the current maximum concurrent enemies that can be active at one time */
	public static getMaxEnemies() {
		return this.difficultyConfig.maxEnemies[this.currentRound];
	}

	/** Return the current minimum warp percentage to display on the player sector UI */
	public static getWarpMinPercent() {
		return this.difficultyBase.warpMinPercent;
	}

	/** Return the current warp out percentage threshold */
	public static getWarpOutPercent() {
		return this.difficultyBase.warpOutPercent;
	}

	/** Return the current speed of the PLAYER */
	public static getPlayerSpeed() {
		return this.difficultyBase.playerSpeed;
	}

	/** Return the current turn rate of the PLAYER */
	public static getPlayerTurnRate() {
		return this.difficultyBase.playerTurnRate;
	}

	/** Return the current spawn chance of the CHASER */
	public static getChaserSpawnChance() {
		return this.difficultyConfig.chaserSpawnChance[this.currentRound];
	}

	/** Return the current Max Number of CHASERS */
	public static getChaserMaxSpawn() {
		return this.difficultyConfig.chaserMaxNum[this.currentRound];
	}

	/** Return the current starting speed of the CHASER */
	public static getChaserSpeed() {
		return (this.difficultyConfig.chaserStartingSpeed[this.currentRound] * this.difficultyBase.chaserSpeedStart);
	}

	/** Return the current turn rate of the CHASER */
	public static getChaserTurnRate() {
		return this.difficultyBase.chaserTurnRate;
	}

	/** Return the current max speed of the CHASER */
	public static getChaserMaxSpeed() {
		return this.difficultyBase.chaserSpeedMax;
	}

	/** Return the current time to get to max speed of the CHASER */
	public static getChaserSpeedRate() {
		return this.difficultyConfig.chaserSpeedRate[this.currentRound];
	}

	/** Return the current detection radius of the CHASER */
	public static getChaserDetectionRadius() {
		return this.difficultyConfig.chaserDetectionRadius[this.currentRound];
	}

	/** Return the current disable time of the CHASER */
	public static getChaserDisableTime() {
		return this.difficultyConfig.chaserDisableTime[this.currentRound];
	}

	/** Return the current BERZERKER spawn chance */
	public static getBerzerkerSpawnChance() {
		return this.difficultyConfig.berzerkerSpawnChance[this.currentRound];
	}

	/** Return the current Max number of BERZERKERS*/
	public static getBerzerkerMaxSpawn() {
		return this.difficultyConfig.berzerkerMaxNum[this.currentRound];
	}

	/** Return the current dart speed of the BERZERKER */
	public static getBerzerkerDartSpeed() {
		return this.difficultyBase.berzerkerDartSpeed;
	}

	/** Return the current BERZERKER shake time */
	public static getBerzerkerShakeTime() {
		return this.difficultyConfig.berzerkerShakeTime[this.currentRound];
	}

	/** Return the current BERZERKER Attack Delay */
	public static getBerzerkerAttackDelay() {
		return this.difficultyConfig.berzerkerAttackDelay[this.currentRound];
	}

	/** Return the current spawn chance of PATROLLER ONE */
	public static getPatrollerOneSpawnChance() {
		return this.difficultyConfig.patrollerOneSpawnChance[this.currentRound];
	}

	/** Return the current speed of PATROLLER ONE */
	public static getPatrollerOneSpeed() {
		return (this.difficultyConfig.patrollerOneSpeed[this.currentRound] * this.difficultyBase.patrollerOneSpeed);
	}

	/** Return the current spawn chance of PATROLLER */
	public static getPatrollerTwoSpawnChance() {
		return this.difficultyConfig.patrollerTwoSpawnChance[this.currentRound];
	}

	/** Return the current speed of PATROLLER TWO */
	public static getPatrollerTwoSpeed() {
		return (this.difficultyConfig.patrollerTwoSpeed[this.currentRound] * this.difficultyBase.patrollerTwoSpeed);
	}

	/** Return the current spawn chance of PATROLLER THREE */
	public static getPatrollerThreeSpawnChance() {
		return this.difficultyConfig.patrollerThreeSpawnChance[this.currentRound];
	}

	/** Return the current speed of PATROLLER THREE */
	public static getPatrollerThreeSpeed() {
		return (this.difficultyConfig.patrollerThreeSpeed[this.currentRound] * this.difficultyBase.patrollerThreeSpeed);
	}

	/** Return the current minimum WINDER distance of PATROLLER THREE */
	public static getPatrollerThreeMinDist() {
		return this.difficultyBase.patrollerThreeMinDist;
	}

	/** Return the current maximum WINDER distance of PATROLLER THREE */
	public static getPatrollerThreeMaxDist() {
		return this.difficultyBase.patrollerThreeMaxDist;
	}

	/** Return the current Max Number of PATROLLERS */
	public static getPatrollerMaxSpawn() {
		return this.difficultyConfig.patrollerMaxNum[this.currentRound];
	}
}
