import * as pixi from "pixi.js";
import { BasicEvent } from "@h4x/common";

export default class GraphicsButton extends pixi.Graphics {

	public readonly onClick = new BasicEvent<(event: pixi.interaction.InteractionEvent) => void>();
	public readonly onMouseUp = new BasicEvent<(event: pixi.interaction.InteractionEvent) => void>();
	public readonly onMouseDown = new BasicEvent<(event: pixi.interaction.InteractionEvent) => void>();
	public readonly onMouseOver = new BasicEvent<(event: pixi.interaction.InteractionEvent) => void>();
	public readonly onMouseOut = new BasicEvent<(event: pixi.interaction.InteractionEvent) => void>();

	private mainColor: number = 0xFFFFFF;
	private hoverColor: number = 0xFF0000;
	private downColor: number = 0x00FF00;

	constructor(geometry?: PIXI.GraphicsGeometry) {
		super(geometry);
		/*this.x = x;
		this.y = y;*/

		this.interactive = true;
		this.buttonMode = true;

		this.on("mousedown", this.handleMouseDown, this);
		this.on("mouseup", this.handleMouseUp, this);
		this.on("mouseover", this.handleMouseOver, this);
		this.on("mouseout", this.handleMouseOut, this);
	}

	public setMainColor(color: number) {
		this.tint = color;
		this.mainColor = color;
		return this;
	}

	public setHoverColor(color: number) {
		this.hoverColor = color;
		return this;
	}

	public setDownColor(color: number) {
		this.downColor = color;
		return this;
	}

	public setPosition(x: number, y: number) {
		this.x = x;
		this.y = y;
		return this;
	}

	private mouseDown = false;
	private handleMouseDown(event: pixi.interaction.InteractionEvent) {
		this.mouseDown = true;
		this.onMouseUp.execute(event);
		this.tint = this.downColor;
	}

	private handleMouseUp(event: pixi.interaction.InteractionEvent) {
		if (this.mouseDown === true) {
			this.mouseDown = false;
			this.onClick.execute(event);
		}
		this.onMouseUp.execute(event);
		this.tint = this.mainColor;
	}

	private handleMouseOver(event: pixi.interaction.InteractionEvent) {
		this.onMouseOver.execute(event);
		this.tint = this.hoverColor;
	}

	private handleMouseOut(event: pixi.interaction.InteractionEvent) {
		this.onMouseOut.execute(event);
		this.tint = this.mainColor;
	}
}
