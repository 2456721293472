type StatsMap = Map<StatsCategory, number>;

/** Scoring Categories */
export enum StatsCategory {
	FINAL_SCORE,
	BEST_ROUND,
	RESEARCH_SOLUTIONS,
	HIGHEST_SECTOR,
}

/** Manager for holding the stats of the game */
export class StatsManager {
	private static activeStats: StatsMap = new Map();

	public static newGame() {
		this.activeStats.clear();

		// Init all stats to 0
		for (let i = 0; i < Object.keys(StatsCategory).length; i++) {
			this.activeStats.set(i, 0);
		}
	}

	/** Add to the current stat for a particular category. */
	public static addToStat(category: StatsCategory, count: number) {
		let val = this.activeStats.get(category);

		if (val === undefined) {
			val = count;
		} else {
			val += count;
		}

		this.activeStats.set(category, val);
	}

	/** Set the current stat for a particular category. */
	public static setStat(category: StatsCategory, count: number, setIfHigher: boolean = false) {
		let val = this.activeStats.get(category);

		val = val ? val : 0;

		if (setIfHigher && count > val || setIfHigher === false) {
			val = count;
		}

		this.activeStats.set(category, val);
	}

	/** Get the current stat for a particular category */
	public static getStat(category: StatsCategory): number {
		let count = this.activeStats.get(category);
		let ret = count ? count : 0;
		return ret;
	}
}
