import { ClassVariable, RegisterClassVariable, GetClassMeta, RegisterClass } from "../core/MetaDecorator";
import { Service } from "../core/Services";


export class DataProviderVariable {
	public name: string;
	public register(variable: ClassVariable) {
		this.name = variable.name;
	}
}

export const VARIABLE = ClassVariable.createType<DataProviderVariable>("DataProviderVariable");

/*
let test_var = new ClassVariable("a");
let data = test_var.getData(VARIABLE);
data;
data = test_var.attachData(VARIABLE, new DataProviderVariable());
data.varA = "test";
data.varB = 123;
data;
data = test_var.getData(VARIABLE);
data;
*/

export function Class() {
	return function (target: any) {
		let classMeta = RegisterClass(target as any, true);
	};
}

export class DataProvider {

	public static variable() {
		return function (target: Object, key: string) {
			let variable = RegisterClassVariable(target.constructor, key).attachData(VARIABLE, new DataProviderVariable());
		};
	}
}

/*
@Class()
class TestAProvider {

	@DataProvider.variable()
	varA: string;

	@DataProvider.variable()
	varB: number;

}

let varAData = GetClassMeta(TestAProvider.prototype, false)!.getVariable("varA")!.getData(VARIABLE);
varAData;

let varBData = GetClassMeta(TestAProvider.prototype, false)!.getVariable("varB")!.getData(VARIABLE);
varBData;
*/
