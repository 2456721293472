import { BinaryUtils } from "./BinaryUtils";

export class Base85 {

	private chars: string[];
	private rev: { [K: string]: number };

	constructor(map: string) {
		if (map.length !== 85) {
			throw new Error();
		}
		let chars: string[] = [];
		for (let i = 0; i < map.length; i++) {
			chars.push(map.charAt(i));
		}

		let rev: { [K: string]: number } = {};
		chars.forEach((c, i) => { rev[c] = i; });

		this.chars = chars;
		this.rev = rev;
	}

	public encodeString(text: string) {
		return this.encode(BinaryUtils.stringToUint8Array(text));
	}

	public encode(array: Uint8Array) {
		let chars = this.chars;
		let out = "";
		let length = array.length;
		let groups = Math.floor(array.length / 4);

		for (let g = 0; g < groups; g++) {
			let number = 0;
			for (let i = 0; i < 4; i++) {
				number = (number * 256) + array[g * 4 + i];
			}
			let divider = 85 * 85 * 85 * 85;
			for (let i = 0; i < 5; i++) {
				out += chars[((number / divider) >>> 0) % 85];
				divider /= 85;
			}
		}

		let lastIndex = groups * 4;
		let lastSize = length - lastIndex;
		if (lastSize !== 0) {
			let number = 0;
			for (let i = 0; i < lastSize; i++) {
				number = (number * 256) + array[lastIndex + i];
			}

			let divider = 85;
			if (lastSize === 3) {
				divider = 85 * 85 * 85;
			} else if (lastSize === 2) {
				divider = 85 * 85;
			}

			for (let i = 0; i < lastSize + 1; i++) {
				out += chars[((number / divider) >>> 0) % 85];
				divider /= 85;
			}
		}

		return out;
	}

	public decodeString(text: string) {
		return BinaryUtils.uint8ToStringArray(this.decode(text));
	}

	public decode(array: string) {
		let rev = this.rev;

		let length = array.length;
		let groups = Math.floor(array.length / 5);
		let lastIndex = groups * 5;
		let lastSize = length - lastIndex;

		let out = new Uint8Array(groups * 4 + (lastSize > 1 ? lastSize - 1 : 0));
		let index = 0;

		for (let g = 0; g < groups; g++) {
			let number = 0;
			for (let i = 0; i < 5; i++) {
				number = number * 85 + rev[array[g * 5 + i]];
			}
			let divider = 256 * 256 * 256;
			for (let i = 0; i < 4; i++) {
				out[index++] = ((number / divider) >>> 0) % 256;
				divider /= 256;
			}
		}

		if (lastSize !== 0) {
			let number = 0;
			for (let i = 0; i < lastSize; i++) {
				number = number * 85 + rev[array[lastIndex + i]];
			}

			let divider = 1;
			if (lastSize === 4) {
				divider = 256 * 256;
			} else if (lastSize === 3) {
				divider = 256;
			}

			for (let i = 0; i < lastSize - 1; i++) {
				out[index++] = ((number / divider) >>> 0) % 256;
				divider /= 256;
			}
		}

		return out;
	}
}
