import { Scene } from "../../core/Scene";
import { App } from "../../app";
import { EnvironmentContainer } from "../../containers/EnvironmentContainer";
import { WarpEffect } from "../../Gameplay/Effects/WarpEffect";
import { Globals } from "../../Gameplay/Constants";

export class TestWarpEffectScene extends Scene {
	private warpEffect: WarpEffect;

	constructor() {
		super();

		App.onResizeEvent.addCallback(() => { });
	}

	public async init() {
		this.physicsWorld.gravity.y = 0;

		this.backgroundContainer.addChild(new EnvironmentContainer());

		this.warpEffect = new WarpEffect();
		this.warpEffect.x = Globals.WORLD_WIDTH / 2;
		this.warpEffect.y = Globals.WORLD_HEIGHT / 2;
		this.gameWorldContainer.addChild(this.warpEffect);

		this.warpEffect.setActive(true);
	}

	public update(dt: number) {
		this.warpEffect.update(dt);
	}

	public onMouseMove(x: number, y: number) { }

	// Handle when a key on the keyboard is released
	public keyUp(key: KeyboardEvent) {

	}
}
