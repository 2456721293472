import { UIContainer } from "../../../core/UI/UIContainer";
import { UISizePercentage } from "../../../core/UIHelpers";
import { PlayerScoreUIElement } from "../PlayerScoreUIElement";
import { Scene } from "../../../core/Scene";
import { HUDIndicatorContainer } from "./HUDIndicatorContainer";
import { HUD_HEIGHT } from "./HUDSizes";
import { HUDPlayerShield } from "./HUDPlayerShield";

export class HUDContainer extends UIContainer {

	public readonly indicator: HUDIndicatorContainer;
	public readonly score: PlayerScoreUIElement;
	public readonly shield: HUDPlayerShield;

	constructor(scene: Scene) {
		super({
			x: 0, y: 0, width: new UISizePercentage(100), height: HUD_HEIGHT
		});

		this.indicator = this.add(new HUDIndicatorContainer());
		this.score = this.add(new PlayerScoreUIElement(scene));
		this.shield = this.add(new HUDPlayerShield());
	}

	public onResize(width: number, height: number) {
		// double the resize event to fix issues when child containers scale up/down
		super.onResize(width, height);
		super.onResize(width, height);
	}

	// TODO Remove with event system tick
	public update(dt: number) {
		this.shield.update(dt);
	}

	/** Set the base global percent for the game */
	public setBaseGlobalPercent(baseGlobalScore: number) {
		this.indicator.setBaseGlobalPercent(baseGlobalScore);
	}

	/** Set the starting global score for the round */
	public setRoundStartingGlobalPercent(globalRoundScore: number) {
		this.indicator.setRoundStartingGlobalPercent(globalRoundScore);
	}

	/** Set the current global score in the round  */
	public setRoundCurrentGlobalPercent(globalRoundScore: number) {
		this.indicator.setRoundCurrentGlobalPercent(globalRoundScore);
	}

	/** Set the current player score  */
	public getNumHashesLit() {
		return this.indicator.getNumHashesLit();
	}

	/** Set the current player score  */
	public setScore(score: number) {
		this.score.setScore(score);
	}

	/** Set the current player health  */
	public setCurrentHealth(health: number) {
		this.shield.setCurrentHealth(health);
	}

}
