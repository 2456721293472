import { BinaryUtils } from "./BinaryUtils";
import { Base85 } from "./Base85";

let base85Encoder = new Base85("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ.-:+=^!/*?&_;()[]{}@%$#");

export class InternalEncryptionUtils {

	public static async sha256(text: string) {
		try {
			return await crypto.subtle.digest("SHA-256", BinaryUtils.stringToUint8Array(text));
		} catch (e) {
			throw new Error("Failed to sha256!");
		}
	}

	public static async aesGcmEncrypt(data: string | Buffer, password: string) {
		if (typeof (data) === "string") {
			data = new Buffer(data);
		}
		try {
			const keyString = await this.sha256(password);

			const iv = crypto.getRandomValues(new Uint8Array(12)) as Uint8Array;
			if (!iv) { throw new Error("Failed to created IV!"); }

			const alg = { name: "AES-GCM", iv: iv };

			const key = await crypto.subtle.importKey("raw", keyString, alg.name, false, ["encrypt"]);

			const encryptedBuffer = await crypto.subtle.encrypt(alg, key, data);

			return Buffer.concat([new Buffer(iv.buffer), new Buffer(encryptedBuffer)]);
		} catch (e) {
			throw new Error("Failed to encrypt!");
		}
	}

	public static async aesGcmDecrypt(encrypted: Buffer, password: string) {
		try {
			const keyString = await this.sha256(password);

			const iv = encrypted.slice(0, 12);
			const data = encrypted.slice(12, -16);

			const alg = { name: "AES-GCM", iv: new Uint8Array(iv) };

			const key = await crypto.subtle.importKey("raw", keyString, alg.name, false, ["decrypt"]);

			const decryptedBuffer = new Uint8Array(await crypto.subtle.decrypt(alg, key, data));
			const text = BinaryUtils.uint8ToStringArray(decryptedBuffer);

			return text;
		} catch (e) {
			throw new Error("Failed to decrypt!");
		}
	}

	public static async aesGcmDecryptBuffer(encrypted: Buffer, password: string) {
		try {
			const keyString = await this.sha256(password);

			const iv = encrypted.slice(0, 12);
			const data = encrypted.slice(12, -16);

			const alg = { name: "AES-GCM", iv: new Uint8Array(iv) };

			const key = await crypto.subtle.importKey("raw", keyString, alg.name, false, ["decrypt"]);

			return Buffer.from(await crypto.subtle.decrypt(alg, key, data));
		} catch (e) {
			throw new Error("Failed to decrypt!");
		}
	}

	public static async aesGcmEncryptBase85(data: string | Buffer, password: string) {
		if (typeof (data) === "string") {
			data = new Buffer(data);
		}
		try {
			const keyString = await this.sha256(password);

			const iv = crypto.getRandomValues(new Uint8Array(12)) as Uint8Array;
			if (!iv) { throw new Error("Failed to created IV!"); }

			const alg = { name: "AES-GCM", iv: iv };

			const key = await crypto.subtle.importKey("raw", keyString, alg.name, false, ["encrypt"]);

			const encryptedBuffer = await crypto.subtle.encrypt(alg, key, data);

			return base85Encoder.encode(iv) + "|" + base85Encoder.encode(new Uint8Array(encryptedBuffer));
		} catch (e) {
			throw new Error("Failed to encrypt!");
		}
	}

	public static async aesGcmDecryptBase85(encrypted: string, password: string) {
		try {
			const keyString = await this.sha256(password);

			const [iv, data] = encrypted.split("|").map(v => base85Encoder.decode(v));

			const alg = { name: "AES-GCM", iv: new Uint8Array(iv) };

			const key = await crypto.subtle.importKey("raw", keyString, alg.name, false, ["decrypt"]);

			const decryptedBuffer = new Uint8Array(await crypto.subtle.decrypt(alg, key, data));
			const text = BinaryUtils.uint8ToStringArray(decryptedBuffer);

			return text;
		} catch (e) {
			throw new Error("Failed to decrypt!");
		}
	}

	public static async aesGcmDecryptBufferBase85(encrypted: string, password: string) {
		try {
			const keyString = await this.sha256(password);

			const [iv, data] = encrypted.split("|").map(v => base85Encoder.decode(v));

			const alg = { name: "AES-GCM", iv: new Uint8Array(iv) };

			const key = await crypto.subtle.importKey("raw", keyString, alg.name, false, ["decrypt"]);

			return Buffer.from(await crypto.subtle.decrypt(alg, key, data));
		} catch (e) {
			throw new Error("Failed to decrypt!");
		}
	}
}
