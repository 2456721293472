

import pixi = require("pixi.js");
import { UIContainer } from "../../../core/UI/UIContainer";
import { TextureManager } from "../../../core/TextureManager";
import { Colors } from "../../../utils/Colors";
import { UIPositionAlign, UIAlign, UIDirection } from "../../../core/UIHelpers";
import { HUD_HEIGHT, HUD_SCALE, HUD_INDICATOR_WIDTH } from "./HUDSizes";

enum IndicatorState {
	NEUTRAL = 0,
	SINGLE_POSITIVE = 1,
	DOUBLE_POSITIVE = 2,
	SINGLE_NEGATIVE = -1,
	DOUBLE_NEGATIVE = -2
}

export class HUDIndicatorGlobal extends UIContainer {
	private indicatorUIContainer: UIContainer;

	private indicatorNeutral: pixi.Sprite;
	private indicatorPositive: pixi.Sprite[] = [];
	private indicatorNegative: pixi.Sprite[] = [];

	constructor() {
		super({
			width: HUD_INDICATOR_WIDTH,
			height: HUD_HEIGHT
		});

		this.indicatorUIContainer = this.add(new UIContainer({
			x: new UIPositionAlign(UIAlign.center), y: new UIPositionAlign(UIAlign.center),
			width: 1, height: 1,
		}));

		// Setup Neutral Indicator
		this.indicatorNeutral = pixi.Sprite.from(TextureManager.UIGlobalIndicatorNeutral);
		this.indicatorNeutral.anchor.set(0.5);
		this.indicatorUIContainer.internal.addChild(this.indicatorNeutral);

		// Load the positive indicators
		const INDICATOR_SPACING = 10;
		const INDICATOR_START = 5;
		for (let i = 0; i < 2; i++) {
			let newSprite = pixi.Sprite.from(TextureManager.UIGlobalIndicatorArrow);
			newSprite.anchor.set(0.5);
			newSprite.y = (((i + 1) * -INDICATOR_SPACING) - INDICATOR_START);
			this.indicatorPositive.push(newSprite);
			this.indicatorUIContainer.internal.addChild(newSprite);
		}

		for (let i = 0; i < 2; i++) {
			let newSprite = pixi.Sprite.from(TextureManager.UIGlobalIndicatorArrow);
			newSprite.anchor.set(0.5);
			newSprite.y = (((i + 1) * INDICATOR_SPACING) + INDICATOR_START);
			newSprite.scale.set(1, -1); // Flip Upside Down
			this.indicatorNegative.push(newSprite);
			this.indicatorUIContainer.internal.addChild(newSprite);
		}
	}

	public onResize(width: number, height: number) {
		super.onResize(width, height);
		let scale = HUD_SCALE.compute(UIDirection.Y, this);
		this.indicatorUIContainer.internal.scale.set(scale);
	}


	// Set the graphics to reflect the given indicator state
	public set(state: IndicatorState) {
		for (let child of this.indicatorUIContainer.internal.children) {
			(child as pixi.Sprite).tint = Colors.IndicatorOff;
		}

		switch (state) {
			case IndicatorState.NEUTRAL:
				this.indicatorNeutral.tint = Colors.IndicatorNeutral;
				break;
			case IndicatorState.SINGLE_POSITIVE:
				this.indicatorPositive[0].tint = Colors.IndicatorPositiveArrowOne;
				break;
			case IndicatorState.DOUBLE_POSITIVE:
				this.indicatorPositive[0].tint = Colors.IndicatorPositiveArrowOne;
				this.indicatorPositive[1].tint = Colors.IndicatorPositiveArrowTwo;
				break;
			case IndicatorState.SINGLE_NEGATIVE:
				this.indicatorNegative[0].tint = Colors.IndicatorNegativeArrowOne;
				break;
			case IndicatorState.DOUBLE_NEGATIVE:
				this.indicatorNegative[0].tint = Colors.IndicatorNegativeArrowOne;
				this.indicatorNegative[1].tint = Colors.IndicatorNegativeArrowTwo;
				break;
			default:
				throw Error("GlobalScoreUIElement:setIndicatorState - Invalid Indicator State Set!");
		}
	}
}
