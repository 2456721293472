
export class TextUtils {

	public static toStringMapping(args: any[]) {
		const map: { [K: string]: boolean } = {};
		args.forEach((arg) => { map[arg] = true; });
		return map;
	}

	public static lowerLetters = TextUtils.toStringMapping("abcdefghijklmnopqrstuvwxyz".split(""));
	public static upperLetters = TextUtils.toStringMapping("ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""));

	public static getLetterData(char: string) {
		let isLowerLetter = !!TextUtils.lowerLetters[char];
		let isUpperLetter = !!TextUtils.upperLetters[char];
		let isLetter = isLowerLetter || isUpperLetter;
		return {
			isLowerLetter,
			isUpperLetter,
			isLetter
		};
	}

	public static cleanWord(str: string) {
		let last = 0;
		let i = 0;
		const parts: string[] = [];
		while (str.length > i) {
			if (!TextUtils.getLetterData(str[i]).isLetter) {
				parts.push(str.substring(last, i));
				last = i + 1;
			}
			i++;
		}
		if (last < str.length) {
			parts.push(str.substring(last, str.length));
		}

		return parts.join("");
	}

	public static splitToWords(str: string) {

		let parts: string[] = [];

		{ // split using spaces
			let lastSplit = 0;
			let i = 0;
			let last = TextUtils.getLetterData(str[0]);
			while (str.length > i) {
				let char = str[i];
				let current = TextUtils.getLetterData(char);
				if (char === " ") {
					parts.push(str.substring(lastSplit, i));
					lastSplit = i + 1;
				}
				i++;
				last = current;
			}

			if (lastSplit < str.length) {
				parts.push(str.substring(lastSplit, str.length));
			}
		}

		{ // split TextTTextText -> Tex TText Text
			let lastSplit = 0;
			let i = 0;
			let last = TextUtils.getLetterData(str[0]);
			while (str.length > i) {
				let char = str[i];
				let current = TextUtils.getLetterData(char);
				if (char === " " || !current.isLetter) {
					parts.push(str.substring(lastSplit, i));
					lastSplit = i + 1;
				} else if (current.isUpperLetter && last.isLowerLetter) {
					parts.push(str.substring(lastSplit, i));
					lastSplit = i;
				}
				i++;
				last = current;
			}

			if (lastSplit < str.length) {
				parts.push(str.substring(lastSplit, str.length));
			}
		}

		{ // split TextTTextText -> Textt Text Text
			let lastSplit = 0;
			let i = 0;
			let last = TextUtils.getLetterData(str[0]);
			while (str.length > i) {
				let char = str[i];
				let current = TextUtils.getLetterData(char);
				if (char === " " || !current.isLetter) {
					parts.push(str.substring(lastSplit, i));
					lastSplit = i + 1;
				} else if (i > 1 && current.isLowerLetter && last.isUpperLetter) {
					parts.push(str.substring(lastSplit, i - 1));
					lastSplit = i - 1;
				}
				i++;
				last = current;
			}

			if (lastSplit < str.length) {
				parts.push(str.substring(lastSplit, str.length));
			}
		}

		{
			let lastSplit = 0;
			let i = 0;
			let last = TextUtils.getLetterData(str[0]);
			while (str.length > i) {
				let char = str[i];
				let current = TextUtils.getLetterData(char);
				if (char === " " || !current.isLetter) {
					parts.push(str.substring(lastSplit, i));
					lastSplit = i + 1;
				} else if (i > 1 && last.isUpperLetter && current.isLowerLetter) {
					parts.push(str.substring(lastSplit, i - 1));
					lastSplit = i - 1;
				}
				i++;
				last = current;
			}

			if (lastSplit < str.length) {
				parts.push(str.substring(lastSplit, str.length));
			}
		}

		{
			let lastSplit = 0;
			let i = 0;
			let last = TextUtils.getLetterData(str[0]);
			while (str.length > i) {
				let char = str[i];
				let current = TextUtils.getLetterData(char);
				if (char === " " || !current.isLetter) {
					parts.push(str.substring(lastSplit, i));
					lastSplit = i + 1;
				} else if (i > 0 && last.isUpperLetter !== current.isUpperLetter) {
					parts.push(str.substring(lastSplit, i));
					lastSplit = i;
				}
				i++;
				last = current;
			}

			if (lastSplit < str.length) {
				parts.push(str.substring(lastSplit, str.length));
			}
		}


		let out: string[] = [];
		parts.forEach((word) => {
			word = word.toLowerCase();
			if (word.length <= 1) { // 1 character words
				return;
			}
			if (out.indexOf(word) === -1) {
				out.push(word);
			}
			let cleanWord = TextUtils.cleanWord(word);
			if (cleanWord !== word) {
				if (out.indexOf(cleanWord) === -1) {
					out.push(cleanWord);
				}
			}

			// 1337 -> leet
		});

		return out;
	}
}
