import * as pixi from "pixi.js";
import { Scene } from "../Scene";
import { Vec2 } from "@h4x/common";

export enum EntityType {
	SectorPlayer,
	SectorPlayerUI,
	SectorPlayerWarpEffect,
	SectorUI,
	WarpPlayer,
	SectorEnemy,
	TestBehaviorEntity,
	Warp,
	Data,
	Impact,
	JumpSelect,
	Divisor,
	Divider,
	Tether,
	WarpHole,
	WarpBackground,
	TutorialArrowUI
}

export abstract class Entity {
	// Entity ID Management
	private static nextEntityID = 0;
	private static getNextEntityID() {
		return this.nextEntityID++;
	}

	protected readonly id: number;
	protected readonly scene: Scene;
	protected readonly graphicsContainer: pixi.Container;
	public isOverlay = false;

	constructor(scene: Scene, x: number, y: number) {
		this.id = Entity.getNextEntityID();
		this.scene = scene;

		this.graphicsContainer = new pixi.Container();

		this.graphicsContainer.x = x;
		this.graphicsContainer.y = y;
	}

	get eventSystem() { return this.scene.eventSystem; }

	/** Get the id of this entity */
	public getID() {
		return this.id;
	}

	public getGraphicsContainer(): pixi.Container {
		return this.graphicsContainer;
	}

	/** Get the scene this entity is a part of */
	public getScene() {
		return this.scene;
	}

	// X and Y position for this entity graphics
	get x() { return this.graphicsContainer.x; }
	get y() { return this.graphicsContainer.y; }
	set x(value: number) { this.graphicsContainer.x = value; }
	set y(value: number) { this.graphicsContainer.y = value; }

	// Alpha property for this entity's graphics
	get alpha() { return this.graphicsContainer.alpha; }
	set alpha(value: number) { this.graphicsContainer.alpha = value; }

	/** Remove this entity from the current scene and all other areas */
	public removeFromWorld() {
		// TODO FIXME (be smarter about what container this should be removed from)
		// Remove from Scene
		this.scene.gameWorldContainer.removeChild(this.graphicsContainer);
		this.scene.gameWorldOverlayContainer.removeChild(this.graphicsContainer);
		this.scene.uiContainer.removeChild(this.graphicsContainer);
		this.scene.backgroundContainer.removeChild(this.graphicsContainer);

		// Destroy the graphics and all children
		this.graphicsContainer.destroy({
			children: true,
		});

		// Remove from the entity manager
		this.scene.entityManager.remove(this);
	}

	/** Get the entity type */
	public abstract get type(): EntityType;

	// Internal update mechanism for core entity types
	protected internalUpdate(dt: number) {
		this.update(dt);
	}

	/** Update Logic for the entity */
	protected update(dt: number) { }

	/** Set the Pixi Container position for this object */
	public setPosition(x: number, y: number) {
		this.graphicsContainer.x = x;
		this.graphicsContainer.y = y;
	}

	/** Return the Pixi Container position for this object */
	public getPosition(): Vec2 {
		return new Vec2(this.graphicsContainer.x, this.graphicsContainer.y);
	}

	/** Set the rotation of the entity in radians */
	public setRotation(rotation: number) {
		this.graphicsContainer.rotation = rotation;
	}

	/** Get the current rotation of the display entity in radians */
	public getRotation(): number {
		return this.graphicsContainer.rotation;
	}

	/** Adjust the rotaiton by this amount, return the new rotation value */
	public adjustRotation(adjust: number): number {
		this.graphicsContainer.rotation += adjust;
		return this.graphicsContainer.rotation;
	}

	/** Return a Vec2 with the current X/Y scale of the display object */
	public getScale(): Vec2 {
		return new Vec2(this.graphicsContainer.scale.x, this.graphicsContainer.scale.y);
	}

	/** Set the current X/Y Scale of the display object */
	public setScale(x: number, y?: number) {
		if (y) {
			this.graphicsContainer.scale.set(x);
		} else {
			this.graphicsContainer.scale.set(x, y);
		}
	}

	/** Get the current alpha value of the display object */
	public getAlpha(): number {
		return this.graphicsContainer.alpha;
	}

	/** Set the current value of the display object */
	public setAlpha(alpha: number) {
		this.graphicsContainer.alpha = alpha;
	}

	/** Returns 'true' if the display object is visible */
	public getVisible(): boolean {
		return this.graphicsContainer.visible;
	}

	/** Set the visible flag of the display object */
	public setVisible(visible: boolean) {
		this.graphicsContainer.visible = visible;
	}

	/** Get the Z-Index value of the display object (rendering order, higher number means drawn last) */
	public getZIndex(): number {
		return this.graphicsContainer.zIndex;
	}

	/** Set the Z-Index value of the display object (rendering order, higher number means drawn last) */
	public setZIndex(index: number) {
		this.graphicsContainer.zIndex = index;
	}
}
