import { Scene } from "../core/Scene";
import { GameplayLogicController, GameplayLogicState } from "../Gameplay/GameLogicController";
import { UIText } from "../core/UI/UIText";
import { UIPositionAlign, UIAlign } from "../core/UIHelpers";
import { EnvironmentContainer } from "../containers/EnvironmentContainer";
import { TITLE_FONTSIZE } from "../Gameplay/UI/UISizes";

export class GameSceneLoading extends Scene {

	protected onResize(appWidth: number, appHeight: number) {
	}

	public init() {
		this.runLogic();
	}
	// Run the game logic for this scene!
	private async runLogic() {
		this.backgroundContainer.addChild(new EnvironmentContainer());

		this.uiManager.add(new UIText("Loading...", {
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.center),
			fontSize: TITLE_FONTSIZE,
			font: "Hyperspace"
		}));

		await GameplayLogicController.setupData();
		GameplayLogicController.setState(GameplayLogicState.SECTOR);
	}

	public update(dt: number) {
	}
}
