import { Entity, EntityType } from "../core/Entities/Entity";
import { Scene } from "../core/Scene";
import pixi = require("pixi.js");
import { TextureManager } from "../core/TextureManager";
import { Utils } from "@h4x/common";

const TIME_TO_LIVE = 1.5;
const TIME_TO_FADE = TIME_TO_LIVE - 0.5;

/** An entity that does the "impact" effect when damage is done */
export class ImpactEntity extends Entity {
	public type = EntityType.Impact;

	private graphicsSprite: pixi.Sprite;
	private timeToLive: number = TIME_TO_LIVE;

	constructor(scene: Scene, x: number, y: number) {
		super(scene, x, y);

		this.graphicsSprite = pixi.Sprite.from(TextureManager.Impact);
		this.graphicsSprite.anchor.set(0.5);

		this.graphicsContainer.addChild(this.graphicsSprite);
	}

	public update(dt: number) {
		this.adjustRotation(0.75 * dt);

		this.timeToLive -= dt;

		let scale = Utils.remapRange(this.timeToLive, 0, TIME_TO_FADE, 0.15, 1.5);

		this.setScale(scale);

		this.graphicsSprite.alpha = Utils.remapRange(this.timeToLive, 0, TIME_TO_FADE, 0, 1);

		if (this.timeToLive <= 0) {
			this.removeFromWorld();
		}
	}
}
