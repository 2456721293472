import { Entity } from "./Entity";
import Matter = require("matter-js");
import { Vec2 } from "@h4x/common";
import { Scene } from "../Scene";

/** Physics Based Entity */
export abstract class PhysicsEntity extends Entity {
	public readonly physicsBody: Matter.Body;

	protected physicsYOffset: number = 0;

	constructor(scene: Scene, x: number, y: number, body: Matter.Body) {
		super(scene, x, y);
		this.physicsBody = body;

		Matter.Body.setPosition(this.physicsBody, Matter.Vector.create(x, y));

		// Store the ID of the entity on the physics body
		this.physicsBody.label = "" + this.id;

		// Add the physics body to the world
		Matter.World.add(this.scene.physicsEngine.world, this.physicsBody);
	}

	// X and Y properties for the physics object
	get x() { return this.physicsBody.position.x; }
	get y() { return this.physicsBody.position.y; }
	set x(value: number) {
		super.x = value;
		Matter.Body.setPosition(this.physicsBody, Matter.Vector.create(value, this.physicsBody.position.y));
	}
	set y(value: number) {
		super.y = value;
		Matter.Body.setPosition(this.physicsBody, Matter.Vector.create(this.physicsBody.position.x, value));
	}

	/** Remove an entity from the world, including it's physics and graphics containers */
	public removeFromWorld() {
		// Remove the physics body from the world
		Matter.World.remove(this.scene.physicsEngine.world, this.physicsBody);
		super.removeFromWorld();
	}

	public internalUpdate(dt: number) {
		super.internalUpdate(dt);
		this.physicsUpdate();
	}

	protected physicsUpdate() {
		// Offset
		let forward = Matter.Vector.rotate(Matter.Vector.create(0, -1), this.physicsBody.angle);

		let offset = new Vec2(forward.x, forward.y);
		offset.multiplyScalar(this.physicsYOffset);

		this.graphicsContainer.x = this.physicsBody.position.x + offset.x;
		this.graphicsContainer.y = this.physicsBody.position.y + offset.y;

		this.graphicsContainer.rotation = this.physicsBody.angle;
	}

	/** Force the position of the physics entity to a specific location */
	public setPosition(x: number, y: number) {
		super.setPosition(x, y);
		Matter.Body.setPosition(this.physicsBody, Matter.Vector.create(x, y));
	}

	/** Force the position of the physics entity to a specific location */
	public setPositionVec(position: Vec2) {
		this.setPosition(position.x, position.y);
	}

	/** Return a copy of the vector position of the entity */
	public getPosition(): Vec2 {
		return new Vec2(this.physicsBody.position.x, this.physicsBody.position.y);
	}

	/** Set the rotation of the entity in radians */
	public setRotation(rotation: number) {
		super.setRotation(rotation);

		Matter.Body.setAngle(this.physicsBody, rotation);
	}

	/** Adjust the rotaiton by this amount, return the new rotation value */
	public adjustRotation(adjust: number): number {
		let newRotation = super.adjustRotation(adjust);
		Matter.Body.setAngle(this.physicsBody, newRotation);

		return newRotation;
	}

	/** Set whether this physics body should be treated as static or not */
	public setStatic(isStatic: boolean) {
		Matter.Body.setStatic(this.physicsBody, isStatic);
	}

	/** Get whether or not this is a static body */
	public isStatic(): boolean {
		return this.physicsBody.isStatic;
	}
}
