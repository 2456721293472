import * as pixi from "pixi.js";
import { UIElement, UIElementProperties } from "../UIElement";
import { UIManager } from "../UIManager";
import { UISize, UIDirection } from "../UIHelpers";

export type UITextProperties = UIElementProperties & {
	color?: number;
	alpha?: number;
	fontSize?: UISize;
	font?: string;
	strokeSize?: number;
	strokeColor?: number;
	align?: "left" | "right" | "center";
	fontWeight?: "bold" | "normal";
};

export class UIText extends UIElement<UITextProperties> {

	public readonly internal: pixi.Text;

	constructor(text: string, properties?: UITextProperties) {
		super(properties);
		this.internal = new pixi.Text(text);
	}

	protected initialize(parent: UIElement | UIManager) {
		this.applyProperty(this.properties, "color", 0xFFFFFF);
		this.applyProperty(this.properties, "alpha", 1);
		this.applyProperty(this.properties, "fontSize", 24);
		this.applyProperty(this.properties, "font");
		this.applyProperty(this.properties, "strokeSize", 0);
		this.applyProperty(this.properties, "strokeColor", 0x000000);

		this.applyProperty(this.properties, "align", "left");
		this.applyProperty(this.properties, "fontWeight", "bold");

		super.initialize(parent);

		this.updateText();
		this.updatePosition();
	}

	public onResize(width: number, height: number) {
		super.onResize(width, height);
		this.updateText();
		this.updatePosition();
	}

	protected updateText() {
		this.internal.style.fontSize = this.computeFontSize(this.$fontSize);
	}

	public get text() { return this.internal.text; }
	public set text(value: string) {
		this.internal.text = value;
		if (this.initialized === true) {
			this.updatePosition();
		}
	}

	/* Font Size */
	private $fontSize: UISize;
	public get fontSize() { return this.internal.style.fontSize; }
	public setFontSize(value: number) { this.fontSize = value; return this; }
	public set fontSize(value: number) {
		this.$fontSize = value;
		if (this.initialized === true) {
			this.internal.style.fontSize = this.computeFontSize(value);
		}
	}

	private computeFontSize(value: UISize): number {
		if (typeof (value) === "number") {
			return value;
		} else {
			return value.compute(UIDirection.Y, this);
		}
	}

	/* Color */
	private $color: number;
	public get color() { return this.$color; }
	public setColor(value: number) { this.color = value; return this; }
	public set color(value: number) {
		this.$color = value;
		this.internal.style.fill = value;
	}

	/* Font */
	public get font() { return this.internal.style.fontFamily; }
	public setFont(value: number) { this.font = value; return this; }
	public set font(value: number) {
		this.internal.style.fontFamily = value;
		if (this.initialized === true) {
			this.updatePosition();
		}
	}

	/* Align */
	public get align() { return this.internal.style.align; }
	public setAlign(value: string) { this.align = value; return this; }
	public set align(value: string) {
		this.internal.style.align = value;
		if (this.initialized === true) {
			this.updatePosition();
		}
	}

	/* Font Weight */
	public get fontWeight() { return this.internal.style.fontWeight; }
	public setFontWeight(value: string) { this.fontWeight = value; return this; }
	public set fontWeight(value: string) {
		this.internal.style.fontWeight = value;
		if (this.initialized === true) {
			this.updatePosition();
		}
	}

	/* Stroke Color */
	private $strokeColor: number;
	public get strokeColor() { return this.$strokeColor; }
	public setStrokeColor(value: number) { this.strokeColor = value; return this; }
	public set strokeColor(value: number) {
		this.$strokeColor = value;
		this.internal.style.stroke = value;
	}

	/* Stroke Size */
	public get strokeSize() { return this.internal.style.strokeThickness; }
	public setStrokeSize(value: number) { this.strokeSize = value; return this; }
	public set strokeSize(value: number) {
		this.internal.style.strokeThickness = value;
		if (this.initialized === true) {
			this.updatePosition();
		}
	}

	public get textAlign() { return this.internal.style.align; }
	public setTextAlign(value: 'left' | 'center' | 'right') { this.textAlign = value; return this; }
	public set textAlign(value: 'left' | 'center' | 'right') {
		this.internal.style.align = value;
	}
}
