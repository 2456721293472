import { Behavior } from "./Behavior";
import { DynamicEntity } from "../../core/Entities/DynamicEntity";
import { NavigateToBehavior } from "./NavigateToBehavior";
import { Vec2, Utils } from "@h4x/common";
import { Globals } from "../Constants";
import { DifficultyManager } from "../DifficultyManager";
import { SoundManager } from "../../core/SoundManager";

const THRESHOLD_FOR_NEW_TARGET = 20;

/** Enemy Patroller One (Horizontal) Behavior */
export class EnemyWinderBehavior extends Behavior<DynamicEntity, void> {
	private traveling: boolean = false;
	private navBehavior: NavigateToBehavior;
	private newTarget: Vec2;

	private horizontal: boolean = true;

	constructor(owner: DynamicEntity) {
		super(owner);

		this.navBehavior = new NavigateToBehavior(owner);
	}

	public update(dt: number) {
		if (this.active === false || this.traveling === false || this.newTarget === undefined) {
			return;
		}

		this.navBehavior.update(dt);

		// Determine if we're close to the point
		let dist = Vec2.distance(this.newTarget, this.owner.getPosition());
		if (dist <= THRESHOLD_FOR_NEW_TARGET) {
			this.selectNewPoint();
		}
	}

	private selectNewPoint() {
		SoundManager.PatrollerSpin.play();
		let currentOwnerPos = this.owner.getPosition();
		this.newTarget = currentOwnerPos.copy();

		let minDist = DifficultyManager.getPatrollerThreeMinDist();
		let maxDist = DifficultyManager.getPatrollerThreeMaxDist();

		let newDist = Utils.randomInt(minDist, maxDist);

		if (this.horizontal) {
			this.newTarget.x += Utils.randomInt(-100, 100);
		} else {
			this.newTarget.y += Utils.randomInt(-100, 100);
		}

		this.newTarget = Vec2.direction(this.newTarget, currentOwnerPos).multiplyScalar(newDist).add(currentOwnerPos);

		this.newTarget.x = Utils.remapRange(this.newTarget.x, 0, Globals.WORLD_WIDTH, 0, Globals.WORLD_WIDTH);
		this.newTarget.y = Utils.remapRange(this.newTarget.y, 0, Globals.WORLD_HEIGHT, 0, Globals.WORLD_HEIGHT);

		this.navBehavior.execute(this.newTarget);

		// Toggle Horiztal/Vertical flag
		this.horizontal = !this.horizontal;
	}

	public execute() {
		this.selectNewPoint();
		this.traveling = true;

		this.owner.setTurnRate(50);
		this.owner.setMaxSpeed(DifficultyManager.getPatrollerThreeSpeed());
	}
}
