
/** Types of enemies */
export enum EnemyType {
	CHASER,
	CHASER_TUTORIAL,
	BERZERKER,
	PATROLLER_ONE,
	PATROLLER_TWO,
	PATROLLER_THREE,
}
