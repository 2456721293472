
export class StringUtils {
	public static splitChar(str: string, char: string, limit?: number) {
		let last = 0;
		let i = 0;
		const parts: string[] = [];
		limit = limit !== undefined ? limit : Number.POSITIVE_INFINITY;
		while (str.length > i && limit > 1) {
			if (str[i] === char) {
				parts.push(str.substring(last, i));
				last = i + 1;
				limit--;
			}
			i++;
		}
		if (last < str.length) {
			parts.push(str.substring(last, str.length));
		} else if (last === i) {
			parts.push("");
		}

		return parts;
	}


	public static replaceChars(str: string, chars: { [K: string]: string }) {
		let last = 0;
		let i = 0;
		const parts: string[] = [];
		while (str.length > i) {
			const replace = chars[str[i]];
			if (replace !== undefined) {
				parts.push(str.substring(last, i));
				parts.push(replace);
				last = i + 1;
			}
			i++;
		}
		if (last < str.length) {
			parts.push(str.substring(last, str.length));
		}

		return parts.join("");
	}

	public static findAndReplaceAll(source: string, find: string, replace: string, flags: string = ""): string {
		return source.replace(new RegExp(find, flags + "g"), replace);
	}

	// Function that count occurrences of a substring in a string;
	// Source: http://stackoverflow.com/questions/4009756/how-to-count-string-occurrence-in-string/7924240#7924240
	public static occurrences(source: string, find: string, allowOverlapping: boolean = false) {
		source += "";
		find += "";
		if (find.length <= 0) {
			return (source.length + 1);
		}

		let n = 0;
		let pos = 0;
		let step = allowOverlapping ? 1 : find.length;

		while (true) {
			pos = source.indexOf(find, pos);
			if (pos >= 0) {
				++n;
				pos += step;
			} else {
				break;
			}
		}
		return n;
	}
}
