import { Scene } from "../../../core/Scene";
import { App } from "../../../app";
import { EnvironmentContainer } from "../../../containers/EnvironmentContainer";
import { UIText } from "../../../core/UI/UIText";
import { Colors } from "../../../utils/Colors";
import { UIContainer, UIContainerProperties } from "../../../core/UI/UIContainer";
import { UIPositionAlign, UIAlign } from "../../../core/UIHelpers";
import { UIBorder, UIBorderMode } from "../../../core/UI/UIBorder";
import { UIHorizontalSlider } from "../../../core/UI/UIHorizontalSlider";
import { UITextButton } from "../../../core/UI/UITextButton";
import { ScenesManager } from "../../../core/SceneManager";
import { UIVerticalContainer } from "../../../core/UI/UIVerticalContainer";
import { UIVerticalSlider } from "../../../core/UI/UIVerticalSlider";
import { UIHorizontalContainer } from "../../../core/UI/UIHorizontalContainer";

export class NewUIScene extends Scene {


	constructor() {
		super();

		App.onResizeEvent.addCallback(() => { });
	}

	public async init() {
		this.backgroundContainer.addChild(new EnvironmentContainer());
		this.uiManager.add(new UIBorder({ borderColor: Colors.Orange, borderWidth: 5, borderMode: UIBorderMode.Inner }));

		let a = this.uiManager.add(new UITextButton("Sound", {
			color: Colors.Black,
			x: new UIPositionAlign(UIAlign.left, 10),
			y: 100,
			width: 150,
			alpha: 0.5,
			backgroundColor: Colors.White,
			borderColor: Colors.Red,
			borderWidth: 4
		}));
		a.onClick.addCallback(() => {
			ScenesManager.changeScene(App.newUISoundScene);
		});

		setTimeout(() => { a.hide(); }, 500);
		setTimeout(() => { a.show(); }, 1000);
		setTimeout(() => { a.alpha = 1; }, 1500);


		this.uiManager.add(new UITextButton("Vertical", { x: new UIPositionAlign(UIAlign.left, 10), y: 150, width: 150 }))
			.onClick.addCallback(() => {
				ScenesManager.changeScene(App.newUIVerticalScene);
			});
		this.uiManager.add(new UITextButton("Horizontal", { x: new UIPositionAlign(UIAlign.left, 10), y: 200, width: 150 }))
			.onClick.addCallback(() => {
				ScenesManager.changeScene(App.newUIHorizontalScene);
			});

		// soundNewUIScene

		this.uiManager.add(new UIText("Top Left", {
			color: 0xFFFFFF,
			x: new UIPositionAlign(UIAlign.left, 10),
			y: 10
		}));
		this.uiManager.add(new UIText("Top Center", {
			color: 0xFFFFFF,
			x: new UIPositionAlign(UIAlign.center),
			y: 10
		}));
		this.uiManager.add(new UIText("Top Right", {
			color: 0xFFFFFF,
			x: new UIPositionAlign(UIAlign.right, 10),
			y: 10
		}));

		this.uiManager.add(new UIText("Middle Left", {
			color: 0xFFFFFF,
			x: 10,
			y: new UIPositionAlign(UIAlign.middle)
		}));
		this.uiManager.add(new UIText("Middle Center", {
			color: 0xFFFFFF,
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.middle)
		}));
		this.uiManager.add(new UIText("Middle Right", {
			color: 0xFFFFFF,
			x: new UIPositionAlign(UIAlign.right, 10),
			y: new UIPositionAlign(UIAlign.middle)
		}));

		this.uiManager.add(new UIText("Bottom Left", {
			color: 0xFFFFFF,
			x: 10,
			y: new UIPositionAlign(UIAlign.bottom, 10)
		}));
		this.uiManager.add(new UIText("Bottom Center", {
			color: 0xFFFFFF,
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.bottom, 10)
		}));
		this.uiManager.add(new UIText("Bottom Right", {
			color: 0xFFFFFF,
			x: new UIPositionAlign(UIAlign.right, 10),
			y: new UIPositionAlign(UIAlign.bottom, 10)
		}));

		let sliderContainer = this.uiManager.add(new UIContainer({
			x: new UIPositionAlign(UIAlign.center),
			y: 350,
			width: 500,
			height: 70,
			backgroundColor: Colors.DarkGray
		}));

		let sliderText = sliderContainer.add(new UIText("??.?%", { color: 0xFFFFFF, x: 10, y: 10 }));

		let slider = new UIHorizontalSlider({
			value: 0.75,
			y: 40
		});
		slider.onValueChanged.addCallback((value) => {
			sliderText.text = (value * 100).toFixed(1) + "%";
		});
		sliderContainer.add(slider);

		let container = this.uiManager.add(new UIContainer({
			x: new UIPositionAlign(UIAlign.center),
			y: 50,
			width: 500,
			height: 200,
			backgroundColor: Colors.DarkGray
		}));

		container.add(new UIBorder({
			borderColor: Colors.Red,
			borderWidth: 10,
			borderMode: UIBorderMode.Outer
		}));
		container.add(new UIBorder({
			borderColor: Colors.Green,
			borderWidth: 10,
			borderMode: UIBorderMode.Default
		}));
		container.add(new UIBorder({
			borderColor: Colors.Blue,
			borderWidth: 10,
			borderMode: UIBorderMode.Inner
		}));

		container.add(new UIText("Top Left", {
			color: Colors.DarkRed,
			x: new UIPositionAlign(UIAlign.left, 10),
			y: 10
		}));
		container.add(new UIText("Top Center", {
			color: Colors.DarkRed,
			x: new UIPositionAlign(UIAlign.center),
			y: 10
		}));
		container.add(new UIText("Top Right", {
			color: Colors.DarkRed,
			x: new UIPositionAlign(UIAlign.right, 10),
			y: 10
		}));

		container.add(new UIText("Middle Left", {
			color: Colors.DarkRed,
			x: 10,
			y: new UIPositionAlign(UIAlign.middle)
		}));
		container.add(new UIText("Middle Center", {
			color: Colors.DarkRed,
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.middle)
		}));
		container.add(new UIText("Middle Right", {
			color: Colors.DarkRed,
			x: new UIPositionAlign(UIAlign.right, 10),
			y: new UIPositionAlign(UIAlign.middle)
		}));

		container.add(new UIText("Bottom Left", {
			color: Colors.DarkRed,
			x: 10,
			y: new UIPositionAlign(UIAlign.bottom, 10)
		}));
		container.add(new UIText("Bottom Center", {
			color: Colors.DarkRed,
			x: new UIPositionAlign(UIAlign.center),
			y: new UIPositionAlign(UIAlign.bottom, 10)
		}));
		container.add(new UIText("Bottom Right", {
			color: Colors.DarkRed,
			x: new UIPositionAlign(UIAlign.right, 10),
			y: new UIPositionAlign(UIAlign.bottom, 10)
		}));
	}
}
