import { Scene } from "../../core/Scene";
import { App } from "../../app";
import { EnvironmentContainer } from "../../containers/EnvironmentContainer";
import { Colors } from "../../utils/Colors";
import { TestBehaviorEntity } from "../../entity/Test/TestBehaviorEntity";
import { SectorPlayerEntity } from "../../entity/SectorPlayerEntity";
import { Globals } from "../../Gameplay/Constants";
import { UIBorder, UIBorderMode } from "../../core/UI/UIBorder";
import { InputManager } from "../../core/InputManager";

export class TestBehaviorScene extends Scene {

	private player: SectorPlayerEntity;
	private enemies: TestBehaviorEntity[] = [];

	constructor() {
		super();

		App.onResizeEvent.addCallback(() => { });
	}

	public async init() {
		this.physicsWorld.gravity.y = 0;

		this.backgroundContainer.addChild(new EnvironmentContainer());

		this.player = new SectorPlayerEntity(this, Globals.WORLD_WIDTH / 2, Globals.WORLD_HEIGHT / 2 - 200);
		this.addToWorld(this.player);
	}

	public onMouseMove(x: number, y: number) {
		this.player.setNavigateTo(x, y);
	}

	// Handle when a key on the keyboard is released
	public keyUp(key: KeyboardEvent) {
		console.log(key.code);
		if (key.keyCode === InputManager.KeyT) {
			let newE = new TestBehaviorEntity(this, 0, 0, 0);
			this.addToWorld(newE);
			this.enemies.push(newE);
		} else if (key.keyCode === InputManager.KeyR) {
			for (let e of this.enemies) {
				this.removeFromWorld(e);
			}

			this.enemies = [];
		}
	}

	public getPlayerEntity() {
		return this.player;
	}
}
