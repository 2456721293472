import { Scene } from "../../../core/Scene";
import { InputManager } from "../../../core/InputManager";
import { EndStatsUIElement } from "../../../Gameplay/UI/EndStatsTallyUIElement";
import { StatsManager, StatsCategory } from "../../../Gameplay/StatsManager";
import { Utils } from "@h4x/common";

export class EndUIStatsScene extends Scene {

	private endScoreTallyUI: EndStatsUIElement;

	constructor() {
		super();
	}

	public async init() {
		this.runStuff();
	}

	// Handle when a key on the keyboard is released
	public keyUp(key: KeyboardEvent) {
		if (key.keyCode === InputManager.KeyR) {
			this.runStuff();
		}
	}

	private runStuff() {
		if (this.endScoreTallyUI) {
			this.endScoreTallyUI.delete();
		}

		let rs = Utils.randomInt(0, 30);
		console.log("RS: " + rs);
		StatsManager.setStat(StatsCategory.FINAL_SCORE, Utils.randomInt(0, 10000000));
		StatsManager.setStat(StatsCategory.BEST_ROUND, Utils.randomInt(0, 500000));
		StatsManager.setStat(StatsCategory.RESEARCH_SOLUTIONS, rs);
		StatsManager.setStat(StatsCategory.HIGHEST_SECTOR, Utils.randomInt(1, 50));

		this.endScoreTallyUI = this.uiManager.add(new EndStatsUIElement(this, false));

		this.endScoreTallyUI.runStats();
	}
}
