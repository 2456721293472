import { Scene } from "../../../core/Scene";
import { App } from "../../../app";
import { EnvironmentContainer } from "../../../containers/EnvironmentContainer";
import { Colors } from "../../../utils/Colors";
import { UIPositionAlign, UIAlign } from "../../../core/UIHelpers";
import { UIBorder, UIBorderMode } from "../../../core/UI/UIBorder";
import { UITextButton } from "../../../core/UI/UITextButton";
import { ScenesManager } from "../../../core/SceneManager";
import { UIVerticalContainer } from "../../../core/UI/UIVerticalContainer";
import { UIContainer } from "../../../core/UI/UIContainer";
import { UIText } from "../../../core/UI/UIText";
import { UIHorizontalSlider } from "../../../core/UI/UIHorizontalSlider";
import { UIVerticalSlider } from "../../../core/UI/UIVerticalSlider";

export class NewUIVerticalScene extends Scene {
	public async init() {
		this.backgroundContainer.addChild(new EnvironmentContainer());
		this.uiManager.add(new UIBorder({ borderColor: Colors.Orange, borderWidth: 5, borderMode: UIBorderMode.Inner }));

		this.uiManager.add(new UITextButton("Back", { x: new UIPositionAlign(UIAlign.center, 10), y: 10, width: 150 }))
			.onClick.addCallback(() => {
				ScenesManager.changeScene(App.newUIScene);
			});

		{
			let container = this.uiManager.add(new UIVerticalContainer({
				x: new UIPositionAlign(UIAlign.center),
				y: 150,
				width: 400,
				height: 100,
				backgroundColor: Colors.DarkGray,
				margin: 10,
				padding: 5,
				fit: true,
				fill: true
			}));

			let sliderHContainer = this.uiManager.add(new UIContainer({
				x: new UIPositionAlign(UIAlign.center),
				y: 50,
				width: 800,
				height: 70,
				backgroundColor: Colors.DarkGray
			}));

			let textH = sliderHContainer.add(new UIText("??.?%", { color: 0xFFFFFF, x: 10, y: 10 }));

			let sliderH = new UIHorizontalSlider({
				value: 0.5,
				y: 40
			});
			sliderH.onValueChanged.addCallback((value) => {
				textH.text = (value * 100).toFixed(1) + "%";
				container.setWidth(value * 800);
			});
			sliderHContainer.add(sliderH);

			let sliderVContainer = this.uiManager.add(new UIContainer({
				x: new UIPositionAlign(UIAlign.left, 10),
				y: 120,
				width: 70,
				height: 800,
				backgroundColor: Colors.DarkGray
			}));

			let textV = sliderVContainer.add(new UIText("??.?%", { color: 0xFFFFFF, x: 30, y: 10 }));

			let sliderV = new UIVerticalSlider({
				value: 0.5,
				x: 5
			});
			sliderV.onValueChanged.addCallback((value) => {
				textV.text = (value * 100).toFixed(1) + "%";
				container.setHeight(value * 800);
			});
			sliderVContainer.add(sliderV);


			container.add(new UIContainer({ width: 10, height: 10, backgroundColor: Colors.DarkRed }));
			container.add(new UIContainer({ width: 10, height: 10, backgroundColor: Colors.DarkGreen }));
			container.add(new UIContainer({ width: 10, height: 10, backgroundColor: Colors.DarkYellow }));
			container.add(new UIContainer({ width: 10, height: 10, backgroundColor: Colors.DarkBlue }));

		}
	}
}
