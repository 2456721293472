import { DynamicEntity } from "../core/Entities/DynamicEntity";
import { Scene } from "../core/Scene";
import pixi = require("pixi.js");

/** Logic for general gameplay entities that have health, lives and other values */
export abstract class BaseGameEntity extends DynamicEntity {

	/** Graphic Sprite */
	public graphicsSprite: pixi.Sprite;

	/** Gameplay Elements */
	private alive: boolean = true;
	private damageModifier: number = 1;
	private health: number = 100; /** The amount of health an entity has */
	private lives: number = 1; /** The number of lives the player has */

	constructor(scene: Scene, x: number, y: number, body: Matter.Body, texture?: pixi.Texture) {
		super(scene, x, y, body);
		if (texture !== undefined) {
			this.graphicsSprite = pixi.Sprite.from(texture);
			this.graphicsSprite.anchor.set(0.5);
			this.graphicsContainer.addChild(this.graphicsSprite);
		}
	}

	/** Set whether this player is alive or not */
	public setAlive(alive: boolean) {
		this.alive = alive;
	}

	/** Get if this player is alive or not */
	public isAlive(): boolean {
		return this.alive;
	}

	/** Set a damage modifier value (default: 1) */
	public setDamageModifier(modifier: number) {
		this.damageModifier = 1;
	}

	/** Apply Damage to the Ship (uses Damage Modifier Value). Returns the amount of health remaining */
	public applyDamage(damage: number) {
		let remainingHealth = this.removeHealth(damage * this.damageModifier);

		// Set alive to false if this entity is now dead
		if (remainingHealth === 0) {
			this.setAlive(false);
		}

		return remainingHealth;
	}

	/** Add lives to the player. Return the new amount for player lives */
	public giveHealth(health: number): number {
		this.health += health;
		return this.health;
	}

	/** Remove health from the player (default: 1). Return the new amount for player lives */
	public removeHealth(health: number): number {
		// Health cannot be negative
		this.health = Math.max(0, this.health - health);

		return this.health;
	}

	/** Set the health of the player */
	public setHealth(health: number) {
		this.health = health;
	}

	/** Get the health of the player */
	public getHealth() {
		return this.health;
	}

	/** Add lives to the player (default: 1). Return the new amount for player lives */
	public giveLives(lives: number = 1) {
		this.lives += lives;
		return this.lives;
	}

	/** Remove lives from the player (default: 1). Return the new amount for player lives */
	public removeLives(lives: number = 1) {
		// Lives cannot be negative
		this.lives = Math.max(0, this.lives - lives);
		return this.lives;
	}

	/** Set the number of lives for the player */
	public setLives(lives: number) {
		if (lives > 0) {
			this.lives = lives;
		} else {
			console.warn("Player: You can not set negative lives on the player.")
		}
	}

	/** Get the number of lives for the player */
	public getLives(): number {
		return this.lives;
	}
}
