import { Vec3 } from "./Vec3";

export class Vec2 {

	constructor()
	constructor(x: number, y: number)
	constructor(public x = 0, public y = 0) {
	}

	public static get zero() { return new Vec2(0, 0); }

	public toString() {
		return `Vec2<${this.x.toFixed(5)},${this.y.toFixed(5)}>`;
	}

	public set(x: number, y: number) {
		this.x = x; this.y = y; return this;
	}

	public toXY() {
		return { x: this.x, y: this.y };
	}

	public toUV(precision?: number) {
		if (precision !== undefined) {
			return { u: parseFloat(this.x.toFixed(precision)), v: parseFloat(this.y.toFixed(precision)) };
		} else {
			return { u: this.x, v: this.y };
		}
	}

	public reset() {
		this.x = 0; this.y = 0; return this;
	}

	public copy(dest?: Vec2): Vec2 {
		if (!dest) {
			return new Vec2(this.x, this.y);
		} else {
			dest.x = this.x;
			dest.y = this.y;
			return dest;
		}
	}

	public negate(dest?: Vec2): Vec2 {
		if (!dest) { dest = this; }

		dest.x = -this.x;
		dest.y = -this.y;

		return dest;
	}

	public equals(vector: Vec2, threshold: number = 0.000001): boolean {
		if (Math.abs(this.x - vector.x) > threshold) { return false; }
		if (Math.abs(this.y - vector.y) > threshold) { return false; }

		return true;
	}

	public length(): number {
		return Math.sqrt(this.squaredLength());
	}

	public squaredLength(): number {
		let { x, y } = this;

		return (x * x + y * y);
	}

	public add(vector: Vec2): Vec2 {
		this.x += vector.x;
		this.y += vector.y;

		return this;
	}

	public addScalar(value: number): Vec2 {
		this.x += value;
		this.y += value;

		return this;
	}

	public addXY(x: number, y: number): Vec2 {
		this.x += x;
		this.y += y;

		return this;
	}

	public subtract(vector: Vec2): Vec2 {
		this.x -= vector.x;
		this.y -= vector.y;

		return this;
	}

	public subtractScalar(value: number): Vec2 {
		this.x -= value;
		this.y -= value;

		return this;
	}

	public subtractXY(x: number, y: number): Vec2 {
		this.x -= x;
		this.y -= y;

		return this;
	}

	public multiply(vector: Vec2): Vec2 {
		this.x *= vector.x;
		this.y *= vector.y;

		return this;
	}

	public multiplyScalar(value: number): Vec2 {
		this.x *= value;
		this.y *= value;

		return this;
	}

	public multiplyXY(x: number, y: number): Vec2 {
		this.x *= x;
		this.y *= y;

		return this;
	}

	public divide(vector: Vec2): Vec2 {
		this.x /= vector.x;
		this.y /= vector.y;

		return this;
	}

	public divideScalar(value: number): Vec2 {
		this.x /= value;
		this.y /= value;

		return this;
	}

	public divideXY(x: number, y: number): Vec2 {
		this.x /= x;
		this.y /= y;

		return this;
	}

	public scale(value: number, dest?: Vec2): Vec2 {
		if (!dest) { dest = this; }

		dest.x *= value;
		dest.y *= value;

		return dest;
	}

	public normalize(dest?: Vec2): Vec2 {
		if (!dest) { dest = this; }

		let length = this.length();

		if (length === 1) {
			return this;
		}

		if (length === 0) {
			dest.x = 0;
			dest.y = 0;

			return dest;
		}

		length = 1.0 / length;

		dest.x *= length;
		dest.y *= length;

		return dest;
	}

	public static cross(vector: Vec2, vector2: Vec2, dest?: Vec3): Vec3 {
		if (!dest) { dest = new Vec3(); }

		dest.x = 0;
		dest.y = 0;
		dest.z = vector.x * vector2.y - vector.y * vector2.x;

		return dest;
	}

	public static dot(vector: Vec2, vector2: Vec2): number {
		return (vector.x * vector2.x + vector.y * vector2.y);
	}

	public static distance(vector: Vec2, vector2: Vec2): number {
		return Math.sqrt(this.squaredDistance(vector, vector2));
	}

	public static squaredDistance(vector: Vec2, vector2: Vec2): number {
		let x = vector2.x - vector.x;
		let y = vector2.y - vector.y;

		return (x * x + y * y);
	}

	public static direction(vector: Vec2, vector2: Vec2, dest?: Vec2): Vec2 {
		if (!dest) { dest = new Vec2(); }

		let x = vector.x - vector2.x;
		let y = vector.y - vector2.y;

		let length = Math.sqrt(x * x + y * y);

		if (length === 0) {
			dest.x = 0;
			dest.y = 0;

			return dest;
		}

		length = 1 / length;

		dest.x = x * length;
		dest.y = y * length;

		return dest;
	}

	public static mix(vector: Vec2, vector2: Vec2, time: number, dest?: Vec2): Vec2 {
		if (!dest) { dest = new Vec2(); }

		let x = vector.x;
		let y = vector.y;

		let x2 = vector2.x;
		let y2 = vector2.y;

		dest.x = x + time * (x2 - x);
		dest.y = y + time * (y2 - y);

		return dest;
	}

	public static sum(vector: Vec2, vector2: Vec2, dest?: Vec2): Vec2 {
		if (!dest) { dest = new Vec2(); }

		dest.x = vector.x + vector2.x;
		dest.y = vector.y + vector2.y;

		return dest;
	}

	public static difference(vector: Vec2, vector2: Vec2, dest?: Vec2): Vec2 {
		if (!dest) { dest = new Vec2(); }

		dest.x = vector.x - vector2.x;
		dest.y = vector.y - vector2.y;

		return dest;
	}

	public static product(vector: Vec2, vector2: Vec2, dest?: Vec2): Vec2 {
		if (!dest) { dest = new Vec2(); }

		dest.x = vector.x * vector2.x;
		dest.y = vector.y * vector2.y;

		return dest;
	}

	public static quotient(vector: Vec2, vector2: Vec2, dest?: Vec2): Vec2 {
		if (!dest) { dest = new Vec2(); }

		dest.x = vector.x / vector2.x;
		dest.y = vector.y / vector2.y;

		return dest;
	}

}



