// import { centroidN, centroidP } from "../test";
import { Utils } from "@h4x/common";
import { ClusterCentroid } from "./ClusterCentroid";

export class GlobalCluster {
	public readonly count: number;

	public readonly dataPoint = 0;
	public readonly scores: number[] = [];
	public readonly scoresRowPos: number[][] = [];
	public readonly scoresIndexes: { x: number; y: number }[] = [];
	public readonly scoresRowIndexes: { x: number; y: number }[][] = [];
	public readonly scoresY: number[] = [];

	private centroids: ClusterCentroid[] = [];

	private countPositive = 0;
	private countNegative = 0;

	constructor(public readonly data: { type: boolean; values: number[]; }[], public readonly balancedMode = true) {
		this.count = data[0].values.length;
		this.calculatePositions();
	}

	private calculatePositions() {
		const { count } = this;
		(this as any).dataPoint = 0;
		(this as any).countPositive = 0;
		(this as any).countNegative = 0;

		(this as any).scoresRowPos = [];
		(this as any).scoresIndexes = [];
		(this as any).scoresRowIndexes = [];

		for (let y = 0; y < count; y++) {
			this.scoresY.push(0);
			this.scoresRowPos.push([]);
			this.scoresRowIndexes.push([]);
		}
		let i = 0;
		for (let y = 0; y < count; y++) {
			for (let x = y + 1; x < count; x++) {
				(this as any).dataPoint++;
				this.scores.push(0);
				this.scoresRowPos[x].push(i);
				this.scoresRowIndexes[x].push({ x: x, y: y });
				this.scoresRowPos[y].push(i);
				this.scoresRowIndexes[y].push({ x: x, y: y });
				this.scoresIndexes.push({ x: x, y: y });
				i++;
			}
		}
		for (const dat of this.data) {
			if (dat.type === true) {
				this.countPositive++;
			} else {
				this.countNegative++;
			}
		}
	}

	public copy(target?: GlobalCluster) {
		if (target === undefined) { target = new GlobalCluster(this.data); } else {
			if (target.data.length !== this.data.length) {
				(target as any).data = this.data;
				target.calculatePositions();
			} else {
				(target as any).data = this.data;
			}
			target.centroids = [];
		}
		(target as any).count = this.count;
		for (const centroid of this.centroids) {
			target.addCentroid(centroid);
		}
		if (target.scores.length === this.scores.length) {
			for (let i = 0; i < this.scores.length; i++) {
				target.scores[i] = this.scores[i];
			}
		} else {
			(target as any).scores = [...this.scores];
		}
		if (target.scoresY.length === this.scoresY.length) {
			for (let i = 0; i < this.scoresY.length; i++) {
				target.scoresY[i] = this.scoresY[i];
			}
		} else {
			(target as any).scoresY = [...this.scoresY];
		}
		return target;
	}

	get globalScore() {
		return this.scores.reduce((p, c) => p + c, 0) / this.scores.length;
	}

	public addCentroid(type: boolean): void;
	public addCentroid(centroid: ClusterCentroid): void;
	public addCentroid(type: boolean | ClusterCentroid): void {
		if (typeof (type) === "boolean") {
			this.centroids.push(new ClusterCentroid(type, Utils.initArray(this.count, () => Math.random())));
		} else {
			if (type.values.length !== this.count) {
				throw new Error("Trying to add a centroid with invalid number of values!");
			}
			this.centroids.push(new ClusterCentroid(type.type, [...type.values]));
		}
	}

	public removeCentroid(centroid: ClusterCentroid) {
		let index = this.centroids.indexOf(centroid);
		if (index >= 0) {
			this.centroids.splice(this.centroids.indexOf(centroid), 1);
			return true;
		} else {
			return false;
		}
	}

	public getCentroid(id: number): ClusterCentroid | undefined {
		return this.centroids[id];
	}

	public getCentroids(): ClusterCentroid[] {
		return [...this.centroids];
	}

	private calculateScores(row?: number) {
		// unbox this.*
		const { count, scores } = this;

		if (this.centroids.length >= 1) {
			if (row === undefined) {
				let i = 0;
				for (let y = 0; y < count; y++) {
					for (let x = y + 1; x < count; x++) {
						let validFraction = this.calculateLocalScore(x, y);
						scores[i] = validFraction;
						i++;
					}
				}
			} else {
				let rowIndexes = this.scoresRowIndexes[row];
				for (let i = 0; i < rowIndexes.length; i++) {
					const pos = rowIndexes[i];
					let validFraction = this.calculateLocalScore(pos.x, pos.y);
					scores[this.scoresRowPos[row][i]] = validFraction;
				}
			}
		} else {
			for (let i = 0; i < this.scores.length; i++) {
				this.scores[i] = 0;
			}
		}
	}


	public calculateLocalScore(x: number, y: number) {
		const { data } = this;
		let validPositive = 0;
		let validNegative = 0;
		for (const dat of data) {
			const pX = dat.values[x];
			const pY = dat.values[y];

			let currentDist = Number.POSITIVE_INFINITY;
			let currentType = undefined as any as boolean;

			for (let c = 0; c < this.centroids.length; c++) {
				const centroid = this.centroids[c];
				let cX = centroid.values[x];
				let cY = centroid.values[y];

				let xDelta = cX - pX;
				let yDelta = cY - pY;

				let cDist = (xDelta * xDelta) + (yDelta * yDelta);
				if (cDist < currentDist) {
					currentDist = cDist;
					currentType = centroid.type;
				}
			}

			if (dat.type === currentType) {
				if (dat.type === true) {
					validPositive++;
				} else {
					validNegative++;
				}
			}
		}
		// return (validT + validF) / data.length;
		if (this.balancedMode === true) {
			return (validPositive / this.countPositive + validNegative / this.countNegative) / 2;
		} else {
			return (validPositive + validNegative) / (this.countPositive + this.countNegative);
		}
	}

	private calculateRowScores() {
		const { count, scores, scoresY } = this;
		for (let y = 0; y < this.count; y++) {
			this.scoresY[y] = 0;
		}

		let i = 0;
		for (let y = 0; y < count; y++) {
			for (let x = y + 1; x < count; x++) {
				let validFraction = scores[i];
				scoresY[y] += (validFraction) / count;
				scoresY[x] += (validFraction) / count;
				i++;
			}
		}
	}


	public getRowScores(row: number) {
		return this.scoresRowPos[row].map(x => this.scores[x]);
	}

	public calculate(row?: number) {
		if (row === undefined) {
			this.calculateScores();
			this.calculateRowScores();
		} else {
			this.calculateScores(row);
			this.calculateRowScores();

			/*let temp = [... this.scores];
			for (let i = 0; i < this.scores.length; i++) {
				this.scores[i] = 0;
			}
			this.calculateScores();

			for (let i = 0; i < this.scores.length; i++) {
				if (temp[i] !== this.scores[i]) {
					console.log("invalid", i, temp[i], this.scores[i], this.scoresIndexes[i]);
				}
			}*/
		}
	}

}
