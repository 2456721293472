/** Difficulty Score Definition */
export type DifficultyScoreType = {
	matterClustered: number,
	clusteringBonus: number,
	globalScoreBonus: number,
	shieldBonus: number,
	levelBonus: number,
	bezerkerKill: number
};

/** Difficulty Score Values */
export const DifficultyScoreDefaults: DifficultyScoreType = {
	matterClustered: 1000,
	clusteringBonus: 1500,
	globalScoreBonus: 5000,
	shieldBonus: 1500,
	levelBonus: 1000,
	bezerkerKill: 25000
};

/** Difficulty Base Definition */
export type DifficultyBaseType = {
	warpMinPercent: number,
	warpOutPercent: number,
	playerSpeed: number,
	playerTurnRate: number,
	chaserSpeedStart: number,
	chaserSpeedMax: number,
	chaserTurnRate: number,
	berzerkerDartSpeed: number,
	patrollerOneSpeed: number,
	patrollerTwoSpeed: number,
	patrollerThreeSpeed: number
	patrollerThreeMinDist: number,
	patrollerThreeMaxDist: number,
};

/** Difficulty Base Values */
export const DifficultyBaseDefaults: DifficultyBaseType = {
	warpMinPercent: 0.2,
	warpOutPercent: 0.6,
	playerSpeed: 750,
	playerTurnRate: 3,
	chaserSpeedStart: 250,
	chaserSpeedMax: 800,
	chaserTurnRate: 5,
	berzerkerDartSpeed: 2500,
	patrollerOneSpeed: 500,
	patrollerTwoSpeed: 500,
	patrollerThreeSpeed: 500,
	patrollerThreeMinDist: 100,
	patrollerThreeMaxDist: 500,
};

/** Holds data on game difficulty */
export type DifficultyConfigType = {
	firstEnemySpawnDelay: number[],
	enemySpawnDelay: number[],
	maxEnemies: number[],
	chaserSpawnChance: number[],
	chaserMaxNum: number[],
	chaserStartingSpeed: number[],
	chaserSpeedRate: number[],
	chaserDetectionRadius: number[],
	chaserDisableTime: number[],
	berzerkerSpawnChance: number[],
	berzerkerMaxNum: number[],
	berzerkerShakeTime: number[],
	berzerkerAttackDelay: number[],
	patrollerMaxNum: number[],
	patrollerOneSpawnChance: number[],
	patrollerOneSpeed: number[],
	patrollerTwoSpawnChance: number[],
	patrollerTwoSpeed: number[],
	patrollerThreeSpawnChance: number[],
	patrollerThreeSpeed: number[],
};

/** Default/Local difficulties the game will use */
export const DifficultyConfigDefaults: DifficultyConfigType = {
	firstEnemySpawnDelay: [10, 9, 9, 8, 8, 7, 6, 6, 5, 10, 10, 10, 9, 9, 9, 9, 8, 8, 8, 8, 7, 7, 7, 7, 6, 6, 6, 6, 5, 5, 5, 5, 4, 4, 4, 4, 3, 3, 3, 3, 2, 2, 2, 2, 1, 1, 1, 1, 0, 0],
	enemySpawnDelay: [10, 9, 9, 8, 8, 7, 6, 6, 5, 10, 10, 10, 9, 9, 9, 9, 8, 8, 8, 8, 8, 7, 7, 7, 7, 6, 6, 6, 6, 6, 5, 5, 5, 5, 4, 4, 4, 4, 3, 3, 3, 3, 3, 2, 2, 2, 2, 1, 1, 1],
	maxEnemies: [1, 2, 3, 1, 2, 4, 1, 3, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7, 7, 7, 7, 7, 8, 8, 8, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9, 9, 9, 9, 10, 10, 10, 10, 10],
	chaserSpawnChance: [1, 1, 1, 0, 0.8, 0.7, 0, 1, 1, 0.6, 0.6, 0.6, 0.6, 0.6, 0.6, 0.6, 0.6, 0.6, 0.6, 0.6, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4],
	chaserMaxNum: [1, 2, 3, 0, 2, 2, 0, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
	chaserStartingSpeed: [0.5, 0.5, 0.6, 0.6, 0.6, 0.7, 0.7, 0.7, 0.7, 0.8, 0.8, 0.8, 0.9, 0.9, 0.9, 1, 1, 1, 1.1, 1.1, 1.1, 1.1, 1.2, 1.2, 1.2, 1.3, 1.3, 1.3, 1.4, 1.4, 1.4, 1.4, 1.5, 1.5, 1.5, 1.6, 1.6, 1.6, 1.7, 1.7, 1.7, 1.8, 1.8, 1.8, 1.8, 1.9, 1.9, 1.9, 2, 2],
	chaserSpeedRate: [30, 29, 29, 28, 28, 27, 27, 26, 26, 25, 25, 24, 24, 23, 23, 22, 22, 21, 21, 20, 20, 19, 19, 18, 18, 17, 17, 16, 16, 15, 15, 14, 14, 13, 13, 12, 12, 11, 11, 10, 10, 9, 9, 8, 8, 7, 7, 6, 6, 5],
	chaserDetectionRadius: [0.3, 0.3, 0.4, 0.4, 0.4, 0.4, 0.5, 0.5, 0.5, 0.3, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.6, 0.6, 0.6, 0.6, 0.6, 0.6, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 1, 1, 1, 1],
	chaserDisableTime: [2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0],
	berzerkerSpawnChance: [0, 0, 0, 1, 0.2, 0.3, 0, 0, 0, 0.2, 0.3, 0.3, 0.4, 0.4, 0.5, 0.5, 0.4, 0.4, 0.3, 0.3, 0.2, 0.3, 0.3, 0.4, 0.4, 0.5, 0.5, 0.4, 0.4, 0.3, 0.3, 0.2, 0.3, 0.3, 0.4, 0.4, 0.5, 0.5, 0.4, 0.4, 0.3, 0.3, 0.2, 0.3, 0.3, 0.4, 0.5, 0.4, 0.3, 0.2],
	berzerkerMaxNum: [0, 0, 0, 1, 1, 2, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
	berzerkerShakeTime: [2, 2, 2, 2, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.4, 1.4, 1.4, 1.3, 1.3, 1.3, 1.3, 1.2, 1.2, 1.2, 1.1, 1.1, 1.1, 1, 1, 1, 0.9, 0.9, 0.9, 0.8, 0.8, 0.8, 0.8, 0.7, 0.7, 0.7, 0.6, 0.6, 0.6, 0.5, 0.5, 0.5, 0.4, 0.4, 0.4, 0.3, 0.3, 0.3, 0.3],
	berzerkerAttackDelay: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 9.9, 9.8, 9.6, 9.5, 9.4, 9.3, 9.1, 9, 8.9, 8.8, 8.6, 8.5, 8.4, 8.3, 8.1, 8, 7.9, 7.8, 7.6, 7.5, 7.4, 7.3, 7.1, 7, 6.9, 6.8, 6.6, 6.5, 6.4, 6.3, 6.1, 6, 5.9, 5.8, 5.6, 5.5, 5.4, 5.3, 5.1, 5],
	patrollerOneSpawnChance: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1],
	patrollerOneSpeed: [0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0.5, 0.5, 0.6, 0.6, 0.7, 0.7, 0.7, 0.8, 0.8, 0.8, 0.9, 0.9, 1, 1, 1, 1.1, 1.1, 1.1, 1.2, 1.2, 1.3, 1.3, 1.3, 1.4, 1.4, 1.4, 1.5, 1.5, 1.6, 1.6, 1.6, 1.7, 1.7, 1.7, 1.8, 1.8, 1.9, 1.9, 1.9, 2, 2],
	patrollerTwoSpawnChance: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1],
	patrollerTwoSpeed: [0, 0, 0, 0, 0, 0, 0, 0.5, 0.5, 0.6, 0.6, 0.6, 0.7, 0.7, 0.8, 0.8, 0.8, 0.9, 0.9, 0.9, 1, 1, 1, 1.1, 1.1, 1.1, 1.2, 1.2, 1.3, 1.3, 1.3, 1.4, 1.4, 1.4, 1.5, 1.5, 1.5, 1.6, 1.6, 1.6, 1.7, 1.7, 1.8, 1.8, 1.8, 1.9, 1.9, 1.9, 2, 2],
	patrollerThreeSpawnChance: [0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],
	patrollerThreeSpeed: [0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0.5, 0.6, 0.6, 0.6, 0.7, 0.7, 0.8, 0.8, 0.8, 0.9, 0.9, 0.9, 1, 1, 1, 1.1, 1.1, 1.2, 1.2, 1.2, 1.3, 1.3, 1.3, 1.4, 1.4, 1.5, 1.5, 1.5, 1.6, 1.6, 1.6, 1.7, 1.7, 1.7, 1.8, 1.8, 1.9, 1.9, 1.9, 2, 2],
	patrollerMaxNum: [0, 0, 0, 0, 0, 0, 1, 2, 2, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4],
};
