import { Entity, EntityType } from "../core/Entities/Entity";
import { Scene } from "../core/Scene";
import pixi = require("pixi.js");
import { Colors } from "../utils/Colors";
import { Vec2 } from "@h4x/common";
import { Globals } from "../Gameplay/Constants";
import { DifficultyManager } from "../Gameplay/DifficultyManager";
import { SoundManager } from "../core/SoundManager";

// Warp Properties
// const WARP_OFFSET = 0;
const WARP_OFFSET = 50;
const WARP_MAX_LENGTH = 200;
const WARP_CAP_RADIUS = 7.5;
const WARP_BONUS_COLOR = Colors.ClusterNegative;

// Shield Properties
const SHIELD_FADE_TIME_SEC = 1;

export class SectorPlayerUIEntity extends Entity {
	public type = EntityType.SectorPlayerUI;

	// Warp UI Properties
	private wuCurrentPercent: number = 0.25;

	// Warp UI Graphics
	private wuContainer: pixi.Container;
	private wuLeftLine: pixi.Graphics;
	private wuLeftCap: pixi.Graphics;
	private wuLeftThresholdCap: pixi.Graphics;
	private wuLeftBonusCap: pixi.Graphics;
	private wuLeftBonusArea: pixi.Graphics;
	private wuRightLine: pixi.Graphics;
	private wuRightCap: pixi.Graphics;
	private wuRightThresholdCap: pixi.Graphics;
	private wuRightBonusCap: pixi.Graphics;
	private wuRightBonusArea: pixi.Graphics;

	// Shield Properties
	private shFadeTTL: number = 0;

	// Shield Graphics
	private shCotnainer: pixi.Container;

	// Sound Mode Flag
	private isAboveThreshold: boolean = false;
	private hasPlayedAboveOnce: boolean = false;

	constructor(scene: Scene, x: number, y: number) {
		super(scene, x, y);

		this.isOverlay = true;

		// Create Player UI Containers
		this.createWarpUIGraphics();

		// Create Player Shield Containers
		this.shCotnainer = new pixi.Container();
		this.graphicsContainer.addChild(this.shCotnainer);
	}

	// Create the warp UI
	private createWarpUIGraphics() {
		// Setup Lines
		this.wuLeftLine = new pixi.Graphics();
		this.wuRightLine = new pixi.Graphics();

		// Setup Caps
		this.wuLeftCap = new pixi.Graphics();
		this.wuLeftCap.beginFill(Colors.White);
		this.wuLeftCap.drawCircle(0, 0, WARP_CAP_RADIUS);
		this.wuLeftCap.endFill();
		this.wuRightCap = this.wuLeftCap.clone();

		// Setup Arcs
		this.wuLeftThresholdCap = new pixi.Graphics();
		this.wuLeftThresholdCap.lineStyle(6, Colors.White);
		this.wuLeftThresholdCap.arc(0, 0, 10, (Math.PI / 2) + 0.2, (Math.PI * 1.5) - 0.2);

		this.wuRightThresholdCap = this.wuLeftThresholdCap.clone();
		this.wuRightThresholdCap.rotation = Math.PI;

		// Setup Bonus Area Indicators
		this.wuLeftBonusArea = new pixi.Graphics();
		this.wuLeftBonusArea.visible = false;

		this.wuRightBonusArea = new pixi.Graphics();
		this.wuRightBonusArea.visible = false;

		// Setup Bonus Caps
		this.wuLeftBonusCap = new pixi.Graphics();
		this.wuLeftBonusCap.beginFill(WARP_BONUS_COLOR);
		this.wuLeftBonusCap.arc(0, 0, 12.5, Math.PI / 2, Math.PI * 1.5);
		this.wuLeftBonusCap.endFill();
		this.wuLeftBonusCap.visible = false;

		this.wuRightBonusCap = this.wuLeftBonusCap.clone();
		this.wuRightBonusCap.rotation = Math.PI;

		// Create Container
		this.wuContainer = new pixi.Container();

		// Add all objects to the container
		this.wuContainer.addChild(this.wuLeftThresholdCap);
		this.wuContainer.addChild(this.wuRightThresholdCap);
		this.wuContainer.addChild(this.wuLeftBonusArea);
		this.wuContainer.addChild(this.wuRightBonusArea);
		this.wuContainer.addChild(this.wuLeftBonusCap);
		this.wuContainer.addChild(this.wuRightBonusCap);
		this.wuContainer.addChild(this.wuLeftLine);
		this.wuContainer.addChild(this.wuRightLine);
		this.wuContainer.addChild(this.wuLeftCap);
		this.wuContainer.addChild(this.wuRightCap);

		// Add container to parent
		this.graphicsContainer.addChild(this.wuContainer);

		this.redrawWarpUI();
	}

	private redrawWarpUI() {
		let warpMinPercent = DifficultyManager.getWarpMinPercent();
		let thresholdPercent = DifficultyManager.getWarpOutPercent() - warpMinPercent;

		let currentPercent = Math.max(this.wuCurrentPercent - warpMinPercent, 0);
		// let thresholdPerent = Math.max(this.wuThresholdPercent - WARP_PERCENT_OFFSET, 0);

		// Calculate Current Dinstance
		let maxDist = (WARP_MAX_LENGTH * thresholdPercent) + WARP_OFFSET;
		let actualDist = (WARP_MAX_LENGTH * currentPercent) + WARP_OFFSET + 0.0001;
		let currentDist = Math.min(maxDist, actualDist);

		// Draw Lines
		this.wuLeftLine.clear();
		this.wuLeftLine.lineStyle(8, Colors.White);
		this.wuLeftLine.moveTo(-WARP_OFFSET, 0);
		this.wuLeftLine.lineTo(-currentDist, 0);

		this.wuRightLine.clear();
		this.wuRightLine.lineStyle(8, Colors.White);
		this.wuRightLine.moveTo(WARP_OFFSET, 0);
		this.wuRightLine.lineTo(currentDist, 0);

		// Place Line Caps
		this.wuLeftCap.position.x = -(currentDist);
		this.wuRightCap.position.x = (currentDist);

		// Place Max Arcs
		this.wuLeftThresholdCap.position.x = -maxDist;
		this.wuRightThresholdCap.position.x = maxDist;

		// If we haven't crossed the threshold, then no need to do anything further
		if (currentPercent < thresholdPercent) {
			this.wuLeftBonusArea.visible = false;
			this.wuRightBonusArea.visible = false;
			this.wuLeftBonusCap.visible = false;
			this.wuRightBonusCap.visible = false;
			this.wuLeftThresholdCap.tint = Colors.White;
			this.wuRightThresholdCap.tint = Colors.White;

			// Sound
			if (!this.isAboveThreshold && this.hasPlayedAboveOnce) {
				SoundManager.ClusterScoreAbove.play();
				this.isAboveThreshold = true;
			}
			return;
		}

		// Sound
		if (this.isAboveThreshold || this.hasPlayedAboveOnce === false) {
			SoundManager.ClusterScoreBelow.play();
			this.isAboveThreshold = false;
			this.hasPlayedAboveOnce = true;
		}

		// Enable Elements
		this.wuLeftBonusArea.visible = true;
		this.wuRightBonusArea.visible = true;
		this.wuLeftBonusCap.visible = true;
		this.wuRightBonusCap.visible = true;

		// Set tint colors
		this.wuLeftThresholdCap.tint = WARP_BONUS_COLOR;
		this.wuRightThresholdCap.tint = WARP_BONUS_COLOR;

		// Calc Properties
		let bonusDist = (actualDist - currentDist);

		// Draw Bonus Area
		this.wuLeftBonusArea.clear();
		this.wuRightBonusArea.clear();

		// Calc Left Bonus Area
		this.wuLeftBonusArea.beginFill(WARP_BONUS_COLOR);
		this.wuLeftBonusArea.drawRect(-actualDist, -12.5, bonusDist, 25);
		this.wuLeftBonusArea.endFill();

		// Calc Right Bonus Area
		this.wuRightBonusArea.beginFill(WARP_BONUS_COLOR);
		this.wuRightBonusArea.drawRect(currentDist, -12.5, bonusDist, 25);
		this.wuRightBonusArea.endFill();

		// Place Line Caps
		this.wuLeftBonusCap.x = -actualDist;
		this.wuRightBonusCap.x = actualDist;
	}

	public update(dt: number) {
		// Shield Logic
		if (this.shFadeTTL <= 0) {
			return;
		}

		this.shFadeTTL -= dt;

		let alphaPerc = Math.max(this.shFadeTTL / SHIELD_FADE_TIME_SEC, 0);
		this.shCotnainer.alpha = alphaPerc;
	}

	/** Set the current percentage of the warp power scale */
	public setWarpCurrentPercent(percent: number) {
		this.wuCurrentPercent = percent;

		this.wuCurrentPercent = Math.min(1, this.wuCurrentPercent);
		this.wuCurrentPercent = Math.max(0, this.wuCurrentPercent);

		this.redrawWarpUI();
	}

	/** Get the current percentage of the warp power scale */
	public getWarpCurrentPercent(): number {
		return this.wuCurrentPercent;
	}

	/** Show the player shields at their current health value from a specific direction */
	public async showShields(currentHealth: number, dirOfDamage: Vec2) {
		if (currentHealth <= 0) {
			return;
		}

		const radius = 75;

		// Clear Current Container
		this.shCotnainer.removeChildren();

		// Create Damage Indicator (debug)
		// let ind = new pixi.Graphics();
		// ind.beginFill(Colors.Yellow);
		// ind.drawCircle(0, 0, 7.5);
		// ind.endFill();

		// let indPos = dirOfDamage.copy().multiplyScalar(radius);
		// ind.x = indPos.x;
		// ind.y = indPos.y;

		// this.shCotnainer.addChild(ind);

		// Math Arc
		let dmgPerc = 1 - (currentHealth / Globals.SECTOR_PLAYER_SHIELD);
		let halfArc = (dmgPerc * (Math.PI * 2)) / 2;

		// Create Arc
		let gfx = new pixi.Graphics();
		gfx.lineStyle(5, Colors.PlayerShields);
		gfx.arc(0, 0, radius, 0 + halfArc, -0.0001 - halfArc);
		gfx.rotation = Math.atan2(dirOfDamage.y, dirOfDamage.x) + Math.PI;

		this.shCotnainer.addChild(gfx);

		// Set this object ready to be faded out
		this.shCotnainer.alpha = 1;
		this.shFadeTTL = SHIELD_FADE_TIME_SEC;
	}
}
