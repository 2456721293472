import { Scene } from "../../../core/Scene";
import { App } from "../../../app";
import { EnvironmentContainer } from "../../../containers/EnvironmentContainer";
import { UIText } from "../../../core/UI/UIText";
import { Colors } from "../../../utils/Colors";
import { UIContainer } from "../../../core/UI/UIContainer";
import { UIPositionAlign, UIAlign } from "../../../core/UIHelpers";
import { UIBorder, UIBorderMode } from "../../../core/UI/UIBorder";
import { UIHorizontalSlider } from "../../../core/UI/UIHorizontalSlider";
import { SoundManager } from "../../../core/SoundManager";
import { UITextButton } from "../../../core/UI/UITextButton";
import { ScenesManager } from "../../../core/SceneManager";

export class NewUISoundScene extends Scene {

	public async init() {
		this.backgroundContainer.addChild(new EnvironmentContainer());
		this.uiManager.add(new UIBorder({ borderColor: Colors.Orange, borderWidth: 5, borderMode: UIBorderMode.Inner }));

		this.uiManager.add(new UITextButton("Back", { x: new UIPositionAlign(UIAlign.center, 10), y: 10, width: 150 }))
			.onClick.addCallback(() => {
				ScenesManager.changeScene(App.newUIScene);
			});

		let menuContainer = this.uiManager.add(new UIContainer({
			x: new UIPositionAlign(UIAlign.center),
			y: 110,
			height: 150,
			width: this.uiManager.width - 20,
			backgroundColor: Colors.Gray
		}));
		console.log("1 this.uiManager.width - 20", this.uiManager.width - 20);
		menuContainer.onResizeEvent.addCallback((width, height) => {
			menuContainer.setWidth(width - 20);
			console.log("2 this.uiManager.width - 20", this.uiManager.width - 20);
			console.log("2 width - 20", width - 20);
		});

		menuContainer.add(new UIText("Sound Volume", { color: 0xFFFFFF, x: new UIPositionAlign(UIAlign.center), y: 10 }));

		let margin = 10;
		let padding = 10;
		let elements = 3; // this setup will only work with 2/3 elements anyway
		padding *= (elements - 1) / elements;
		{
			let sliderContainer = menuContainer.add(new UIContainer({
				x: new UIPositionAlign(UIAlign.left, margin),
				y: 60,
				width: ((menuContainer.width - margin * 2) / elements - padding),
				height: 80,
				backgroundColor: Colors.DarkGray
			}));

			sliderContainer.add(new UIText("Global", { color: 0xFFFFFF, x: new UIPositionAlign(UIAlign.left), y: 10 }));

			let sliderText = sliderContainer.add(new UIText("??.?%", { color: 0xFFFFFF, x: new UIPositionAlign(UIAlign.right), y: 10 }));

			let slider = new UIHorizontalSlider({ value: SoundManager.volume, y: 40 });
			slider.onValueChanged.addCallback((value) => {
				sliderText.text = (value * 100).toFixed(1) + "%";
				SoundManager.setVolumeMultiplayer(value);
			});
			sliderContainer.add(slider);
			menuContainer.onResizeEvent.addCallback(() => { sliderContainer.setWidth(((menuContainer.width - margin * 2) / elements - padding)); });
		}

		{
			let sliderContainer = menuContainer.add(new UIContainer({
				x: new UIPositionAlign(UIAlign.center),
				y: 60,
				width: ((menuContainer.width - margin * 2) / elements - padding),
				height: 80,
				backgroundColor: Colors.DarkGray
			}));

			sliderContainer.add(new UIText("Effects", { color: 0xFFFFFF, x: new UIPositionAlign(UIAlign.left), y: 10 }));

			let sliderText = sliderContainer.add(new UIText("??.?%", { color: 0xFFFFFF, x: new UIPositionAlign(UIAlign.right), y: 10 }));

			let slider = new UIHorizontalSlider({ value: SoundManager.Effects.volume, y: 40 });
			slider.onValueChanged.addCallback((value) => {
				sliderText.text = (value * 100).toFixed(1) + "%";
				SoundManager.Effects.setVolumeMultiplier(value);
			});
			sliderContainer.add(slider);
			menuContainer.onResizeEvent.addCallback(() => { sliderContainer.setWidth(((menuContainer.width - margin * 2) / elements - padding)); });
		}

		{
			let sliderContainer = menuContainer.add(new UIContainer({
				x: new UIPositionAlign(UIAlign.right, margin),
				y: 60,
				width: ((menuContainer.width - margin * 2) / elements - padding),
				height: 80,
				backgroundColor: Colors.DarkGray
			}));

			sliderContainer.add(new UIText("Background", { color: 0xFFFFFF, x: new UIPositionAlign(UIAlign.left), y: 10 }));

			let sliderText = sliderContainer.add(new UIText("??.?%", { color: 0xFFFFFF, x: new UIPositionAlign(UIAlign.right), y: 10 }));

			let slider = new UIHorizontalSlider({ value: SoundManager.Background.volume, y: 40 });
			slider.onValueChanged.addCallback((value) => {
				sliderText.text = (value * 100).toFixed(1) + "%";
				SoundManager.Background.setVolumeMultiplier(value);
			});
			sliderContainer.add(slider);
			menuContainer.onResizeEvent.addCallback(() => { sliderContainer.setWidth(((menuContainer.width - margin * 2) / elements - padding)); });
		}


		let soundContainer = this.uiManager.add(new UIContainer({
			x: new UIPositionAlign(UIAlign.center),
			y: 300,
			height: 250,
			width: this.uiManager.width - 20,
			backgroundColor: Colors.Gray
		}));
		soundContainer.onResizeEvent.addCallback((width, height) => {
			soundContainer.setWidth(width - 20);
			console.log("2 this.uiManager.width - 20", this.uiManager.width - 20);
			console.log("2 width - 20", width - 20);
		});

		{

			soundContainer.add(new UITextButton("TestBomb (background)", {
				color: 0xFFFFFF,
				x: new UIPositionAlign(UIAlign.left, 10),
				y: 10,
				width: 300,
				height: 40
			})).onClick.addCallback(() => {
				SoundManager.TestBomb.play();
			});

			soundContainer.add(new UITextButton("TestExplosion (effect)", {
				color: 0xFFFFFF,
				x: new UIPositionAlign(UIAlign.left, 10),
				y: 60,
				width: 300,
				height: 40
			})).onClick.addCallback(() => {
				SoundManager.TestExplosion.play();
			});
		}

	}
}
