import { UISizeDynamic } from "../../../core/UIHelpers";

export const HUD_HEIGHT = new UISizeDynamic([
	{ height: 600, size: 70 },
	{ height: 450, size: 53 },
	{ height: 300, size: 35 }
], { interpolate: true, floor: false });

export const HUD_PANEL_MARGIN = new UISizeDynamic([
	{ height: 600, size: 50 },
	{ height: 450, size: 38 },
	{ height: 300, size: 25 }
], { interpolate: true, floor: false });

export const HUD_PANEL_OFFSET = new UISizeDynamic([
	{ height: 600, size: 45 },
	{ height: 450, size: 30 },
	{ height: 300, size: 20 }
], { interpolate: true, floor: false });

export const HUD_SCALE = new UISizeDynamic([
	{ height: 600, size: 1 },
	{ height: 450, size: 0.75 },
	{ height: 300, size: 0.5 }
], { interpolate: true, floor: false });

export const PIP_RADIUS = 25;

export const HUD_SHIELD_SIZE = new UISizeDynamic([
	{ height: 600, size: PIP_RADIUS * 2 },
	{ height: 450, size: PIP_RADIUS * 1.5 },
	{ height: 300, size: PIP_RADIUS }
], { interpolate: true, floor: false });

export const HUD_SCORE_OFFSET = new UISizeDynamic([
	{ height: 600, size: 350 },
	{ height: 450, size: 250 },
	{ height: 300, size: 160 }
], { interpolate: true, floor: false });

export const HUD_SCORE_FONTSIZE = new UISizeDynamic([
	{ height: 600, size: 42 },
	{ height: 450, size: 36 },
	{ height: 300, size: 24 }
], { interpolate: true, floor: true });

/* HUD Indicator sizes */

export const HUD_INDICATOR_WIDTH = new UISizeDynamic([
	{ height: 600, size: 35 },
	{ height: 300, size: 18 }
], { interpolate: true, floor: false });

export const HUD_BONUS_FONTSIZE = new UISizeDynamic([
	{ height: 600, size: 24 },
	{ height: 450, size: 18 },
	{ height: 300, size: 14 }
], { interpolate: true, floor: true });

export const HUD_HASH_OFFSET = new UISizeDynamic([
	{ height: 600, size: 7 },
	{ height: 450, size: 5 },
	{ height: 300, size: 3 }
], { interpolate: true, floor: false });
